import React from "react";

const renderBanner = (src, i) => (
  <div className="bannerImage">
    <img src={src} alt={`banner${i + 1}`} width="100%" height="auto" />
  </div>
);

const Banners = ({ data }) => {
  return Object.values(data)
    .slice(0, 2)
    .map((banner, i) => (
      <div key={`banner${i.toString()}`}>
        {banner?.field_link?.uri ? (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={banner?.field_link?.uri || "#"}
          >
            {renderBanner(banner.field_image.url, i)}
          </a>
        ) : (
          renderBanner(banner.field_image.url, i)
        )}
      </div>
    ));
};

export default Banners;
