import React, { useState } from "react";
import { Row, Col, Input, Spin, Form } from "antd";
import { useTranslation } from "react-i18next";

import Modal from "../../shared/Modal";
import PhoneInput from "../../shared/formItems/PhoneInput";

import { defaultLabelText, requiredField } from "../../../utils/formUtils";

const EditMobileLogin = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [phoneText, setPhoneText] = useState(defaultLabelText);
  const [personalCodeText, setPersonalCodeText] = useState(defaultLabelText);

  return (
    <>
      <Modal
        width="48.125rem"
        className="modal personal-information-action blue editMobileId"
        title={t("accountEditMobileIdLoginTitle")}
        visible={props.modalVisible}
        okText={t("save")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          form.resetFields();
          props.onModalClose(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setLoading(true);
              const result = await props.changeAccount({
                personal_code: values.personalCode,
                mobile_id_phone_number: values.phoneCode + values.phoneNumber,
              });
              setLoading(false);

              if (result.error) {
                form.setFields([
                  {
                    name: "personal_code",
                    errors: [t("accountChangeMobileIdError")],
                  },
                ]);
              } else {
                props.onModalSave();
                form.resetFields();
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Spin spinning={loading} tip={t("loading") + "..."}>
          <Row className="description">
            <Col className="text" span={24}>
              <p>{t("editMobileExplanationText")}</p>
            </Col>
          </Row>
          <Row className="current">
            <Col span={12}>
              <label>{t("accountCurrentPersonalCode")}</label>
              <p>{props.currentPersonalCode || "—"}</p>
            </Col>
            <Col span={12}>
              <label>{t("accountCurrentPhone")}</label>
              <p>{props.currentPhone || "—"}</p>
            </Col>
          </Row>
          <Row className="action">
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                initialValues={{
                  phoneCode: "+370",
                }}
              >
                <Row>
                  <Col span={10}>
                    <Form.Item
                      label={personalCodeText}
                      name="personalCode"
                      rules={[
                        {
                          required: true,
                          message: t("personalCode") + " " + t("isRequired"),
                        },
                      ]}
                    >
                      <Input
                        onChange={(event) => {
                          const { value } = event.target;

                          form.setFieldsValue({
                            personalCode: value,
                          });

                          setPersonalCodeText(
                            event.target.value
                              ? t("accountNewPersonalCode")
                              : defaultLabelText
                          );
                        }}
                        placeholder={requiredField(t("personalCode"))}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={1} />
                  <Col span={13}>
                    <PhoneInput
                      countriesPhoneCodes={props.countriesWithPhoneCodes}
                      phoneText={phoneText}
                      setPhoneText={setPhoneText}
                      placeholder={t("phoneNumber")}
                      form={form}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default EditMobileLogin;
