import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login";
import ResetPassword from "./pages/resetPassword";
import ResetPasswordConfirm from "./pages/resetPasswordConfirm";
import PasswordChanger from "./pages/PasswordChanger";
import InvoiceVerificationConfirmation from "./pages/invoiceVerificationConfirmation";
import FinancingRequestFormRouter from "./pages/FinancingRequestFormRouter";
import BankStatement from "./pages/BankStatement";
import PartnerRouter from "./pages/PartnerRouter";
import pipedriveRequest from "./pages/pipedriveRequest";
import Dashboard from "./pages/dashboard";
import Buyer from "./pages/buyer";
import Account from "./pages/account";
import InvoiceUpload from "./pages/factoring/upload/invoiceUpload";
import NotFoundPage from "./pages/NotFoundPage";
import ExpiredLink from "./pages/ExpiredLink";
import FaqPage from "./pages/FaqPage";
import Page from "./pages/base/Page";
import ScrollToTopOnPageRender from "./utils/ScrollToTopOnPageRender";

import LegacyBrowserMessage from "./components/shared/legacyBrowserMessage";

import PublicRoute from "./components/routes/publicRoute";
import PrivateRoute from "./components/routes/privateRoute";

import { useTranslation } from "react-i18next";
import "./assets/scss/styles.scss";

import {
  getFromLocalStorage,
  getSessionToken,
  saveToLocalStorage,
  saveSessionToken,
  clearLocalStorage,
  clearSessionStorage,
  initialState,
  logoutUser,
} from "./services/userService";

import { productType, getActiveParty, getActiveProduct } from "./utils/partiesUtils";
import { CONTRACT_TYPES, PRODUCT_TYPES } from "services/formService";
import GeneralDashboard from "pages/general/dashboard/dashboard";

export const AuthContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      const activeParty = getActiveParty(action.payload.parties);
      const activeProduct = getActiveProduct(activeParty);

      saveSessionToken(action.payload.token);
      saveToLocalStorage(action.payload);

      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        parties: action.payload.parties,
        assignedManager: action.payload.assignedManager,
        token: action.payload.token,
        activePartyFid: activeParty?.fid,
        activeProductFid: activeProduct?.fid,
        productType: activeProduct?.type?.toLowerCase(),
      };
    case "LOGOUT":
      async function logout() {
        await logoutUser();
      }

      logout();
      clearLocalStorage();
      clearSessionStorage();
      return {
        ...state,
        isAuthenticated: false,
        isEmptySsp: false,
        parties: null,
        assignedManager: null,
        user: null,
        activePartyFid: null,
        activeProductFid: null,
        selectedContracts: null,
        reason: action.payload && action.payload.reason ? action.payload.reason : null,
      };

    case "SET_EMPTY_SSP":
      return {
        ...state,
        isEmptySsp: true,
      };
    case "SELECT_PARTY":
      const localStorage = getFromLocalStorage();
      localStorage.parties = action.payload.parties;
      localStorage.assignedManager = action.payload.assignedManager;
      saveToLocalStorage(localStorage);

      return {
        ...state,
        activePartyFid: action.payload.activePartyFid,
        activeProductFid: action.payload.activeProductFid,
        parties: action.payload.parties,
        productType: action.payload.productType,
        assignedManager: action.payload.assignedManager,
        selectedContracts: null,
        isEmptySsp: false,
      };
    case "SELECT_CONTRACTS":
      return {
        ...state,
        selectedContracts: action.payload.selectedContracts,
      };
    case "SET_TERMS_CONDITIONS_ACCEPTED": {
      const localStorage = getFromLocalStorage();
      localStorage.user.termsConditionsAccepted = true;
      saveToLocalStorage(localStorage);

      return {
        ...state,
        user: { ...state.user, termsConditionsAccepted: true },
      };
    }
    case "SELECT_PRODUCT": {
      const localStorage = getFromLocalStorage();
      localStorage.parties = action.payload.parties;
      saveToLocalStorage(localStorage);

      return {
        ...state,
        activeProductFid: action.payload.activeProductFid,
        productType: action.payload.productType,
        parties: action.payload.parties,
        selectedContracts: null,
        isEmptySsp: false,
      };
    }
    case "SELECT_LANGUAGE": {
      return {
        ...state,
        user: { ...state.user, language: action.payload.language },
      };
    }
    case "SHOW_SERVICE_UNAVAILABLE_MSG": {
      return {
        ...state,
        isServiceUnavailable: true,
      }
    }
    case "HIDE_SERVICE_UNAVAILABLE_MSG": {
      return {
        ...state,
        isServiceUnavailable: false,
      }
    }
    default:
      return state;
  }
};

function App(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { t } = useTranslation();

  React.useEffect(() => {
    const token = getSessionToken();
    const { user, parties, assignedManager } = getFromLocalStorage();

    if (user && token && parties) {
      const activeParty = getActiveParty(parties);
      const activeProduct = getActiveProduct(activeParty);

      dispatch({
        type: "LOGIN",
        payload: {
          user,
          parties: parties,
          assignedManager: assignedManager,
          token,
          activePartyFid: activeParty?.fid,
          activeProductFid: activeProduct?.fid,
          productType: activeProduct?.type?.toLowerCase(),
        },
      });
    }
  }, []);

  return (
    <>
      <AuthContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <Router>
          <ScrollToTopOnPageRender />
          <LegacyBrowserMessage />
          <Switch>
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/resetPassword" component={ResetPassword} />
            <PublicRoute path="/resetPasswordConfirm" component={ResetPasswordConfirm} />
            <PublicRoute path="/password-change/:token" component={PasswordChanger} formType="password-change" />
            <PublicRoute
              path="/invoiceVerification/:key"
              component={InvoiceVerificationConfirmation}
              formType="invoice-verification"
            />
            <PublicRoute path="/formRequest/:hash?" component={pipedriveRequest} formType="pipedrive-request" />
            <PublicRoute
              path="/partner/prefill/:originatorPrivateToken/:contractType?"
              component={PartnerRouter}
              formType="partner"
              exact
            />
            <PublicRoute
              path="/partner/:originatorToken/:contractType?"
              component={PartnerRouter}
              formType="partner"
              exact
            />
            <PublicRoute
              path="/leasingRequest/business"
              component={FinancingRequestFormRouter}
              formType="leasing-request"
              formSubtype={CONTRACT_TYPES.BUSINESS}
              productType={PRODUCT_TYPES.LEASING}
              exact
            />
            <PublicRoute
              path="/leasingRequest/partner/(prefill)?/:formType"
              component={FinancingRequestFormRouter}
              formType="leasing-request"
              productType={PRODUCT_TYPES.LEASING}
              exact
            />
            <PublicRoute
              path="/leasingRequest/:applicationToken"
              component={FinancingRequestFormRouter}
              formType="leasing-request"
              productType={PRODUCT_TYPES.LEASING}
              exact
            />
            <PublicRoute
              path="/leasingRequest"
              component={FinancingRequestFormRouter}
              formType="leasing-request"
              formSubtype={CONTRACT_TYPES.PRIVATE}
              productType={PRODUCT_TYPES.LEASING}
            />
            <PublicRoute
              path="/loanRequest/partner/(prefill)?/:formType"
              component={FinancingRequestFormRouter}
              formType="leasing-request"
              productType={PRODUCT_TYPES.LOAN}
              exact
            />
            <PublicRoute
              path="/loanRequest/:applicationToken"
              component={FinancingRequestFormRouter}
              formType="leasing-request"
              formSubtype={CONTRACT_TYPES.PRIVATE}
              productType={PRODUCT_TYPES.LOAN}
              exact
            />
            <PublicRoute
              path="/loanRequest"
              component={FinancingRequestFormRouter}
              formType="leasing-request"
              formSubtype={CONTRACT_TYPES.PRIVATE}
              productType={PRODUCT_TYPES.LOAN}
            />
            <PublicRoute
              path="/test/:applicationToken"
              component={FinancingRequestFormRouter}
              formType="leasing-request"
              formSubtype="test"
            />
            <PublicRoute path="/statement" component={BankStatement} formType="bank-statement" exact />
            <PrivateRoute exact authed={state.isAuthenticated} path="/" component={Dashboard} />
            <PrivateRoute
              authed={state.isAuthenticated}
              path={"/general/dashboard"}
              component={GeneralDashboard}
              page={productType.General}
            />
            <PrivateRoute
              authed={state.isAuthenticated}
              path={`/${productType.Factoring}`}
              component={Page}
              page={productType.Factoring}
            />
            <PrivateRoute
              authed={state.isAuthenticated}
              path={`/${productType.Loan}`}
              component={Page}
              page={productType.Loan}
            />
            <PrivateRoute authed={state.isAuthenticated} title={t("buyer")} path="/buyer/:id" component={Buyer} />
            <PrivateRoute authed={state.isAuthenticated} title={t("userAccount")} path="/account" component={Account} />
            <PrivateRoute
              authed={state.isAuthenticated}
              title={t("uploadInvoices")}
              path="/invoiceUpload"
              component={InvoiceUpload}
            />
            <PrivateRoute authed={state.isAuthenticated} title={t("faq")} path="/faq" component={FaqPage} />
            <Route path="/expired" component={ExpiredLink} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
      </AuthContext.Provider>
    </>
  );
}

export default App;
