import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Input from "components/shared/form/Input";
import HiddenInput from "components/shared/form/HiddenInput";

import { generateTranslationString } from "utils/helpers";
import { APPLICANT_TYPES, PRODUCT_TYPES } from "services/formService";

const AssetInformationDisplayBlock = ({ formInstance, productType }) => {
  const { t } = useTranslation();

  const [dynamicFieldContent, populateDynamicFields] = useState();

  useEffect(() => {
    if (formInstance.getFieldValue(["lending", "is_property_selected"])) {
      populateDynamicFields(constructTable());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldSetMandatoryOnly = [
    {
      name: ["lending", "brand"],
      label: t("g4rFormBrand"),
    },
    {
      name: ["lending", "model"],
      label: t("g4rFormModel"),
    },
    {
      name: ["lending", "manufacturing_year"],
      label: t("g4rFormYearMade"),
      translate: (value) => (value === " " ? t("g4rFormYearMadeOther") : value),
    },
    {
      name: ["lending", "fuel_type"],
      label: t("g4rFormFuelType"),
      translate: (value) => t(generateTranslationString(value, "FuelType")),
    },
  ];

  const generateFields = () =>
    fieldSetMandatoryOnly
      .filter((field) => !!formInstance.getFieldValue(field.name))
      .map((field) => {
        if (field.translate) {
          formInstance.setFieldsValue(
            ["translation"]
              .concat(field.name)
              .reverse()
              .reduce((result, item) => {
                return { [item]: result };
              }, field.translate(formInstance.getFieldValue(field.name)))
          );
          return (
            <>
              <Input name={["translation"].concat(field.name)} label={field.label} disabled />
              <HiddenInput name={field.name} />
            </>
          );
        } else {
          return <Input name={field.name} label={field.label} disabled />;
        }
      });

  const constructTable = () => {
    return generateFields()
      .reduce((result, field, index) => {
        if (!(index % 2)) {
          result[(index / 2) | 0] = [];
        }
        result[(index / 2) | 0].push(field);
        return result;
      }, [])
      .map((row, rowIndex) => {
        return (
          <Row key={rowIndex}>
            {row.map((field, colIndex) => (
              <Col xs={24} sm={12} key={colIndex}>
                {field}
              </Col>
            ))}
          </Row>
        );
      });
  };

  return (
    <>
      {PRODUCT_TYPES.isLeasing(productType) && (
        <h3 className="leasing-request-form__heading">{t("g4rFormPropertyInfo")}</h3>
      )}
      <Row>
        <HiddenInput name={["lending", "type"]} />
        <HiddenInput name={["lending", "is_property_selected"]} />
        {APPLICANT_TYPES.isSpouse(formInstance.getFieldValue(["applicant", "role"])) ? (
          <>
            <Col span={12}>
              <Input
                name={["lending", "property_value"]}
                label={PRODUCT_TYPES.isLoan(productType) ? t("loanFormPropertyValue") : t("leasingFormPropertyValue")}
                disabled
              />
            </Col>
            <Col span={12}>
              <Input name={["lending", "monthly_payment"]} label={t("leasingFormMonthlyPayment")} disabled />
            </Col>
          </>
        ) : (
          <Col span={24}>
            <Input name={["lending", "property_value"]} label={t("leasingFormPropertyValue")} disabled />
          </Col>
        )}
      </Row>
      {}
      {dynamicFieldContent}
    </>
  );
};

export default AssetInformationDisplayBlock;
