import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Form, DatePicker } from "antd";
import i18next from "i18next";
import ltLocale from "translations/datepickerLocale";
import eeLocale from "translations/datepickerLocale-ee";
import lvLocale from "translations/datepickerLocale-lv";
import enLocale from "antd/es/date-picker/locale/en_US";

import { requiredField, defaultLabelText } from "utils/formUtils";
import { dateMomentFormatter } from "utils/dateUtils";

const CustomDatepicker = (props) => {
  const locales = {
    en: enLocale,
    lt: ltLocale,
    ee: eeLocale,
    lv: lvLocale,
  };

  const defaultPlaceholderText =
    props?.rules?.length && props?.rules[0]?.required ? requiredField(props.label) : props.label;
  const [currentValue, setCurrentValue] = useState(null);
  const [labelText, setLabelText] = useState(defaultLabelText);
  const [placeholderText, setPlaceholderText] = useState(defaultPlaceholderText);

  const language = i18next.language;

  useEffect(() => {
    setLabelText(
      currentValue
        ? props?.rules?.length && props?.rules[0]?.required
          ? requiredField(props.label)
          : props.label
        : defaultLabelText
    );

    setPlaceholderText(currentValue ? "" : defaultPlaceholderText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentValue]);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  return (
    <>
      {props.inlineLabel && props.label && <label>{labelText}</label>}
      {props.inlineLabel && (
        <DatePicker
          className={cx("datepicker", { "bill-filter": currentValue })}
          locale={locales[language]}
          style={props.style}
          disabledDate={props.disabledDate}
          onChange={(value, valueString) => {
            props.onChange && props.onChange(value, valueString);
          }}
          placeholder={placeholderText}
          value={dateMomentFormatter(props.value)}
        />
      )}

      {!props.inlineLabel && (
        <Form.Item
          label={labelText}
          name={props.name}
          rules={props.rules}
          className={props.className}
          style={props.style}
        >
          <DatePicker
            locale={locales[language]}
            style={props.style}
            disabledDate={props.disabledDate}
            className={`datepicker`}
            placeholder={placeholderText}
            onChange={(value) => {
              props.onChange && props.onChange(value);
            }}
            name={props.name}
          />
        </Form.Item>
      )}
    </>
  );
};

export default CustomDatepicker;
