import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import useComponentMountHandler from "../../../hooks/useComponentMountHandler";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Spin from "components/shared/Spin";
import { Table } from "antd";
import ContractSelector from "components/contractSelector/contractSelector";
import BillFilters from "components/bill/billFilters";
import BillListItemDetails from "./billListItemDetails";
import DownloadButton from "components/shared/DownloadButton";
import { RightOutlined } from "@ant-design/icons";

import { getLoanList, getBillsList, geBillPdfFile } from "services/hwService";
import { getPdfFile } from "services/fileService";

import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { productType } from "utils/partiesUtils";
import { reformatUnderscore, updateRouteIfMounted } from "utils/helpers";

import { AuthContext } from "App";
import apiWrapper from "services/apiWrapper";
import { errorNotification } from "utils/notificationUtils";

const Bill = (props) => {
  const { t } = useTranslation();
  const pageSizes = ["10", "20", "30"];
  const defaultPageSize = parseInt(pageSizes[0]);
  const [contractList, setContractList] = useState([]);
  const { state: authState, dispatch } = useContext(AuthContext);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [billList, setBillList] = useState([]);

  const [contractListLoading, setContractListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const productFid = authState.activeProductFid;

  const [pagination, setPagination] = useState({
    defaultPageSize: defaultPageSize,
    showSizeChanger: true,
    pageSizeOptions: pageSizes,
    locale: { items_per_page: "" },
    showTitle: false,
  });

  const [filteredInfo, setFilteredInfo] = useState({});
  const [filterData, setFilterData] = useState();
  const [sortDirection, setSortDirection] = useState(null);

  const [expandableRows, setExpandableRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const isMounted = useRef(false);
  useComponentMountHandler(isMounted);

  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  const columns = [
    {
      width: 30,
      className: "table-cell--arrow",
      render: (value, record) =>
        isExpandable(record.id) && (
          <div className="arrowIconWrapper" onClick={(e) => onExpandClick(record)}>
            <RightOutlined
              className={cx({
                rotateDown: !isExpanded(record.id),
              })}
            />
          </div>
        ),
    },
    {
      title: t("loanBillNumber"),
      dataIndex: "contractName",
      sorter: billList?.length,
    },
    {
      title: t("billBillType"),
      dataIndex: "billType",
      className: "invoiceNumber",
      render: (value) => t("billsFilter" + reformatUnderscore("_" + value)),
      sorter: billList?.length,
      width: 190,
    },
    {
      title: t("loanBillIssueDate"),
      dataIndex: "issueDate",
      className: "invoiceDate",
      render: (value, item) => dateFormatter(value),
      sorter: billList?.length,
      defaultSortOrder: !sortDirection && "descend",
      width: 120,
    },
    {
      title: t("loanBillDueDate"),
      dataIndex: "dueDate",
      className: "invoiceDueDate",
      render: (value, item) => dateFormatter(value),
      sorter: billList?.length,
      width: 120,
    },

    {
      title: t("billsTableAmount"),
      dataIndex: "totalBillAmount",
      className: "paid dataAlignAmount",
      render: (value, item) => moneyFormatter(value, item.currencyCode),
      sorter: billList?.length,
    },
    {
      title: t("billPaidAmount"),
      dataIndex: "paidAmount",
      className: "invoiceAmount",
      render: (value, item) => moneyFormatter(value || 0, item.currencyCode),
      sorter: billList?.length,
    },
    {
      title: t("loanBillOpenBillAmount"),
      dataIndex: "openAmount",
      className: "unpaid",
      render: (value, item) => moneyFormatter(value || 0, item.currencyCode),
      sorter: billList?.length,
    },
    {
      title: "",
      dataIndex: "hasPdf",
      key: "file",
      className: "linkButton",
      width: 20,
      render: (value, item) => (
        <DownloadButton
          onClick={async () => {
            const status = 
              await geBillPdfFile(contractList.find((i) => i.id === item.contractNumber)?.companyId, item.billNumber);

            if (status?.isEmpty) {
              errorNotification(t("error"), t("billEmptyFile"), 10);
            }
          }}
        />
      ),
    },
    {
      title: t("billsTableStatus"),
      dataIndex: "status",
      className: "invoiceStatusTxt",
      render: (value) => {
        return value ? t(reformatUnderscore(value.toLowerCase())) : null;
      },
      sorter: billList?.length,
    },
  ];

  useEffect(() => {
    let contractsIds = [];
    let page = 1;
    let size = pagination.defaultPageSize;
    const filter = {};
    setFilterData(null);

    async function fetchData() {
      setContractListLoading(true);

      const contractListResponse = apiWrapper(await getLoanList(productFid), dispatch);
      setContractListLoading(false);

      if (props.location.search) {
        const searchParams = new URLSearchParams(props.location.search);
        contractsIds = searchParams.get("contractId").split(",");

        const contractsContainsCurrentContract = contractListResponse?.contracts.some((r) =>
          contractsIds.includes(r.id)
        );

        if (contractsContainsCurrentContract) {
          page = searchParams.get("page");
          size = searchParams.get("size");

          for (const key of searchParams.keys()) {
            if (searchParams.get(key) !== "undefined" && key !== "contractId" && key !== "page" && key !== "size") {
              filter[key] = searchParams.get(key);
            }
          }

          setPagination({
            ...pagination,
            current: page,
            defaultPageSize: size,
          });
        }
      }

      setFilteredInfo(filter);

      if (contractListResponse?.contracts && contractListResponse?.contracts.length) {
        const contractList = contractListResponse.contracts;
        contractList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

        contractsIds = contractsIds.filter((i) => contractListResponse.contracts.map((i) => i.id).includes(i));

        if (contractList.length !== 0) {
          setContractList(contractList);

          if (authState.selectedContracts) {
            contractsIds = [...new Set([...contractsIds, ...authState.selectedContracts.map((i) => i.id)])];
          } else {
            if (!contractsIds.length) {
              contractsIds.push(contractList[0].id);
            }
          }

          contractsIds = contractsIds.filter((i) => contractListResponse.contracts.map((i) => i.id).includes(i));
        }

        setSelectedContracts(contractsIds);
        setContractList(contractList);

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: {
              selectedContracts: contractsIds.length
                ? [contractListResponse.contracts.find((i) => i.id === contractsIds[0])]
                : [contractListResponse.contracts[0]],
            },
          });
        }

        const selectedItems = contractListResponse.contracts.filter((i) => contractsIds.includes(i.id));

        await fetchBillList(selectedItems, page, size, filter, null);
      } else {
        updateRouteIfMounted(isMounted.current, () => props.history.push(`/${productType.Loan}/dashboard`));
      }
    }

    if (productFid && authState.productType === productType.Loan) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  const getFilterParams = (value) => {
    return Object.entries(value)
      .map(([key, val]) => (key !== "billType" ? `${key}=${val}` : ""))
      .join("&");
  };

  const contractsSelected = async (contractsIds) => {
    const selectedItems = contractList.filter((i) => contractsIds.includes(i.id));

    selectedItems.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [selectedItems[0]],
        previouslySelectedContracts: selectedContracts,
      },
    });

    setSelectedContracts([contractsIds]);

    await fetchBillList(
      selectedItems,
      1,
      pagination.pageSize || pagination.defaultPageSize,
      filteredInfo,
      sortDirection
    );
  };

  const fetchBillList = async (contracts, page, size, filteredInfo, sortInfo, updateFilterButtons) => {
    setPagination({
      ...pagination,
      current: pagination.current || page ? parseInt(page) : 1,
    });
    setLoading(true);

    const billListResponse = await getBillsList(contracts, page || 1, size, processFilteredInfo(filteredInfo), sortInfo);

    setLoading(false);

    if (billListResponse) {
      const bills = billListResponse?.bills;

      if (bills) {
        bills.forEach((i, index) => {
          i.id = i.billNumber + index;
          i.hasPayments = i.payments.length > 0;
        });

        setBillList(bills);

        setExpandableRows(bills.filter((bill) => bill.payments && bill.payments.length).map((bill) => bill.id));
      }
    }

    if (billListResponse?.count) {
      setPagination({
        ...pagination,
        total: billListResponse.count,
        current: page ? parseInt(page) : 1,
        pageSize: size,
      });
    } else {
      setPagination({
        ...pagination,
        pageSize: size,
        total: 0,
      });
    }

    setFilterData({
      Contract: billListResponse?.billTypeContractCount,
      Principle: billListResponse?.billTypePrincipleCount,
      Interest: billListResponse?.billTypeInterestCount,
      DefaultInterest:
        stringToNumber(billListResponse?.billTypeDefaultInterestCount) +
        stringToNumber(billListResponse?.billTypeCompensatoryInterestCount),
    });
    updateRouteIfMounted(isMounted.current, () =>
      props.history.push(
        `/${productType.Loan}/bills/?contractId=${contracts.map((i) => i.id).join()}&page=${
          page || 1
        }&size=${size}&${getFilterParams(filteredInfo)}`
      )
    );
  };

  const stringToNumber = (string) => {
    return string ? parseInt(string) : 0;
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const selectedItems = contractList.filter((i) => selectedContracts.includes(i.id));

    const sortFieldOrder = sorter.order ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";

    setSortDirection(sortFieldOrder);

    await fetchBillList(selectedItems, pagination.current, pagination.pageSize, filteredInfo, sortFieldOrder);
  };

  const renderExpandableRow = (record) => {
    return (
      <BillListItemDetails
        expandedKeys={expandedRows}
        pdfText={t("factoringReportPdfLinkText")}
        onGetPdfFile={async (fileId) => await getPdfFile(fileId)}
        record={record}
      />
    );
  };

  const filterInfoChanged = async (value) => {
    const selectedItems = contractList.filter((i) => selectedContracts.includes(i.id));
    setFilteredInfo(value);
    await fetchBillList(
      selectedItems,
      1,
      pagination.defaultPageSize,
      !Object.keys(value).length
        ? value
        : {
            ...filteredInfo,
            ...value,
          },
      sortDirection
    );
  };

  const processFilteredInfo = (filter) => {
    const contractBillFilter = ["Contract", "Prolong", "Guarantee"].join();
    const defaultInterestBillFilter = ["Default_Interest", "Compensatory_Interest"].join();

    filter["billType"] =
      Object.keys(filter)
        .filter((i) => i.includes("billsFilter") && filter[i])
        .map((i) =>
          i
            .replace("billsFilter", "")
            .replace("Contract", contractBillFilter)
            .replace("DefaultInterest", defaultInterestBillFilter)
            .toUpperCase()
        )
        .join() || "";

    return filter;
  };

  const onExpandClick = (record) => {
    if (!isExpanded(record.id)) {
      setExpandedRows([...expandedRows.filter((key) => key !== record.id)]);
    } else {
      setExpandedRows([...expandedRows, record.id]);
    }
  };

  const isExpanded = (itemId) => expandedRows.findIndex((key) => key === itemId) === -1;
  const isExpandable = (itemId) => expandableRows.findIndex((key) => key === itemId) !== -1;

  return (
    <div className="Bills-page">
      <Row>
        <Col span={24}>
          <Spin spinning={contractListLoading} tip={spinConfiguration.tip}>
            {contractList.length && selectedContracts.length && (
              <ContractSelector
                selectedContract={contractList.find((i) => i.id === selectedContracts[0])}
                contractList={contractList}
                contractSelected={contractsSelected}
                type="loan"
                productName={t("productLoan")}
                productType="loan"
              />
            )}
          </Spin>
        </Col>
      </Row>

      <Row>
        <Col>
          <BillFilters
            selectedContracts={selectedContracts}
            filterInfoChanged={filterInfoChanged}
            filteredInfo={filteredInfo}
            product={productType.Loan}
            filterData={filterData}
          />
        </Col>
      </Row>

      <div className="section section--mt-1 section--padding-all">
        <div className="table-wrapper">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={billList}
            rowClassName={(record) =>
              cx("table-row-right-border", {
                paid: record.status === "PAID",
                unpaid: record.status === "UNPAID",
                overdue: record.status === "OVERDUE",
                "partially-paid": record.status === "PARTIALLY_PAID",
              })
            }
            onChange={handleTableChange}
            loading={spinConfiguration}
            pagination={billList?.length ? pagination : false}
            expandable={{
              expandedRowClassName: () => "remove-default-ant-cell-padding",
              onExpand: (e, record) => onExpandClick(record),
              expandedRowRender: billList?.length > 0 ? renderExpandableRow : null,
              expandedRowKeys: expandableRows,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Bill;
