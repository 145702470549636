import React, { useEffect, useState, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Table, Row, Col, Spin, Empty } from "antd";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import cx from "classnames";
import useComponentMountHandler from "../../../hooks/useComponentMountHandler";

import ContractFilter from "components/contractSelector/contractFilter";
import BillFilters from "components/bill/billFilters";
import BillListItemDetails from "components/bill/billListItemDetails";
import DownloadButton from "components/shared/DownloadButton";
import Button from "components/shared/Button";
import BillsPaymentStatus from "components/bill/BillsPaymentStatus";
import BillsPayment from "components/bill/BillsPayment";
import { RightOutlined } from "@ant-design/icons";
import { setExpOffsets, getExpRowKeys, setExpParent, newExpKeys } from "components/shared/expandRowTable";

import { getContractList } from "services/contractService";
import {
  getBillList,
  getInvoicePaymentRecepts,
  getBillTotals,
  getUnpaidBillsList,
  BILL_STATUS,
  CALLBACK_STATUS,
} from "services/billService";
import { getPdfFile } from "services/fileService";

import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { reformatUnderscore, updateRouteIfMounted } from "utils/helpers";
import { productType } from "utils/partiesUtils";
import { errorNotification } from "utils/notificationUtils";

import { AuthContext } from "App";
import apiWrapper from "services/apiWrapper";

import paymentSuccessfulIcon from "assets/icons/payment/icon_payment_successful.svg";
import paymentPendingIcon from "assets/icons/payment/icon_payment_pending.svg";
import paymentCanceledIcon from "assets/icons/payment/icon_payment_canceled.svg";

const BILL_QUANTITY = 1000;

const Bill = (props) => {
  const creditMemoType = "CREDIT_MEMO";
  const { t } = useTranslation();
  const location = useLocation();
  const params = Object.keys(queryString.parse(location.search));
  const pageSizes = ["10", "20", "30"];
  const defaultPageSize = parseInt(pageSizes[0]);
  const [contractList, setContractList] = useState([]);
  const [filterData, setFilterData] = useState({});
  const { state: authState, dispatch } = useContext(AuthContext);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [billList, setBillList] = useState([]);
  const [unpaidBills, setUnpaidBills] = useState([]);
  const isMounted = useRef(false);

  const [contractListLoading, setContractListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const productFid = authState.activeProductFid;

  const [pagination, setPagination] = useState({
    defaultPageSize: defaultPageSize,
    showSizeChanger: true,
    pageSizeOptions: pageSizes,
    locale: { items_per_page: "" },
    showTitle: false,
  });

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortDirection, setSortDirection] = useState(null);

  const [expandedKeys, setExpandedKeys] = useState([]);

  const [activeItems, setActiveItems] = useState({});

  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [paidModalVisible, setPaidModalVisible] = useState(false);
  const [paymentStatusModalVisible, setPaymentStatusModalVisible] = useState(false);
  const [callBackStatus, setCallBackStatus] = useState({});

  useComponentMountHandler(isMounted);

  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  const formatAmountByType = (amount, invoiceType) => {
    return amount * (invoiceType !== creditMemoType && amount > 0 ? 1 : -1);
  };

  const columns = [
    {
      width: 30,
      className: "table-cell--arrow",
      render: (value, record) => (record.invoiceStatus === "PAID" ||  record.invoiceStatus === "PARTIALLY_PAID") &&
          <div className="arrowIconWrapper" onClick={(e) => onExpand(record, expandedKeys)}>
            <RightOutlined
              className={cx({
                rotateDown: !isExpanded(record.id),
              })}
            />
          </div> 
    },
    {
      title: t("billsTableNumber"),
      dataIndex: "invoiceNumber",
      className: "invoiceNumber",
      sorter: billList?.length,
    },
    {
      title: t("billsTableType"),
      dataIndex: "invoiceFeeType",
      className: "type",
      render: (value) => t(reformatUnderscore(value)),
      sorter: billList?.length,
    },
    {
      title: t("billsTableDate"),
      dataIndex: "invoiceDate",
      className: "invoiceDate",
      render: (value, item) => dateFormatter(value),
      sorter: billList?.length,
      defaultSortOrder: !sortDirection && "descend",
    },
    {
      title: t("billsTableDueDate"),
      dataIndex: "invoiceDueDate",
      className: "invoiceDueDate",
      render: (value, item) => dateFormatter(value),
      sorter: billList?.length,
    },
    {
      title: t("billsTableAmount"),
      dataIndex: "invoiceAmount",
      className: "invoiceAmount",
      render: (value, item) =>
        moneyFormatter(formatAmountByType(item.invoiceAmount.amount, item.invoiceType), item.invoiceAmount.currency),
      sorter: billList?.length,
    },
    {
      title: t("billsTablePaid"),
      dataIndex: "amountPaid",
      className: "paid dataAlignAmount",
      render: (value, item) =>
        moneyFormatter(formatAmountByType(item.amountPaid.amount, item.invoiceType), item.amountPaid.currency),
      sorter: billList?.length,
    },
    {
      title: t("billsTableUnpaid"),
      dataIndex: "amountUnpaid",
      className: "unpaid",
      render: (value, item) =>
        moneyFormatter(formatAmountByType(item.amountUnpaid.amount, item.invoiceType), item.amountUnpaid.currency),
      sorter: billList?.length,
    },
    {
      title: "",
      dataIndex: "file",
      key: "file",
      className: "linkButton",
      render: (value, item) => <DownloadButton onClick={async () => await getPdfFile(item.id)} />,
    },
    {
      title: t("billsTableStatus"),
      dataIndex: "invoiceStatus",
      className: "invoiceStatusTxt",
      render: (value) => t(reformatUnderscore(value)),
      sorter: billList?.length,
    },
  ];

  useEffect(() => {
    const status = CALLBACK_STATUS.getStatus(params);
    if (status) {
      setTimeout(() => {
        setCallBackStatus(status);
        setPaymentStatusModalVisible(true);
      }, 100);
    }
    return () => setCallBackStatus("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    let contractsIds = [];
    let page = pagination.current || 1;
    let size = pagination.defaultPageSize;
    const filter = {};

    async function fetchData() {
      setContractListLoading(true);

      const contractListResponse = apiWrapper(await getContractList(productFid, signal), dispatch);
      setContractListLoading(false);

      if (props.location.search) {
        const searchParams = new URLSearchParams(props.location.search);
        contractsIds = searchParams.get("contractId").split(",");

        const contractsContainsCurrentContract = contractListResponse?.contracts.some((r) =>
          contractsIds.includes(r.id)
        );

        if (contractsContainsCurrentContract) {
          page = searchParams.get("page");
          size = searchParams.get("size");

          for (const key of searchParams.keys()) {
            if (searchParams.get(key) !== "undefined" && key !== "contractId" && key !== "page" && key !== "size") {
              filter[key] = searchParams.get(key);
            }
          }
          setPagination({
            ...pagination,
            current: page,
            defaultPageSize: size,
          });
        }
      }

      setFilteredInfo(filter);

      if (contractListResponse?.contracts && contractListResponse?.contracts.length) {
        const contractList = contractListResponse.contracts;

        const filteredList = contractList.filter((item) => item.status !== "READY_FOR_SIGNING");

        const sortedList = filteredList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

        if (sortedList?.length === 0) {
          dispatch({
            type: "SET_EMPTY_SSP",
            payload: {},
          });
          updateRouteIfMounted(isMounted.current, () => props.history.push(`/factoring/dashboard`));

          return;
        }

        contractsIds = contractsIds.filter((i) => contractListResponse.contracts.map((i) => i.id).includes(i));

        if (contractList.length !== 0) {
          setContractList(contractList);

          if (authState.selectedContracts) {
            contractsIds = [...new Set([...contractsIds, ...authState.selectedContracts.map((i) => i.id)])];
          } else {
            if (!contractsIds.length) {
              contractsIds.push(contractList[0].id);
            }
          }

          contractsIds = contractsIds.filter((i) => contractListResponse.contracts.map((i) => i.id).includes(i));
        }

        setSelectedContracts(contractsIds);
        setContractList(contractList);

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: {
              selectedContracts: contractsIds.length
                ? [contractListResponse.contracts.find((i) => i.id === contractsIds[0])]
                : [contractListResponse.contracts[0]],
            },
          });
        }

        const billTotalResponse = await getBillTotals(productFid, contractsIds);

        if (billTotalResponse) {
          setFilterData(billTotalResponse);
        }

        await fetchBillList(contractsIds, page, size, filter, null);
      } else {
        updateRouteIfMounted(isMounted.current, () => props.history.push(`/${productType.Factoring}/dashboard`));
      }
    }

    if (productFid && authState.productType === productType.Factoring) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  const getFilterParams = (value) => {
    return Object.entries(value)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");
  };

  const contractsSelected = async (contractsIds) => {
    const selectedItems = contractList.filter((i) => contractsIds.includes(i.id));

    selectedItems.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [selectedItems[0]],
        previouslySelectedContracts: selectedContracts,
      },
    });

    setSelectedContracts(contractsIds);

    await fetchBillList(
      contractsIds,
      1,
      pagination.pageSize || pagination.defaultPageSize,
      filteredInfo,
      sortDirection
    );

    const billTotalResponse = await getBillTotals(productFid, contractsIds);

    if (billTotalResponse) {
      setFilterData(billTotalResponse);
    }
  };

  const fetchBillList = async (contractsIds, page, size, filteredInfo, sortInfo) => {
    setPagination({
      ...pagination,
      current: pagination.current || page ? parseInt(page) : 1,
    });
    setLoading(true);

    const billListResponse = await getBillList(productFid, contractsIds, page || 1, size, filteredInfo, sortInfo);

    setLoading(false);

    if (billListResponse) {
      setBillList(billListResponse.content);
    }

    if (billListResponse?.totalElements) {
      setPagination({
        ...pagination,
        total: billListResponse.totalElements,
        current: page ? parseInt(page) : 1,
        pageSize: size,
      });
    } else {
      setPagination({
        ...pagination,
        pageSize: size,
        total: 0,
      });
    }
    updateRouteIfMounted(isMounted.current, () =>
      props.history.push(
        `/${productType.Factoring}/bills/?contractId=${contractsIds}&page=${page || 1}&size=${size}&${getFilterParams(
          filteredInfo
        )}`
      )
    );
  };

  const filterInfoChanged = async (value) => {
    setFilteredInfo(value);
    await fetchBillList(
      selectedContracts,
      1,
      pagination.defaultPageSize,
      !Object.keys(value).length
        ? value
        : {
            ...filteredInfo,
            ...value,
          },
      sortDirection
    );
  };

  const onExpand = async (record, passedExpandedKeys) => {
    const expanded = passedExpandedKeys.includes(record.id);

    const tempActiveItems = { ...activeItems };

    if (!expanded) {
      const activeItemResponse = await getInvoicePaymentRecepts(record.id);
      tempActiveItems[record.id] = activeItemResponse;
      setExpandedKeys((expandedKeys) => [...expandedKeys, record.id]);
    } else {
      setExpandedKeys((expandedKeys) => [...expandedKeys.filter((key) => key !== record.id)]);
      delete tempActiveItems[record.id];
    }
    setActiveItems(tempActiveItems);
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const sortFieldOrder = sorter.order ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";

    setSortDirection(sortFieldOrder);

    setActiveItems([]);
    setExpandedKeys([]);
    await fetchBillList(selectedContracts, pagination.current, pagination.pageSize, filteredInfo, sortFieldOrder);
  };

  const renderExpandableRow = (record) => { 
    return (
    <BillListItemDetails
      onItemFetch={(recordID) => setExpOffsets(recordID, () => setExpandedKeys(newExpKeys(recordID, expandedKeys)))}
      activeItem={activeItems[record.id]}
      expandedKeys={expandedKeys}
      onGetPdfFile={async (fileId) => await getPdfFile(fileId)}
      record={record}
      formatAmountByType={formatAmountByType}
    />
  );
  }

  const tableLocale = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />,
  };

  const isExpanded = (itemId) => expandedKeys.findIndex((key) => key === itemId) === -1;

  const fetchAllContractsUnpaidBills = async () => {
    const contractsIds = contractList.map((contract) => contract.id);
    const unpaidBillsRequest = {
      partyId: productFid,
      contractId: contractsIds,
      size: BILL_QUANTITY,
      status: BILL_STATUS.UNPAID,
    };

    const unpaidBillsResponse = await getUnpaidBillsList({ ...unpaidBillsRequest });
    if(unpaidBillsResponse.payload.code) {
      errorNotification(t("error"), t("serviceUnavailable"), 5);
    } else if (unpaidBillsResponse.payload.content.length === 0) {
      setPaidModalVisible(true);
    } else {
      setUnpaidBills(unpaidBillsResponse.payload.content);
      setPaymentModalVisible(true);
    }
  };

  const payBillsButton = () => (
    <Button type="primary" size="large" onClick={fetchAllContractsUnpaidBills}>
      {t("billsTablePayBillsButton")}
    </Button>
  );

  const billsPaymentStatusConfig = {
    pending: {
      title: t("billsPaymentProcessingModalTitle"),
      image: paymentPendingIcon,
      message: t("billsPaymentProcessingModalMessage"),
      showThankYouText: true,
    },
    canceled: {
      title: t("billsPaymentCanceledModalTitle"),
      image: paymentCanceledIcon,
      message: t("billsPaymentCanceledModalMessage"),
      showThankYouText: true,
    },
    //success status
    token: {
      title: t("billsPaymentConfirmedModalTitle"),
      image: paymentSuccessfulIcon,
      message: t("billsPaymentConfirmedModalMessage"),
      showThankYouText: true,
    },
  };

  return (
    <>
      <BillsPaymentStatus
        {...billsPaymentStatusConfig[callBackStatus]}
        modalVisible={paymentStatusModalVisible}
        onModalClose={() => setPaymentStatusModalVisible(false)}
      />

      <BillsPaymentStatus
        modalVisible={paidModalVisible}
        title={t("billsPaidModalTitle")}
        image={paymentSuccessfulIcon}
        onModalClose={() => setPaidModalVisible(false)}
      />

      <BillsPayment
        modalVisible={paymentModalVisible}
        contractList={contractList}
        unpaidBills={unpaidBills}
        onModalClose={() => setPaymentModalVisible(false)}
      />

      <div className="Bills-page">
        <Row>
          <Col>
            <Spin className="mainSpinner" spinning={contractListLoading} tip={spinConfiguration.tip}>
              {contractList.length && selectedContracts.length && (
                <ContractFilter
                  contractsSelected={contractsSelected}
                  contractList={contractList}
                  currentContract={selectedContracts}
                />
              )}
            </Spin>
          </Col>
        </Row>
        <Row>
          <Col>
            {filterData && (
              <BillFilters
                selectedContracts={selectedContracts}
                filterInfoChanged={filterInfoChanged}
                filteredInfo={filteredInfo}
                filterData={filterData}
                product={productType.Factoring}
              />
            )}
          </Col>
        </Row>
        <Row className="section section--mt-1 section--padding-all">
          <Col>
            <div className="table-wrapper">
              <Table
                locale={tableLocale}
                rowKey={(record) => record.id}
                dataSource={billList}
                columns={columns}
                rowClassName={(record) =>
                  cx("table-row-right-border", {
                    "paid": record.invoiceStatus === "PAID",
                    "unpaid": record.invoiceStatus === "UNPAID",
                    "overdue": record.invoiceStatus === "OVERDUE",
                    "partially-paid": record.invoiceStatus === "PARTIALLY_PAID",
                  })
                }
                onChange={handleTableChange}
                loading={spinConfiguration}
                pagination={billList?.length ? pagination : false}
                footer={payBillsButton}

                expandable={{
                  expandedRowClassName: () => "remove-default-ant-cell-padding",
                  onExpand: (e, record) => onExpand(record, expandedKeys),
                  expandedRowRender: billList?.length > 0 ? renderExpandableRow : null,
                  expandedRowKeys: getExpRowKeys(billList, expandedKeys, "hasPaymentReceipts"),
                  onRow:(record) => setExpParent(record, expandedKeys),
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Bill;
