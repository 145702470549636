import React from "react";
import { useTranslation } from "react-i18next";

import Input from "components/shared/form/Input";

const MandatoryInputLimited = ({
  name,
  label,
  max = 50,
  rules = [],
  readOnly = false,
  required = true,
  onChange = () => undefined,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        label={label}
        name={name}
        rules={[
          ...[
            {
              required: required,
              message: label + " " + t("isRequired"),
            },
            {
              max: max,
              message: t("g4rFormFieldValueTooLong"),
            },
          ],
          ...(rules || []),
        ]}
        disabled={readOnly}
        onChange={onChange}
      />
    </>
  );
};

export default MandatoryInputLimited;
