import React from 'react';
import {Button} from 'antd';

const DownloadButton = (props) => {
  return (
    <div className="downloadBtnWrap">
      <Button type="link" onClick={props.onClick} className="downloadBtn" />
    </div>
  );
};

export default DownloadButton;
