import React, { useState, useEffect } from "react";
import { VictoryPie } from "victory";
import { moneyFormatter } from "utils/moneyUtils";
import cx from "classnames";
import { factoringAmounts, loansAmounts } from "services/donutPieService";
import { productType } from "utils/partiesUtils";
import { useTranslation } from "react-i18next";
import "assets/scss/new/components/miniDonutPie.scss";

const MiniDonutPie = ({ showPlate, type, data }) => {
  const { t } = useTranslation();

  const [donutData, setDonutData] = useState([]);
  const [isOver, setIsOver] = useState(false);
  const [isActive, setIsActive] = useState();
  const [isDonutDataValid, setIsDonutDataValid] = useState(true);

  const DONUT_DEFAULT_COLOR = "#F2F2F2";

  useEffect(() => {
    if (type === productType.Factoring) {
      data[factoringAmounts.USED].donutAmount =
        data[factoringAmounts.USED].amount - data[factoringAmounts.OVERDUE].amount;

      setIsDonutDataValid(data[factoringAmounts.TOTAL].amount === 0 ? false : true);
    }
    if (type === productType.Loan) {
      data[loansAmounts.REPAYMENT].donutAmount =
        data[loansAmounts.REPAYMENT].amount - data[loansAmounts.OVERDUE].amount;

      setIsDonutDataValid(data[loansAmounts.DISBURSED].amount === 0 ? false : true);
    }

    setDonutData(data.map((a) => ({ ...a })));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleLeave = (e) => {
    setIsOver(false);
    setIsActive(null);

    donutData[factoringAmounts.TOTAL].color = data[factoringAmounts.TOTAL].color;
    donutData[factoringAmounts.USED].color = data[factoringAmounts.USED].color;
    donutData[factoringAmounts.OVERDUE].color = data[factoringAmounts.OVERDUE].color;
    donutData[factoringAmounts.AVAILABLE].color = data[factoringAmounts.AVAILABLE].color;
  };

  const handleOver = (neededChartElemIndex) => {
    if (!showPlate) {
      return;
    }

    setIsOver(true);
    setIsActive(neededChartElemIndex);

    if (type === productType.Factoring && neededChartElemIndex === factoringAmounts.TOTAL) {
      donutData[factoringAmounts.USED].color = donutData[factoringAmounts.TOTAL].color;
      donutData[factoringAmounts.OVERDUE].color = donutData[factoringAmounts.TOTAL].color;
      donutData[factoringAmounts.AVAILABLE].color = donutData[factoringAmounts.TOTAL].color;
      return;
    }
    if (type === productType.Factoring && neededChartElemIndex === factoringAmounts.USED) {
      donutData[factoringAmounts.USED].color = data[factoringAmounts.USED].color;
      donutData[factoringAmounts.OVERDUE].color = data[factoringAmounts.USED].color;
      donutData[factoringAmounts.AVAILABLE].color = DONUT_DEFAULT_COLOR;
      return;
    }

    if (type === productType.Loan && neededChartElemIndex === loansAmounts.DISBURSED) {
      donutData[loansAmounts.REPAYMENT].color = data[loansAmounts.DISBURSED].color;
      donutData[loansAmounts.OVERDUE].color = data[loansAmounts.DISBURSED].color;
      donutData[loansAmounts.REPAID].color = data[loansAmounts.DISBURSED].color;
      return;
    }
    if (type === productType.Loan && neededChartElemIndex === loansAmounts.REPAYMENT) {
      donutData[loansAmounts.REPAYMENT].color = data[loansAmounts.REPAYMENT].color;
      donutData[loansAmounts.OVERDUE].color = data[loansAmounts.REPAYMENT].color;
      donutData[loansAmounts.REPAID].color = DONUT_DEFAULT_COLOR;
      return;
    }

    donutData[neededChartElemIndex].color = data[neededChartElemIndex].color;
    data.forEach((a, index) => {
      if (index !== neededChartElemIndex) donutData[index].color = DONUT_DEFAULT_COLOR;
    });
  };

  const parseAmount = (amount) => {
    return parseInt(amount?.toString()?.replace(/\s/g, ""));
  };

  const getDonutData = () => {
    return donutData
      .filter((v, index) => v.include !== false)
      .map((v) => ({
        x: v.title,
        y: v.donutAmount >= 0 ? parseAmount(v.donutAmount) : parseAmount(v.amount),
      }));
  };

  const getColors = () => {
    return donutData.filter((v) => v.include !== false).map((v) => v.color);
  };

  return (
    <div className="mini-donut-pie">
      <div
        className={cx({
          "donut-unavailable__content": !isDonutDataValid,
        })}
      >
        <div className="legend-wrapper">
          {donutData && donutData.length && (
            <>
              <div
                onMouseLeave={handleLeave}
                onMouseOver={() => {
                  handleOver(0);
                }}
                className={cx("donut-legend total flex flex-direction-column flex-ai-start", {
                  blur: isOver,
                  active: isActive === 0,
                })}
              >
                <h4>{t(donutData[0].title)}</h4>
                <span>{moneyFormatter(donutData[0].amount, donutData[0].currency)}</span>
              </div>

              <div
                onMouseLeave={handleLeave}
                onMouseOver={() => {
                  handleOver(1);
                }}
                className={cx("donut-legend used flex flex-direction-column flex-ai-end", {
                  blur: isOver,
                  active: isActive === 1,
                })}
              >
                <h4>{t(donutData[1].title)}</h4>
                <span>{moneyFormatter(donutData[1].amount, donutData[1].currency)}</span>
              </div>

              <div
                onMouseLeave={handleLeave}
                onMouseOver={() => {
                  handleOver(3);
                }}
                className={cx("donut-legend total flex flex-direction-column flex-ai-start flex-jc-end", {
                  blur: isOver,
                  active: isActive === 3,
                })}
              >
                <h4>{t(donutData[3].title)}</h4>
                <span>{moneyFormatter(donutData[3].amount, donutData[3].currency)}</span>
              </div>

              <div
                onMouseLeave={handleLeave}
                onMouseOver={() => {
                  handleOver(2);
                }}
                className={cx("donut-legend total flex flex-direction-column flex-ai-end flex-jc-end", {
                  blur: isOver,
                  active: isActive === 2,
                })}
              >
                <h4>{t(donutData[2].title)}</h4>
                <span>{moneyFormatter(donutData[2].amount, donutData[2].currency)}</span>
              </div>
            </>
          )}
        </div>

        {showPlate && (
          <>
            <div className={"plate"} />
            <svg>
              <defs>
                <filter id="filter" x0="-50%" y0="-50%" width="200%" height="200%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur"></feGaussianBlur>
                  <feOffset dy="2" dx="3"></feOffset>
                  <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>

                  <feFlood floodColor="#000" floodOpacity="0.6"></feFlood>
                  <feComposite in2="shadowDiff" operator="in"></feComposite>
                  <feComposite in2="SourceGraphic" operator="over" result="firstfilter"></feComposite>
                </filter>
              </defs>
            </svg>
          </>
        )}

        <div className="donut-wrapper">
          <VictoryPie
            colorScale={getColors()}
            data={getDonutData()}
            innerRadius={85}
            animate={{ easing: "exp", duration: 1000 }}
            labels={(data) => null}
          />
        </div>
      </div>
      {!isDonutDataValid && <div className="donut-unavailable__message">{t("donutUnavailable")}</div>}
    </div>
  );
};

export default MiniDonutPie;
