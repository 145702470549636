import { createSlice } from "@reduxjs/toolkit";
import kebabDataInitialState from "../initialState/kebabDataInitialState";

export const kebabDataSlice = createSlice({
  name: "kebabPie",
  initialState: {
    kebabData: kebabDataInitialState,
  },
  reducers: {
    setKebabData: (state, action) => {
      state.kebabData = action.payload;
    },
    updateKebabData: (state, action) => {
      state.kebabData = action.payload;
    },
    setLoansData: (state, action) => {
      state.kebabData.loan = action.payload;
    },
    setFactoringData: (state, action) => {
      state.kebabData.factoring = action.payload;
    },
    setProductStatuses: (state, action) => {
      state.kebabData.factoring.loading = action.payload.factoringLoading;
      state.kebabData.factoring.status = action.payload.factoringAvailable;
      state.kebabData.loan.loading = false;
      state.kebabData.loan.status = action.payload.loanAvailable;
      state.kebabData.leasing.loading = action.payload.leasingLoading;
      state.kebabData.leasing.status = action.payload.leasingAvailable;
      state.kebabData.limit.loading = action.payload.limitLoading;
      state.kebabData.limit.status = action.payload.limitAvailable;
    },
    setError: (state, action) => {
      state.kebabData[action.payload.product].loading = false;
      state.kebabData[action.payload.product].error = true;
    },
  },
});

export default kebabDataSlice.reducer;

export const selectKebabData = (state) => state.kebabData.kebabData;

export const { setKebabData, updateKebabData, setLoansData, setFactoringData, setProductStatuses, setError } =
  kebabDataSlice.actions;
