import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";

const InvoiceVerificationStatus = (props) => {
  const { t } = useTranslation();

  const [statusModalVisible, setStatusModalVisible] = useState(false);

  useEffect(() => {
    setStatusModalVisible(props.statusModalVisible);
  }, [props.statusModalVisible]);

  return (
    <>
      <Modal
        width="53.8125rem"
        forceRender
        maskClosable={false}
        className={`invoice-verification-status`}
        title={t("invoiceVerificationStatusTitle")}
        visible={statusModalVisible}
        okButtonProps={{
          disabled: true,
        }}
        cancelButtonProps={{
          disabled: true,
        }}
      >
        <Row>
          <Col span={22}>
            <CheckCircleOutlined className="invoice-verification-status__icon" />

            <div className="invoice-verification-status__thanks-text">{t("thankYouForReviewingMessage")}</div>

            <Button className="invoice-verification-status__close" type="link" onClick={() => props.onClose()}>
              {t("closeWindow")}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default InvoiceVerificationStatus;
