import React from "react";
import HiddenInput from "components/shared/form/HiddenInput";

const PartnerReferenceBlock = () => {
  return (
    <>
      <HiddenInput name={["applicant", "token"]} />
      <HiddenInput name={["lending", "prefill"]} />
      <HiddenInput name={["lending", "partner_quote"]} />
      <HiddenInput name={["lending", "originator_token"]} />
      <HiddenInput name={["lending", "originator_no"]} />
    </>
  );
};

export default PartnerReferenceBlock;
