import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import { AuthContext } from "App";
import Button from "components/shared/Button";

import NewUser from "./../user/newUser";
import CheckPassword from "./personalInfoActions/checkPassword";
import EditUser from "./personalInfoActions/editUser";

import {
  productType,
  hasProductTypeInMainParty,
  hasPermissionInAnyParty,
  hasProductPermission,
  getPermissions,
} from "utils/partiesUtils";
import { factoringPermissions as translationMapping, permissionTypes } from "utils/statusUtils";
import { getCompanyOfficialsTitle } from "utils/translationIdUtils";

const CompanyInformation = (props) => {
  const { t } = useTranslation();
  const { state } = React.useContext(AuthContext);

  const [newUserModalVisible, setNewUserModalVisible] = useState(false);
  const [checkPasswordModalVisible, setCheckPasswordModalVisible] = useState(false);

  const [editUserModalVisible, setEditUserModalVisible] = useState(false);

  const [type, setType] = useState(null);
  const [currentProductType, setCurrentProductType] = useState(null);
  const [deletableUserEmail, setDeletableUserEmail] = useState(null);
  const [editableUserEmail, setEditableUserEmail] = useState(null);
  const [currentPermissions, setCurrentPermissions] = useState(null);

  const onNewUserSave = (data) => {
    props.onNewUserSave();
    setNewUserModalVisible(false);
  };

  const onPasswordModalClose = () => {
    setCheckPasswordModalVisible(false);
  };

  const onPasswordModalSave = async () => {
    setCheckPasswordModalVisible(false);

    if (type === "deleteUser") {
      const response = await props.deleteUser({
        email: deletableUserEmail,
        product_type: currentProductType,
      });

      props.onUserDelete(!response?.error ? "success" : "error");
    }

    if (type === "editUser") {
      setEditUserModalVisible(true);
    }
  };

  const onEditUserModalSave = () => {
    setEditUserModalVisible(false);

    props.onUserEdit();
  };

  return (
    <>
      <NewUser
        onModalSave={onNewUserSave}
        modalVisible={newUserModalVisible}
        onModalClose={() => setNewUserModalVisible(false)}
        countriesWithPhoneCodes={props.countriesWithPhoneCodes}
        countries={props.countries}
        postNewUser={props.postNewUser}
        company={props.company}
        parties={state.parties}
      />
      <EditUser
        modalVisible={editUserModalVisible}
        onModalClose={() => setEditUserModalVisible(false)}
        onModalSave={onEditUserModalSave}
        email={editableUserEmail}
        currentPermissions={currentPermissions}
        changeUserPermissions={props.changeUserPermissions}
        currentProductType={currentProductType}
      />
      <CheckPassword
        type={type}
        modalVisible={checkPasswordModalVisible}
        onModalClose={onPasswordModalClose}
        onModalSave={onPasswordModalSave}
        authorizeUser={props.authorizeUser}
        changePassword={props.changePassword}
        email={props?.person?.email}
      />
      <Row className={"CompanyInformation"}>
        <Col span={12}>
          <label>{t("companyName")}</label>
          <p>{props.company.name || "-"}</p>
          <label>{t("companyCode")}</label>
          <p>{props.company.registrationCode || "-"}</p>
          <label>{t("vatCode")}</label>
          <p>{props.company.vatNumber || "-"}</p>
          <label>{t("companyPhone")}</label>
          <p>{props.company.phoneNumber || "-"}</p>
        </Col>
        <Col span={12}>
          <label>{t("country")}</label>
          <p>{props.company.country || "-"}</p>
          <label>{t("city")}</label>
          <p>{(props.company.address && props.company.address.city) || "-"}</p>
          <label>{t("address")}</label>
          <p>{(props.company.address && props.company.address.addressLine1) || "-"}</p>
          <label>{t("postalCode")}</label>
          <p>{(props.company.address && props.company.address.postalCode) || "-"}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h4>{t("accounts")}</h4>

          <table className="accounts">
            <thead>
              <tr>
                <th>{t("companyAccount")}</th>
                <th>{t("bank")}</th>
              </tr>
            </thead>
            <tbody>
              {props.company?.bankAccounts &&
                props.company.bankAccounts.map((item, i) => (
                  <tr key={i}>
                    <td>{item.accountNumber}</td>
                    <td>{item.bankName}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>
      </Row>

      <Row className="company-officials">
        <Col span={24}>
          {Object.values(productType)
            .filter((productType) => hasProductTypeInMainParty(state.parties, productType))
            .map((productType) => (
              <div key={`div-${productType}`}>
                <h4>{t(getCompanyOfficialsTitle(productType))}</h4>

                <table className="companyOfficials">
                  <thead>
                    <tr>
                      <th>{t("fullName")}</th>
                      <th>{t("email")}</th>
                      <th>{t("roles")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.partyOfficials &&
                      props.partyOfficials
                        .filter((users) => getPermissions(users.partyList, props.company.id, productType))
                        .map((users, i) => (
                          <tr key={`tr-${productType}-${i}`}>
                            <td>
                              {users?.user?.firstName} {users?.user?.lastName}
                            </td>
                            <td>{users?.user?.email}</td>
                            <td>
                              {getPermissions(users.partyList, props.company.id, productType)?.map((permission, i) => {
                                return (
                                  <p key={`p-${productType}-${i}`}>
                                    {t(translationMapping[permission.type.toLowerCase()])}
                                  </p>
                                );
                              })}
                            </td>
                            <td>
                              {hasProductPermission(state.parties, productType, permissionTypes.ManageUsers) &&
                                getPermissions(users.partyList, props.company.id, productType) && (
                                  <div key={`buttons-${productType}-${i}`}>
                                    <Button
                                      className="delete"
                                      onClick={() => {
                                        setDeletableUserEmail(users.user?.email);
                                        setType("deleteUser");
                                        setCurrentProductType(productType.toUpperCase());
                                        setCheckPasswordModalVisible(true);
                                      }}
                                    ></Button>
                                    <Button
                                      className="update"
                                      onClick={() => {
                                        setCurrentPermissions(
                                          getPermissions(users.partyList, props.company.id, productType)
                                        );
                                        setEditableUserEmail(users.user?.email);
                                        setType("editUser");
                                        setCurrentProductType(productType.toUpperCase());
                                        setCheckPasswordModalVisible(true);
                                      }}
                                    ></Button>
                                  </div>
                                )}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            ))}

          {hasPermissionInAnyParty(state.parties, permissionTypes.ManageUsers) && (
            <Button
              className="company-officials__add-new-user"
              type="default"
              size="small"
              onClick={() => setNewUserModalVisible(true)}
            >
              {t("addNewUser")}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CompanyInformation;
