import React, { useEffect, useState, useContext, useRef } from "react";
import { Table, Row, Col, Spin, Empty, Button } from "antd";
import { useTranslation } from "react-i18next";

import { AuthContext } from "App";
import useComponentMountHandler from "../../../hooks/useComponentMountHandler";

import ContractFilter from "components/contractSelector/contractFilter";
import InvoiceListFilter from "components/invoice/invoiceListFilter";

import { getInvoiceList, getCustomInvoiceStatuses, getExportToExcelFile } from "services/invoiceService";
import { getContractList, getContractBuyersList } from "services/contractService";

import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { reformatUnderscore, updateRouteIfMounted } from "utils/helpers";

import apiWrapper from "services/apiWrapper";

import { productType } from "utils/partiesUtils";

import InvoiceTableExpandedRow from "./invoiceTableExpandedRow";
import { RightOutlined, UpCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import cx from "classnames";

const pageSizes = ["10", "20", "30"];
const defaultPageSize = parseInt(pageSizes[0]);

const Invoice = (props) => {
  const { t } = useTranslation();
  const { state: authState, dispatch } = useContext(AuthContext);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortDirection, setSortDirection] = useState(null);
  const [contractList, setContractList] = useState([]);
  const isMounted = useRef(false);

  let [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListTotal, setInvoiceListTotal] = useState({});
  const [contractListLoading, setContractListLoading] = useState(false);
  const [exportToExcelLoading, setExportToExcelLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    defaultPageSize: defaultPageSize,
    showSizeChanger: true,
    pageSizeOptions: pageSizes,
    locale: { items_per_page: "" },
    showTitle: false,
  });

  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  const [selectedContracts, setSelectedContracts] = useState([]);
  const [buyersList, setBuyersList] = useState([]);

  const [buyerSupplierLabel, setBuyerSupplierLabel] = useState(null);

  const [totalsPosition, setTotalsPosition] = useState({});

  const customInvoiceStatuses = getCustomInvoiceStatuses();

  const productFid = authState.activeProductFid;

  const [expandableRows, setExpandableRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  useComponentMountHandler(isMounted);

  useEffect(() => {
    let contractsIds = [];
    let page = pagination.current || 1;
    let size = pagination.defaultPageSize;
    const filter = {};

    async function fetchData() {
      setContractListLoading(true);
      const contractListResponse = apiWrapper(await getContractList(productFid), dispatch);
      setContractListLoading(false);

      if (contractListResponse && contractListResponse.contracts && contractListResponse.contracts.length) {
        const contractList = contractListResponse.contracts;

        const filteredList = contractList.filter((item) => item.status !== "READY_FOR_SIGNING");

        const sortedList = filteredList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

        if (sortedList?.length === 0) {
          dispatch({
            type: "SET_EMPTY_SSP",
            payload: {},
          });
          updateRouteIfMounted(isMounted.current, () => props.history.push(`/factoring/dashboard`));

          return;
        }

        setContractList(sortedList);

        if (props.location.search) {
          const searchParams = new URLSearchParams(props.location.search);

          contractsIds = searchParams.get("contractId").split(",");
          contractsIds = contractsIds.filter((i) => contractListResponse.contracts.map((i) => i.id).includes(i));

          const contractsContainsCurrentContract = contractListResponse?.contracts.some((r) =>
            contractsIds.includes(r.id)
          );

          if (contractsContainsCurrentContract) {
            page = searchParams.get("page");
            size = searchParams.get("size");

            for (const key of searchParams.keys()) {
              if (searchParams.get(key) !== "undefined" && key !== "contractId" && key !== "page" && key !== "size") {
                filter[key] = searchParams.get(key);
              }
            }

            setPagination({
              ...pagination,
              current: page,
              defaultPageSize: size,
            });
          }
        }

        setFilteredInfo(filter);

        if (authState.selectedContracts) {
          contractsIds = [...new Set([...contractsIds, ...authState.selectedContracts.map((i) => i.id)])];
        } else {
          if (!contractsIds.length) {
            contractsIds.push(contractList[0].id);
          }
        }

        setSelectedContracts(contractsIds);
        setBuyerSupplierLabel(getBuyerSupplierLabel(contractList, contractsIds));

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: {
              selectedContracts: contractsIds.length
                ? [contractListResponse.contracts.find((i) => i.id === contractsIds[0])]
                : [contractListResponse.contracts[0]],
            },
          });
        }
        const buyersListResponse = await getContractBuyersList(contractsIds);

        if (buyersListResponse && buyersListResponse.thirdParties) {
          buyersListResponse.thirdParties.sort(function (a, b) {
            return a.name[0].localeCompare(b.name[0]);
          });

          setBuyersList(buyersListResponse.thirdParties);

          if (filter && filter.thirdPartyId) {
            const existingThirdParty = buyersListResponse.thirdParties.find((i) => i.id === filter.thirdPartyId);

            if (!existingThirdParty) {
              filter.thirdPartyId = undefined;
              setFilteredInfo(filter);
            }
          }
        }

        await fetchInvoiceList(contractsIds, page, size, filter, null);
      } else {
        updateRouteIfMounted(isMounted.current, () => props.history.push(`/${productType.Factoring}/dashboard`));
      }
    }

    if (productFid && authState.productType === productType.Factoring) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  const fetchInvoiceList = async (contractsIds, page, size, filteredInfo, sortInfo) => {
    size = size || defaultPageSize;

    setPagination({
      ...pagination,
      current: pagination.current || page ? parseInt(page) : 1,
      pageSize: size,
    });
    setLoading(true);

    const invoiceListResponse = await getInvoiceList(contractsIds, page || 1, size, filteredInfo, sortInfo);
    setLoading(false);

    if (invoiceListResponse) {
      setInvoiceList(invoiceListResponse.invoices?.content);
      setExpandableRows(
        invoiceListResponse?.invoices?.content.filter((record) => !!record.invoiceUploadDate).map((record) => record.id)
      );

      if (invoiceListResponse?.totals) {
        setInvoiceListTotal(invoiceListResponse?.totals);
      }

      setTotalsPosition({
        invoice: getTotalItemPosition(5),
        financed: getTotalItemPosition(6),
        remainder: getTotalItemPosition(7),
        toBePaid: getTotalItemPosition(8),
      });

      if (invoiceListResponse.invoices?.totalElements) {
        setPagination({
          ...pagination,
          total: invoiceListResponse.invoices.totalElements,
          current: page ? parseInt(page) : 1,
          pageSize: size,
        });
      } else {
        setPagination({ ...pagination, pageSize: size });
      }
    } else {
      setInvoiceListTotal({
        advanceAmountFunded: { amount: 0, currency: "EUR" },
        advanceAmountFundedOutstanding: { amount: 0, currency: "EUR" },
        advanceAmountUnfunded: { amount: 0, currency: "EUR" },
        amountCollected: { amount: 0, currency: "EUR" },
        invoiceAmount: { amount: 0, currency: "EUR" },
        invoiceAmountUnfunded: { amount: 0, currency: "EUR" },
      });

      setTotalsPosition({
        invoice: getTotalItemPosition(5),
        financed: getTotalItemPosition(6),
        remainder: getTotalItemPosition(7),
        toBePaid: getTotalItemPosition(8),
      });
    }
    updateRouteIfMounted(isMounted.current, () =>
      props.history.push(
        `/factoring/invoices/?contractId=${contractsIds}&page=${page || 1}&size=${size}&${getFilterParams(filteredInfo)}`
      )
    );
  };

  const getTotalItemPosition = (positionNumber) => {
    const element = document.querySelector(`.invoiceTable table th:nth-child(${positionNumber})`);

    return element ? element.offsetLeft : 0;
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    const sortFieldOrder = sorter.order ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";

    setSortDirection(sortFieldOrder);

    await fetchInvoiceList(selectedContracts, pagination.current, pagination.pageSize, filteredInfo, sortFieldOrder);
  };

  const getBuyerSupplierLabel = (list, selectedItems) => {
    const contracts = list?.filter((i) => selectedItems?.includes(i.id));

    if (contracts?.every((i) => i.factoringProduct !== "REVERSE")) {
      return t("invoicesTableBuyer");
    }

    if (contracts?.every((i) => i.factoringProduct === "REVERSE")) {
      return t("invoicesTableSupplier");
    }

    return `${t("invoicesTableBuyer")}/${t("invoicesTableSupplier")}`;
  };

  const getFilterParams = (value) => {
    return Object.entries(value)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");
  };

  const contractsSelected = async (contractsIds) => {
    const selectedItems = contractList.filter((i) => contractsIds.includes(i.id));

    selectedItems.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [selectedItems[0]],
      },
    });

    setSelectedContracts(contractsIds);

    setBuyerSupplierLabel(getBuyerSupplierLabel(contractList, contractsIds));

    const filter = { ...filteredInfo };

    const buyersListResponse = await getContractBuyersList(contractsIds);

    if (buyersListResponse && buyersListResponse.thirdParties) {
      buyersListResponse.thirdParties.sort(function (a, b) {
        return a.name[0].localeCompare(b.name[0]);
      });

      setBuyersList(buyersListResponse.thirdParties);

      if (filteredInfo && filteredInfo.thirdPartyId) {
        const existingThirdParty = buyersListResponse.thirdParties.find((i) => i.id === filteredInfo.thirdPartyId);

        if (!existingThirdParty) {
          filter.thirdPartyId = undefined;

          setFilteredInfo(filter);
        }
      }
    }

    await fetchInvoiceList(contractsIds, 1, pagination.pageSize || pagination.defaultPageSize, filter, sortDirection);
  };

  const filterInfoChanged = async (value) => {
    setFilteredInfo(value);
    await fetchInvoiceList(
      selectedContracts,
      1,
      pagination.pageSize || pagination.defaultPageSize,
      !Object.keys(value).length
        ? value
        : {
            ...filteredInfo,
            ...value,
          },
      sortDirection
    );
  };

  const onCellClick = (item) => {
    return {
      onClick: () => {
        updateRouteIfMounted(isMounted.current, () =>
          props.history.push({
            pathname: `/factoring/invoice/${item.id}/?contractId=${selectedContracts}&${getFilterParams(filteredInfo)}`,
            state: { pathname: props.history.location.pathname },
          })
        );
      },
    };
  };

  const onExpandClick = (record) => {
    if (!isExpanded(record.id)) {
      setExpandedRows([...expandedRows.filter((key) => key !== record.id)]);
    } else {
      setExpandedRows([...expandedRows, record.id]);
    }
  };

  const isExpanded = (itemId) => expandedRows.findIndex((key) => key === itemId) === -1;
  const isExpandable = (itemId) => expandableRows.findIndex((key) => key === itemId) !== -1;

  const renderExpandableRow = (record, index, indent, expanded) => (
    <InvoiceTableExpandedRow record={record} expandedKeys={expandedRows} />
  );

  const exportToExcel = async () => {
    setExportToExcelLoading(true);

    await getExportToExcelFile(selectedContracts, pagination.current, pagination.pageSize, filteredInfo, sortDirection);

    setExportToExcelLoading(false);
  };

  const columns = [
    {
      width: 30,
      className: "cellWithNoBorder",
      render: (value, record) =>
        isExpandable(record.id) && (
          <div className="arrowIconWrapper" onClick={(e) => onExpandClick(record)}>
            <RightOutlined
              className={cx({
                rotateDown: isExpanded(record.id),
              })}
            />
          </div>
        ),
    },
    {
      title: buyerSupplierLabel,
      dataIndex: "thirdPartyName",
      className: "buyer overflow",
      width: 180,
      render: (value, item) =>
        item.thirdPartyName ? item.thirdPartyName : item.invoiceType ? t(reformatUnderscore(item.invoiceType)) : "",
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      title: t("invoicesTableNumber"),
      dataIndex: "invoiceNumber",
      className: "invoiceNumber overflow",
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      title: t("invoicesTableIssueDate"),
      dataIndex: "invoiceDate",
      className: "invoiceDate",
      render: (value, item) => dateFormatter(value),
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      title: t("invoicesTableDueDate"),
      dataIndex: "invoiceDueDate",
      className: "invoiceDueDate",
      render: (value, item) => dateFormatter(value),
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      title: t("invoicesTableInvoiceAmount"),
      dataIndex: "invoiceAmount",
      className: "invoiceAmount",
      render: (value, item) => moneyFormatter(value.amount, value.currency),
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      title: t("invoicesTableFinanced"),
      dataIndex: "advanceAmountFunded",
      className: "advanceAmountTotal",
      render: (value, item) => moneyFormatter(value.amount, value.currency),
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      title: t("invoicesTableRemainder"),
      dataIndex: "invoiceAmountUnfunded",
      className: "remainder",
      render: (value, item) => moneyFormatter(value.amount, value.currency),
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      title: t("invoicesTableUnpaidAmount"),
      dataIndex: "invoiceAmountAssignedOutstanding",
      className: "toBePaid",
      render: (value, item) => moneyFormatter(value.amount, value.currency),
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      title: t("invoicesTableStatus"),
      dataIndex: "invoiceStatus",
      className: "invoiceStatusTxt",
      render: (value) => (value ? t(reformatUnderscore(value)) : value),
      width: 80,
      sorter: invoiceList?.length,
      onCell: onCellClick,
    },
    {
      width: 30,
      render: (value, record) =>
        isExpandable(record.id) && (
          <div className="infoIconWrapper" onClick={() => onExpandClick(record)}>
            {!isExpanded(record.id) ? <UpCircleOutlined /> : <InfoCircleOutlined />}
          </div>
        ),
    },
    {
      title: "",
      dataIndex: "invoiceStatus",
      className: "invoiceStatusCell",
      render: (value, item) => {
        let statusStr = value.replace(/\s/g, "").toLowerCase();
        statusStr = statusStr.replace(/_/g, "");
        const invoiceStatusClasses = "invoiceStatus invoiceStatus-" + statusStr;
        return {
          props: {
            className: invoiceStatusClasses,
          },
        };
      },
    },
  ];

  const totalColumns = [
    {
      title: (
        <>
          <Row>
            <Col style={{ left: totalsPosition.invoice }} span={1}>
              {t("invoicesTableTotalInvoiceAmount")}
            </Col>
            <Col
              style={{
                left: totalsPosition.financed,
              }}
              span={1}
            >
              {t("invoicesTableTotalFinanced")}
            </Col>

            <Col
              style={{
                left: totalsPosition.remainder,
              }}
              span={1}
            >
              {t("invoicesTableTotalRemainder")}
            </Col>

            <Col style={{ left: totalsPosition.toBePaid }} span={1}>
              {t("invoicesTableTotalUnpaidAmount")}
            </Col>
          </Row>
        </>
      ),
      dataIndex: "invoiceAmount",
      className: "totalInvoiceAmt",

      render: (value, item) => {
        return {
          children: (
            <>
              <Row>
                <Col style={{ left: totalsPosition.invoice }} span={1}>
                  {item.invoiceAmount && moneyFormatter(item.invoiceAmount.amount, item.invoiceAmount.currency)}
                </Col>
                <Col
                  style={{
                    left: totalsPosition.financed,
                  }}
                  span={1}
                >
                  {item.advanceAmountFunded &&
                    moneyFormatter(item.advanceAmountFunded.amount, item.advanceAmountFunded.currency)}
                </Col>

                <Col
                  style={{
                    left: totalsPosition.remainder,
                  }}
                  span={1}
                >
                  {item.invoiceAmountUnfunded &&
                    moneyFormatter(item.invoiceAmountUnfunded.amount, item.invoiceAmountUnfunded.currency)}
                </Col>

                <Col style={{ left: totalsPosition.toBePaid }} span={1}>
                  {item.invoiceAmountAssignedOutstanding &&
                    moneyFormatter(
                      item.invoiceAmountAssignedOutstanding.amount,
                      item.invoiceAmountAssignedOutstanding.currency
                    )}
                </Col>
              </Row>
            </>
          ),
          props: {
            colSpan: 9,
          },
        };
      },
    },
  ];

  const tableLocale = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />,
  };

  return (
    <div className="invoices-container">
      <ContractFilter
        contractsSelected={contractsSelected}
        contractList={contractList}
        currentContract={selectedContracts}
      />
      <Spin className="mainSpinner" spinning={contractListLoading} tip={spinConfiguration.tip}></Spin>
      {!!selectedContracts.length && (
        <InvoiceListFilter
          selectedContracts={selectedContracts}
          contractList={contractList}
          filterInfoChanged={filterInfoChanged}
          filteredInfo={filteredInfo}
          buyersList={buyersList}
          customInvoiceStatuses={customInvoiceStatuses}
        />
      )}

      <div className="block totalInvoiceDataWrapper">
        <Spin className="mainSpinner" spinning={exportToExcelLoading} tip={spinConfiguration.tip}>
          <Button onClick={exportToExcel} className="export" type="link">
            {t("invoicesExportToExcel")} <span className="export-icon"></span>
          </Button>
        </Spin>

        <Table
          className={"totalInvoiceData"}
          columns={totalColumns}
          dataSource={[invoiceListTotal]}
          rowKey={(record) => 1}
          loading={spinConfiguration}
        />
      </div>

      <Table
        className={"invoiceTable"}
        locale={tableLocale}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={invoiceList}
        rowClassName={(record) => !isExpanded(record.id) && "invoiceTableRow"}
        onChange={handleTableChange}
        loading={spinConfiguration}
        pagination={invoiceList?.length ? pagination : false}
        expandable={{
          expandedRowClassName: () => "noPaddingOnExpandedRow",
          onExpand: (e, record) => onExpandClick(record),
          expandedRowRender: invoiceList?.length > 0 ? renderExpandableRow : null,
          expandedRowKeys: expandableRows,
        }}
      />
    </div>
  );
};

export default Invoice;
