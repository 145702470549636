import React, { useEffect, useState, useContext, useRef } from "react";
import { Spin, notification } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useComponentMountHandler from "../../../hooks/useComponentMountHandler";

import ContractHeader from "components/contractSelector/contractHeader";
import ContractDetails from "./contractDetails";
import ContractSelector from "components/contractSelector/contractSelector";
import BuyerDetails from "components/buyer/buyerDetails";

import { AuthContext } from "App";

import {
  getContractList,
  getContract,
  getContractFiles,
  getContractFile,
  getIncreaseLimitFileUploadData,
  getProlongFileUploadData,
  getUploadedFiles,
  postIncreaseLimit,
  postProlong,
  postAddBuyer,
  deleteIncreaseLimitUploadedFile,
  deleteProlongUploadedFile,
} from "services/contractService";
import { getContractLimits } from "services/invoiceService";

import { hasPermission } from "services/userService";
import { permissionTypes, invoiceStatuses } from "utils/statusUtils";
import apiWrapper from "services/apiWrapper";
import { productType } from "../../../utils/partiesUtils";
import { factoringAmounts, factoringDataInitBuild } from "services/donutPieService";
import { updateRouteIfMounted } from "utils/helpers";

const Contract = (props) => {
  const alertTimeout = 20;

  const { t } = useTranslation();

  const donutDataInit = factoringDataInitBuild(invoiceStatuses);

  const [loading, setLoading] = useState(false);
  const [contractListLoading, setContractListLoading] = useState(false);
  const [contractList, setContractList] = useState([]);
  const { state: authState, dispatch } = useContext(AuthContext);
  const [selectedContract, setSelectedContract] = useState(null);
  const [donutData, setDonutData] = useState(donutDataInit);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showIncreaseLimitView, setShowIncreaseLimitView] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [buyerDetails, setBuyerDetails] = useState(null);
  const [contractFiles, setContractFiles] = useState(null);
  const isMounted = useRef(false);

  const [contractFunctionEnabled, setContractFunctionEnabled] = useState(false);
  const [newBuyerEnabled, setNewBuyerEnabled] = useState(false);
  useComponentMountHandler(isMounted);

  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  let location = useLocation();

  const productFid = authState.activeProductFid;

  useEffect(() => {
    async function fetchData() {
      setContractListLoading(true);
      const contractListResponse = apiWrapper(await getContractList(productFid), dispatch);
      setContractListLoading(false);

      if (contractListResponse?.contracts && contractListResponse.contracts.length) {
        let contractList = contractListResponse.contracts;
        contractList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

        contractList = contractList.filter((item) => item.status !== "READY_FOR_SIGNING");

        if (contractList?.length === 0) {
          dispatch({
            type: "SET_EMPTY_SSP",
            payload: {},
          });
          updateRouteIfMounted(isMounted.current, () => props.history.push(`/factoring/dashboard`));

          return;
        }

        setContractList(contractList);

        let firstContractId = authState.selectedContracts
          ? authState.selectedContracts[0].id
          : contractListResponse.contracts && contractListResponse.contracts.length && contractList[0].id
          ? contractList[0].id
          : 0;

        //TODO: replace with more appropriate solution
        if (location.pathname.length && location.pathname.length > 1) {
          const buyerContractIdParts = location.pathname.split("/");
          const buyerContractId = buyerContractIdParts[buyerContractIdParts.length - 1];

          const contractIndex = contractListResponse.contracts.findIndex((i) => i.id === buyerContractId);

          if (contractIndex > 0) {
            firstContractId = buyerContractId;
          }
        }

        setLoading(true);
        const contract = apiWrapper(await getContract(firstContractId), dispatch);

        const contractVersion = contractListResponse.contracts.find((i) => i.id === firstContractId)?.contractVersion;

        const contractFilesResponse = await getContractFiles(firstContractId, contractVersion);
        if (contractFilesResponse) {
          setContractFiles(contractFilesResponse?.documents);
        }

        const limitData = await getContractLimits(firstContractId);

        if (limitData) {
          handleDonutData(limitData);
        }

        const filesResponse = getUploadedFiles(firstContractId);

        deleteFiles(filesResponse);

        setShowIncreaseLimitView(props.location.search.includes("openIncreaseLimitView"));

        setLoading(false);

        setSelectedContract(contract);

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: {
              selectedContracts: [contractList.find((i) => i.id === firstContractId)],
            },
          });
        }
        updateRouteIfMounted(isMounted.current, () =>
          props.history.push(`/${productType.Factoring}/contracts/contract/${firstContractId}`)
        );
      } else {
        updateRouteIfMounted(isMounted.current, () => props.history.push(`/${productType.Factoring}/dashboard`));
      }
    }

    if (productFid && authState.productType === productType.Factoring) {
      fetchData();
      setContractFunctionEnabled(hasPermission(authState.parties, permissionTypes.ManageContract));
      setNewBuyerEnabled(hasPermission(authState.parties, permissionTypes.AddBuyer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  const contractSelected = async (selectedContractId) => {
    setLoading(true);
    const contract = apiWrapper(await getContract(selectedContractId), dispatch);
    if(!contract) {
      const restorePreviousContract = selectedContract;
      setSelectedContract(null);
      setSelectedContract(restorePreviousContract);
      setLoading(false);
      return;
    }

    const limitData = await getContractLimits(selectedContractId);

    if (limitData) {
      handleDonutData(limitData);
    }

    const filesResponse = await getUploadedFiles(selectedContractId);
    deleteFiles(filesResponse);

    const contractFilesResponse = await getContractFiles(selectedContractId, contract.contractVersion);
    if (contractFilesResponse) {
      setContractFiles(contractFilesResponse?.documents);
    }

    setLoading(false);
    setSelectedContract(contract);

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [contractList.find((i) => i.id === selectedContractId)],
        previouslySelectedContracts: [contractList.find((i) => i.id === selectedContract.id)],
      },
    });
    updateRouteIfMounted(isMounted.current, () =>
      props.history.push(`/${productType.Factoring}/contracts/contract/${selectedContractId}`)
    );
  };

  const increaseLimitSubmit = async () => {
    onContractAction("success", t("success"), t("contractFunctionsSuccess"));
  };

  const prolongSubmit = async () => {
    onContractAction("success", t("success"), t("contractFunctionsSuccess"));
  };

  const addNewBuyerSubmit = async (isAdded) => {
    if (isAdded) {
      onContractAction("success", t("success"), t("contractFunctionsSuccess"));
    } else {
      onContractAction("error", t("error"), t("serviceUnavailable"));
    }
  };

  const openUploadMessage = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
      duration: alertTimeout,
    });
  };

  const deleteIncreaseLimitFile = async (fileId) => {
    await deleteIncreaseLimitUploadedFile(fileId);

    await filesChanged();
  };

  const deleteProlongFile = async (fileId) => {
    await deleteIncreaseLimitUploadedFile(fileId);

    await filesChanged();
  };

  const filesChanged = async () => {
    const filesResponse = await getUploadedFiles(selectedContract?.id);

    setUploadedFiles(filesResponse);
  };

  const deleteFiles = async (filesResponse) => {
    if (filesResponse && filesResponse.length) {
      filesResponse.forEach(async (i) => {
        if (i.type === "CONTRACT_INCREASE_LIMIT_ATTACHMENT") {
          await deleteIncreaseLimitUploadedFile(i?.uuid);
        } else {
          await deleteProlongUploadedFile(i?.uuid);
        }
      });
    }
  };

  const onContractAction = (type, message, description) => {
    openUploadMessage(type, message, description);
  };

  const showBuyerDetails = (data) => {
    setShowDetails(true);
    setBuyerDetails(data);
  };

  const hideBuyerDetails = () => {
    setShowDetails(false);
    setBuyerDetails(null);
  };

  const downloadContractFile = async (fileId) => {
    await getContractFile(fileId);
  };

  const handleDonutData = (totalsData) => {
    donutData[factoringAmounts.TOTAL].amount = totalsData.factoringCreditLimit ?? 0;
    donutData[factoringAmounts.TOTAL].currency = totalsData.currency;

    donutData[factoringAmounts.AVAILABLE].amount = totalsData.availableFactoringCreditLineAmount ?? 0;
    donutData[factoringAmounts.AVAILABLE].currency = totalsData.currency;

    donutData[factoringAmounts.OVERDUE].amount = totalsData.overdueInvoicesAmount ?? 0;
    donutData[factoringAmounts.OVERDUE].currency = totalsData.currency;

    donutData[factoringAmounts.USED].amount = totalsData.usedFactoringCreditLineAmount ?? 0;
    donutData[factoringAmounts.USED].currency = totalsData.currency;

    setDonutData(donutData.map((object) => ({ ...object })));
  };

  return !showDetails ? (
    <div className={"contractListCollapse factoring"}>
      <Spin spinning={contractListLoading} tip={spinConfiguration.tip}>
        {!!contractList.length && selectedContract && (
          <ContractSelector
            selectedContract={contractList.find((i) => i.id === selectedContract.id)}
            contractList={contractList}
            contractSelected={contractSelected}
            type="contracts"
          />
        )}
      </Spin>
      <Spin spinning={loading} tip={spinConfiguration.tip}>
        {!!contractList.length &&
          selectedContract &&
          ((<ContractHeader isActive={selectedContract} item={selectedContract} />),
          (
            <ContractDetails
              contract={selectedContract}
              contractFiles={contractFiles}
              downloadContractFile={downloadContractFile}
              contractList={contractList}
              productFid={productFid}
              donutData={donutData}
              increaseLimitSubmit={increaseLimitSubmit}
              prolongSubmit={prolongSubmit}
              addNewBuyerSubmit={addNewBuyerSubmit}
              prolong={postProlong}
              increaseLimit={postIncreaseLimit}
              addBuyer={postAddBuyer}
              getIncreaseLimitFileUploadData={getIncreaseLimitFileUploadData}
              uploadedFiles={uploadedFiles}
              getProlongFileUploadData={getProlongFileUploadData}
              deleteIncreaseLimitFile={deleteIncreaseLimitFile}
              deleteProlongFile={deleteProlongFile}
              filesChanged={filesChanged}
              showIncreaseLimitView={showIncreaseLimitView}
              contractFunctionEnabled={contractFunctionEnabled}
              newBuyerEnabled={newBuyerEnabled}
              showBuyerDetails={showBuyerDetails}
            />
          ))}
      </Spin>
    </div>
  ) : (
    <div>
      <BuyerDetails details={buyerDetails} contract={selectedContract} hideBuyerDetails={hideBuyerDetails} />
    </div>
  );
};

export default Contract;
