import { compose, createStore } from '@reduxjs/toolkit';
import { applyMiddleware} from 'redux'
import reducers from './reducers';
import reduxImutableStateInvariant from "redux-immutable-state-invariant";
import thunk from 'redux-thunk'

 function configureStore(initialState){
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(thunk, reduxImutableStateInvariant()))
  );
}
const store = configureStore();
export default store;