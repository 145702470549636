import React, { useEffect, useState, useContext } from "react";

import { useTranslation } from "react-i18next";
import { permissionTypes } from "../../../utils/statusUtils";
import { hasPermission } from "../../../services/userService";
import { AuthContext } from "../../../App";

import { Row, Col, Modal } from "antd";

const UploadStatus = (props) => {
  const { state } = useContext(AuthContext);
  const [showSubbmitButton, setShowSubbmitButton] = useState(true);
  const { t } = useTranslation();
  const [uploadStatusModalVisible, setUploadStatusModalVisible] = useState(false);
  const [uploadInvoiceStatus, setUploadInvoiceStatus] = useState(null);

  useEffect(() => {
    setUploadInvoiceStatus(props.uploadInvoiceStatus);
    setUploadStatusModalVisible(props.uploadStatusModalVisible);
    setShowSubbmitButton(hasPermission(state.parties, permissionTypes.ApproveInvoices));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.uploadStatusModalVisible, props.uploadInvoiceStatus, state.parties]);

  return (
    <>
      <Modal
        width="53.8125rem"
        forceRender
        maskClosable={false}
        className={`uploadStatus validationStatus  ${
          uploadInvoiceStatus && !uploadInvoiceStatus.data ? "success" : "error"
        }`}
        title={uploadInvoiceStatus && !uploadInvoiceStatus.data ? t("validationSuccessful") : t("validationErrors")}
        visible={uploadStatusModalVisible}
        okText={props.showNextStep ? t("continue") : t("submitForFinancing")}
        cancelText={t("back")}
        onCancel={() => {
          setUploadStatusModalVisible(false);
        }}
        onOk={() => {
          props.submitForFinancing();
          setUploadStatusModalVisible(false);
        }}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          disabled: uploadInvoiceStatus?.data?.validInvoiceCount === 0 || !showSubbmitButton,
          className: "button button--primary button--large",
        }}
      >
        {uploadInvoiceStatus &&
          uploadInvoiceStatus.data &&
          uploadInvoiceStatus.data.invalidInvoiceCount > 0 &&
          uploadInvoiceStatus.data.errors && (
            <Row>
              <Col className="message" span={21}>
                <div>
                  {t("totalInvoices")}: {uploadInvoiceStatus?.data?.totalInvoiceCount}
                </div>{" "}
                <p>
                  {t("correctInvoices")}: {uploadInvoiceStatus?.data?.validInvoiceCount}
                </p>
                <p className="incorrectInvoices">{t("incorrectInvoices")}: </p>
                <Row>
                  <Col span={6}>{t("invoiceNo")}</Col>
                  <Col span={18}>{t("errors")}</Col>
                </Row>
                {uploadInvoiceStatus.data.errors.map((item, i) => (
                  <Row key={item.invoiceNumber}>
                    <Col span={6}>{item.invoiceNumber}</Col>
                    <Col span={18}>{t(item.errorCode)}</Col>
                  </Row>
                ))}
              </Col>
            </Row>
          )}

        {uploadInvoiceStatus && !uploadInvoiceStatus.data && (
          <Row>
            <Col className="message" span={21}>
              <p>{t("invoiceValidationSuccessful")}</p>
            </Col>
          </Row>
        )}

        {uploadInvoiceStatus?.data?.validInvoiceCount > 0 && (
          <Row>
            <Col className="invoiceValidationFooter" span={21}>
              <p>{t("invoiceValidationErrorFooter")}?</p>
            </Col>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default UploadStatus;
