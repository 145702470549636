import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import uuid from "uuid/v4";
import i18n from "config/i18n";

import { Form, Result } from "antd";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";

import Button from "components/shared/Button";
import LoadingSpin from "components/shared/LoadingSpin";
import BankSelector from "components/nordigen/BankSelector";
import BankStatementForm from "./BankStatementForm";

import {
  postStatementBankSelection,
  postStatementFile,
  saveToLocalStorage,
  getFromLocalStorage,
  cleanLocalStorage,
  getBankList,
  getCountries,
} from "services/statementService";
import { errorNotification } from "utils/notificationUtils";
import settings from "config/config";

const BankStatementFormController = () => {
  const { t } = useTranslation();

  const [formInstance] = Form.useForm();
  const [formValues, setFormValues] = useState();
  const [bankSelectorModalOpen, setBankSelectorModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);

  const [visitedBanks, setVisitedBanks] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();

  const location = useLocation();
  const params = queryString.parse(location.search);
  const language = i18n.language;

  useEffect(() => {
    const { visited, ...storedData } = getFromLocalStorage();
    cleanLocalStorage();

    if (params.ref) {
      if (storedData?.md5_code === params.ref && !params.error) {
        (async () => {
          const response = await postStatementFile(
            storedData.formValues,
            storedData.md5_code,
            storedData.requisition_id
          );
          setFormValues(storedData.formValues);

          if (!response.ok) {
            setVisitedBanks(...visited.previous);
            setError(true);
          } else {
            setVisitedBanks([...visited.previous, visited.current]);
            setFormValues(storedData.formValues);
            setSuccess(true);
          }
        })();
      } else {
        errorNotification(t("error"), t("g4rFormErrorWrongRef"), 5);
        setRender(true);
      }
    } else {
      fillCountries();
      setRender(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBankSelect = async (bankId) => {
    setBankSelectorModalOpen(false);
    if (!bankId) {
      setLoading(false);
      return;
    }

    const md5Code = uuid().replace(/-/g,"");
    const response = await postStatementBankSelection(bankId, md5Code, language.toUpperCase());

    if (!response.ok || !response.payload.url) {
      setLoading(false);
      return errorNotification(t("error"), t("g4rFormErrorBank"), 5);
    }

    saveToLocalStorage({
      formValues: formInstance.getFieldsValue(),
      md5_code: md5Code,
      requisition_id: response.payload.requisition_id,
      visited: {
        current: bankId,
        previous: visitedBanks,
      },
    });

    window.location.href = response.payload.url;
  };

  const onFinish = async () => {
    setLoading(true);
    setBankSelectorModalOpen(true);
  };

  const continueProcess = () => {
    fillCountries();
    setSuccess(false);
    setError(false);
    setRender(true);
    setBankSelectorModalOpen(true);
  };

  const redirectHome = () => {
    window.location.href = settings.homeUrl;
  };

  const fillCountries = () => {
    getCountries().then((response) => {
      if (response.ok) {
        setFormValues({
          ...formValues,
          countries: response.payload,
        });
      }
    });
  };

  return (
    <>
      <div className="public-form">
        <div className="public-form__header">
          <h1 className="public-form__heading">{t("bankStatementTitle")}</h1>
          <div className="public-form__description-box">
            <p>
              {t("g4rDescriptionFirstLine")}
              <br />
              {t("g4rDescriptionSecondLine")}
            </p>
            <p>
              <a href={`tel:${t("bankStatementFormPhoneNumber")}`}>{t("bankStatementFormPhoneNumber")}</a>
              <br />
              <a href={`mailto:${t("bankStatementFormEmailAddress")}`}>{t("bankStatementFormEmailAddress")}</a>
            </p>
          </div>
        </div>
        <div className="public-form__form-block block">
          {error && (
            <div>
              <Result
                icon={<FrownOutlined className="public-form__state-icon" />}
                title={t("bankStatementFormErrorState")}
                extra={
                  <Button type="primary" size="large" onClick={continueProcess}>
                    {t("bankStatementFormErrorButton")}
                  </Button>
                }
              />
            </div>
          )}

          {success && (
            <div>
              <Result
                icon={<SmileOutlined className="public-form__state-icon" />}
                title={t("bankStatementFormSuccessState")}
                extra={
                  <div className="public-form__state-action-buttons">
                    <Button type="primary" size="large" onClick={redirectHome}>
                      {t("bankStatementFormSuccessButtonHome")}
                    </Button>
                    <Button type="primary" size="large" onClick={continueProcess}>
                      {t("bankStatementFormSuccessButtonBack")}
                    </Button>
                  </div>
                }
              />
            </div>
          )}
          {!error &&
            !success &&
            ((render && (
              <BankStatementForm
                onFinish={onFinish}
                onSelectCountry={setSelectedCountry}
                formInstance={formInstance}
                initialValues={formValues}
                loading={loading}
              />
            )) || <LoadingSpin />)}
          {bankSelectorModalOpen && selectedCountry && (
            <BankSelector
              onFinish={onBankSelect}
              bankSelectionFn={getBankList(selectedCountry)}
              visitedBanks={visitedBanks}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BankStatementFormController;
