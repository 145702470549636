import React from "react";
import { useTranslation } from "react-i18next";

import Button from "components/shared/Button";

const SubmitButton = ({ enabled = true, loading = false, textTranslationId = "g4rFormSubmit", ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button disabled={!enabled} loading={loading} type="primary" size="large" htmlType="submit" {...rest}>
      {t(textTranslationId)}
    </Button>
  );
};

export default SubmitButton;
