import { getAuthHeader } from "./guardService";

import settings from "../config/config";

import { invoiceStatuses } from "../utils/statusUtils";

const getInvoiceVerificationUrl = settings.backendApiUrl + "/invoice/buyer-verification/contact";

const getInvoiceListApiUrl = settings.backendApiUrl + "/invoices";
const getInvoiceApiUrl = settings.backendApiUrl + "/invoice";
const getUpcommingPaymentsUrl = settings.backendApiUrl + "/invoice/upcoming-payments";

const getExportToExcelFileUrl = settings.backendApiUrl + "/invoices:export";

const getActsListApiUrl = settings.backendApiUrl + "/acts";

const getContractLimitsUrl = settings.backendApiUrl + "/invoices/totals";
const getMultipleContractLimitsUrl = settings.backendApiUrl + "/general-dashboard/factoring/totals-multiple-contracts";

const automaticInvoiceUploadUrl = settings.backendApiUrl + "/v2/invoice/upload";

const getUploadedInvoicesUrl = settings.backendApiUrl + "/invoice/upload";

const getInvoicePayoutsUrl = settings.backendApiUrl + "/payouts";
const getInvoicePaymentReceiptsUrl = settings.backendApiUrl + "/payment-receipts";
const getInvoiceInterestFeesUrl = settings.backendApiUrl + "/interest-fees-acts";
const getInvoiceLateFeesUrl = settings.backendApiUrl + "/late-fees-acts";
const getInvoiceCommissionFeesUrl = settings.backendApiUrl + "/commission-fees-acts";

const deleteInvoiceUrl = settings.backendApiUrl + "/invoice/upload";

const postManualInvoiceUploadUrl = settings.backendApiUrl + "/invoice/manual-upload";

const validateInvoicesUrl = settings.backendApiUrl + "/invoice/upload/{contractId}/validate";

const submitUnrecognizedFiledUrl = settings.backendApiUrl + "/invoice/upload/{contractId}";

const submitInvoicesUrl = settings.backendApiUrl + "/invoice/upload";
const sendEmailForFinancingUrl = settings.backendApiUrl + "/invoice/upload/{contractId}/buyer-verification:send";

const uploadFilesManuallyUrl = settings.backendApiUrl + "/invoice/attachment-upload";

const deleteInvoiceUploadAttachmentFileUrl = settings.backendApiUrl + "/invoice/attachment-upload";

const getAttachmentFilesUrl = settings.backendApiUrl + "/invoice/uploaded-files";

const getSummaryUrl = settings.backendApiUrl + "/invoice/upload/summary";

const getExcelFileApiUrl = settings.backendApiUrl + "/acts:export";

const getInvoiceVerifyUrl = settings.backendApiUrl + "/public/invoice/buyer/unverified";
const postInvoiceVerifyUrl = settings.backendApiUrl + "/public/invoice/buyer/verify";
const postAdditionalAttachmentUrl = settings.backendApiUrl + "/invoice/attachment-upload/additional";
const postStoreAdditionalAttachmentUrl = settings.backendApiUrl + "/invoice/store/additional-attachment";

export const getInvoiceList = async (contractIds, page, size, filter, sort) => {
  try {
    let url = new URL(getInvoiceApiUrl);
    url.search = new URLSearchParams({
      contractId: contractIds.join(),
      page: page,
      size: size,
      sort: sort ? sort : "",
    });

    Object.entries(filter).map(([key, val]) => {
      if (key === "invoiceStatus" && val.indexOf(",") > -1) {
        const invoiceStatusParts = val.split(",");

        invoiceStatusParts.map((i) => {
          url.searchParams.append("invoiceStatus", i);
          return null;
        });
      } else {
        url.searchParams.append(key, val);
      }

      return null;
    });

    const searchParams = Object.fromEntries(url.searchParams);

    if (!searchParams.invoiceStatus && settings.allActiveInvoiceStatuses) {
      settings.allActiveInvoiceStatuses.split(",").forEach((i) => {
        url.searchParams.append("invoiceStatus", i);
      });
    }

    const response = await fetch(url, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    let invoicesList = await response.json();
    return invoicesList;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getExportToExcelFile = async (contractIds, page, size, filter, sort) => {
  try {
    let url = new URL(getExportToExcelFileUrl);
    url.search = new URLSearchParams({
      contractId: contractIds.join(),
    });

    Object.entries(filter).map(([key, val]) => {
      if (key === "invoiceStatus" && val.indexOf(",") > -1) {
        const invoiceStatusParts = val.split(",");

        invoiceStatusParts.map((i) => {
          url.searchParams.append("invoiceStatus", i);
          return null;
        });
      } else {
        url.searchParams.append(key, val);
      }

      return null;
    });

    const searchParams = Object.fromEntries(url.searchParams);

    if (!searchParams.invoiceStatus && settings.allActiveInvoiceStatuses) {
      settings.allActiveInvoiceStatuses.split(",").forEach((i) => {
        url.searchParams.append("invoiceStatus", i);
      });
    }

    const response = await fetch(url, getAuthHeader());

    const blob = await response.blob();

    const fileName = new Date().toJSON().replace(/\..*$/g, "").replace(/:/g, "-").replace("T", "_");

    const url_ = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url_;
    link.setAttribute("download", `invoices_${fileName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getUpcommingPayments = async (contractId, page, size, sort) => {
  try {
    let url = new URL(getUpcommingPaymentsUrl);
    url.search = new URLSearchParams({
      contractId: contractId,
      page: page ?? "",
      size: size,
      sort: sort ?? "dueDate,ASC",
    });

    if (settings.upcomingPaymentsInvoiceStatuses) {
      settings.upcomingPaymentsInvoiceStatuses.split(",").forEach((i) => {
        url.searchParams.append("invoiceStatus", i);
      });
    }

    const response = await fetch(url, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    let listItems = await response.json();
    console.log(listItems)
    return listItems;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getActs = async (contractId, page, size, partyId, filter, sort) => {
  try {
    let url = new URL(getActsListApiUrl);
    url.search = new URLSearchParams({
      contractId,
      page,
      size,
      partyId,
      ignoreUnbilledFeeActs: true,
      sort: sort ? sort : "",
    });

    filter.map(({ key, value }) => {
      url.searchParams.append(key, value);

      return null;
    });

    const response = await fetch(url, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    let listItems = await response.json();
    return listItems;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getContractLimits = async (contractId) => {
  try {
    let url = new URL(getContractLimitsUrl);
    url.search = new URLSearchParams({
      contractId: contractId,
    });
    const response = await fetch(url, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const invoiceBuyersList = await response.json();
    return invoiceBuyersList;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
export const getMultipleContractLimits = async (partyId) => {
  try {
    let url = new URL(getMultipleContractLimitsUrl);
    url.search = new URLSearchParams({
      partyId: partyId
    });
    const response = await fetch(url, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const contractsTotalAmounts = await response.json();
    return contractsTotalAmounts;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoice = async (invoiceId, contractId, filter, page, size) => {
  try {
    let url = new URL(`${getInvoiceListApiUrl}/${invoiceId}`);
    url.search = new URLSearchParams({
      contractId: contractId.join(),
      page: page,
      size: size,
    });

    Object.entries(filter).map(([key, val]) => {
      if (key === "invoiceStatus" && val.indexOf(",") > -1) {
        const invoiceStatusParts = val.split(",");

        invoiceStatusParts.map((i) => {
          url.searchParams.append("invoiceStatus", i);
          return null;
        });
      } else {
        url.searchParams.append(key, val);
      }

      return null;
    });

    const searchParams = Object.fromEntries(url.searchParams);

    if (!searchParams.invoiceStatus && settings.allActiveInvoiceStatuses) {
      settings.allActiveInvoiceStatuses.split(",").forEach((i) => {
        url.searchParams.append("invoiceStatus", i);
      });
    }
    const response = await fetch(url, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const invoice = await response.json();
    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoicePayouts = async (invoiceId) => {
  try {
    const response = await fetch(`${getInvoicePayoutsUrl}?invoiceId=${invoiceId}`, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const items = await response.json();

    return items;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoicePaymentReceipts = async (invoiceId) => {
  try {
    const response = await fetch(`${getInvoicePaymentReceiptsUrl}?invoiceId=${invoiceId}`, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const items = await response.json();

    return items;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoiceInterestFees = async (invoiceId) => {
  try {
    const response = await fetch(`${getInvoiceInterestFeesUrl}?invoiceId=${invoiceId}`, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const items = await response.json();

    return items;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoiceLateFees = async (invoiceId) => {
  try {
    const response = await fetch(`${getInvoiceLateFeesUrl}?invoiceId=${invoiceId}`, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const items = await response.json();

    return items;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoiceCommissionFees = async (invoiceId) => {
  try {
    const response = await fetch(`${getInvoiceCommissionFeesUrl}?invoiceId=${invoiceId}`, getAuthHeader());

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const items = await response.json();

    return items;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postManualInvoiceUpload = async (
  contractId,
  files,
  { buyerName, buyerCode, currency, dueDate, invoiceAmount, invoiceNumber, issueDate, transferClaimAmount }
) => {
  try {
    const suffix = files.length ? "?fileUploadUuids=" + files.join() : "";
    const url = new URL(`${postManualInvoiceUploadUrl}/${contractId}${suffix}`);

    const data = {
      buyerName,
      buyerCode,
      currency,
      dueDate: dueDate.format().split("T")[0],
      invoiceAmount,
      invoiceNumber,
      issueDate: issueDate.format().split("T")[0],
      transferClaimAmount,
    };
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok && response.status !== 400) {
      throw Error(response.statusText);
    }

    const invoice = await response.json();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getUploadedInvoices = async (contractId, sort) => {
  try {
    const response = await fetch(
      `${getUploadedInvoicesUrl}/${contractId}?size=1000` + (sort ? `&sort=${sort}` : ""),
      getAuthHeader()
    );
    if (!response.ok) {
      throw Error(response.statusText);
    }

    const list = await response.json();

    return list;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const validateInvoices = async (contractId) => {
  try {
    const response = await fetch(validateInvoicesUrl.replace("{contractId}", contractId), {
      method: "POST",
      headers: {
        ...getAuthHeader().headers,
      },
    });

    const invoice = await response.json();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const submitUnrecognizedFiles = async (contractId) => {
  try {
    const response = await fetch(submitUnrecognizedFiledUrl.replace("{contractId}", contractId), {
      method: "POST",
      headers: {
        ...getAuthHeader().headers,
      },
    });

    const invoice = await response.json();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const submitInvoices = async (contractId) => {
  try {
    const response = await fetch(`${submitInvoicesUrl}/${contractId}`, {
      method: "POST",
      headers: {
        ...getAuthHeader().headers,
      },
    });

    const invoice = await response.json();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const sendEmailForFinancing = async (contractId, data) => {
  try {
    const response = await fetch(`${sendEmailForFinancingUrl.replace("{contractId}", contractId)}`, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const invoice = await response.json();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const removeInvoices = async (invoiceIds) => {
  try {
    const url = new URL(deleteInvoiceUrl);
    url.search = new URLSearchParams({
      uuids: invoiceIds.join(","),
    });

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        ...getAuthHeader().headers,
      },
    });

    const invoice = await response.text();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const deleteInvoiceUploadAttachmentFile = async (uuids) => {
  try {
    const url = new URL(deleteInvoiceUploadAttachmentFileUrl);
    url.search = new URLSearchParams({
      uuids: uuids,
    });

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        ...getAuthHeader().headers,
      },
    });

    const invoice = await response.text();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getCustomInvoiceStatuses = () => {
  return {
    [invoiceStatuses.Overdue]: settings.overdueInvoiceStatus,
    [invoiceStatuses.Financed]: settings.financedInvoiceStatus,
  };
};

export const getAutomaticInvoiceUploadRequestData = (contractId) => {
  return {
    action: automaticInvoiceUploadUrl + "?contractId=" + contractId,
    ...getAuthHeader(),
  };
};

export const getUploadFilesManuallyData = (contractId) => {
  return {
    action: uploadFilesManuallyUrl + (contractId ? "?contractId=" + contractId : ""),
    ...getAuthHeader(),
  };
};

export const getAttachmentFiles = async (contractId) => {
  try {
    const response = await fetch(`${getAttachmentFilesUrl}/${contractId}`, getAuthHeader());

    const list = await response.json();

    return list;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoiceSummarry = async (contractId) => {
  try {
    const response = await fetch(`${getSummaryUrl}?contractId=${contractId}`, getAuthHeader());

    const summary = await response.json();

    return summary;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const editInvoice = async (contractId, data) => {
  try {
    const fetchData = {
      ...data,
      dueDate: data.dueDate.format().split("T")[0],
      issueDate: data.issueDate.format().split("T")[0],
    };

    const response = await fetch(`${getUploadedInvoicesUrl}/${contractId}`, {
      method: "PUT",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(fetchData),
    });

    if (!response.ok && response.status !== 400) {
      throw Error(response.statusText);
    }

    const invoice = await response.json();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoiceVerify = async (key, thirdPartyId) => {
  try {
    const response = await fetch(
      getInvoiceVerifyUrl + (thirdPartyId ? "?thirdPartyId=" + thirdPartyId : ""),
      getAuthHeader(key)
    );
    const responseJson = await response.json();

    return responseJson;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postInvoiceVerify = async (key, data) => {
  try {
    const response = await fetch(postInvoiceVerifyUrl, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader(key).headers,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return { error: true };
    }

    const responseText = await response.text();

    return responseText;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getInvoiceVerification = async (invoiceId, contractId, thirdPartyId, buyerVerificationId) => {
  try {
    let url = new URL(getInvoiceVerificationUrl);
    url.search = new URLSearchParams({
      invoiceId,
      contractId,
      thirdPartyId,
      ...(buyerVerificationId ? { buyerVerificationId } : null),
    });

    const response = await fetch(url, getAuthHeader());

    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getActExcelFile = async (fileId) => {
  try {
    const url = new URL(getExcelFileApiUrl);
    url.search = new URLSearchParams({
      feesActId: fileId,
    });

    const response = await fetch(url, getAuthHeader());
    const blob = await response.blob();

    if (blob.size) {
      const fileName = new Date().toJSON().replace(/\..*$/g, "").replace(/:/g, "-").replace("T", "_");

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.setAttribute("download", `act_${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postStoreAdditionalAttachments = async (data) => {
  try {
    const url = new URL(postStoreAdditionalAttachmentUrl);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const responseText = await response.text();
    return responseText;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postAdditionalAttachmentData = (contractId) => {
  return {
    action: postAdditionalAttachmentUrl + "?contractId=" + contractId,
    ...getAuthHeader(),
  };
};

export const deleteAdditionalAttachmentFiles = async (uuids) => {
  try {
    if (!Array.isArray(uuids) || !uuids.length) {
      return { error: true };
    }

    const url = new URL(deleteInvoiceUploadAttachmentFileUrl);
    url.search = new URLSearchParams({
      uuids: uuids.join(","),
      fileTypes: "INVOICE_ADDITIONAL_ATTACHMENT",
    });

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        ...getAuthHeader().headers,
      },
    });

    const responseText = await response.text();

    return responseText;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
