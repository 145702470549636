import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Upload, Row, Col, Spin, Tooltip } from "antd";
import createDOMPurify from "dompurify";

import settings from "../../config/config";

import { validateFiles, fileSizeIsValid } from "./../../utils/fileUtils";

import { CloseOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const UploadAutomatically = (props) => {
  const { t } = useTranslation();

  const DOMPurify = createDOMPurify(window);

  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const [supportingFiles, setSupportingFiles] = useState([]);

  const [invoiceFilesUploadLoading, setInvoiceFilesUploadLoading] = useState(false);

  const onChange = async (info, callback) => {
    const { status } = info.file;

    if (status !== "uploading") {
    }
    if (status === "done") {
    } else if (status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }

    if (info.fileList.length === info.fileList.filter((i) => i.status !== "uploading").length) {
      callback(info);
    }
  };

  const onInvoiceFilesChange = (info) => {
    const newList = info.fileList
      .filter((file) => {
        if (
          file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          file.status !== "uploading" &&
          file.status !== "error"
        ) {
          return file.response.invoiceErrors?.length !== 0;
        }
        return file.status !== "done";
      })
      .map((file) => {
        if (file.status !== "error") return file;

        if (file.response.code === "SME59") {
          file.response = t("SME59");
        }

        if (file.validationText) {
          file.response = file.validationText;
        }

        return file;
      });

    setInvoiceFiles(validateFiles([...newList]));

    setInvoiceFilesUploadLoading(true);

    onChange(info, async function (response) {
      if (response.file && !response.file.validationText) {
        props.addUploadedItems(response);
      }

      setInvoiceFiles([]);

      setInvoiceFilesUploadLoading(false);
    });
  };

  const onSupportingDocumentsChange = (info) => {
    const newList = info.fileList
      .filter((file) => file.status !== "done")
      .map((file) => {
        if (file.status !== "error") return file;

        if (file.response.code === "SME9" || file.response.code === "SME11") {
          file.response = t("SME11");
        }

        if (file.response.code === "SME32" || file.response.code === "SME33") {
          file.response = t("SME32");
        }

        if (file.validationText) {
          file.response = file.validationText;
        }

        return file;
      });

    if (info.fileList.length === info.fileList.filter((i) => i.status !== "uploading").length) {
      props.addAutomatiSupportedFiles();
    }

    setSupportingFiles([...newList]);
  };

  const beforeInvoiceFilesUpload = (file, fileList) => {
    if (!fileSizeIsValid(file.size)) {
      file.validationText = t("fileSizeValidationMessage");
      file.hasError = true;
      file.status = "error";
      file.response = { code: "" };
      setInvoiceFiles([...fileList, file]);
      return false;
    }

    return true;
  };

  const beforeSuportingFilesUpload = (file, fileList) => {
    if (!fileSizeIsValid(file.size)) {
      file.validationText = t("fileSizeValidationMessage");
      file.hasError = true;
      file.status = "error";
      file.response = { code: "" };
      setSupportingFiles([...fileList, file]);
      return false;
    }

    return true;
  };

  const automaticInvoiceUploadRequestData = props.getAutomaticInvoiceUploadRequestData(props.contract.id);

  const uploadSupportingFilesData = props.getUploadFilesManuallyData(props.contract.id);

  return (
    <div className="block uploadAutomatically">
      <div className="descriptionWrapper">
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              t("automaticUploadDescription").replace(/<a>/, `<a href="${settings.invoiceTemplateUrl}">`)
            ),
          }}
        ></p>
      </div>
      <Row>
        <Col span="11">
          <Spin spinning={invoiceFilesUploadLoading} tip={t("loading") + "..."}>
            <Dragger
              name="file"
              accept={settings.file.types}
              multiple={true}
              fileList={invoiceFiles}
              beforeUpload={beforeInvoiceFilesUpload}
              action={automaticInvoiceUploadRequestData.action}
              onChange={onInvoiceFilesChange}
              headers={automaticInvoiceUploadRequestData.headers}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon"></p>
              <p className="ant-upload-text">+ {t("invoiceUploadUploadAutomaticallyUploadInvoices")}</p>
              <p className="ant-upload-hint">{t("or")}</p>
              <p className="ant-upload-hint">{t("dragDropDescription")}</p>
            </Dragger>
          </Spin>
        </Col>
        <Col span="11" className="supportingDocumentsDragger">
          <Dragger
            name="file"
            multiple={true}
            fileList={supportingFiles}
            beforeUpload={beforeSuportingFilesUpload}
            showUploadList={{
              showRemoveIcon: true,
              removeIcon: (
                <Tooltip title={t("removeFile")}>
                  <CloseOutlined />
                </Tooltip>
              ),
            }}
            action={uploadSupportingFilesData.action}
            onChange={onSupportingDocumentsChange}
            headers={uploadSupportingFilesData.headers}
          >
            <p className="ant-upload-drag-icon"></p>
            <p className="ant-upload-text">+ {t("invoiceUploadUploadAutomaticallyAttachDocuments")}</p>
            <p className="ant-upload-hint">{t("or")}</p>
            <p className="ant-upload-hint">{t("dragDropSupportingFilesDescription")}</p>
          </Dragger>
        </Col>
      </Row>
    </div>
  );
};

export default UploadAutomatically;
