import React, { useState } from "react";
import { Row, Col, Input, Spin, Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Modal from "../../../components/shared/Modal";

import { defaultLabelText, requiredField } from "../../../utils/formUtils";

const countries = [
  {
    code: "EE",
    dial_code: "+372",
    latitude: 59,
    longitude: 26,
    name: "Estonia",
  },
  {
    code: "LV",
    dial_code: "+371",
    latitude: 57,
    longitude: 25,
    name: "Latvia",
  },
  {
    code: "LT",
    dial_code: "+370",
    latitude: 56,
    longitude: 24,
    name: "Lithuania",
  },
];

const { Option } = Select;

const EditSmartIdLogin = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [personalCodeText, setPersonalCodeText] = useState(defaultLabelText);
  const [countryLabelText, setCountryLabelText] = useState(defaultLabelText);

  return (
    <>
      <Modal
        width="48.125rem"
        className="modal personal-information-action blue editSmartId"
        title={t("accountEditSmartIdLoginTitle")}
        visible={props.modalVisible}
        okText={t("save")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          form.resetFields();
          props.onModalClose(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setLoading(true);
              const result = await props.changeAccount(values);
              setLoading(false);

              if (result.error) {
                form.setFields([
                  {
                    name: "country",
                    errors: [t("accountSmartIdError")],
                  },
                ]);
              } else {
                props.onModalSave();
                form.resetFields();
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Spin spinning={loading} tip={t("loading") + "..."}>
          <Row className="description">
            <Col className="text" span={24}>
              <p>{t("editSmartExplanationText")}</p>
            </Col>
          </Row>
          <Row className="current">
            <Col span={12}>
              <label>{t("accountCurrentCountry")}</label>
              <p>{props.currentCountry || "—"}</p>
            </Col>
            <Col span={12}>
              <label>{t("accountCurrentPersonalCode")}</label>
              <p>{props.currentPersonalCode || "—"}</p>
            </Col>
          </Row>
          <Row className="action">
            <Col span={24}>
              <Form
                layout="vertical"
                form={form}
                initialValues={{
                  phoneCode: "+370",
                }}
              >
                <Form.Item
                  label={countryLabelText}
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: t("accountNewCountry") + " " + t("isRequired"),
                    },
                  ]}
                  style={{ display: "inline-block", width: "calc(50%)" }}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{
                      width: "100%",
                    }}
                    onChange={(value) =>
                      setCountryLabelText(
                        value
                          ? requiredField(t("accountNewCountry"))
                          : defaultLabelText
                      )
                    }
                    placeholder={requiredField(t("accountNewCountry"))}
                  >
                    {props.countries.length &&
                      props.countries
                        .filter((i) =>
                          countries.map((j) => j.code).includes(i.codeAlpha2)
                        )
                        .map((item, i) => (
                          <Option key={i} value={item.codeAlpha2}>
                            {item.translations[i18next.language.toUpperCase()]}
                          </Option>
                        ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={personalCodeText}
                  name="personal_code"
                  style={{ display: "inline-block", width: "calc(50%)" }}
                  rules={[
                    {
                      required: true,
                      message: t("personalCode") + " " + t("isRequired"),
                    },
                  ]}
                >
                  <Input
                    onChange={(event) => {
                      const { value } = event.target;

                      form.setFieldsValue({
                        personalCode: value,
                      });

                      setPersonalCodeText(
                        event.target.value
                          ? t("accountNewPersonalCode")
                          : defaultLabelText
                      );
                    }}
                    placeholder={requiredField(t("accountNewPersonalCode"))}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default EditSmartIdLogin;
