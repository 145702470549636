// FACTORING PRODUCT
export const factoringAmounts = {
  TOTAL: 0,
  USED: 1,
  OVERDUE: 2,
  AVAILABLE: 3,
};

export const factoringDataInitBuild = (invoiceStatuses) => {
  const data = [{}, {}, {}, {}];
  const donutActionPrefix = `/factoring/invoices/?contractId={contractId}&page=1&size=10`;

  data[loansAmounts.DISBURSED] = {
      title: "total",
      amount: 0,
      calculatedAmount: 0,
      currency: undefined,
      description: "donutTotalMsg",
      action: {
        title: "increaseLimit",
        action: "/factoring/contracts?openIncreaseLimitView",
      },
      color: "#d0d0d0",
      include: false,
  }
  data[loansAmounts.REPAYMENT] = {
      title: "used",
      amount: 0,
      calculatedAmount: 0,
      currency: undefined,
      description: "donutUsedMsg",
      action: {
        title: "view",
        action: donutActionPrefix + `&invoiceStatus=${invoiceStatuses.Financed},${invoiceStatuses.Overdue}`,
      },
      color: "#2b64f5",
      include: true,
  }
  data[loansAmounts.OVERDUE] = {
      title: "overdue",
      amount: 0,
      calculatedAmount: 0,
      currency: undefined,
      description: "donutOverdueMsg",
      action: {
        title: "view",
        action: donutActionPrefix + `&invoiceStatus=${invoiceStatuses.Overdue}`,
      },
      color: "#ff555e",
      include: true,
      list: [],
  }
  data[loansAmounts.REPAID] = {
      title: "available",
      amount: 0,
      calculatedAmount: 0,
      currency: undefined,
      description: "donutAvailableMsg",
      action: {
        title: "uploadInvoice",
        action: "/invoiceUpload",
      },
      color: "#b5f09c",
      include: true,
  }

  return data;
} 


// LOANS PRODUCT
export const loansAmounts = {
  DISBURSED: 0,
  REPAYMENT: 1,
  OVERDUE: 2,
  REPAID: 3,
};

export const loanDataInitBuild = () => {
    const data = [{}, {}, {}, {}];
    data[loansAmounts.DISBURSED] = {
        title: "loanDisbursed",
        amount: 0,
        calculatedAmount: 0,
        currency: undefined,
        description: "loanDisbursedDescription",
        action: {
          title: "",
          action: "/loan/contracts/{contractId}",
        },
        color: "#d0d0d0",
        include: false,
    }
    data[loansAmounts.REPAYMENT] = {
        title: "loanRepaymentLeft",
        amount: 0,
        calculatedAmount: 0,
        currency: undefined,
        description: "loanRepaymentLeftDescription",
        action: {
          title: "",
          action: "",
        },
        color: "#2b64f5",
        include: true,
    }
    data[loansAmounts.OVERDUE] = {
        title: "loanOverdue",
        amount: 0,
        calculatedAmount: 0,
        currency: undefined,
        description: "loanOverdueDescription",
        action: {
          title: "view",
          action: "/loan/bills/?contractId={contractId}&page=1&size=10&&status=OVERDUE",
        },
        color: "#ff555e",
        include: true,
        list: [],
    }
    data[loansAmounts.REPAID] = {
        title: "loanRepaidAmount",
        amount: 0,
        calculatedAmount: 0,
        currency: undefined,
        description: "loanRepaidAmountDescription",
        action: {
          title: "view",
          action: "/loan/bills/?contractId={contractId}&page=1&size=10&billsFilterPrinciple=true&status=PAID",
        },
        color: "#b5f09c",
        include: true,
    }

    return data;
} 