export const moneyFormatter = (value, currencyCode) => {
  if (value) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: currencyCode ? currencyCode : "EUR",
    }).format(value);
  }

  return value === 0 ? `0,00 ${currencyCodeToSign(currencyCode)}` : value;
};

export const currencyCodeToSign = (currencyCode) => {
  let currencyText = "";

  switch (currencyCode) {
    case "EUR":
      currencyText = "\u20AC";
      break;
    default:
      currencyText = "\u20AC";
      break;
  }

  return currencyText;
};

export const parseAmount = (amount) => {
  if (amount === undefined) {
    return undefined;
  }

  return parseFloat(amount.replace(/\./g, "").replace(",", ".")).toFixed(2);
};

export const formatAmount = (amount) => {
  if (amount === undefined) {
    return undefined;
  }

  const amountFixed = parseFloat(amount).toFixed(2);

  return amountFixed
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
