import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Input from "components/shared/form/Input";
import HiddenInput from "components/shared/form/HiddenInput";
import InputTooltip from "components/forms/formElements/InputTooltip";
import InputSlider from "components/shared/form/InputSlider";

import { getCalculatorMonthlyPaymentSetup, getCalculatorMontlyPayment, APPLICANT_TYPES } from "services/formService";
import { errorNotification } from "utils/notificationUtils";

const LoanCalculatorBlock = ({ formInstance, readOnly = true, editable = false, tooltipText }) => {
  const { t } = useTranslation();

  const [setup, setSetup] = useState();
  const [monthlyPaymentResponse, setMonthlyPaymentResponse] = useState();

  useEffect(() => {
    if (!editable || readOnly) {
      return;
    }

    getCalculatorMonthlyPaymentSetup(formInstance.getFieldValue("financial_product_type")).then((response) => {
      if (response.ok) {
        getMonthlyPaymentData(getInitialMonthlyPaymentRequest(response));
        setSetup(response.payload);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialDefaultMonthlyPaymentRequest = (response) => {
    return {
      productType: formInstance.getFieldValue("financial_product_type"),
      assetAmount: response.payload.asset_amount.default_value,
      periodMonths: response.payload.period_months.default_value,
    };
  };

  const initialPartlyFilledMonthlyPaymentRequest = {
    productType: formInstance.getFieldValue("financial_product_type"),
    assetAmount: formInstance.getFieldValue(["lending", "property_value"]),
    periodMonths: formInstance.getFieldValue(["lending", "period"]),
  };

  const getInitialMonthlyPaymentRequest = (response) => {
    const isPartlyFilledFormByPartner =
      formInstance.getFieldValue(["lending", "prefill"]) &&
      APPLICANT_TYPES.isDefault(formInstance.getFieldValue(["applicant", "role"]));

    return isPartlyFilledFormByPartner
      ? initialPartlyFilledMonthlyPaymentRequest
      : initialDefaultMonthlyPaymentRequest(response);
  };

  const getMonthlyPaymentData = async (monthlyPaymentRequest) => {
    if (!editable || !monthlyPaymentRequest || isNaN(monthlyPaymentRequest.assetAmount)) {
      return;
    }

    const response = await getCalculatorMontlyPayment({ ...monthlyPaymentRequest });
    if (!response.ok) {
      errorNotification(t("error"), t("calculationError"), 5);
      return;
    }

    formInstance.setFieldsValue({
      lending: {
        property_value: response.payload.asset_amount,
        period: response.payload.period_month,
        monthly_payment: response.payload.monthly_payment_amount,
      },
    });

    setMonthlyPaymentResponse(response.payload);
  };

  const onAssetAmountChange = (val) => {
    const monthlyPaymentRequest = {
      productType: formInstance.getFieldValue("financial_product_type"),
      assetAmount: val ?? setup.asset_amount.default_value,
      periodMonths: monthlyPaymentResponse ? monthlyPaymentResponse.period_month : setup.period_months.default_value,
    };
    getMonthlyPaymentData(monthlyPaymentRequest);
  };

  const onPeriodMonthChange = (val) => {
    const monthlyPaymentRequest = {
      productType: formInstance.getFieldValue("financial_product_type"),
      assetAmount: monthlyPaymentResponse ? monthlyPaymentResponse.asset_amount : setup.asset_amount.default_value,
      periodMonths: val ?? setup.period_months.default_value,
    };
    getMonthlyPaymentData(monthlyPaymentRequest);
  };

  return (
    <>
      <HiddenInput name={["lending", "type"]} />
      {editable && setup && monthlyPaymentResponse ? (
        <>
          <Row>
            <Col className="slider-col" xs={24} sm={12}>
              <InputSlider
                label={t("leasingFormLoanAmount")}
                inputName={["lending", "property_value"]}
                type="decimal"
                value={monthlyPaymentResponse.asset_amount}
                onChange={onAssetAmountChange}
                sliderMin={setup.asset_amount.min}
                sliderMax={setup.asset_amount.max}
                sliderMinMaxSuffix={" €"}
              />
            </Col>
            <Col className="slider-col" xs={24} sm={12}>
              <InputSlider
                label={t("g4rFormTerm")}
                inputName={["lending", "period"]}
                type="decimal"
                value={monthlyPaymentResponse.period_month}
                onChange={onPeriodMonthChange}
                sliderMin={setup.period_months.min}
                sliderMax={setup.period_months.max}
                sliderMinMaxSuffix={" " + t("g4rFormTermShort")}
                sliderMarks={setup.period_months.values}
              />
            </Col>
          </Row>
          <Row>
            <Col className="monthly-payment-col" xs={24} sm={12}>
              <Row>
                <Col>
                  <label>
                    {t("leasingFormMonthlyPayment")} <InputTooltip innerHtml={tooltipText} />
                  </label>
                </Col>
                <Col>
                  <div className="monthly-payment">{monthlyPaymentResponse.monthly_payment_amount.toFixed(2)} €</div>
                  <HiddenInput name={["lending", "monthly_payment"]} />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Input
                label={t("leasingFormLoanAmount")}
                name={["lending", "property_value"]}
                type="decimal"
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Input label={t("g4rFormTerm")} name={["lending", "period"]} disabled={true} />
            </Col>
            <Col xs={24} sm={12}>
              <Input
                label={t("g4rFormMonthlyPay")}
                name={["lending", "monthly_payment"]}
                type="decimal"
                disabled={true}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default LoanCalculatorBlock;
