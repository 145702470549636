import React, { useState } from "react";
import { Row, Col, Spin, Form, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import Modal from "components/shared/Modal";

import { factoringPermissions, loanPermissions } from "utils/statusUtils";
import { productType } from "utils/partiesUtils";
import { getEditUserCurrentRolesText, getEditUserNewRolesText } from "utils/translationIdUtils";

const EditUser = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const availablePermissions = Object.entries(
    props.currentProductType === productType.Loan?.toUpperCase() ? loanPermissions : factoringPermissions
  ).map((i) => ({
    label: t(i[1]),
    value: i[0],
    disabled: i[0] === "read",
  }));

  const currentPermissions = props.currentPermissions
    ? [...new Set([...props.currentPermissions?.map((permission) => permission.type?.toLowerCase()), ...["read"]])]
    : [];

  return (
    <>
      <Modal
        width="48.125rem"
        className="modal personal-information-action edit-user blue"
        title={t("accountEditUserTitle")}
        visible={props.modalVisible}
        okText={t("save")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          form.resetFields();
          props.onModalClose(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setLoading(true);
              const result = await props.changeUserPermissions(props.email, {
                permissions: values?.permissions?.map((i) => i.toUpperCase()),
                product_type: props.currentProductType,
              });
              setLoading(false);

              if (result.error) {
                form.setFields([
                  {
                    name: "permissions",
                    errors: [t("accountChangeUserRolesError")],
                  },
                ]);
              } else {
                props.onModalSave(values);
                form.resetFields();
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Spin spinning={loading} tip={t("loading") + "..."}>
          <Row className="description">
            <Col className="text" span={24}>
              <p>{t("editUserInfoText")}</p>
            </Col>
          </Row>
          <Row className="current action">
            <Col span={12}>
              <label>{props.currentProductType && t(getEditUserCurrentRolesText(props.currentProductType))}</label>

              <Checkbox.Group defaultValue={currentPermissions}>
                {availablePermissions.map((i, j) => (
                  <Row key={j}>
                    <Col span={24}>
                      <Checkbox value={i.value} disabled={true} style={{ lineHeight: "32px" }}>
                        {i.label}
                      </Checkbox>
                    </Col>
                  </Row>
                ))}
              </Checkbox.Group>
            </Col>
            <Col span={12}>
              <Form
                layout="vertical"
                form={form}
                initialValues={{
                  permissions: ["read"],
                }}
              >
                <Form.Item
                  name="permissions"
                  label={props.currentProductType && t(getEditUserNewRolesText(props.currentProductType))}
                >
                  <Checkbox.Group>
                    {availablePermissions.map((i, j) => (
                      <Row key={j}>
                        <Col span={24}>
                          <Checkbox value={i.value} disabled={i.disabled} style={{ lineHeight: "32px" }}>
                            {i.label}
                          </Checkbox>
                        </Col>
                      </Row>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default EditUser;
