import React from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import Col from "components/shared/Col";
import Row from "components/shared/Row";
import SubmitButton from "components/forms/formElements/SubmitButton";
import PartnerHeader from "./partners/PartnerHeader";

import { PRODUCT_TYPES } from "services/formService";

const ProductTypeSelector = ({ partnerToken, onSelect = () => undefined }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="public-form">
        <PartnerHeader partnerToken={partnerToken} />
        <div className="public-form__form-block block">
          <Form className="form leasing-request-form">
            <h3 className="leasing-request-form__heading">{t("leasingFormTypeSelect")}</h3>
            <Row className="leasing-form-selector__wrapper">
              <Col span={12} className="leasing-form-selector__submit">
                <SubmitButton
                  textTranslationId={t("productTypeLeasing")}
                  onClick={() => {
                    onSelect(PRODUCT_TYPES.LEASING.toLowerCase());
                  }}
                />
              </Col>
              <Col span={12} className="leasing-form-selector__submit">
                <SubmitButton
                  textTranslationId={t("productLoan")}
                  onClick={() => {
                    onSelect(PRODUCT_TYPES.LOAN.toLowerCase());
                  }}
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ProductTypeSelector;
