import React, { useState, useEffect, useContext } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import ButtonLink from "components/shared/ButtonLink";
import Button from "components/shared/Button";
import apiWrapper from "services/apiWrapper";
import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { Table } from "antd";
import cx from "classnames";

import { getCountriesWithPhoneCodes, getCountries } from "services/accountService";
import { getSchedulesList } from "services/hwService";

import { AuthContext } from "App";

const Schedules = ({ contract, onGetPdfFile }) => {
  const { t } = useTranslation();
  const pageSizes = ["10", "20", "30"];
  const defaultPageSize = parseInt(pageSizes[0]);
  const { state: authState, dispatch } = useContext(AuthContext);

  const [historyOnly, setHistoryOnly] = useState(false);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schedulesList, setSchedulesList] = useState([]);
  const [pagination, setPagination] = useState({
    defaultPageSize: defaultPageSize,
    defaultCurrent: 1,
    showSizeChanger: true,
    pageSizeOptions: pageSizes,
    locale: { items_per_page: "" },
    showTitle: false,
  });

  const language = i18next.language;

  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  const tableColums = [
    {
      title: t("loanPaymentDate"),
      dataIndex: "paymentDate",
      className: "first-column",
      render: (value, item) => dateFormatter(value),
      width: 150,
    },
    {
      title: t("loanUnpaidAmount"),
      dataIndex: "unpaidAmount",
      render: (value, item) => moneyFormatter(value, item.currencyCode),
      width: 200,
    },
    {
      title: t("loanInterestAmount"),
      dataIndex: "interestAmount",
      render: (value, item) => moneyFormatter(value, item.currencyCode),
      width: 200,
    },
    {
      title: t("loanPrincipalPartAmount"),
      dataIndex: "principalPartAmount",
      className: "used",
      render: (value, item) => moneyFormatter(value, item.currencyCode),
      width: 200,
    },
    {
      title: t("loanVatTotal"),
      dataIndex: "vatTotal",
      render: (value, item) => moneyFormatter(value, item.currencyCode),
      width: 150,
    },
    {
      title: t("loanTotalAmount"),
      dataIndex: "totalAmount",
      className: "insuredFactoringLimit",
      render: (value, item) => moneyFormatter(value, item.currencyCode),
      width: 100,
    },
  ];

  useEffect(() => {
    getSchedulesListWrapper(pagination.defaultCurrent, pagination.defaultPageSize, historyOnly, null, null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  useEffect(() => {
    async function fetchData() {
      const countriesWithPhoneCodesResponse = apiWrapper(await getCountriesWithPhoneCodes(), dispatch);
      const countriesWithPhoneCodes = countriesWithPhoneCodesResponse;

      countriesWithPhoneCodes.sort((a, b) => (a.dial_code > b.dial_code ? 1 : -1));

      const countriesResponse = apiWrapper(await getCountries(), dispatch);
      const countiesList = countriesResponse?.countries;

      countiesList.sort((a, b) =>
        a.translations[i18next.language.toUpperCase()] > b.translations[i18next.language.toUpperCase()] ? 1 : -1
      );
      setCountries(countiesList);
    }
    if (authState.user) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  useEffect(() => {
    countries.sort((a, b) =>
      a.translations[i18next.language.toUpperCase()] > b.translations[i18next.language.toUpperCase()] ? 1 : -1
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const onTableSortClick = async (paginationData, filters, sorter) => {
    const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";

    getSchedulesListWrapper(paginationData.current, paginationData.pageSize, historyOnly, sorter.field, sortOrder);
  };

  const getSchedulesListWrapper = async (page, size, history, sortField, sortOrder) => {
    setLoading(true);
    const schedulesList = await getSchedulesList(
      contract.companyId,
      contract.id,
      history,
      page,
      size,
      sortField,
      sortOrder
    );

    setSchedulesList(schedulesList?.schedules ? schedulesList.schedules : []);

    setPagination((pagination) => ({
      ...pagination,
      total: schedulesList.count,
      current: page,
      pageSize: size,
    }));

    setLoading(false);
  };

  const onToggleHistoryClicked = async () => {
    getSchedulesListWrapper(pagination.defaultCurrent, pagination.defaultPageSize, !historyOnly, null, null);
    setHistoryOnly(!historyOnly);
  };

  return (
    <>
      <div className="schedules flex flex-direction-column">
        <div className="flex flex-jc-space-between">
          <h3>{t("loanSchedules")}</h3>

          <div>
            {contract?.pdfFileExists && (
              <ButtonLink className="buyersList__download-pdf" onClick={async () => await onGetPdfFile()}>
                {t("loanDownloadSchedule")}
              </ButtonLink>
            )}

            <Button
              type="default"
              size="small"
              className={cx({
                history: historyOnly,
              })}
              onClick={onToggleHistoryClicked}
            >
              {t(historyOnly ? "loanFutureSchedules" : "loanHistoricalSchedules")}
            </Button>
          </div>
        </div>

        <div className="table-wrapper">
          <Table
            columns={tableColums}
            dataSource={schedulesList}
            rowKey={(record, index) => index}
            loading={spinConfiguration}
            onChange={onTableSortClick}
            pagination={schedulesList?.length ? pagination : false}
          ></Table>
        </div>
      </div>
    </>
  );
};

export default Schedules;
