import { AuthContext } from "App";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useContext } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { isActiveProductByType, productType } from "utils/partiesUtils";
import { permissionTypes } from "utils/statusUtils";
import { hasPermission } from "services/userService";
import Button from "components/shared/Button";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

const TopMenu = (props) => {
  const [showUploadButton, setShowUploadButton] = useState(false);
  const { state } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    setShowUploadButton(hasPermission(state.parties, permissionTypes.UploadInvoices));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeProductFid]);
  
  const { isEmptySsp: isDisabledMenuItem } = useContext(AuthContext);
  const menuItemState = isDisabledMenuItem ? "disabled-menu-item" : "";

  return (
    <nav className="top-menu">
      <Row>
        <Col>
          <ul>
            {props.routes.map((link) => (
              <li key={link.title}>
                <NavLink
                  activeClassName="nav-active"
                  to={link.path}
                  className={`top-menu__link ${menuItemState}`}
                  exact={link.exact}
                  disabled={isDisabledMenuItem}
                >
                  {link.title}
                </NavLink>
              </li>
            ))}
          </ul>
        </Col>
        <Col span="5">
          {showUploadButton && isActiveProductByType(state.parties, productType.Factoring) && (
            <Button className={`uploadInvoices ${!state.isEmptySsp ? "" : "disabled-menu-item"}`} type="primary">
              <Link to={!state.isEmptySsp ? "/invoiceUpload" : "#"}>{t("uploadInvoices")}</Link>
            </Button>
          )}
        </Col>
      </Row>
    </nav>
  );
};

export default withRouter(TopMenu);
