import { getAuthHeader } from "./guardService";

import settings from "../config/config";

const getPdfFileApiUrl = settings.backendApiUrl + "/documents";

export const getPdfFile = async (fileId) => {
  try {
    const response = await fetch(`${getPdfFileApiUrl}/${fileId}`, getAuthHeader());

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
