import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Prolong from "./actions/prolong";
import IncreaseLimit from "./actions/increaseLimit";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Button from "components/shared/Button";
import StatusHeader from "components/contract/statusHeader";
import ContractCollaterals from "./contractCollaterals";

import { contractFunctionIsActive } from "utils/statusUtils";
import { dateFormatter } from "utils/dateUtils";
import { moneyFormatter } from "utils/moneyUtils";
import { percentageNumberFormatter } from "utils/numberUtils";

const BasicContractInfo = (props) => {
  const { t } = useTranslation();

  const [prolongModalVisible, setProlongModalVisible] = useState(false);

  const [increaseLimitModalVisible, setIncreaseLimitModalVisible] = useState(false);

  const [contractStatus, setContractStatus] = useState("");

  const [contractCollateralsModalVisible, setContractCollateralsModalVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIncreaseLimitModalVisible(props.showIncreaseLimitView);
    }, 0);
  }, [props.showIncreaseLimitView]);

  useEffect(() => {
    const contract = props.contractList.find((listItem) => listItem.id === props.contract.id);

    if (contract) setContractStatus(contract.status);
  }, [props.contract, props.contractList]);

  const onProlongClick = () => {
    setProlongModalVisible(true);
  };

  const onProlongSave = async (data) => {
    setProlongModalVisible(false);
    await props.prolongSubmit(data);
  };

  const onProlongClose = () => {
    setProlongModalVisible(false);
  };

  const onIncreaseLimitClick = () => {
    setIncreaseLimitModalVisible(true);
  };

  const onIncreaseLimitSave = async (data) => {
    setIncreaseLimitModalVisible(false);
    await props.increaseLimitSubmit(data);
  };

  const onIncreaseLimitClose = () => {
    setIncreaseLimitModalVisible(false);
  };

  const renderStatus = () => {
    switch (contractStatus) {
      case "ACTIVE":
        return "contractsActive";
      case "EXPIRED":
        return "contractsExpired";
      case "MANUALLY_SUSPENDED":
        return "contractsSuspended";
      default:
        return contractStatus;
    }
  };

  const showCollateralsButton = () => {
    return (
      props.contract?.recourseConditions?.billsOfExchange ||
      props.contract?.recourseConditions?.sureties ||
      props.contract?.recourseConditions?.otherCollaterals
    );
  };

  return (
    <>
      <IncreaseLimit
        contract={props.contract}
        productFid={props.productFid}
        increaseLimit={props.increaseLimit}
        onIncreaseLimitSave={onIncreaseLimitSave}
        modalVisible={increaseLimitModalVisible}
        onIncreaseLimitClose={onIncreaseLimitClose}
        currentLimit={props.contract.factoringCreditLimit}
        getIncreaseLimitFileUploadData={props.getIncreaseLimitFileUploadData}
        currencyCode={"Eur"}
        uploadedFiles={props.uploadedFiles}
        filesChanged={props.filesChanged}
        deleteIncreaseLimitFile={props.deleteIncreaseLimitFile}
      />
      <Prolong
        contract={props.contract}
        productFid={props.productFid}
        prolong={props.prolong}
        onProlongSave={onProlongSave}
        modalVisible={prolongModalVisible}
        onIncreaseLimitClose={onProlongClose}
        validTo={props.contract.contractTerm.endDate}
        getProlongFileUploadData={props.getProlongFileUploadData}
        uploadedFiles={props.uploadedFiles}
        filesChanged={props.filesChanged}
        deleteProlongFile={props.deleteProlongFile}
      />

      <div className="basicContractInfo">
        <Row
          className={cx("contractStatus", {
            activeStatus: contractStatus === "ACTIVE",
            expiredStatus: contractStatus === "EXPIRED",
            suspendedStatus: contractStatus === "MANUALLY_SUSPENDED",
          })}
        >
          <Col span={24} className="contract-status-header">
            <div className="flex flex-jc-end">
              <StatusHeader translationId={renderStatus()} status={contractStatus} />
            </div>
          </Col>
          <Col span={12}>
            <label>{t("typeOfFactoring")}</label>
            <p>
              {props.contract.factoringProduct
                ? t(`factoringProduct${props.contract.factoringProduct.toLowerCase()}`)
                : ""}
              <span> {t("factoring")} </span>
            </p>

            <label>{t("factoringLimit")}</label>
            <p>
              {moneyFormatter(props.contract.factoringCreditLimit)}
              {props.contract && contractFunctionIsActive(props.contract.status) && props.contractFunctionEnabled && (
                <Button
                  className="margin-left-for-prolong-button"
                  type="primary"
                  size="small"
                  onClick={onIncreaseLimitClick}
                >
                  {t("increase")}
                </Button>
              )}
            </p>
          </Col>
          <Col span={11} className="padding-after-border">
            <label>{t("financier")}</label>
            <p>{props.contract.factor.name}</p>

            <div className="flex flex-jc-space-between">
              <div>
                <label>{t("contractsValidFrom")}</label>
                <p>{dateFormatter(props.contract.contractTerm.startDate)}</p>
              </div>

              <div>
                <label>{t("contractsValidTo")}</label>
                <p>
                  {dateFormatter(props.contract.contractTerm.endDate)}
                  {props.contract && contractFunctionIsActive(props.contract.status) && props.contractFunctionEnabled && (
                    <Button
                      className="margin-left-for-prolong-button"
                      type="primary"
                      size="small"
                      onClick={onProlongClick}
                    >
                      {t("prolong")}
                    </Button>
                  )}
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="basicContractDetails">
          <Col span={12} className={"border-right"}>
            <div>
              <label>{t("contractsMaxDueTerm")}</label>
              <p>
                {props.contract.thirdPartyConstraints.maxInvoicePaymentTerm}
                &nbsp;
                {t("days")}
              </p>
              <label>{t("contractsClientsInsurance")}</label>
              <p>
                {props.contract.recourseConditions.insolvencyRiskInsuredByClient ? t("applicable") : t("notApplicable")}
              </p>
              <label>{t("contractsFinanciersInsurance")}</label>
              <p>
                {props.contract.recourseConditions.insolvencyRiskInsuredByFactor ? t("applicable") : t("notApplicable")}
              </p>
              <label>{t("contractsClientsResponsability")}</label>
              <p>{percentageNumberFormatter(props.contract.recourseConditions.clientLiabilityRate * 100)} %</p>
            </div>
          </Col>
          <Col span={12} className={"padding-after-border"}>
            <label>{t("contractsAdvance")}</label>
            <p>{percentageNumberFormatter(props.contract.thirdPartyConstraints.maxAdvanceRate * 100)} %</p>
            <label>{t("contractsInterest")}</label>
            <p>{percentageNumberFormatter(props.contract.interestRate * 100)} %</p>

            <label>{t("contractsCommissions")}</label>
            <p>{percentageNumberFormatter(props.contract.commissionFeeConditions.commissionFeeRate * 100)} %</p>
            <label>{t("contractsLateFee")}</label>
            <p className="late-fee">
              {percentageNumberFormatter(props.contract.lateFeeConditions.lateFeeRate * 100)} %
            </p>

            {!!showCollateralsButton() && (
              <p>
                <Button
                  className="collaterals"
                  type="default"
                  size="small"
                  onClick={() => setContractCollateralsModalVisible(true)}
                >
                  {t("factoringCollateral")}
                </Button>
              </p>
            )}
          </Col>
        </Row>
      </div>
      <ContractCollaterals
        visible={contractCollateralsModalVisible}
        onClose={() => setContractCollateralsModalVisible(false)}
        billOfExchange={
          props.contract?.recourseConditions?.billsOfExchange
            ? [props.contract?.recourseConditions?.billsOfExchange]
            : []
        }
        sureties={props.contract?.recourseConditions?.sureties ? [props.contract?.recourseConditions?.sureties] : []}
        pledges={
          props.contract?.recourseConditions?.otherCollaterals
            ? [props.contract?.recourseConditions?.otherCollaterals]
            : []
        }
        pledgeInvega={props.contract?.recourseConditions?.pledgeInvega}
        currencyCode={props.contract?.financingCurrencyCode}
      />
    </>
  );
};

export default BasicContractInfo;
