import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Form, Input, Select, DatePicker } from "antd";
import moment from "moment";
import CurrencyInput from "../../shared/CurrencyInput";
import { dateIsLaterThanToday, dateIsEarlierThanToday } from "../../../utils/dateUtils";
import { defaultLabelText, requiredField } from "../../../utils/formUtils";
import apiWrapper from "../../../services/apiWrapper";
import { getContractBuyersList } from "../../../services/contractService";
import { AuthContext } from "../../../App";
import settings from "../../../config/config";
import { parseAmount, formatAmount } from "../../../utils/moneyUtils";

const { Option } = Select;

const EditInvoice = ({
  invoiceEditVisible,
  contract,
  setInvoiceEditVisible,
  invoiceEditData,
  editInvoiceSubmit,
  setInvoiceEditData,
  uploadedInvoices,
}) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const [form] = Form.useForm();

  const [contractBuyersList, setContractBuyersList] = useState([]);
  const [labelText, setLabelText] = useState({
    invoiceNumber: defaultLabelText,
    buyer: defaultLabelText,
    supplier: defaultLabelText,
    issueDate: defaultLabelText,
    dueDate: defaultLabelText,
    amount: defaultLabelText,
    transferClaim: defaultLabelText,
    invoiceFiles: defaultLabelText,
    supportingDocuments: defaultLabelText,
  });

  useEffect(() => {
    async function fetchData() {
      const contractBuyersListResponse = apiWrapper(await getContractBuyersList([contract.id]), dispatch);

      if (contractBuyersListResponse?.thirdParties) {
        contractBuyersListResponse.thirdParties.sort(function (a, b) {
          return a.name[0].localeCompare(b.name[0]);
        });

        setContractBuyersList(contractBuyersListResponse.thirdParties);
      }
    }

    if (contract?.id) {
      fetchData();
    }

    resetForm();
    setLabelText({
      invoiceNumber: requiredField(t("invoiceNumber")),
      buyer: requiredField(t("buyer")),
      supplier: requiredField(t("supplier")),
      issueDate: requiredField(t("issueDate")),
      dueDate: requiredField(t("dueDate")),
      amount: requiredField(t("amount")),
      transferClaim: requiredField(t("transferClaim")),
      invoiceFiles: requiredField(t("invoiceFiles")),
      supportingDocuments: requiredField(t("supportingDocuments")),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, invoiceEditData]);

  const updateLabel = (name, value) => {
    setLabelText({
      ...labelText,
      [name]: value ? requiredField(t(name)) : defaultLabelText,
    });
  };

  const resetForm = () => {
    form.resetFields();
    resetLabels();
  };

  const resetLabels = () => {
    const resetedLabelText = { ...labelText };
    for (const property in resetedLabelText) {
      resetedLabelText[property] = defaultLabelText;
    }

    setLabelText(resetedLabelText);
  };

  const disabledIssueDate = (current) => {
    return current && dateIsLaterThanToday(current);
  };

  const disabledDueDate = (current) => {
    return current && dateIsEarlierThanToday(current);
  };

  const onFinish = async (values) => {
    if (dateIsEarlierThanToday(values.dueDate)) {
      form.setFields([
        {
          name: "dueDate",
          errors: [t("invoiceDateDateValidationMessage")],
        },
      ]);
    } else {
      const newBuyerName = contractBuyersList.find((item) => item.registrationCode === values.buyerCode).name;
      editInvoiceSubmit({
        ...values,
        invoiceAmount: parseAmount(values.invoiceAmount),
        transferClaimAmount: parseAmount(values.transferClaimAmount),
        buyerName: newBuyerName,
        invoiceUuid: invoiceEditData.uuid,
      });

      setInvoiceEditVisible(false);
      setInvoiceEditData(null);
    }
  };

  const validateInvoiceNumber = (value) => {
    if (
      uploadedInvoices
        .filter((invoice) => invoice.invoiceNumber !== invoiceEditData.invoiceNumber)
        .findIndex((invoice) => invoice.invoiceNumber === value) !== -1
    ) {
      return Promise.reject(t("existingInvoiceValidationMessage"));
    }

    return Promise.resolve();
  };

  return (
    <Modal
      maskClosable={false}
      className="edit-invoice-modal"
      title="Edit Invoice"
      visible={invoiceEditVisible}
      okText={t("submit")}
      cancelText={t("back")}
      getContainer={false}
      width={"48.125rem"}
      onCancel={() => {
        setInvoiceEditVisible(false);
        setInvoiceEditData(null);
      }}
      onOk={() => {
        form.submit();
      }}
      cancelButtonProps={{
        className: "button button--default button--large",
      }}
      okButtonProps={{
        className: "button button--primary button--large",
      }}
    >
      <Form
        form={form}
        className="edit-invoice-form"
        layout="vertical"
        initialValues={{
          modifier: "public",
          currency: "eur",
          invoiceNumber: invoiceEditData?.invoiceNumber,
          buyerCode: invoiceEditData?.buyerCode,
          issueDate: moment(invoiceEditData?.issueDate),
          dueDate: moment(invoiceEditData?.dueDate),
          invoiceAmount: formatAmount(invoiceEditData?.invoiceAmount),
          transferClaimAmount: formatAmount(invoiceEditData?.transferClaimAmount),
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="invoiceNumber"
          label={labelText["invoiceNumber"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyInvoiceNumber") + " " + t("isRequired"),
            },
            {
              validator: (_, value) => validateInvoiceNumber(value),
            },
          ]}
        >
          <Input
            onChange={(event) => updateLabel("invoiceNumber", event.target.value)}
            placeholder={requiredField(t("invoiceUploadUploadManuallyInvoiceNumber"))}
          />
        </Form.Item>
        <Form.Item
          name="buyerCode"
          label={labelText[contract?.factoringProduct !== "REVERSE" ? "buyer" : "supplier"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message:
                t(
                  contract?.factoringProduct !== "REVERSE"
                    ? "invoiceUploadUploadManuallyBuyer"
                    : "invoiceUploadUploadManuallySupplier"
                ) +
                " " +
                t("isRequired"),
            },
          ]}
        >
          <Select
            placeholder={requiredField(
              t(
                contract?.factoringProduct !== "REVERSE"
                  ? "invoiceUploadUploadManuallyBuyer"
                  : "invoiceUploadUploadManuallySupplier"
              )
            )}
            style={{ width: "100%" }}
            onChange={(value) => updateLabel(contract?.factoringProduct !== "REVERSE" ? "buyer" : "supplier", value)}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {contractBuyersList.map((item) => (
              <Option key={item.id} value={item.registrationCode}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="issueDate"
          label={labelText["issueDate"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyIssueDate") + " " + t("isRequired"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const dueDate = getFieldValue("dueDate");
                if (!dueDate || (dueDate && dueDate >= value)) {
                  return Promise.resolve();
                }

                return Promise.reject(t("invoiceDateDateValidationMessage"));
              },
            }),
          ]}
        >
          <DatePicker
            disabledDate={disabledIssueDate}
            onChange={(value) => {
              updateLabel("issueDate", value);
            }}
            placeholder={requiredField(t("invoiceUploadUploadManuallyIssueDate"))}
            style={{
              cursor: "pointer",
            }}
          />
        </Form.Item>
        <Form.Item
          name="dueDate"
          label={labelText["dueDate"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyDueDate") + " " + t("isRequired"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const issueDate = getFieldValue("issueDate");

                if (!issueDate | (issueDate && moment(issueDate).startOf("day") < moment(value).startOf("day"))) {
                  return Promise.resolve();
                }

                return Promise.reject(t("invoiceDateDateValidationMessage"));
              },
            }),
          ]}
        >
          <DatePicker
            disabledDate={disabledDueDate}
            onChange={(value) => updateLabel("dueDate", value)}
            placeholder={requiredField(t("invoiceUploadUploadManuallyDueDate"))}
            style={{
              cursor: "pointer",
            }}
          />
        </Form.Item>
        <Form.Item
          label={labelText["amount"]}
          name="currency"
          className="currency"
          style={{
            display: "inline-block",
            width: "calc(3%)",
          }}
        >
          <Select disabled>
            {settings.currencies.length &&
              settings.currencies.map((item, i) => (
                <Option key={i} value={item.name}>
                  {item.sign}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="invoiceAmount"
          className="invoiceAmount"
          label={defaultLabelText}
          style={{
            display: "inline-block",
            width: "calc(44%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyAmount") + " " + t("isRequired"),
            },
          ]}
        >
          <CurrencyInput
            className="ant-input-number-input"
            placeholder={requiredField(t("invoiceUploadUploadManuallyAmount"))}
            onBlur={({ target: { value } }) => {
              const amount = parseAmount(value);

              form.setFieldsValue({
                invoiceAmount: formatAmount(amount),
                transferClaimAmount: formatAmount(amount),
              });
            }}
            onChange={({ target: { value } }) => {
              if (!value) return;

              form.setFieldsValue({
                transferClaimAmount: value,
              });

              setLabelText({
                ...labelText,
                amount: value ? t("invoiceUploadUploadManuallyAmount") : defaultLabelText,
                transferClaim: value ? t("invoiceUploadUploadManuallyTransferClaim") : defaultLabelText,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="transferClaimAmount"
          label={labelText["transferClaim"]}
          className="transferClaim"
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyTransferClaim") + " " + t("isRequired"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const invoiceAmountField = getFieldValue("invoiceAmount");
                const transferClaimAmount = parseAmount(value);

                const amount = parseAmount(invoiceAmountField);

                if (Math.abs(Number(transferClaimAmount)) <= Math.abs(Number(amount))) {
                  return Promise.resolve();
                }

                return Promise.reject(t("transferClaimBiggerThanAmountValidationMessage"));
              },
            }),
          ]}
        >
          <CurrencyInput
            className="ant-input-number-input"
            placeholder={requiredField(t("invoiceUploadUploadManuallyTransferClaim"))}
            onChange={({ target: { value } }) => {
              setLabelText({
                ...labelText,
                transferClaim: value ? t("invoiceUploadUploadManuallyTransferClaim") : defaultLabelText,
              });
            }}
            onBlur={({ target: { value } }) => {
              const amount = parseAmount(value);

              form.setFieldsValue({
                transferClaimAmount: formatAmount(amount),
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditInvoice;
