import settings from "config/config";
import fetchWrapper from "./fetchWrapper";
import {  } from "services/classifierService";

const classifierBaseUrl = settings.backendApiUrl + "/form/classifier/";

const classifierRequestWrapper = (list, group = undefined) =>
  fetchWrapper.getJSON(new URL(list, classifierBaseUrl), group ? { group } : undefined).then((response) => {
    if (!response.ok) {
      return [];
    }
    return response.payload.values?.map((item) => {
      return {
        key: item.code,
        value: item.code,
        title: item.description || item.code,
        label: item.description || item.code,
      };
    });
  });

export const getIncomeSources = async () => classifierRequestWrapper("income-sources");

export const getDocumentTypes = async () => classifierRequestWrapper("document-types");

export const getAssetBrands = async (scope) => classifierRequestWrapper("asset-brands", scope);

export const getAssetModels = async (manufacturer) => classifierRequestWrapper("asset-models", manufacturer);

export const getAssetFuelTypes = async () => classifierRequestWrapper("asset-fuel-types");

export const getAssetBodyTypes = async () => classifierRequestWrapper("asset-body-types");

export const getAssetYears = async () => classifierRequestWrapper("asset-years");

export const getAssetTaxes = async (scope) => classifierRequestWrapper("asset-taxes", scope);

export const getLeasePurposes = async (scope) => classifierRequestWrapper("lease-purposes", scope);

export const getLoanPurposes = async (scope) => classifierRequestWrapper("loan-purposes", scope);

export const getAssetDocumentType = async (productType) => classifierRequestWrapper("asset-document-type", productType);

export const getCallingCodes = async () => classifierRequestWrapper("asset-calling-codes");
