import React, { useState } from "react";
import { Row, Col, Input, Spin, Form, notification, Button } from "antd";
import { useTranslation } from "react-i18next";

import Modal from "../../../components/shared/Modal";
import FloatFormItem from "../../shared/floatFormItem";

const CheckPassword = (props) => {
  const alertTimeout = 20;
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const getTitle = (type) => {
    let title = "";

    switch (type) {
      case "email":
        title = t("accountEditEmailTitle");
        break;
      case "phone":
        title = t("accountEditPhoneTitle");
        break;
      case "sms":
        title = t("accountEditSmsPhoneTitle");
        break;
      case "smartid":
        title = t("accountEditSmartIdLoginTitle");
        break;
      case "mobileid":
        title = t("accountEditMobileIdLoginTitle");
        break;
      case "deleteUser":
        title = t("accountDeleteUserTitle");
        break;
      case "editUser":
        title = t("accountEditUserTitle");
        break;

      default:
        title = t("accountEditPhoneTitle");
        break;
    }

    return title;
  };

  const getDescription = (type) => {
    let title = "";

    switch (type) {
      case "email":
        title = t("editEmailExplanationTextP");
        break;
      case "phone":
        title = t("editPhoneExplanationTextP");
        break;
      case "sms":
        title = t("editSMSPhoneExplanationTextP");
        break;
      case "smartid":
        title = t("editSmartExplanationTextP");
        break;
      case "mobileid":
        title = t("editMobileExplanationTextP");
        break;
      case "deleteUser":
        title = t("deleteUserExplanationTextP");
        break;
      case "editUser":
        title = t("editUserExplanationTextP");
        break;

      default:
        title = t("accountEditPhoneTitle");
        break;
    }

    return title;
  };

  const changePassword = async () => {
    const response = await props.changePassword({ email: props.email });

    if (response.error) {
      form.setFields([
        {
          name: "password",
          errors: [t("2FAerror")],
        },
      ]);
    } else {
      notification["success"]({
        message: t("success"),
        description:
          response?.is_reset_password_email_sent &&
          t("resetPasswordLinkSentToEmail"),
        duration: alertTimeout,
      });

      props.onModalClose(false);
    }
  };

  return (
    <>
      <Modal
        width="48.125rem"
        className="modal personal-information-action blue check-password"
        title={getTitle(props.type)}
        visible={props.modalVisible}
        okText={t("save")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          form.resetFields();
          props.onModalClose(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setLoading(true);
              const result = await props.authorizeUser(values);
              setLoading(false);

              if (result.code === "SME34" || result.code === "SME5") {
                form.setFields([
                  {
                    name: "password",
                    errors: [t("passwordIncorrectUserAccount")],
                  },
                ]);
              } else {
                props.onModalSave(values);
                form.resetFields();
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Spin spinning={loading} tip={t("loading") + "..."}>
          <Row className="description">
            <Col className="text" span={24}>
              <p>{getDescription(props.type)}</p>
            </Col>
          </Row>
          <Row className="action">
            <Col span={24}>
              <Form layout="vertical" form={form}>
                <FloatFormItem
                  className="minimal-bmargin"
                  type={Input.Password}
                  label={t("password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("password-empty-validation"),
                      max: 255,
                    },
                  ]}
                />
                <Button
                  className="check-password__reset-password"
                  type="link"
                  onClick={changePassword}
                >
                  {t("accountResetPassword")}
                </Button>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default CheckPassword;
