import React from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import Tooltip from "components/shared/Tooltip";

const InputTooltip = ({ innerHtml = undefined }) => {
  return (
    (innerHtml && (
      <Tooltip placement="right" title={<p dangerouslySetInnerHTML={{ __html: innerHtml }} />}>
        <InfoCircleOutlined className="public-form__comment-icon" />
      </Tooltip>
    )) ||
    null
  );
};

export default InputTooltip;
