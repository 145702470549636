import settings from "../config/config";
import { getAuthHeader } from "./guardService";

const getCountriesApiUrl = "/data/countries.json";
const getCountriesListApiUrl = settings.backendApiUrl + "/countries";

const postMobileIdAuthenticateRequestApiUrl =
  settings.backendApiUrl + "/login/mobileid/authenticate-request";

const postMobileIdAuthenticateApiUrl =
  settings.backendApiUrl + "/login/mobileid/authenticate";

const postSmartIdAuthenticateRequestApiUrl =
  settings.backendApiUrl + "/login/smartid/authenticate-request";

const postSmartIdAuthenticateApiUrl =
  settings.backendApiUrl + "/login/smartid/authenticate";

export const getCountriesWithPhoneCodes = async () => {
  let items = [];
  const keyName = "countriesList";
  try {
    const savedItems = localStorage.getItem(keyName);

    if (!savedItems) {
      const response = await fetch(getCountriesApiUrl);

      items = await response.json();

      localStorage.setItem(keyName, JSON.stringify(items));
    } else {
      items = JSON.parse(savedItems);
    }

    return items;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getCountries = async () => {
  try {
    const response = await fetch(getCountriesListApiUrl, getAuthHeader());

    const summary = await response.json();

    return summary;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postMobileIdAuthenticateRequest = async (data) => {
  try {
    const response = await fetch(postMobileIdAuthenticateRequestApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const countries = await response.json();

    return countries;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postMobileIdAuthenticate = async (data) => {
  try {
    const response = await fetch(postMobileIdAuthenticateApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const authData = await response.json();

    return authData;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postSmartIdAuthenticateRequest = async (data) => {
  try {
    const response = await fetch(postSmartIdAuthenticateRequestApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const authData = await response.json();

    return authData;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postSmartIdAuthenticate = async (data) => {
  try {
    const response = await fetch(postSmartIdAuthenticateApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const authData = await response.json();

    return authData;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
