import settings from "../config/config";
import fetchWrapper from "./fetchWrapper";
import { getAuthHeader } from "./guardService";

const loginUserUrl = settings.backendApiUrl + "/login/username";
const loginUserBySmsUrl = settings.backendApiUrl + "/login/mfa/authenticate-request";
const authenticateUserBySmsUrl = settings.backendApiUrl + "/login/mfa/authenticate";
const reloginUserBySmsUrl = settings.backendApiUrl + "/login/mfa/resend-sms";
const logoutUserUrl = settings.backendApiUrl + "/logout/user";
const resetPasswordUrl = settings.backendApiUrl + "/user/reset-password";
const changePasswordUrl = settings.backendApiUrl + "/user/change-password";
const changePassworInternaldUrl = settings.backendApiUrl + "/user/reset-password/authenticated";
const getUserUrl = settings.backendApiUrl + "/user";
const postNewUserUrl = settings.backendApiUrl + "/user";
const deleteUserUrl = settings.backendApiUrl + "/user";
const getUserAccountUrl = settings.backendApiUrl + "/user/account";
const changeAccountUrl = settings.backendApiUrl + "/user/account";
const getPartyOfficialsUrl = settings.backendApiUrl + "/user/party-officials";
const authorizeUserUrl = settings.backendApiUrl + "/user/authorize";
const changeEmailUrl = settings.backendApiUrl + "/user/change-email";
const changeUserPermissionsUrl = settings.backendApiUrl + "/user/{username}/permissions";
const changeSmsLoginPhoneUrl = settings.backendApiUrl + "/user/mfa/change-phone";
const postTermAndConditionsStatusUrl = settings.backendApiUrl + "/user/terms-conditions:accept";
const getPartiesUrl = settings.backendApiUrl + "/user/parties";
const switchSelectedPartyUrl = settings.backendApiUrl + "/user/parties/switch";
const switchSelectedProductUrl = settings.backendApiUrl + "/user/products/switch";
const getBoPartiesUrl = settings.backendApiUrl + "/parties";
const verifyTokenUrl = settings.backendApiUrl + "/internal/password-change-token/verify";
const changeUserLanguageUrl = settings.backendApiUrl + "/user/language/switch";

export const initialState = {
  isAuthenticated: false,
  user: null,
  parties: null,
  token: null,
  activePartyFid: null,
  activeProductFid: null,
  selectedContracts: null,
};

export const loginUser = async (data) => {
  try {
    const response = await fetch(loginUserUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if (response.status) {
        const errorResponse = await response.json();

        return {
          error: true,
          errorCode: errorResponse.code,
        };
      }

      throw Error(response.statusText);
    }

    const loginResponse = await response.json();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const loginUserBySms = async (data) => {
  try {
    const response = await fetch(loginUserBySmsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if (response.status) {
        const errorResponse = await response.json();

        return {
          error: true,
          errorCode: errorResponse.code,
        };
      }

      throw Error(response.statusText);
    }

    const loginResponse = await response.json();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const authenticateUserBySms = async (data) => {
  try {
    const response = await fetch(authenticateUserBySmsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if (response.status) {
        const errorResponse = await response.json();

        return {
          error: true,
          errorCode: errorResponse.code,
        };
      }

      throw Error(response.statusText);
    }

    const loginResponse = await response.json();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const reloginUserBySms = async (data) => {
  try {
    const response = await fetch(reloginUserBySmsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if (response.status) {
        const errorResponse = await response.json();

        return {
          error: true,
          errorCode: errorResponse.code,
        };
      }

      throw Error(response.statusText);
    }

    const loginResponse = await response.json();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const logoutUser = async () => {
  try {
    const response = await fetch(logoutUserUrl, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }

    const logoutResponse = await response.text();
    return logoutResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const getUser = async (token) => {
  try {
    const response = await fetch(getUserUrl, getAuthHeader(token));

    const userResponse = await response.json();
    return userResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const getUserAccount = async (token) => {
  try {
    const response = await fetch(getUserAccountUrl, getAuthHeader(token));

    const userResponse = await response.json();
    return userResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const getParties = async (token) => {
  try {
    const partiesResponse = await fetch(getPartiesUrl, getAuthHeader(token));
    const partiesResponseJson = await partiesResponse.json();

    return partiesResponseJson;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const getBoParty = async (id, token) => {
  try {
    const boPartyResponse = await fetch(getBoPartiesUrl + "/" + id, getAuthHeader(token));

    return await boPartyResponse.json();
  } catch (e) {
    console.log(e);

    return {
      error: true,
      message: e,
    };
  }
};

export const getPartyOfficials = async () => {
  try {
    const response = await fetch(getPartyOfficialsUrl, getAuthHeader());

    const items = await response.json();
    return items;
  } catch (e) {
    console.log(e);

    return {
      error: true,
      message: e,
    };
  }
};

export const postSelectedParty = async (partyFid) => {
  try {
    const url = new URL(switchSelectedPartyUrl);
    url.search = new URLSearchParams({
      partyFid: partyFid,
    });

    const partySwitchResponse = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
    });

    if (!partySwitchResponse.ok) {
      throw Error(partySwitchResponse.statusText);
    }

    const partySwitchResponseJson = await partySwitchResponse.json();

    return partySwitchResponseJson;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const postSelectedProduct = async (productFid) => {
  try {
    const url = new URL(switchSelectedProductUrl);
    url.search = new URLSearchParams({
      productFid: productFid,
    });

    const productSwitchResponse = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
    });

    if (!productSwitchResponse.ok) {
      throw Error(productSwitchResponse.statusText);
    }

    const productSwitchResponseJson = await productSwitchResponse.json();

    return productSwitchResponseJson;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const resetPassword = async (email) => {
  try {
    const response = await fetch(resetPasswordUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    });

    const loginResponse = await response.json();
    return loginResponse;
  } catch (e) {
    return {
      error: true,
      message: e,
    };
  }
};

export const changePassword = async (data) => fetchWrapper.putJSONReceiveText(changePasswordUrl, data);

export const changePasswordInternal = async (email) => {
  try {
    const response = await fetch(changePassworInternaldUrl, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(email),
    });

    const loginResponse = await response.json();
    return loginResponse;
  } catch (e) {
    return {
      error: true,
      message: e,
    };
  }
};

export const authorizeUser = async (data) => {
  try {
    const response = await fetch(authorizeUserUrl, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const loginResponse = await response.json();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const changeEmail = async (data) => {
  try {
    const response = await fetch(changeEmailUrl, {
      method: "PATCH",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const loginResponse = await response.text();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const changeAccount = async (data) => {
  try {
    const response = await fetch(changeAccountUrl, {
      method: "PATCH",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const loginResponse = await response.text();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const changeSmsLoginPhone = async (data) => {
  try {
    const response = await fetch(changeSmsLoginPhoneUrl, {
      method: "PATCH",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const loginResponse = await response.text();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const changeSelectedLanguage = async (data, addHeaders) =>
  fetchWrapper.postJSON(changeUserLanguageUrl, data, addHeaders);

export const postNewUser = async (data) => {
  try {
    const response = await fetch(postNewUserUrl, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const loginResponse = await response.json();
    return loginResponse;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const deleteUser = async (data) => {
  try {
    const response = await fetch(deleteUserUrl, {
      method: "DELETE",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.text();

    return responseData;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const changeUserPermissions = async (userName, data) => {
  try {
    const response = await fetch(`${changeUserPermissionsUrl.replace("{username}", userName)}`, {
      method: "PATCH",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const items = await response.text();
    return items;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const saveToLocalStorage = ({ user, parties, assignedManager }) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("assignedManager", JSON.stringify(assignedManager || ""));

  if (Array.isArray(parties)) {
    localStorage.setItem("parties", JSON.stringify(parties));
  }
};

export const saveSessionToken = (token) => {
  sessionStorage.setItem("token", token);
};

export const getFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem("user") || null);
  const parties = JSON.parse(localStorage.getItem("parties") || null);
  const assignedManager = JSON.parse(localStorage.getItem("assignedManager") || null);

  return {
    user,
    parties,
    assignedManager,
  };
};

export const getSessionToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const hasPermission = (parties, permissionType) => {
  if (!parties) {
    return false;
  }

  const activeParty = parties.find((party) => party.is_main);
  const activeProduct = activeParty?.productList.find((product) => product.is_main);

  return activeProduct && activeProduct.permissions
    ? activeProduct.permissions.some((permission) => permission.type === permissionType)
    : false;
};

export const postTermAndConditionsStatus = async () => {
  try {
    const url = new URL(postTermAndConditionsStatusUrl);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
    });

    if (!response.ok && response.status !== 400) {
      throw Error(response.statusText);
    }

    const responseData = await response.text();
    return responseData;
  } catch (e) {
    console.log(e);
    return {
      error: true,
      message: e,
    };
  }
};

export const verifyToken = async (data) => fetchWrapper.putText(verifyTokenUrl, data);

export const storeLanguage = (lang) => {
  localStorage.setItem("language", lang);
};

export const getStoredLanguage = () => {
  return localStorage.getItem("language") || null;
};

export const getCorrectLanguage = (token, lang) => {
  // if needed overrides newly chosen user language and informs BE
  const storedLanguage = getStoredLanguage();
  if (storedLanguage && storedLanguage !== lang) {
    changeSelectedLanguage(
      { language: storedLanguage },
      {
          ...{
            "Content-Type": "application/json",
          },
          ...getAuthHeader(token).headers,
      }
    );

    return storedLanguage;
  }

  return lang;
};
