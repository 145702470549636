import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Table, notification, Spin, Empty } from "antd";
import { useTranslation } from "react-i18next";
import { SafetyOutlined, LineOutlined } from "@ant-design/icons";

import Tooltip from "components/shared/Tooltip";

import Button from "../shared/Button";
import EditBuyer from "./editBuyer";
import DeleteBuyer from "./deleteBuyer";
import NewContact from "./contact/newContact";
import EditContact from "./contact/editContact";
import DeleteContact from "./contact/deleteContact";
import { AuthContext } from "App";
import { hasPermission } from "../../services/userService";
import { getBuyerContactList } from "../../services/buyerService";
import { getContractList } from "../../services/contractService";
import { percentageNumberFormatter } from "./../../utils/numberUtils";
import { moneyFormatter } from "../../utils/moneyUtils";
import { permissionTypes } from "../../utils/statusUtils";

const BuyerDetails = ({ details, contract, hideBuyerDetails }) => {
  const { dispatch, state } = React.useContext(AuthContext);
  const [deleteBuyerModalVisible, setDeleteBuyerModalVisible] = useState(false);
  const [buyerEditVisible, setBuyerEditVisible] = useState(false);
  const [newContactVisible, setNewContactVisible] = useState(false);
  const [editContactVisible, setEditContactVisible] = useState(false);
  const [deleteContactVisible, setDeleteContactVisible] = useState(false);
  const [singleContactData, setSingleContactData] = useState(null);
  const [showActions, setShowActions] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const alertTimeout = 20;
  const isSupplier = contract.factoringProduct === "REVERSE";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const newContactList = await getBuyerContactList({
        contractId: contract.id,
        thirdPartytId: details.party.id,
      });

      if (newContactList?.contacts) {
        setContactList(newContactList.contacts);
      }
      setLoading(false);
    };

    if (contract.id && details.party.id) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.id, details.party.id]);

  useEffect(() => {
    const fetchData = async () => {
      const contractListResponse = await getContractList(state.activeProductFid);

      if (contractListResponse?.contracts && contractListResponse?.contracts.length) {
        const contractList = contractListResponse.contracts;
        contractList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

        dispatch({
          type: "SELECT_CONTRACTS",
          payload: {
            selectedContracts: [contractList[0]],
          },
        });
        hideBuyerDetails();
      }
    };

    if (!state.selectedContracts) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeProductFid]);

  const tableColumns = [
    {
      title: t("buyerDetailsFullName"),
      dataIndex: "fullName",
      width: "15rem",
      render: (value, item) => `${item.name} ${item.surname}`,
    },
    {
      title: t("buyerDetailsEmail"),
      dataIndex: "email",
      width: "20rem",
      render: (value, item) => item.email,
    },
    {
      title: t("buyerDetailsPhone"),
      dataIndex: "phone",
      width: showActions ? "15rem" : "20rem",
      render: (value, item) => item.phoneNumber,
    },
    {
      title: t("buyerDetailsJob"),
      dataIndex: "job",
      width: "10rem",
      render: (value, item) => item.jobTitle,
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => {
        return (
          showActions && (
            <div className="buyer-details__contacts-container__table__actions">
              <button
                className="buyer-details__contacts-container__table__actions__button buyer-details__contacts-container__table__actions__button--edit"
                onClick={() => openEditContact(record)}
              ></button>

              <Tooltip
                overlayClassName="buyer-details__contacts-container__table__tooltip"
                placement="bottomRight"
                title={contactList.length <= 1 ? t("factoringLastContactRemovePreventMessage") : ""}
              >
                <button
                  className={`buyer-details__contacts-container__table__actions__button buyer-details__contacts-container__table__actions__button--delete ${
                    contactList.length <= 1 && "disabled"
                  }`}
                  onClick={() => (contactList.length > 1 ? openDeleteContact(record) : false)}
                ></button>
              </Tooltip>
            </div>
          )
        );
      },
    },
  ];

  const showNotification = (status, message) => {
    notification[status]({
      message: t(status),
      description: status === "success" ? message : t(status),
      duration: alertTimeout,
    });
  };
  const closeDeleteBuyerModal = (status) => {
    setDeleteBuyerModalVisible(false);

    if (status === "success") {
      showNotification(status, t("deleteBuyerSuccess"));
    } else if (status === "error") {
      showNotification(status, t("deleteBuyerError"));
    }
  };

  const openEditBuyer = () => {
    setBuyerEditVisible(true);
  };

  const closeEditBuyer = (status) => {
    setBuyerEditVisible(false);

    if (status === "success") {
      showNotification(status, t("editBuyerSuccess"));
    } else if (status === "error") {
      showNotification(status, t("editBuyerError"));
    }
  };

  const closeNewContact = async (status) => {
    setNewContactVisible(false);

    if (status === "success") {
      showNotification(status, t("newContactSuccess"));
    } else if (status === "error") {
      showNotification(status, t("newContactError"));
    }
    setLoading(true);
    const newContactList = await getBuyerContactList({
      contractId: contract.id,
      thirdPartytId: details.party.id,
    });

    if (newContactList?.contacts) {
      setContactList(newContactList.contacts);
    }
    setLoading(false);
  };

  const openEditContact = (data) => {
    setSingleContactData(data);
    setEditContactVisible(true);
  };

  const closeEditContact = async (status) => {
    setSingleContactData(null);
    setEditContactVisible(false);

    if (status === "success") {
      showNotification(status, t("editContactSuccess"));
    } else if (status === "error") {
      showNotification(status, t("editContactError"));
    }
    setLoading(true);
    const newContactList = await getBuyerContactList({
      contractId: contract.id,
      thirdPartytId: details.party.id,
    });

    if (newContactList?.contacts) {
      setContactList(newContactList.contacts);
    }
    setLoading(false);
  };

  const openDeleteContact = (data) => {
    setSingleContactData(data);
    setDeleteContactVisible(true);
  };

  const closeDeleteContact = async (status) => {
    setSingleContactData(null);
    setDeleteContactVisible(false);

    if (status === "success") {
      showNotification(status, t("deleteContactSuccess"));
    } else if (status === "error") {
      showNotification(status, t("deleteContactError"));
    }
    setLoading(true);
    const newContactList = await getBuyerContactList({
      contractId: contract.id,
      thirdPartytId: details.party.id,
    });

    if (newContactList?.contacts) {
      setContactList(newContactList.contacts);
    }
    setLoading(false);
  };

  useEffect(() => {
    setShowActions(hasPermission(state.parties, permissionTypes.AddBuyer));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableLocale = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />,
  };

  return (
    <Fragment>
      <DeleteBuyer
        modalVisible={deleteBuyerModalVisible}
        closeModal={closeDeleteBuyerModal}
        buyerDetails={details}
        contract={contract}
        currentLimit={moneyFormatter(
          details.insuredFactoringCreditLimit ? details.insuredFactoringCreditLimit : details.factoringCreditLimit,
          "Eur"
        )}
      />
      <EditBuyer
        buyerEditVisible={buyerEditVisible}
        buyerDetails={details}
        contract={contract}
        close={closeEditBuyer}
        currentLimit={
          details.insuredFactoringCreditLimit ? details.insuredFactoringCreditLimit : details.factoringCreditLimit
        }
      />
      <NewContact
        modalVisible={newContactVisible}
        closeModal={closeNewContact}
        companyName={details.party.name}
        buyerDetails={details}
        contract={contract}
      />
      <EditContact
        modalVisible={editContactVisible}
        closeModal={closeEditContact}
        companyName={details.party.name}
        data={singleContactData ? singleContactData : {}}
        buyerDetails={details}
        contract={contract}
      />
      <DeleteContact
        modalVisible={deleteContactVisible}
        closeModal={closeDeleteContact}
        companyName={details.party.name}
        data={singleContactData ? singleContactData : {}}
        buyerDetails={details}
        contract={contract}
      />
      <div className="buyer-details">
        <div className="buyer-details__header">
          <button className="buyer-details__header__back-button" onClick={hideBuyerDetails} />
          <h2 className="buyer-details__header__heading">{`${t("buyerDetailsContractNo")} ${
            contract.contractNumber
          }`}</h2>
        </div>
        <div className="buyer-details__information-box">
          <div className="buyer-details__information-box__header">
            <h2 className="buyer-details__information-box__header__heading">{details.party.name}</h2>
            {showActions && (
              <div>
                <button
                  className="buyer-details__information-box__header__action-button buyer-details__information-box__header__action-button--edit"
                  onClick={openEditBuyer}
                />
                <button
                  className="buyer-details__information-box__header__action-button buyer-details__information-box__header__action-button--delete"
                  onClick={() => setDeleteBuyerModalVisible(true)}
                />
              </div>
            )}
          </div>
          <Row className="buyer-details__information-box__row">
            <Col span={6} className="buyer-details__information-box__column">
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsCompanyCode")}</label>
                <p>{details.party.registrationCode}</p>
              </div>
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsVatCode")}</label>
                <p>{details.party.vatNumber}</p>
              </div>
              {!isSupplier && (
                <div className="buyer-details__information-box__container">
                  <label>{t("buyerDetailsInsurance")}</label>
                  <p>
                    {details.insuredFactoringCreditLimit === 0 ? (
                      <LineOutlined
                        style={{
                          color: "#b0b0b0",
                          fontSize: "19px",
                        }}
                      />
                    ) : (
                      <SafetyOutlined
                        style={{
                          color: "#2b63f4",
                          fontSize: "19px",
                        }}
                      />
                    )}
                  </p>
                </div>
              )}
            </Col>
            <Col span={6} className="buyer-details__information-box__column">
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsCountry")}</label>
                <p>{details.party.country}</p>
              </div>
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsAdress")}</label>
                <p>
                  {details.party.postalAddress
                    ? `${details.party.postalAddress.addressLine1}, ${details.party.postalAddress.city}`
                    : ""}
                </p>
              </div>
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsPostalCode")}</label>
                <p>{details.party.postalAddress ? details.party.postalAddress.postalCode : ""}</p>
              </div>
            </Col>
            <Col span={6} className="buyer-details__information-box__column">
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsFactoringLimit")}</label>
                <p>
                  {moneyFormatter(
                    details.insuredFactoringCreditLimit
                      ? details.insuredFactoringCreditLimit
                      : details.factoringCreditLimit,
                    "Eur"
                  )}
                </p>
              </div>
              {!isSupplier && (
                <div className="buyer-details__information-box__container">
                  <label>{t("buyerDetailsUninsuredFactoringLimit")}</label>
                  <p>{moneyFormatter(details.securedFactoringCreditLimit, "Eur")}</p>
                </div>
              )}
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsUsed")}</label>
                <p>{moneyFormatter(details.usedFactoringCreditLimit, "Eur")}</p>
              </div>
            </Col>
            <Col span={6} className="buyer-details__information-box__column">
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsAvailable")}</label>
                <p>{moneyFormatter(details.availableFactoringCreditLimit, "Eur")}</p>
              </div>
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsDueDays")}</label>
                <p>{details.maxInvoicePaymentTerm}</p>
              </div>
              <div className="buyer-details__information-box__container">
                <label>{t("buyerDetailsInterest")}</label>
                <p>{percentageNumberFormatter(details.interestRate * 100)} %</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="buyer-details__contacts-container">
          <div className="buyer-details__contacts-container__header">
            <h2 className="buyer-details__contacts-container__header__heading">{t("buyerDetailsContacts")}</h2>
            {showActions && (
              <Button type="default" size="small" onClick={() => setNewContactVisible(true)}>
                {t("buyerDetailsAddContact")}
              </Button>
            )}
          </div>
          <Spin spinning={loading} tip={t("loading") + "..."}>
            <Table
              className="buyer-details__contacts-container__table"
              locale={tableLocale}
              columns={tableColumns}
              pagination={false}
              dataSource={contactList ? contactList : []}
            />
          </Spin>
        </div>
      </div>
      <Button type="primary" size="large" className="buyer-details__back-button" onClick={hideBuyerDetails}>
        {t("buyerDetailsBack")}
      </Button>
    </Fragment>
  );
};

export default BuyerDetails;
