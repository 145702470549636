import React from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateLayout from "../../layouts/privateLayout/privateLayout";
import { getFromLocalStorage, getSessionToken } from "../../services/userService";

const PrivateRoute = ({ component: Component, authed, page, title, ...rest }) => {
  if (!authed) {
    const { user, parties } = getFromLocalStorage();
    authed = user && getSessionToken() && parties;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <PrivateLayout title={title}>
            <Component {...props} page={page}/>
          </PrivateLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
