import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { errorNotification } from "utils/notificationUtils";
import PipedriveForm from "../components/forms/pipedriveForm";
import { getDealData } from "../services/formService";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";

const PipedriveRequest = () => {
  const { t } = useTranslation();
  const { hash } = useParams();
  const location = useLocation();
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!hash) {
      errorNotification(t("error"), t("pipedriveFormWrongHash"), 5);
      return;
    }

    getValues(hash);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getValues = async (hash) => {
    setLoading(true);
    const deal = await getDealData(hash);

    if (!deal.ok) {
      errorNotification(t("error"), t("pipedriveFormApiError"), 5);
      setLoading(false);
      return;
    }

    setInitialValues(deal.payload);
    setLoading(false);
  };

  const onFinish = (values) => {
    // TODO: Not finished
    console.log(values);
  };

  return (
    <div className="public-form public-form--go4rent-theme">
      <div className="public-form__header">
        <h1 className="public-form__heading">{t("pipedriveTitle")}</h1>
      </div>
      <div className="public-form__form-block block">
        {loading ? (
          <div className="public-form__loading-screen">
            <Spin indicator={<LoadingOutlined className="public-form__loading-indicator" spin />} />
          </div>
        ) : (
          <PipedriveForm formInstance={form} onFormFinish={onFinish} initialValues={initialValues} />
        )}
      </div>
    </div>
  );
};

export default PipedriveRequest;
