import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import DownloadButton from "../../components/shared/DownloadButton";

import { moneyFormatter } from "../../utils/moneyUtils";
import { dateFormatter } from "../../utils/dateUtils";

const BillListItemDetails = ({
  activeItem,
  record,
  onGetPdfFile,
  expandedKeys,
  onItemFetch,
  formatAmountByType,
}) => {
  const { t } = useTranslation();
  const payoutName = "PAYOUT";
  const receipts =
    activeItem?.invoicePaymentReceipts && activeItem.invoicePaymentReceipts;
  const expanded = expandedKeys && expandedKeys.includes(record.id);

  if (receipts && !expanded) {
    const waitForRender = setInterval(() => {
      // On tests - only 1 loop, but just to be sure
      if (document.querySelector(`[data-select="${record.id}"]`)) {
        onItemFetch(record.id);
        clearInterval(waitForRender);
      }
    }, 100);
    setTimeout(() => {
      if (waitForRender) {
        clearInterval(waitForRender);
      }
    }, 2000);
  }

  return (
    <div
      data-select={record.id}
      className={cx("table-row-expanded expanded-loan-bills-row", {
        expanded: expandedKeys.includes(record.id),
      })}
    >
      <table className="table-expanded">
        <colgroup>
          <col style={{ width: "7.5rem" }} />
          <col style={{ width: "10.9375rem" }} />
          <col style={{ width: "7.8125rem" }} />
          <col />
        </colgroup>
        <tbody>
          {receipts &&
            receipts.map((item, i) => (
              <tr 
                className={cx("table-expanded-row-right-border", {
                  "paid": record.invoiceStatus === "PAID",
                  "unpaid": record.invoiceStatus === "UNPAID",
                  "overdue": record.invoiceStatus === "OVERDUE",
                  "partially-paid": record.invoiceStatus === "PARTIALLY_PAID",
                })}
                key={`${item.id}-${i.toString()}`}
              >
                <td>{dateFormatter(item.paymentCollectionDate)}</td>
                <td>
                  {item.origin === payoutName
                    ? t("reconciliation")
                    : t("bankPayment")}
                </td>
                <td className="dataAlignAmount">
                  {item.invoiceCollectedAmount
                    ? moneyFormatter(
                        item.invoiceCollectedAmount.amount,
                        item.invoiceCollectedAmount.currency
                      )
                    : moneyFormatter(
                        formatAmountByType(
                          item.factorInvoiceReconciledAmount.amount,
                          item.invoiceType
                        ),
                        item.factorInvoiceReconciledAmount.currency
                      )}
                </td>
                <td>
                  <DownloadButton
                    onClick={() =>
                      onGetPdfFile(
                        item.origin === payoutName
                          ? item.reconciliationActId
                          : item.accountsReceivableActId
                      )
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillListItemDetails;
