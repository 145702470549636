import React, { useState, useEffect, useContext } from "react";
import { Form, Input, Row, Col, Select, notification } from "antd";
import { useTranslation } from "react-i18next";

import NewContact from "components/buyer/contact/newContact";
import EditContact from "components/buyer/contact/editContact";
import Button from "components/shared/Button";

import { defaultLabelText } from "utils/formUtils";
import { permissionTypes } from "utils/statusUtils";

import { hasPermission } from "services/userService";

import { AuthContext } from "App";

const { Option } = Select;

const BuyerContact = (props) => {
  const { state } = useContext(AuthContext);

  const alertTimeout = 20;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [newContactVisible, setNewContactVisible] = useState(false);
  const [editContactVisible, setEditContactVisible] = useState(false);

  const [showButton, setShowButton] = useState(false);

  const [labelText, setLabelText] = useState({
    phoneNumber: defaultLabelText,
    buyerEmail: defaultLabelText,
    fullName: defaultLabelText,
  });

  useEffect(() => {
    if (props.contacts && props.contacts.length) {
      let selectedContact = currentContact
        ? props.contacts.find((i) => i.email === currentContact.email)
        : props.contacts[0];

      form.setFieldsValue({
        fullName: selectedContact.name + " " + selectedContact.surname,
        personId: selectedContact?.email,
        buyerEmail: selectedContact.email,
        phoneNumber: selectedContact?.phoneNumber,
      });

      setCurrentContact(selectedContact);

      setLabelText({
        phoneNumber: t("phoneNumber"),
        buyerEmail: t("buyerEmail"),
        fullName: t("fullName"),
      });
    }

    setShowButton(hasPermission(state.parties, permissionTypes.AddBuyer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contacts]);

  useEffect(() => {
    if (props.showError) {
      form.setFields([
        {
          name: "fullName",
          errors: [t("uploadInvoiceNoBuyerContactMessage")],
        },
      ]);
    } else {
      form.setFields([
        {
          name: "fullName",
          errors: [],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, props.showError]);

  const updateLabel = (name, value) => {
    setLabelText({ ...labelText, [name]: value ? t(name) : defaultLabelText });
  };

  const [currentContact, setCurrentContact] = useState(null);

  const showNotification = (status, message) => {
    notification[status]({
      message: t(status),
      description: status === "success" ? message : t(status),
      duration: alertTimeout,
    });
  };

  const closeContact = async (status) => {
    if (status === "success") {
      showNotification(status, t("newContactSuccess"));
    } else if (status === "error") {
      showNotification(status, t("newContactError"));
    }

    await props.reloadContacts(props.buyerCode);
  };

  return (
    <>
      <NewContact
        modalVisible={newContactVisible}
        closeModal={() => {
          setNewContactVisible(false);
          closeContact();
        }}
        companyName={props.buyerName}
        buyerDetails={{ party: { id: props.buyerId } }}
        contract={{ id: props.contract.id }}
      />
      <EditContact
        modalVisible={editContactVisible}
        closeModal={() => {
          setEditContactVisible(false);
          closeContact();
        }}
        companyName={props.buyerName}
        data={currentContact || {}}
        buyerDetails={{ party: { id: props.buyerId } }}
        contract={{ id: props.contract.id }}
      />
      <Form className="buyer-contract" form={form}>
        <Row>
          <Col span={6}>
            {(!props.contacts || props.contacts?.length <= 1) && (
              <Form.Item className="fullName" name="fullName" label={labelText["fullName"]}>
                <Input
                  disabled={true}
                  onChange={(event) => updateLabel("fullName", event.target.value)}
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("fullName")}
                />
              </Form.Item>
            )}

            {props.contacts?.length > 1 && (
              <Form.Item className="personId" name="personId" label={labelText["fullName"]}>
                <Select
                  className="fullName"
                  onChange={(value) => {
                    updateLabel("fullName", value);

                    const activeContact = props?.contacts.find((i) => i.email === value);

                    if (activeContact) {
                      form.setFieldsValue({
                        buyerEmail: activeContact?.email,
                        phoneNumber: activeContact.phoneNumber,
                        fullName: activeContact?.name + " " + activeContact?.surname,
                      });

                      setCurrentContact(activeContact);

                      props.updateBuyerSelectedContact(props.buyerCode, activeContact.email);
                    }
                  }}
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("fullName")}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {props.contacts &&
                    props.contacts.map((item, i) => (
                      <Option key={i} value={item.email}>
                        {item.name + " " + item.surname}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            )}
          </Col>
          <Col span={8}>
            <Form.Item className="buyerEmail" name="buyerEmail" label={labelText["buyerEmail"]}>
              <Input
                className="input"
                disabled={true}
                onChange={(event) => updateLabel("buyerEmail", event.target.value)}
                style={{
                  width: "100%",
                }}
                placeholder={t("buyerEmail")}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item className="phoneNumber" name="phoneNumber" label={labelText["phoneNumber"]}>
              <Input
                className="input"
                disabled={true}
                onChange={(event) => updateLabel("phoneNumber", event.target.value)}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Form.Item>
              <>
                {showButton && !!props?.contacts?.length && (
                  <button
                    onClick={() => setEditContactVisible(true)}
                    className="invoice-verification__button invoice-verification__button--edit buyer-details__contacts-container__table__actions__button buyer-details__contacts-container__table__actions__button--edit"
                  ></button>
                )}
              </>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            {showButton && (
              <Button type="default" size="small" onClick={() => setNewContactVisible(true)}>
                {t("buyerDetailsAddContact")}
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BuyerContact;
