import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "components/Logo";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Footer from "components/Footer";
import { DeprecationCard } from "deprecationCard";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className={"App pageNotFound"}>
      <DeprecationCard globalBanner />
      <Row className={"header404"}>
        <Col span={14} offset={5}>
          <Row>
            <Col span={24}>
              <div className="headerContent">
                <Logo type="smeWhite" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={"pageNotFoundMsg"}>{t("pageNotFound")}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={"content404"}>
        <Col span={10} offset={7}>
          <p>
            {t("pageNotFoundMsgLine1")}
            <br />
            {t("pageNotFoundMsgLine2")}
          </p>
          <Link className={"ant-btn login-form-button ant-btn-primary"} to="/">
            <span>{t("pageNotFoundGoToHome")}</span>
          </Link>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
