import React, { useState } from "react";
import { Form, Input } from "antd";

import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Spin from "components/shared/Spin";
import Modal from "components/shared/Modal";
import CurrencyInput from "components/shared/CurrencyInput";

import { moneyFormatter, parseAmount } from "utils/moneyUtils";
import { editBuyer } from "services/buyerService";

const EditBuyer = ({ buyerEditVisible, buyerDetails, contract, currentLimit, close }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      className="edit-buyer modal grey large"
      width="43.75rem"
      title={contract.factoringProduct === "REVERSE" ? t("editSupplier") : t("editBuyer")}
      visible={buyerEditVisible}
      okText={t("save")}
      cancelText={t("back")}
      onCancel={() => {
        close();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(async ({ newLimit, newAddress }) => {
            setLoading(true);
            const result = await editBuyer({
              buyer_requested_new_limit: parseAmount(newLimit),
              contract_id: contract.id,
              new_buyer_address: newAddress ? newAddress : "",
              third_party_id: buyerDetails.party.id,
            });
            setLoading(false);
            result.error || result.code ? close("error") : close("success");
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      cancelButtonProps={{
        className: "button button--default button--large",
      }}
      okButtonProps={{
        className: "button button--primary button--large",
      }}
    >
      <Spin spinning={loading} tip={t("loading") + "..."}>
        <Form
          form={form}
          className="edit-buyer__form"
          layout="vertical"
          initialValues={{
            newLimit: currentLimit ? moneyFormatter(currentLimit, "Eur") : "",
            newAddress: buyerDetails.party.postalAddress
              ? `${buyerDetails.party.postalAddress.addressLine1}, ${buyerDetails.party.postalAddress.city}, ${buyerDetails.party.postalAddress.postalCode}`
              : "",
          }}
        >
          <Row className="edit-buyer__row">
            <Col span={12} className="edit-buyer__column">
              <div className="edit-buyer__column__box">
                <label>
                  {contract.factoringProduct === "REVERSE" ? t("editSupplierCompany") : t("editBuyerCompany")}
                </label>
                <p>{buyerDetails.party.name}</p>
              </div>
              <div className="edit-buyer__column__box">
                <label>{t("editBuyerCompanyCode")}</label>
                <p>{buyerDetails.party.registrationCode}</p>
              </div>
              <div className="edit-buyer__column__box">
                <label>{t("editBuyerCountry")}</label>
                <p>{buyerDetails.party.country}</p>
              </div>
              <div className="edit-buyer__column__box">
                <label>{t("editBuyerCurrentLimit")}</label>
                <p>{moneyFormatter(currentLimit, "Eur")}</p>
              </div>
            </Col>
            <Col span={12} className="edit-buyer__column edit-buyer__column--new-limit">
              <Form.Item name="newLimit" label={t("editBuyerRequestLimit")} className="edit-buyer__new-limit">
                <CurrencyInput className="currency-number" maskOptions={{ suffix: "€" }} placeholder={`0,00 €`} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="edit-buyer__address-row">
            <div>
              <label>{t("editBuyerCurrentAddress")}</label>
              <p>
                {buyerDetails.party.postalAddress
                  ? `${buyerDetails.party.postalAddress.addressLine1}, ${buyerDetails.party.postalAddress.city}, ${buyerDetails.party.postalAddress.postalCode}`
                  : ""}
              </p>
            </div>
            <Form.Item name="newAddress" label={t("editBuyerNewAddress")} className="edit-buyer__new-address">
              <Input placeholder={t("editBuyerNewAddressPlaceholder")} />
            </Form.Item>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditBuyer;
