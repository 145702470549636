import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import InvoiceFileTag from "../invoiceFileTag";
import ButtonLink from "components/shared/ButtonLink";

const SupportingFilesList = (props) => {
  const { t } = useTranslation();

  const [supportingFiles, setSupportingFiles] = useState([]);

  useEffect(() => {
    setSupportingFiles(props.supportingFiles);
  }, [props.supportingFiles]);

  const filterFiles = (term, arr) => {
    if (arr.length) {
      switch (term) {
        case "recognized":
          return arr.filter((item) => item.status !== "UNRECOGNIZED" && item.type !== "INVOICE_OCR");
        case "unrecognized":
          return arr.filter((item) => item.status === "UNRECOGNIZED" && item.type === "INVOICE_OCR");

        default:
          return arr;
      }
    }
    return arr;
  };

  return (
    <>
      <div className="supportingFiles">
        <Row>
          <Col className="listWidth">
            <p>{t("supportingDocumentsforAutomaticUpload")}</p>
          </Col>
          <Col />
          <Col className="listWidth">
            <p>{t("supportingDocumentsforAutomaticUnrecignized")}</p>
          </Col>
        </Row>
        <Row>
          <Col className="listFiles listWidth">
            {filterFiles("recognized", supportingFiles).map((item, i) => (
              <InvoiceFileTag
                key={i}
                id={item.uuid}
                fileName={item.name}
                fileSize={item.size}
                remove={props.deleteInvoiceUploadAttachmentFile}
              />
            ))}
          </Col>
          <Col />
          <Col className="listFiles listWidth">
            {filterFiles("unrecognized", supportingFiles).map((item, i) => (
              <InvoiceFileTag
                key={i}
                id={item.uuid}
                fileName={item.name}
                fileSize={item.size}
                remove={props.deleteInvoiceUploadAttachmentFile}
              />
            ))}
          </Col>
        </Row>
        <ButtonLink onClick={() => props.setAddAdditionalAttachmentsModalVisible(true)}>
          {t("invoiceBtnAddAdditionalAttachmentFiles")}
        </ButtonLink>
      </div>
    </>
  );
};

export default SupportingFilesList;
