import React from "react";
import { RightOutlined } from "@ant-design/icons";

export const getExpRowKeys = (list, expandedKeys, hasKey) => {
  const keys = [];

  if (list != null) {
    list.forEach((item) => {
      const { id } = item;
      if (expandedKeys.includes(id)) {
        keys.push(id);
      } else if (item[hasKey]) {
        keys.push(id);
      }
    });
  }

  return keys;
};

export const setExpParent = (record, expandedKeys) => {
  return expandedKeys.includes(record.id) && { className: "expand-parent" };
};

export const newExpKeys = (recordID, expandedKeys) =>
  expandedKeys.includes(recordID) ? expandedKeys.filter((k) => k !== recordID) : expandedKeys.concat(recordID);

export const expConfig = (expandedKeys, hasKey) => ({
  rowExpandable: (record) => record[hasKey],
  expandIcon: ({ onExpand, record }) =>
    record[hasKey] ? (
      <div className="arrowIconWrap">
        <RightOutlined
          className={expandedKeys.includes(record.id) && "rotateDown"}
          onClick={(e) => onExpand(record, e)}
        />
      </div>
    ) : null,
  expandIconColumnIndex: 0,
  expandedRowClassName: (record) => "status-" + (record.invoiceStatus || (!record.isPaid ? "UN" : "") + "PAID"),
});

// TODO remove this method
export const setExpOffsets = (dataSelect = null, callback = null) => {
  const expandedRowClass = "expanded-report-item";
  const expandedSelector = document.querySelector(".ant-table-expanded-row");
  if (!expandedSelector) return;
  const rowWidth = expandedSelector.offsetWidth;
  const rowOffest = document.querySelector("table thead .dataAlignAmount").offsetLeft;
  const rowsSelector = dataSelect
    ? document.querySelectorAll(`.${expandedRowClass}[data-select="${dataSelect}"]`)
    : document.querySelectorAll(`.${expandedRowClass}`);
  if (rowsSelector.length) {
    rowsSelector.forEach((row) => {
      const expandedRowWidth = row.offsetWidth;
      const expandedAling = row.querySelector(".dataAlignAmount");
      if (!expandedAling) return;
      const total = rowOffest - expandedAling.offsetLeft - (rowWidth - expandedRowWidth);
      if (total < 0) {
        const table = row.querySelector("table");
        table.style.transform = `translateX(${total}px)`;
      }
    });
  }
  if (callback) callback();
};
