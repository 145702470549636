import React, { useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { isIE, isChrome, isFirefox, isSafari, isEdge, browserVersion } from "react-device-detect";
import { notification } from "antd";

const supportedBrowsersVersions = {
  chrome: 86,
  firefox: 80,
  safari: 14,
  edge: 86,
};

const browserLinks = {
  chrome: "https://www.google.com/chrome/",
  firefox: "https://www.mozilla.org/en-US/firefox/new/",
  safari: "https://support.apple.com/en-us/HT204416",
  edge: "https://www.microsoft.com/en-us/edge",
};

const LegacyBrowserMessage = () => {
  const getMessageText = () => {
    let messageText = "";
    let type = "";

    if (isIE) {
      messageText = t("mainBrowserNotSupported");
    } else {
      if (
        (isChrome && browserVersion < supportedBrowsersVersions.chrome) ||
        (isFirefox && browserVersion < supportedBrowsersVersions.firefox) ||
        (isSafari && browserVersion < supportedBrowsersVersions.safari) ||
        (isEdge && browserVersion < supportedBrowsersVersions.edge)
      ) {
        if (isChrome) {
          type = "chrome";
        }
        if (isFirefox) {
          type = "firefox";
        }
        if (isSafari) {
          type = "safari";
        }
        if (isEdge) {
          type = "edge";
        }

        messageText = t("mainBrowserVersionNotSupported");
      }
    }

    return { message: messageText, type: type };
  };

  const { t } = useTranslation();

  const language = i18next.language;

  useEffect(() => {
    const message = getMessageText();

    if (message.message) {
      notification.destroy();
      notification["info"]({
        message: t("mainBrowserNotSupportedTitle"),
        description: (
          <>
            {" "}
            {message.message}{" "}
            {!isIE && (
              <a rel="noopener noreferrer" target="_blank" href={browserLinks[message.type]}>
                {browserLinks[message.type]}
              </a>
            )}{" "}
          </>
        ),
        duration: 0,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return <></>;
};

export default LegacyBrowserMessage;
