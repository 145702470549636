import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Input from "components/shared/form/Input";
import Select from "components/shared/form/CustomSelect";
import Radio from "components/shared/form/Radio";
import FormHeader from "components/forms/formElements/FormHeader";

import { getIncomeSources } from "services/classifierService";
import { generateTranslationString } from "utils/helpers";

const FinancialStateBlock = ({
  formInstance,
  incomeTooltip = undefined,
  liabilitiesTooltip = undefined,
  readOnly = false,
  prefill,
}) => {
  const { t } = useTranslation();

  const [hasRevenueReductionCircumstances, setHasRevenueReductionCircumstances] = useState();
  const [incomeSources, setIncomeSources] = useState([]);

  useEffect(() => {
    getIncomeSources().then(setIncomeSources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasRevenueReductionCircumstances === "Taip") {
      formInstance.resetFields([["applicant", "finances", "basic", "amount"]]);
      formInstance.scrollToField([["applicant", "finances", "basic", "amount"]], { block: "nearest" });
      formInstance.validateFields([["applicant", "finances", "basic", "amount"]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRevenueReductionCircumstances]);

  return (
    <>
      <FormHeader text={t("leasingFormCustomerIncome")} tooltip={incomeTooltip} />
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormIncome")}
            name={["applicant", "finances", "basic", "amount"]}
            type="decimal"
            rules={[
              {
                required: !prefill,
                message: t("g4rFormIncome") + " " + t("isRequired"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Select
            label={t("g4rFormIncomeSource")}
            name={["applicant", "finances", "basic", "source"]}
            rules={[
              {
                required: !prefill,
                message: t("g4rFormIncomeSource") + " " + t("isRequired"),
              },
            ]}
            options={incomeSources.map((item) => {
              return {
                ...item,
                title: t(generateTranslationString(item.title, "IncomeSource")),
              };
            })}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormAdditionalIncome")}
            name={["applicant", "finances", "additional", "amount"]}
            type="decimal"
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormAdditionalIncomeSource")}
            name={["applicant", "finances", "additional", "source"]}
            rules={[
              {
                max: 100,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>

      <h3 className="leasing-request-form__heading">
        {t("leasingFormRevenueReductionCircumstances")} {prefill ? "" : "*"}
      </h3>
      <Row>
        <Col span={24}>
          <Form.Item
            name={["applicant", "finances", "has_revenue_reduction_circumstances"]}
            rules={[
              {
                required: !prefill,
                message: t("leasingFormRevenueReductionCircumstances") + " " + t("isRequired"),
              },
            ]}
          >
            <Radio
              defaultValue={hasRevenueReductionCircumstances}
              options={[
                { key: 1, value: "Taip", label: t("leasingFormHasRevenueReductionCircumstancesYes") },
                { key: 2, value: "Ne", label: t("leasingFormHasRevenueReductionCircumstancesNo") },
              ]}
              onChange={setHasRevenueReductionCircumstances}
              disabled={readOnly}
            />
          </Form.Item>
        </Col>
      </Row>

      {hasRevenueReductionCircumstances === "Taip" && (
        <Row>
          <Col span={24}>{t("leasingFormHasRevenueReductionCircumstancesText")}</Col>
        </Row>
      )}

      <FormHeader text={t("leasingFormLiabilities")} tooltip={liabilitiesTooltip} />
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("leasingFormLiabilities")}
            name={["applicant", "finances", "commitments"]}
            type="decimal"
            rules={[
              {
                required: !prefill,
                message: t("leasingFormLiabilities") + " " + t("isRequired"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>
    </>
  );
};

export default FinancialStateBlock;
