import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Select, Upload } from "antd";
import DatePicker from "../shared/DatePicker";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";

import apiWrapper from "../../services/apiWrapper";
import Button from "../shared/Button";
import CurrencyInput from "../shared/CurrencyInput";

import { getContractBuyersList } from "../../services/contractService";

import { defaultLabelText, requiredField } from "./../../utils/formUtils";
import { removeTitles } from "./../../utils/helpers";
import { fileSizeIsValid } from "./../../utils/fileUtils";
import { dateIsLaterThanToday, dateIsEarlierThanToday } from "./../../utils/dateUtils";
import { parseAmount, formatAmount } from "../../utils/moneyUtils";

import settings from "../../config/config";

import { AuthContext } from "../../App";

const { Option } = Select;

const UploadManually = (props) => {
  const { t } = useTranslation();

  const { dispatch } = useContext(AuthContext);

  const errorStatus = "error";

  const [form] = Form.useForm();

  const [contractBuyersList, setContractBuyersList] = useState([]);

  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const [supportingFiles, setSupportingFiles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const contractBuyersListResponse = apiWrapper(await getContractBuyersList([props.contract.id]), dispatch);

      if (contractBuyersListResponse?.thirdParties) {
        contractBuyersListResponse.thirdParties.sort(function (a, b) {
          return a.name[0].localeCompare(b.name[0]);
        });

        setContractBuyersList(contractBuyersListResponse.thirdParties);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contract.id]);

  const [labelText, setLabelText] = useState({
    invoiceNumber: defaultLabelText,
    buyer: defaultLabelText,
    supplier: defaultLabelText,
    issueDate: defaultLabelText,
    dueDate: defaultLabelText,
    amount: defaultLabelText,
    transferClaim: defaultLabelText,
    invoiceFiles: defaultLabelText,
    supportingDocuments: defaultLabelText,
  });

  const updateLabel = (name, value) => {
    setLabelText({
      ...labelText,
      [name]: value ? requiredField(t(name)) : defaultLabelText,
    });
  };

  const resetLabels = () => {
    const resetedLabelText = { ...labelText };
    for (const property in resetedLabelText) {
      resetedLabelText[property] = defaultLabelText;
    }

    setLabelText(resetedLabelText);
  };

  const onFinish = async (values) => {
    values.invoiceAmount = parseAmount(values.invoiceAmount);
    values.transferClaimAmount = parseAmount(values.transferClaimAmount);

    const existingInvoice = props.uploadedInvoices.find((i) => i.invoiceNumber === values.invoiceNumber);

    if (existingInvoice) {
      form.setFields([
        {
          name: "invoiceNumber",
          errors: [t("existingInvoiceValidationMessage")],
        },
      ]);
    } else {
      const invoiceFiles = values.invoiceFiles
        ? values.invoiceFiles.fileList.filter((i) => i.status !== errorStatus).map((i) => i.response)
        : [];

      const supportingDocuments = values.supportingDocuments
        ? values.supportingDocuments.fileList.filter((i) => i.status !== errorStatus).map((i) => i.response)
        : [];

      const postManualInvoiceUploadResponse = await props.postManualInvoiceUpload(
        props.contract.id,
        [...invoiceFiles, ...supportingDocuments],
        {
          ...values,
          buyerName: contractBuyersList.find((i) => i.registrationCode === values.buyerCode).name,
        }
      );

      const status = postManualInvoiceUploadResponse?.valid ? "success" : postManualInvoiceUploadResponse?.invoiceManualUploadErrors[0]?.errorCode;
      props.onUploadManuallySuccess(status, invoiceFiles.length + supportingDocuments.length);

      //props.addUploadedItems(postManualInvoiceUploadResponse);

      if (postManualInvoiceUploadResponse?.valid) {
        resetForm();
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onInvoiceFilesChange = (info) => {
    info.fileList.map((file) => {
      if (file.status !== "error") return file;

      if (file.response.code === "SME9" || file.response.code === "SME11") {
        file.response = t("SME11");
      }

      if (file.response.code === "SME32" || file.response.code === "SME33") {
        file.response = t("SME32");
      }

      if (file.validationText) {
        file.response = file.validationText;
      }

      return file;
    });

    setInvoiceFiles([...info.fileList]);

    onFileSelectedChange("invoiceFiles", info);

    removeTitles();
  };

  const onSupportingDocumentsChange = (info) => {
    info.fileList.map((file) => {
      if (file.status !== "error") return file;

      if (file.response.code === "SME9" || file.response.code === "SME11") {
        file.response = t("SME11");
      }

      if (file.response.code === "SME32" || file.response.code === "SME33") {
        file.response = t("SME32");
      }

      if (file.validationText) {
        file.response = file.validationText;
      }

      return file;
    });

    setSupportingFiles([...info.fileList]);

    onFileSelectedChange("supportingDocuments", info);

    removeTitles();
  };

  const onFileSelectedChange = (type, info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }

    setLabelText({
      ...labelText,
      [type]: info.fileList.length ? t(type) : defaultLabelText,
    });
  };

  const resetForm = () => {
    form.resetFields();
    resetLabels();
    setInvoiceFiles([]);
    setSupportingFiles([]);
  };

  const disabledDueDate = (current) => {
    return current && dateIsEarlierThanToday(current);
  };

  const disabledIssueDate = (current) => {
    return current && dateIsLaterThanToday(current);
  };

  const beforeInvoiceFilesUpload = (file, fileList) => {
    if (!fileSizeIsValid(file.size)) {
      file.validationText = t("fileSizeValidationMessage");
      file.status = "error";
      file.response = { code: "" };
      setInvoiceFiles([...fileList, file]);
      return false;
    }

    return true;
  };

  const beforeSuportingFilesUpload = (file, fileList) => {
    if (!fileSizeIsValid(file.size)) {
      file.validationText = t("fileSizeValidationMessage");
      file.status = "error";
      file.response = { code: "" };
      setSupportingFiles([...fileList, file]);
      return false;
    }

    return true;
  };

  const uploadFilesManuallyData = props.getUploadFilesManuallyData(props.productFid);

  return (
    <div className="block uploadManually">
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        initialValues={{
          modifier: "public",
          currency: "eur",
        }}
      >
        <Form.Item
          name="invoiceNumber"
          label={labelText["invoiceNumber"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyInvoiceNumber") + " " + t("isRequired"),
            },
          ]}
        >
          <Input
            onChange={(event) => updateLabel("invoiceNumber", event.target.value)}
            placeholder={requiredField(t("invoiceUploadUploadManuallyInvoiceNumber"))}
          />
        </Form.Item>
        <Form.Item
          name="buyerCode"
          label={labelText[props.contract?.factoringProduct !== "REVERSE" ? "buyer" : "supplier"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message:
                t(
                  props.contract?.factoringProduct !== "REVERSE"
                    ? "invoiceUploadUploadManuallyBuyer"
                    : "invoiceUploadUploadManuallySupplier"
                ) +
                " " +
                t("isRequired"),
            },
          ]}
        >
          <Select
            placeholder={requiredField(
              t(
                props.contract?.factoringProduct !== "REVERSE"
                  ? "invoiceUploadUploadManuallyBuyer"
                  : "invoiceUploadUploadManuallySupplier"
              )
            )}
            style={{ width: "100%" }}
            onChange={(value) =>
              updateLabel(props.contract?.factoringProduct !== "REVERSE" ? "buyer" : "supplier", value)
            }
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {contractBuyersList.map((item, i) => (
              <Option key={item.id} value={item.registrationCode}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="issueDate"
          label={labelText["issueDate"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyIssueDate") + " " + t("isRequired"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const dueDate = getFieldValue("dueDate");
                if (!dueDate || (dueDate && dueDate >= value)) {
                  return Promise.resolve();
                }

                return Promise.reject(t("invoiceDateDateValidationMessage"));
              },
            }),
          ]}
        >
          <DatePicker
            disabledDate={disabledIssueDate}
            onChange={(value) => {
              updateLabel("issueDate", value);
            }}
            placeholder={requiredField(t("invoiceUploadUploadManuallyIssueDate"))}
            style={{
              cursor: "pointer",
            }}
          />
        </Form.Item>
        <Form.Item
          name="dueDate"
          label={labelText["dueDate"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyDueDate") + " " + t("isRequired"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const issueDate = getFieldValue("issueDate");

                if (!issueDate | (issueDate && moment(issueDate).startOf("day") < moment(value).startOf("day"))) {
                  return Promise.resolve();
                }

                return Promise.reject(t("invoiceDateDateValidationMessage"));
              },
            }),
          ]}
        >
          <DatePicker
            disabledDate={disabledDueDate}
            onChange={(value) => updateLabel("dueDate", value)}
            placeholder={requiredField(t("invoiceUploadUploadManuallyDueDate"))}
            style={{
              cursor: "pointer",
            }}
          />
        </Form.Item>
        <Form.Item
          label={labelText["amount"]}
          name="currency"
          className="currency"
          style={{
            display: "inline-block",
            width: "calc(3%)",
          }}
        >
          <Select disabled>
            {settings.currencies.length &&
              settings.currencies.map((item, i) => (
                <Option key={i} value={item.name}>
                  {item.sign}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="invoiceAmount"
          className="invoiceAmount"
          label={defaultLabelText}
          style={{
            display: "inline-block",
            width: "calc(44%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyAmount") + " " + t("isRequired"),
            },
          ]}
        >
          <CurrencyInput
            className="ant-input-number-input"
            placeholder={requiredField(t("invoiceUploadUploadManuallyAmount"))}
            onBlur={({ target: { value } }) => {
              if (value) {
                const amount = parseAmount(value);

                form.setFieldsValue({
                  invoiceAmount: formatAmount(amount),
                  transferClaimAmount: formatAmount(amount),
                });
              }
            }}
            onChange={({ target: { value } }) => {
              if (!value) return;

              form.setFieldsValue({
                transferClaimAmount: value,
              });

              setLabelText({
                ...labelText,
                amount: value ? t("invoiceUploadUploadManuallyAmount") : defaultLabelText,
                transferClaim: value ? t("invoiceUploadUploadManuallyTransferClaim") : defaultLabelText,
              });
            }}
          />
        </Form.Item>
        <Form.Item
          name="transferClaimAmount"
          label={labelText["transferClaim"]}
          className="transferClaim"
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          rules={[
            {
              required: true,
              message: t("invoiceUploadUploadManuallyTransferClaim") + " " + t("isRequired"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const invoiceAmountField = getFieldValue("invoiceAmount");
                const transferClaimAmount = parseAmount(value);

                const amount = parseAmount(invoiceAmountField);

                if (!amount || Math.abs(Number(transferClaimAmount)) <= Math.abs(Number(amount))) {
                  return Promise.resolve();
                }

                return Promise.reject(t("transferClaimBiggerThanAmountValidationMessage"));
              },
            }),
          ]}
        >
          <CurrencyInput
            className="ant-input-number-input"
            placeholder={requiredField(t("invoiceUploadUploadManuallyTransferClaim"))}
            onChange={({ target: { value } }) => {
              setLabelText({
                ...labelText,
                transferClaim: value ? t("invoiceUploadUploadManuallyTransferClaim") : defaultLabelText,
              });
            }}
            onBlur={({ target: { value } }) => {
              if (value) {
                const amount = parseAmount(value);

                form.setFieldsValue({
                  transferClaimAmount: formatAmount(amount),
                });
              }
            }}
          />
        </Form.Item>

        <Form.Item
          name="invoiceFiles"
          className="invoiceFiles"
          label={labelText["invoiceFiles"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
          value="fileList"
        >
          <Upload
            fileList={invoiceFiles}
            beforeUpload={beforeInvoiceFilesUpload}
            multiple={true}
            onChange={onInvoiceFilesChange}
            showUploadList={{
              showRemoveIcon: true,
              removeIcon: <CloseOutlined />,
            }}
            name="file"
            action={uploadFilesManuallyData.action}
            headers={uploadFilesManuallyData.headers}
          >
            {labelText.invoiceFiles === defaultLabelText
              ? t("invoiceUploadUploadManuallyInvoiceFile")
              : defaultLabelText}
            <span className="invoiceAttachment"></span>
          </Upload>
        </Form.Item>
        <Form.Item
          name="supportingDocuments"
          className="supportingDocuments"
          label={labelText["supportingDocuments"]}
          style={{
            display: "inline-block",
            width: "calc(47%)",
          }}
        >
          <Upload
            fileList={supportingFiles}
            beforeUpload={beforeSuportingFilesUpload}
            showUploadList={{
              showRemoveIcon: true,
              removeIcon: <CloseOutlined />,
            }}
            onChange={onSupportingDocumentsChange}
            multiple={true}
            name="file"
            action={uploadFilesManuallyData.action}
            headers={uploadFilesManuallyData.headers}
          >
            {labelText.supportingDocuments === defaultLabelText
              ? t("invoiceUploadUploadManuallyAttachDocuments")
              : defaultLabelText}
            <span className="attachment"></span>
          </Upload>
        </Form.Item>

        <Form.Item className="uploadActions">
          <Button onClick={resetForm} type="default" size="large" className="uploadActions__clear-button">
            {t("clear")}
          </Button>
          <Button htmlType="submit" type="primary" size="large">
            {t("upload")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UploadManually;
