import general from "assets/icons/menu/icon_dash.svg";
import loan from "assets/icons/menu/icon_card.svg";
import factoring from "assets/icons/menu/icon_percent.svg";
import leasing from "assets/icons/menu/icon_key.svg";
import limit from "assets/icons/menu/icon_lock.svg";
import faq from "assets/icons/icon_faq.svg";
import phone from "assets/icons/icon_phone.svg";
import phoneLight from "assets/icons/icon_phoneLight.svg";
import email from "assets/icons/icon_email.svg";
import assistantHeaderIcon from "assets/icons/icon_assistant_header.svg";
import infoBlueIcon from "assets/icons/menu/icon_info_blue.svg";
import noProduct from 'assets/icons/icon_noProduct.svg';

const icons = {
  general,
  loan,
  factoring,
  leasing,
  limit,
  faq,
  email,
  phone,
  phoneLight,
  assistantHeaderIcon,
  infoBlueIcon,
  noProduct,
};
export default icons;
