import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Form, Input } from "antd";
import i18next from "i18next";

import { requiredField, defaultLabelText } from "utils/formUtils";

const CustomInputSearch = (props) => {
  const defaultPlaceholderText =
    props?.rules?.length && props?.rules[0]?.required ? requiredField(props.label) : props.label;
  const [currentValue, setCurrentValue] = useState(null);
  const [labelText, setLabelText] = useState(defaultLabelText);
  const [placeholderText, setPlaceholderText] = useState(defaultPlaceholderText);

  const language = i18next.language;

  useEffect(() => {
    setLabelText(
      currentValue
        ? props?.rules?.length && props?.rules[0]?.required
          ? requiredField(props.label)
          : props.label
        : defaultLabelText
    );

    setPlaceholderText(currentValue ? "" : defaultPlaceholderText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentValue]);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  return (
    <>
      {props.inlineLabel && props.label && <label>{labelText}</label>}
      {props.inlineLabel && (
        <div className={cx("input-search-wrapper", { "bill-filter": currentValue })}>
          <Input.Search
            className="input"
            placeholder={placeholderText}
            onSearch={(event) => {
              props.onSearch && props.onSearch(event);
            }}
            onInput={(event) => {
              props.onSearch && props.onInput(event);
            }}
            value={props.value}
          />
        </div>
      )}

      {!props.inlineLabel && (
        <Form.Item
          label={labelText}
          name={props.name}
          rules={props.rules}
          className={props.className}
          style={props.style}
        >
          <Input.Search
            style={{ width: "100px" }}
            placeholder={placeholderText}
            onSearch={(event) => {
              props.onSearch && props.onSearch(event);
            }}
            onInput={(event) => {
              props.onSearch && props.onInput(event);
            }}
            value={props.value}
          />
        </Form.Item>
      )}
    </>
  );
};

export default CustomInputSearch;
