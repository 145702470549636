import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useTranslation } from "react-i18next";

import { resetPassword } from "./../services/userService";

import { defaultLabelText } from "./../utils/formUtils";

const ResetPassword = (props) => {
  const alertTimeout = 20;
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [labelText, setLabelText] = useState({
    emailAddress: defaultLabelText,
  });

  const updateLabel = (name, value) => {
    setLabelText({ ...labelText, [name]: value ? t(name) : defaultLabelText });
  };

  const onFinish = async (values) => {
    const response = await resetPassword({ email: values.email });

    const status = !response.error ? "success" : "error";

    notification[status]({
      message: t(status),
      description:
        status === "success"
          ? response?.is_reset_password_email_sent &&
            t("resetPasswordLinkSentToEmail")
          : t("resetPasswordError"),
      duration: alertTimeout,
    });

    form.resetFields();
  };

  return (
    <>
      <div className="block loginForm resetPassword">
        <div className="form-wrapper">
          <h1>{t("forgotPassword")}</h1>
          <p>{t("forgotPasswordDescription")}</p>
          <Form
            name="reset_password"
            onFinish={onFinish}
            className="login-form"
            form={form}
          >
            <Form.Item
              label={labelText["emailAddress"]}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("emailAddress") + " " + t("isRequired"),
                },
              ]}
            >
              <Input
                placeholder={t("emailAddress")}
                onChange={(event) =>
                  updateLabel("emailAddress", event.target.value)
                }
              />
            </Form.Item>

            <Form.Item>
              <Button
                onClick={() => {
                  props.history.push("/");
                }}
              >
                {t("cancel")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("resetPassword")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
