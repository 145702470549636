import React, { useContext, useEffect, useState } from "react";
import { Table, Empty, Spin, Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import { getUpcomingPaymentsForMultipleContracts } from "services/hwService";
import { getMultipleContractLimits } from "services/invoiceService";
import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { reformatUnderscore } from "utils/helpers";
import { logoutReasons } from "utils/statusUtils";
import { getActiveParty, hasProductTypeInMainParty, productType } from "utils/partiesUtils";
import { useSelector, useDispatch } from "react-redux";
import { setFactoringData, setProductStatuses, setError, setKebabData } from "state/reducers/kebabDataReducer";
import kebabDataInitialState from "state/initialState/kebabDataInitialState";

import cx from "classnames";
import KebabPie from "../KebabPie/kebabPie";
import {
  selectChartLoadingState,
  selectGeneralDashboardState,
  setChartLoading,
  setFactoringProductFid,
} from "state/reducers/generalDashboardReducer";
import { getBanners } from "services/cmsService";
import i18n from "config/i18n";
import Banners from "components/dashboard/banners";
import { AuthContext } from "App";

const GeneralDashboard = (props) => {
  const { t } = useTranslation();
  const chartsLoading = useSelector(selectChartLoadingState);

  const { state: authState, dispatch } = useContext(AuthContext);
  const hasFactoringInMainParty = hasProductTypeInMainParty(authState.parties, productType.Factoring);
  const dispatchToRedux = useDispatch();

  const hasError = (data) => {
    return !data || data.status || data.error;
  };

  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [pageBanners, setPageBanners] = useState({});
  const [upcommingPaymentsLoading, setUpcommingPaymentsLoading] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(true);
  const [limit, setLimit] = useState(5);
  const [upcomingPaymentsTableSort, setUpcomingPaymentsTableSort] = useState();
  const language = i18n.language;
  const { loanProductFid, factoringProductFid } = useSelector(selectGeneralDashboardState);

  useEffect(() => {
    async function fetchData() {
      const mainParty = getActiveParty(authState.parties);
      dispatchToRedux(setKebabData(kebabDataInitialState));
      setUpcommingPaymentsLoading(true);
      dispatchToRedux(setChartLoading(true));
      setAvailability(authState.parties);
      dispatchToRedux(setChartLoading(false));
      let factoringProductFid;
      let loanProductFid;

      if (hasFactoringInMainParty) {
        factoringProductFid = mainParty.productList.find((product) => product.type === "FACTORING").fid;
        dispatchToRedux(setFactoringProductFid(factoringProductFid));
        const factoringTotalData = await getMultipleContractLimits(factoringProductFid);
        if (!hasError(factoringTotalData)) {
          const newData = {
            title: "Factoring",
            loading: false,
            total: {
              amount: factoringTotalData.factoringCreditLimit ?? 0,
              currency: factoringTotalData.currency,
            },
            available: {
              amount: factoringTotalData.availableFactoringCreditLineAmount ?? 0,
              currency: factoringTotalData.currency,
            },
            used: {
              amount: factoringTotalData.usedFactoringCreditLineAmount ?? 0,
              currency: factoringTotalData.currency,
            },
            overdue: {
              amount: factoringTotalData.overdueInvoicesAmount ?? 0,
              currency: factoringTotalData.currency,
            },
          };
          dispatchToRedux(setFactoringData(newData));
        } else {
          dispatchToRedux(setError({product: productType.Factoring}))
        }
      }
      
      handleUpcomingPayments(loanProductFid, factoringProductFid, limit, null);
    }

    if (authState.user && authState.user.termsConditionsAccepted) {
      try {
        fetchData();
      } catch {
        dispatch({
          type: "LOGOUT",
          payload: { reason: logoutReasons.SessionExpired },
        });
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchBanners = async (lang) => {
      const bannersResponse = await getBanners(lang);
      if (!bannersResponse?.code) {
        setPageBanners(bannersResponse);
      }
    };
    fetchBanners(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const spinConfiguration = {
    tip: t("loading") + "...",
  };

  const upcommingPaymentsSpinConfiguration = {
    tip: spinConfiguration.tip,
    spinning: upcommingPaymentsLoading,
  };

  const upcomingPaymentsColumns = [
    {
      title: t("company"),
      dataIndex: "companyName",
      key: "companyName",
      className: "table-row-cell__companyName",
      sorter: upcomingPayments?.length,
    },
    {
      title: t("contractNumber"),
      dataIndex: "contractNumber",
      key: "contractNumber",
      className: "table-row-cell__contractNumber",
      sorter: upcomingPayments?.length,
    },
    {
      title: t("loanBillNumber"),
      dataIndex: "billNumber",
      key: "billNumber",
      className: "table-row-cell__billNumber",
      sorter: upcomingPayments?.length,
    },
    {
      title: t("loanBillType"),
      dataIndex: "billType",
      key: "billType",
      className: "table-row-cell__billType",
      render: (value) => t("billsFilter" + reformatUnderscore("_" + value)),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("loanBillDueDate"),
      dataIndex: "dueDate",
      key: "dueDate",
      className: "table-row-cell__dueDate",
      render: (value, item) => dateFormatter(value),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("loanBillOpenBillAmount"),
      dataIndex: "openAmount",
      key: "openAmount",
      className: "table-row-cell__openAmount",
      render: (value, item) => moneyFormatter(value || 0, item.currencyCode),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("billsTableStatus"),
      dataIndex: "status",
      key: "status",
      className: "table-row-cell__status",
      render: (value) => {
        return value ? t(reformatUnderscore(value.toLowerCase())) : null;
      },
      sorter: upcomingPayments?.length,
    },
  ];

  const setAvailability = () => {
    const isLoanAvailable = hasProductTypeInMainParty(authState.parties, productType.Loan);
    const isFactoringAvailable = hasProductTypeInMainParty(authState.parties, productType.Factoring);
    const isLimitAvailable = hasProductTypeInMainParty(authState.parties, productType.Limit);
    const isLeasingAvailable = hasProductTypeInMainParty(authState.parties, productType.Leasing);
    dispatchToRedux(
      setProductStatuses({
        loanAvailable: getAvailability(isLoanAvailable),
        loanLoading: isLoanAvailable,
        factoringAvailable: getAvailability(isFactoringAvailable),
        factoringLoading: isFactoringAvailable,
        limitAvailable: getAvailability(isLimitAvailable),
        limitLoading: isLimitAvailable,
        leasingAvailable: getAvailability(isLeasingAvailable),
        leasingLoading: isLeasingAvailable,
      })
    );
  };

  const handleUpcomingPayments = async (loanProductFid, factoringProductFid, limit, sort) => {
    setUpcommingPaymentsLoading(true);
    const upcomingPaymentsResponse = await getUpcomingPaymentsForMultipleContracts(loanProductFid, factoringProductFid, limit, sort);
    setUpcomingPayments(upcomingPaymentsResponse?.upcomingPayments ?? []);
    setIsLimitReached(upcomingPaymentsResponse?.total === upcomingPayments?.length);
    setUpcommingPaymentsLoading(false);
  };

  const sortUpcomingPaymentsTable = async (pagination, filters, sorter) => {
    const sortFieldOrder = sorter.order ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";
    setUpcomingPaymentsTableSort(sortFieldOrder);
    handleUpcomingPayments(loanProductFid, factoringProductFid, limit, sortFieldOrder);
  };

  const handleShowMoreUpcomingPayments = () => {
    const newLimit = limit + 5;
    setLimit(newLimit);
    handleUpcomingPayments(loanProductFid, factoringProductFid, newLimit, upcomingPaymentsTableSort);
  };

  const emptyData = (type) => ({
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={type === "payments" ? t("emptyUpcommingPayments") : t("hadNoActivities")}
      />
    ),
  });

  const getAvailability = (status) => {
    return status ? "available" : "unavailable";
  };

  return (
    <div className="dashboard-container">
      <Row>
        <Col span={20}>
          <Row>
            <Col>
              <Spin spinning={chartsLoading} tip={spinConfiguration.tip}>
                <KebabPie />
              </Spin>
            </Col>
          </Row>

          <Row>
            <Col>
              <Spin spinning={chartsLoading} tip={spinConfiguration.tip}>
                <div className="block block-upcomingPayments general">
                  <div className="table-wrapper">
                    <h3>{t("upcomingPayments")}</h3>
                    <Table
                      rowClassName={(record) =>
                        cx("table-row-right-border", {
                          paid: record.status === "PAID",
                          unpaid: record.status === "UNPAID",
                          overdue: record.status === "OVERDUE",
                          "partially-paid": record.status === "PARTIALLY_PAID",
                        })
                      }
                      className={`table ${!upcomingPayments?.length && "table-empty"}`}
                      locale={emptyData("payments")}
                      pagination={false}
                      columns={upcomingPaymentsColumns}
                      dataSource={upcomingPayments}
                      onChange={sortUpcomingPaymentsTable}
                      rowKey={(record) => record.billNumber}
                      loading={upcommingPaymentsSpinConfiguration}
                    />
                    <div className={"buttonContainer"}>
                      {!isLimitReached && (
                        <Button onClick={handleShowMoreUpcomingPayments} className={"loadMore"} type="default">
                          {t("loadMore")}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Spin>
            </Col>
          </Row>
        </Col>
        <Col className="banners" span={4}>
          <Banners data={pageBanners} />
        </Col>
      </Row>
    </div>
  );
};

export default GeneralDashboard;
