import React, { useEffect, useState, useContext } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import createDOMPurify from "dompurify";

import Button from "components/shared/Button";

import { AuthContext } from "App";

import apiWrapper from "services/apiWrapper";

import { getLegalTexts } from "services/legalService";

const UploadStepActions = (props) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(AuthContext);
  const language = i18next.language;

  const DOMPurify = createDOMPurify(window);

  const [current, setCurrent] = useState({});
  const [uploadedInvoices, setUploadedInvoices] = useState({});
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [data, setData] = useState(null);

  useEffect(() => {
    setCurrent(props.current);
    setUploadedInvoices(props.uploadedInvoices);
    setSelectedInvoices(props.selectedInvoices);
  }, [props]);

  useEffect(() => {
    const getData = async (lang) => {
      const response = await apiWrapper(getLegalTexts(lang), dispatch);

      if (response && !response.code) {
        const legalInfo = response.find(
          (item) => item.field_type_of_legal_text === "Simple text" && item.field_where_to_show === "Invoice upload"
        );

        setData(legalInfo);
      }
    };

    getData(language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <div className={"steps-action step-" + current}>
      {current > 0 && current !== 2 && (
        <Button className="back" onClick={() => props.prev()} type="default" size="large">
          {t("back")}
        </Button>
      )}

      {current === 1 && uploadedInvoices.length > 0 && (
        <Button
          className="back"
          disabled={selectedInvoices.length === 0}
          onClick={() => props.removeSelectedItems()}
          type="default"
          size="large"
        >
          {t("removeSelected")}
        </Button>
      )}

      {current < props.steps.length - 1 && current !== props.steps.length - (props.steps.length - 1) && (
        <Button type="primary" onClick={() => props.next()} size="large">
          {t("continue")}
        </Button>
      )}

      {current === 2 && (
        <Button type="primary" onClick={async () => await props.sendEmails()} size="large">
          {t("sendEmails")}
        </Button>
      )}
      {current === 0 && (
        <Button type="default" size="large">
          <Link to="/">{t("cancel")} </Link>
        </Button>
      )}
      {((current === props.steps.length - (props.steps.length - 1) &&
        current !== 2 &&
        current !== 0 &&
        uploadedInvoices &&
        !!uploadedInvoices.length) ||
        (current !== 2 && current !== 0 && props.isUnrecognizedFiles)) && (
        <>
          {data ? (
            <div
              className="uploadInvoiceDisclaimer"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data.body[0].value),
              }}
            ></div>
          ) : null}
          <Button
            disabled={current === 1 && uploadedInvoices.length === 0 && !props.isUnrecognizedFiles}
            type="primary"
            size="large"
            onClick={() => props.next()}
          >
            {t("continue")}
          </Button>
        </>
      )}
    </div>
  );
};

export default UploadStepActions;
