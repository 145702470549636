import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import serviceUnavailableIcon from "assets/icons/icon_service_unavailable.svg";

const ServiceUnavailableModal = ({ productType, modalVisible, closeModal }) => {
  const { t } = useTranslation();
  const SERVICE_UNAVAILABLE_MESSAGE = "ServiceUnavailbaleMessage";

  const getServiceUnavailableMessage = () => {
    return productType + SERVICE_UNAVAILABLE_MESSAGE;
  };

  return (
    <Modal
      className="modal paid-modal grey"
      visible={modalVisible}
      title={t("serviceUnavailableTitle")}
      footer={null}
      destroyOnClose={true}
      closable={true}
      onCancel={closeModal}
    >
      <div className="service-unavalable-wrapper">
        <img className="image" src={serviceUnavailableIcon} alt="test" />
        <p className="message">{t(getServiceUnavailableMessage())}</p>
      </div>
    </Modal>
  );
};

export default ServiceUnavailableModal;
