import React from "react";
import { useTranslation } from "react-i18next";
import { dateFormatter } from "utils/dateUtils";
import cx from "classnames";
import InvoiceStatus from "./invoiceStatus";

const InvoiceTableExpandedRow = ({ record, expandedKeys }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx("invoiceTableExpandedRow", {
        expanded: expandedKeys.includes(record.id),
      })}
    >
      <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>{t("upcomingPaymentUploadBy")}</th>
            <th></th>
            <th>{t("upcomingPaymentUploadDate")}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={cx("invoiceStatus", InvoiceStatus[record.invoiceStatus])}>
            <td className="cellWithNoBorder"></td>
            <td></td>
            <td>{record.sspUserFirstName + " " + record.sspUserLastName}</td>
            <td></td>
            <td>{dateFormatter(record.invoiceUploadDate)}</td>
          </tr>
        </tbody>
      </table>

      <div className="line"></div>
    </div>
  );
};

export default InvoiceTableExpandedRow;
