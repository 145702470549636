import React from "react";
import { List } from "antd";

import Empty from "components/shared/Empty";

const CustomList = (props) => (
  <List
    className={`list ${props.className}`}
    locale={{
      emptyText: <Empty className="empty" image={Empty.PRESENTED_IMAGE_SIMPLE} description={props.noData} />,
    }}
    size={props.size}
    dataSource={props.dataSource}
    renderItem={(item) => <List.Item>{props.renderItem ? props.renderItem(item) : item}</List.Item>}
  />
);
export default CustomList;
