import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Button from "components/shared/Button";
import ContractCollaterals from "./contractCollaterals";
import StatusHeader from "components/contract/statusHeader";

import { dateFormatter } from "utils/dateUtils";
import { moneyFormatter } from "utils/moneyUtils";
import { percentageNumberFormatter } from "utils/numberUtils";
import { reformatUnderscore } from "utils/helpers";

const BasicContractInfo = ({ contract, defaultEmptyText }) => {
  const { t } = useTranslation();

  const [contractCollateralsModalVisible, setContractCollateralsModalVisible] = useState(false);
  const [contractStatus, setContractStatus] = useState("");

  useEffect(() => {
    if (contract) {
      setContractStatus(contract.status);
    }
  }, [contract]);

  const getFirstCollateralItemName = (contract) => {
    return (
      contract?.billOfExchange?.[0]?.name ||
      contract?.guarantees?.[0]?.name ||
      contract?.pledges?.[0]?.name ||
      defaultEmptyText
    );
  };

  const isActiveStatus = (status) => {
    return status === "SIGNED" || status === "ACTIVE" || status === "DISBURSED";
  };
  const isEexpiredStatus = (status) => {
    return status === "FINISHED";
  };
  const isSuspendedStatus = (status) => {
    return status === "CANCELED" || status === "PAUSED";
  };
  const isOtherStatus = (status) => {
    return status === "NEW" || status === "APPROVED";
  };

  const showCollateralsButton =
    contract?.guarantees?.length || contract?.billOfExchange?.length || contract?.pledges?.length;

  return (
    <>
      <div className="basicContractInfo">
        <Row
          className={cx("contractStatus", {
            activeStatus: isActiveStatus(contractStatus),
            expiredStatus: isEexpiredStatus(contractStatus),
            suspendedStatus: isSuspendedStatus(contractStatus),
            otherStatus: isOtherStatus(contractStatus),
          })}
        >
          <Col span={24} className="contract-status-header">
            <div className="flex flex-jc-end">
              <StatusHeader
                translationId={`loanStatus${reformatUnderscore("_" + contractStatus)}`}
                status={contractStatus}
              />
            </div>
          </Col>
          <Col span={12}>
            <label>{t("loanTotalLoanAmount")}</label>
            <p>{moneyFormatter(contract.totalLoanAmount, contract?.currencyCode) || defaultEmptyText}</p>

            <label>{t("loanFinancier")}</label>
            <p>{contract?.financier}</p>
          </Col>
          <Col span={8} className="padding-after-border">
            <label>{t("loanDisbursedAmount")}</label>
            <p>{moneyFormatter(contract?.disbursedAmount, contract?.currencyCode) || defaultEmptyText}</p>

            <div className="flex flex-jc-space-between">
              <div>
                <label>{t("loanValidFrom")}</label>
                <p>{dateFormatter(contract?.startDate) || defaultEmptyText}</p>
              </div>

              <div>
                <label>{t("loanValidTo")}</label>
                <p>{dateFormatter(contract?.endDate) || defaultEmptyText}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="basicContractDetails">
          <Col span={12} className={"border-right"}>
            <div>
              <label>{t("loanInterest")}</label>
              <p>{contract?.interest ? `${percentageNumberFormatter(contract?.interest * 1)} %` : defaultEmptyText}</p>
              <label>{t("loanInterestType")}</label>
              <p>{contract?.interestType ? t("loanInterestType" + reformatUnderscore("_" + contract?.interestType)) : defaultEmptyText}</p>
              <label>{t("loanDisbursementPeriodEndDate")}</label>
              <p>{contract?.disbursementEndDate || defaultEmptyText}</p>
            </div>
          </Col>
          <Col span={12} className={"padding-after-border"}>
            <label>{t("loanGracePeriodEndDate")}</label>
            <p>{contract?.graceEndDate}</p>
            <label>{t("loanPenalties")}</label>
            <p>{contract?.penalties || contract?.penalties === 0 ? `${contract?.penalties} %` : defaultEmptyText}</p>
            <label>{t("loanCollateral")}</label>
            <p>{getFirstCollateralItemName(contract)}</p>

            {!!showCollateralsButton && (
              <p>
                <Button
                  className="collaterals"
                  type="default"
                  size="small"
                  onClick={() => setContractCollateralsModalVisible(true)}
                >
                  {t("loanViewCollaterals")}
                </Button>
              </p>
            )}
          </Col>
        </Row>
      </div>

      <ContractCollaterals
        visible={contractCollateralsModalVisible}
        onClose={() => setContractCollateralsModalVisible(false)}
        billOfExchange={contract?.billOfExchange ? contract?.billOfExchange.map((i) => i.name) : null}
        guarantees={contract?.guarantees ? contract?.guarantees.map((i) => i.name) : null}
        pledges={contract?.pledges ? contract?.pledges.map((i) => i.provider + " " + i.name) : null}
      />
    </>
  );
};

export default BasicContractInfo;
