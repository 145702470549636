import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import GridFilterBlock from "components/shared/GridFilterBlock";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Form from "components/shared/form/Form";
import Button from "components/shared/Button";
import InputSearch from "components/shared/form/InputSearch";
import FilterButton from "components/shared/FilterButton";
import DatePicker from "components/shared/form/DatePicker";

const ReporFilters = (props) => {
  const { t } = useTranslation();
  const [filteredInfo, setFilteredInfo] = useState(props.filteredInfo);

  useEffect(() => {
    setFilteredInfo(props.filteredInfo);
  }, [props.filteredInfo]);

  const numberChanged = (value) => {
    const newFilteredInfo = { ...filteredInfo, actNumber: value };
    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const numberValueChanged = (e) => {
    setFilteredInfo({ ...filteredInfo, actNumber: e.target.value });
  };

  const issueDateChangedFrom = (date, dateString) => {
    const newFilteredInfo = {
      ...filteredInfo,
      dateFrom: dateString,
    };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const issueDateChangedTo = (date, dateString) => {
    const newFilteredInfo = {
      ...filteredInfo,
      dateTo: dateString,
    };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const onReset = () => {
    filteredInfo.actNumber = "";
    setFilteredInfo({});
    props.filterInfoChanged({});
  };

  const filterButtonChange = (value) => {
    const newFilteredInfo = { ...filteredInfo, ...value };
    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  return (
    <GridFilterBlock className="InvoiceListFilter ReportListFilter">
      <Form>
        <Row>
          <Col span={24} className="checboxFilters">
            <FilterButton
              name="statementsFilterPayout"
              key="PAYOUT"
              onChange={filterButtonChange}
              active={filteredInfo.statementsFilterPayout}
            />

            <FilterButton
              name="statementsFilterPayment"
              key="PAYMENT"
              onChange={filterButtonChange}
              active={filteredInfo.statementsFilterPayment}
            />

            <FilterButton
              name="statementsFilterInterest"
              key="INTEREST_FEE"
              onChange={filterButtonChange}
              active={filteredInfo.statementsFilterInterest}
            />

            <FilterButton
              name="statementsFilterCommisionFee"
              key="COMMISSION_FEE"
              onChange={filterButtonChange}
              active={filteredInfo.statementsFilterCommisionFee}
            />

            <FilterButton
              name="statementsFilterLateFee"
              key="LATE_FEE"
              onChange={filterButtonChange}
              active={filteredInfo.statementsFilterLateFee}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <DatePicker
              inlineLabel={true}
              onChange={issueDateChangedFrom}
              label={t("statementsFilterDateFrom")}
              value={filteredInfo.dateFrom}
            />
          </Col>
          <Col span={6}>
            <DatePicker
              inlineLabel={true}
              onChange={issueDateChangedTo}
              label={t("statementsFilterDateTo")}
              value={filteredInfo.dateTo}
            />
          </Col>
          <Col span={6}>
            <InputSearch
              inlineLabel={true}
              className="byNumberSearch"
              label={t("statementsFilterNumber")}
              onSearch={numberChanged}
              onInput={numberValueChanged}
              value={filteredInfo.actNumber}
            />
          </Col>
          <Col span={6}>
            <Button className="filter-search" onClick={onReset}>
              {t("clear")}
            </Button>
          </Col>
        </Row>
      </Form>
    </GridFilterBlock>
  );
};

export default ReporFilters;
