import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import LoadingSpin from "components/shared/LoadingSpin";
import ContractTypeSelector from "components/forms/leasing/ContractTypeSelector";
import ProductTypeSelector from "components/forms/leasing/ProductTypeSelector";

import {
  validatePartnerToken,
  validatePartnerPrivateToken,
  CONTRACT_TYPES,
  storeOriginatorToken,
  storeFinancingCompany,
  getStoredOriginatorToken,
} from "services/formService";

const PartnerRouter = () => {
  const location = useLocation();
  const { originatorToken, originatorPrivateToken, contractType } = useParams();
  const history = useHistory();

  const [isValid, setValidity] = useState(undefined);
  const [isPrefillForm, setIsPrefillForm] = useState();

  useEffect(() => {
    const validatePartnerTokens = async () => {
      if (location.pathname.includes("/prefill/")) {
        const response = await validatePartnerPrivateToken(originatorPrivateToken);
        if (!response.ok) {
          history.push("/404");
        }

        setIsPrefillForm(true);
        storeOriginatorToken(response.payload?.originator_token);
        storeFinancingCompany(response.payload?.financing_company);
        setValidity(!!response.ok);
      } else {
        const response = await validatePartnerToken(originatorToken);
        setValidity(!!response.ok);
        if (!response.ok) {
          history.push("/404");
        }

        storeFinancingCompany(response.payload);
      }
    };

    validatePartnerTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const chooseContractType = (contractType) => {
    if (contractType === CONTRACT_TYPES.PRIVATE) {
      history.push(
        isPrefillForm
          ? `/partner/prefill/${originatorPrivateToken}/${contractType}`
          : `/partner/${originatorToken}/${contractType}`
      );
    } else {
      history.push(
        isPrefillForm
          ? `/leasingRequest/partner/prefill/${contractType}?originatorPrivateToken=${originatorPrivateToken}`
          : `/leasingRequest/partner/${contractType}?originatorToken=${originatorToken}`
      );
    }
  };

  const chooseProductType = (productType) => {
    history.push(
      isPrefillForm
        ? `/${productType}Request/partner/prefill/${contractType}?originatorPrivateToken=${originatorPrivateToken}`
        : `/${productType}Request/partner/${contractType}?originatorToken=${originatorToken}`
    );
  };

  return (
    <>
      {contractType ? (
        <ProductTypeSelector
          partnerToken={originatorToken || getStoredOriginatorToken()}
          onSelect={chooseProductType}
        />
      ) : isValid ? (
        <ContractTypeSelector
          partnerToken={originatorToken || getStoredOriginatorToken()}
          onSelect={chooseContractType}
        />
      ) : (
        <LoadingSpin />
      )}
    </>
  );
};

export default PartnerRouter;
