import React from "react";

import FinancingFormController from "./FinancingFormController";
import FinancingCoApplicantForm from "./FinancingCoApplicantForm";
import GuarantorConfirmation from "./confirmations/GuarantorConfirmation";
import PrivateConfirmation from "./confirmations/PrivateConfirmation";

import { APPLICANT_TYPES, postApplicantData, validateApplicantData } from "services/formService";

const FinancingCoApplicantFormController = ({ values, formMode, productType }) => {

  return (
    <>
      <FinancingFormController
        FormContents={FinancingCoApplicantForm}
        FormConfirmator={APPLICANT_TYPES.isSpouse(formMode) ? PrivateConfirmation : GuarantorConfirmation}
        setValues={values}
        formMode={formMode}
        validationFn={(data) => validateApplicantData(data.meta.application_token, data.meta.applicant_token, data)}
        submitFn={(data) => postApplicantData(data.meta.application_token, data.meta.applicant_token, data)}
        productType={productType}
      />
    </>
  );
};

export default FinancingCoApplicantFormController;
