import React, { useEffect, useRef } from "react";
import { Checkbox } from "antd";
import cx from "classnames";

const CustomCheckbox = ({ onChange = () => undefined, className, children, ...args }) => {
  const checkboxRef = useRef();

  useEffect(() => {
    if (checkboxRef.current.props) {
      onChange(!!checkboxRef.current.props.checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Checkbox
      ref={checkboxRef}
      onChange={(e) => onChange(!!e.target.checked)}
      className={cx("checkbox", className)}
      {...args}
    >
      {children}
    </Checkbox>
  );
};

export default CustomCheckbox;
