import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Checkbox, Table, Empty, Row, Col } from "antd";

import Button from "components/shared/Button";
import TextArea from "components/shared/form/TextArea";
import Input from "components/shared/form/Input";
import InlineInput from "components/shared/form/InlineInput";
import InvoiceVerificationAction from "components/invoice/invoiceVerificationConfirmation/invoiceVerificationAction";
import InvoiceVerificationStatus from "components/invoice/invoiceVerificationConfirmation/invoiceVerificationStatus";

import { dateFormatter } from "utils/dateUtils";
import { moneyFormatter } from "utils/moneyUtils";
import { errorNotification } from "utils/notificationUtils";
import { reformatUnderscore } from "utils/helpers";

import { getInvoiceVerify, postInvoiceVerify } from "services/invoiceService";

const emptyState = "";
const emptyStateIcon = "—";

const InvoiceVerificationConfirmation = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [submitComment, setSubmitComment] = useState(null);
  const [authorityChecked, setAuthorityChecked] = useState(false);
  const [data, setData] = useState([]);
  const { key } = props.match.params;
  const [thirdPartyId, setThirdPartyId] = useState(null);
  const [invoiceVerificationModalVisible, setInvoiceVerificationModalVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const invoiceVerifyResponse = await getInvoiceVerify(key, thirdPartyId);
      if (invoiceVerifyResponse?.invoices) {
        invoiceVerifyResponse.invoices.forEach((i) => {
          i.invoiceStatus = "";
          i.commentText = "";
        });

        setData(invoiceVerifyResponse?.invoices);
      }
    }
    if (key) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const allItemsProcessed = data.filter((i) => i.invoiceStatus !== emptyState).length === data.length;

  const atLeastOneItemProcessed = data.filter((i) => i.invoiceStatus !== emptyState).length > 0;

  const columns = [
    {
      title: t("invoiceVerificationInvoiceNumber"),
      dataIndex: "invoiceNumber",
      className: "invoiceNumber",
      sorter: data?.length,
    },
    {
      title: t("invoiceVerificationTotalAmount"),
      dataIndex: "invoiceAmount",
      className: "totalAmount",
      render: (value, item) => moneyFormatter(value.amount, value.currency),
      sorter: data?.length,
    },
    {
      title: t("invoiceVerificationDueDate"),
      dataIndex: "invoiceDueDate",
      className: "dueDate",
      render: (value, item) => dateFormatter(value),
      sorter: data?.length,
    },
    {
      title: t("invoiceVerificationComment"),
      dataIndex: "commentText",
      className: "text",
      render: (value, item) => (
        <>
          <InlineInput
            onInput={(event) => change(value, item, event.target.value)}
            value={item.commentText}
          />
          <Button
            type="link"
            className="edit"
            onClick={() => {
              change(value, item);
            }}
          ></Button>
        </>
      ),
      sorter: data?.length,
    },
    {
      title: t("invoicesTableStatus"),
      dataIndex: "invoiceStatus",
      className: "invoiceStatusTxt",
      render: (value) => (value && value !== "SUBMITTED" ? t(reformatUnderscore(value)) : emptyStateIcon),
      sorter: data?.length,
    },
    {
      title: "",
      dataIndex: "invoiceStatus",
      className: "status-column",
      render: (value, item) => {
        let statusStr = value.replace(/\s/g, "").toLowerCase();
        statusStr = statusStr.replace(/_/g, "");
        const invoiceStatusClasses = "status-" + (value ? statusStr : "empty");
        return {
          props: {
            className: invoiceStatusClasses,
          },
        };
      },
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedItems(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: !authorityChecked,
      name: record.name,
    }),
  };

  const change = (value, record, commentText) => {
    const newData = [...data];

    const index = newData.findIndex((item) => item.id === record.id.toString());

    if (index > -1) {
      const item = newData[index];

      if (commentText) {
        newData.splice(index, 1, { ...item, commentText: commentText });
      } else {
        newData.splice(index, 1, {
          ...item,
          commentText: "",
          invoiceStatus: emptyState,
        });
      }

      setData(newData);
    }
  };

  const onAuthorityChecked = async (event) => {
    if (event.target.checked) {
      form.validateFields();

      const fieldsValues = form.getFieldsValue();

      if (fieldsValues.fullName && fieldsValues.email) {
        setAuthorityChecked(true);
      }
    } else {
      setAuthorityChecked(false);
    }
  };

  const processSelected = (status) => {
    const newData = [...data];
    selectedItems.forEach((id) => {
      const index = newData.findIndex((item) => item.id === id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, invoiceStatus: status });
      }
    });

    setData(newData);
    setSelectedItems([]);
  };

  const onSubmit = async () => {
    const response = await postInvoiceVerify(key, {
      thirdPartyId: data?.[0]?.thirdPartyId,
      verificationComment: submitComment,
      invoices: data
        .filter((i) => i.invoiceStatus !== emptyState)
        .map((i) => ({ invoiceId: i.id, action: i.invoiceStatus, comment: i.commentText })),
      verifier: {
        fullName: form.getFieldsValue()?.fullName,
        email: form.getFieldsValue()?.email,
        agreesWithVerificationTerms: authorityChecked,
      },
    });

    if (response?.error) {
      errorNotification(t("error"), t("invoiceVerificationSubmitError"), 5);
      return;
    }

    setThirdPartyId(data?.[0]?.thirdPartyId);
    setSubmitComment(null);
    setInvoiceVerificationModalVisible(true);
  };

  const onClose = async () => {
    const invoiceVerifyResponse = await getInvoiceVerify(key, thirdPartyId);
    if (invoiceVerifyResponse) {
      invoiceVerifyResponse.invoices.forEach((i) => {
        i.invoiceStatus = "";
      });

      setData(invoiceVerifyResponse?.invoices);
    }
    setInvoiceVerificationModalVisible(false);
  };

  return (
    <>
      <InvoiceVerificationStatus statusModalVisible={invoiceVerificationModalVisible} onClose={onClose} />
      <div className="invoice-verification-confirmation-wrapper">
        <h1>{t("invoiceVerification")}</h1>
        <p>{t("uploadInvoiceBuyerContactDescription")}</p>
        <div className="block invoice-verification-confirmation">
          <h3 className="invoice-verification-confirmation__header">{t("invoiceVerificationConfirmIdentity")}</h3>

          <Form className="form" form={form}>
            <Row>
              <Col span="12">
                <Input
                  label={t("fullName")}
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: t("fullName") + " " + t("isRequired"),
                    },
                  ]}
                />
              </Col>
              <Col span="12">
                <Input
                  label={t("email")}
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: t("email") + " " + t("isRequired"),
                      max: 255,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item>
                  <Checkbox className="authority" checked={authorityChecked} onChange={onAuthorityChecked}>
                    {t("invoiceVerificationAuthorityMessage")}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <h3 className="invoice-verification-confirmation__header">{t("invoiceVerificationReviewInvoices")}</h3>
          {!allItemsProcessed && authorityChecked && <InvoiceVerificationAction processSelected={processSelected} />}

          <div className="uploadDocumentList">
            <Table
              className={`grid ${!data.length ? "empty" : ""}`}
              locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />,
              }}
              rowSelection={{
                selectedRowKeys: selectedItems,
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={false}
            />
          </div>

          {!allItemsProcessed && authorityChecked && <InvoiceVerificationAction processSelected={processSelected} />}

          {!!data.length && (
            <div className="invoice-verification-confirmation__submit">
              <Row>
                <Col span="12">
                  <TextArea
                    inlineLabel={true}
                    label={t("invoiceVerificationSubmitComment")}
                    onValueChange={(value) => setSubmitComment(value)}
                    value={submitComment}
                  />
                </Col>
                <Col span="12">
                  <Button disabled={!atLeastOneItemProcessed} onClick={onSubmit} type="primary" size="large">
                    {t("submit")}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceVerificationConfirmation;
