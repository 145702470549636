import React, { useState } from "react";
import { Row, Col, Spin, Form } from "antd";
import { useTranslation } from "react-i18next";

import Modal from "../../../components/shared/Modal";
import PhoneInput from "../../shared/formItems/PhoneInput";

import { defaultLabelText } from "../../../utils/formUtils";

const EditPhone = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [phoneText, setPhoneText] = useState(defaultLabelText);

  const onFinish = async (values) => {
    setLoading(true);
    const result = await props.loginBySms(values);
    setLoading(false);

    if (!result || result.error) {
      form.setFields([
        {
          name: "phoneNumber",
          errors: [t("password-incorrect-validation")],
        },
      ]);
    } else {
      props.valuesSubmitted({ ...values.userName, ...result });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        width="48.125rem"
        className="modal personal-information-action blue"
        title={t("accountEditPhoneTitle")}
        visible={props.modalVisible}
        okText={t("save")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          form.resetFields();
          props.onModalClose(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setLoading(true);
              const result = await props.changePhone({
                phone_number: values.phoneCode + values.phoneNumber,
              });
              setLoading(false);

              if (result.error) {
                form.setFields([
                  {
                    name: "phone_number",
                    errors: [t("accountChangePhoneError")],
                  },
                ]);
              } else {
                props.onModalSave(values);
                form.resetFields();
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Spin spinning={loading} tip={t("loading") + "..."}>
          <Row className="description">
            <Col className="text" span={24}>
              <p>{t("editPhoneExplanationText")}</p>
            </Col>
          </Row>
          <Row className="current">
            <Col span={24}>
              <label>{t("accountCurrentPhone")}</label>
              <p>{props.currentValue || "—"}</p>
            </Col>
          </Row>
          <Row className="action">
            <Col span={24}>
              <Form
                layout="vertical"
                name="sms_login"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="login-form"
                placeholder={t("accountNewPhone")}
                form={form}
                initialValues={{
                  phoneCode: "+370",
                }}
              >
                <PhoneInput
                  countriesPhoneCodes={props.countriesWithPhoneCodes}
                  phoneText={phoneText}
                  setPhoneText={setPhoneText}
                  placeholder={t("accountNewPhone")}
                  form={form}
                  required
                />
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default EditPhone;
