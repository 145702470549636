import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Modal from "components/shared/Modal";
import NewBuyerFormPanel from "./newBuyerFormPanel";

const NewBuyer = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [erroredForms, setErroredForms] = useState([]);

  useEffect(() => {
    setModalVisible(props.modalVisible);
  }, [props.modalVisible]);

  const validateMessages = {
    required: `${t("email")} ${t("isRequired")}`,
    types: {
      email: t("isNotValidEmail"),
    },
  };

  return (
    <>
      <Modal
        width="48.125rem"
        className="newBuyer customForm contractFunction modal grey"
        title={props.isBuyer ? t("AddBuyer") : t("addSupplier")}
        visible={modalVisible}
        okText={t("submit")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          form.resetFields();
          setModalVisible(false);
          props.onModalClose(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              const buyers = values.buyers.map((buyer) => {
                return {
                  buyer_address_city: buyer.city,
                  buyer_address_house_no: buyer.houseNo,
                  buyer_address_postal_code: buyer.postalCode,
                  buyer_address_street: buyer.street,
                  buyer_company_code: buyer.companyCode,
                  buyer_company_name: buyer.buyersCompanyName,
                  buyer_contact_email: buyer.email,
                  buyer_contact_full_name: buyer.fullPersonName,
                  buyer_contact_phone_code: buyer.phoneCode,
                  buyer_contact_phone_no: buyer.phoneNumber,
                  buyer_country: buyer.country,
                  buyer_requested_limit: parseFloat(buyer.limit.replace(".", "").replace(",", ".")),
                };
              });
              form.resetFields();

               const isAdded = await props.addBuyer(props.contract?.id, {
                contract_add_buyer: {
                  buyers: buyers,
                },
                contract_id: props.contract?.id,
              });
              props.onModalSave(isAdded);
            })
            .catch((info) => {
              let errorList = [];

              info.errorFields.forEach((field) => {
                const formNumber = field.name[1];

                if (!errorList.includes(formNumber)) {
                  errorList.push(formNumber);
                }
              });

              setErroredForms(errorList);
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Row>
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            validateMessages={validateMessages}
            initialValues={{
              buyers: [""],
            }}
          >
            <NewBuyerFormPanel
              countries={props.countries}
              countriesWithPhoneCodes={props.countriesWithPhoneCodes}
              form={form}
              isBuyer={props.isBuyer}
              formErrors={erroredForms}
            />
          </Form>
        </Row>
      </Modal>
    </>
  );
};

export default NewBuyer;
