import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Form, Select } from "antd";
import i18next from "i18next";

import { requiredField, defaultLabelText } from "utils/formUtils";

const { Option } = Select;

const CustomSelect = (props) => {
  const defaultPlaceholderText =
    props?.rules?.length && props?.rules[0]?.required ? requiredField(props.label) : props.label;
  const [currentValue, setCurrentValue] = useState(null);
  const [labelText, setLabelText] = useState(defaultLabelText);
  const [placeholderText, setPlaceholderText] = useState(defaultPlaceholderText);

  const language = i18next.language;

  useEffect(() => {
    setLabelText(
      currentValue
        ? props?.rules?.length && props?.rules[0]?.required
          ? requiredField(props.label)
          : props.label
        : defaultLabelText
    );

    setPlaceholderText(currentValue ? "" : defaultPlaceholderText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentValue]);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  return (
    <>
      {props.inlineLabel && props.label && <label>{labelText}</label>}
      {props.inlineLabel && (
        <Select
          className={cx(`select ${props.className ? props.className : ""}`, {
            "bill-filter": currentValue,
          })}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(value) => {
            props.onChange && props.onChange(value);
          }}
          placeholder={placeholderText}
          defaultValue=""
          value={props.value}
        >
          {props.options &&
            props.options.map((item, i) => (
              <Option key={item.key} value={item.value}>
                {item.title}
              </Option>
            ))}
        </Select>
      )}

      {!props.inlineLabel && (
        <Form.Item
          label={labelText}
          name={props.name}
          rules={props.rules}
          className={props.className}
          style={props.style}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={(value) => {
              props.onChange && props.onChange(value);
            }}
            placeholder={placeholderText}
            defaultValue=""
            value={props.value}
          >
            {props.options &&
              props.options.map((item, i) => (
                <Option key={item.key} value={item.value}>
                  {item.title}
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
};

export default CustomSelect;
