import React from "react";
import { useTranslation } from "react-i18next";
import PartnerLogo from "./PartnerLogo";

const PartnerHeader = ({ partnerToken }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="public-form__header">
        <PartnerLogo partnerToken={partnerToken} />
        <div className="public-form__description-box">
          <p>
            {t("g4rDescriptionFirstLine")}
            <br />
            {t("g4rDescriptionSecondLine")}
          </p>
          <p>
            <a href={`tel:${t("leasingFormPhoneNumber")}`}>{t("leasingFormPhoneNumber")}</a>
            <br />
            <a href={`mailto:${t("leasingFormEmailAddress")}`}>{t("leasingFormEmailAddress")}</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default PartnerHeader;
