import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Upload, Input, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import Modal from "components/shared/Modal";
import CurrencyInput from "components/shared/CurrencyInput";

import { moneyFormatter, currencyCodeToSign } from "utils/moneyUtils";
import { validateFiles, processFileValidationMessages, fileSizeIsValid } from "utils/fileUtils";
import { requiredField } from "utils/formUtils";
import { parseAmount, formatAmount } from "utils/moneyUtils";
import { removeTitles } from "utils/helpers";

const { Dragger } = Upload;
const { TextArea } = Input;

const IncreaseLimit = (props) => {
  const { t } = useTranslation();

  const defaultIncreaseLimitData = {
    limit: "",
    explanation: "",
    files: [],
  };

  const [increaseLimitData, setIncreaseLimitData] = useState(defaultIncreaseLimitData);

  const currencyCode = currencyCodeToSign(props.currencyCode);
  const currencyInputPlaceholder = `0,00 ${currencyCode}`;

  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const [currencyValue, setCurrencyValue] = useState("");

  const [loading, setLoading] = useState(false);

  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [showLimitValidationError, setShowLimitValidationError] = useState(false);

  useEffect(() => {
    setCurrencyValue("");
  }, [props.modalVisible]);

  const onChange = async (info, callback) => {
    const { status } = info.file;

    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      console.log(`${info.file.name} file uploaded successfully.`);

      callback(info);
    } else if (status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const onInvoiceFilesChange = async (info) => {
    const files = [...processFileValidationMessages(info, t)];

    setInvoiceFiles(validateFiles(files));

    setFileUploadLoading(true);

    onChange(info, async function (response) {
      if (response.file) {
        const newIncreaseLimitData = { ...increaseLimitData };

        newIncreaseLimitData.files = [...increaseLimitData.files, response.file.response];

        setIncreaseLimitData(newIncreaseLimitData);
      }
    });

    setFileUploadLoading(false);

    removeTitles();
  };

  const processNumber = (value) => {
    return parseFloat(value.replace(/\./g, "").replace(/,/, "."));
  };

  const onFileRemove = async (item) => {
    if (item.hasError) return;

    await props.deleteIncreaseLimitFile(item.response);

    const newIncreaseLimitData = { ...increaseLimitData };

    newIncreaseLimitData.files = increaseLimitData.files.filter((i) => i !== item.response);

    setIncreaseLimitData(newIncreaseLimitData);
  };

  const onNewLimitChange = (value) => {
    setCurrencyValue(value);

    setIncreaseLimitData({
      ...increaseLimitData,
      limit: value,
    });

    setShowLimitValidationError(!limitIsValid(value));
  };

  const onExplanationChange = (e) => {
    setIncreaseLimitData({ ...increaseLimitData, explanation: e.target.value });
  };

  const limitIsValid = (value) => {
    const processedValue = processNumber(value);
    return processedValue >= 0 && processedValue > props.currentLimit;
  };

  const beforeFilesUpload = (file, fileList) => {
    if (!fileSizeIsValid(file.size)) {
      file.validationText = t("fileSizeValidationMessage");
      file.hasError = true;
      setInvoiceFiles([...fileList, file]);
      return false;
    }

    return true;
  };

  const uploadFilesData = props.getIncreaseLimitFileUploadData(props.productFid, props.contract?.id);

  return (
    <>
      <Modal
        width="48.125rem"
        className="increaseLimit contractFunction modal grey medium"
        title={t("increaseLimit")}
        visible={props.modalVisible}
        okText={t("submit")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          props.onIncreaseLimitClose();
          setShowLimitValidationError(false);
          setIncreaseLimitData(defaultIncreaseLimitData);
          setInvoiceFiles([]);
        }}
        onOk={async () => {
          if (limitIsValid(increaseLimitData.limit)) {
            setLoading(true);

            const increaseLimitDataRequest = {
              contract_id: props.contract?.id,
              contract_increase_limit: {
                application_explanation: increaseLimitData.explanation,
                contract_requested_limit: processNumber(increaseLimitData.limit),
              },
              file_upload_uuids: increaseLimitData.files,
            };

            const response = await props.increaseLimit(increaseLimitDataRequest);

            if (response.error) {
              setShowLimitValidationError(true);
              setLoading(false);
              return;
            }

            props.onIncreaseLimitSave();
            setIncreaseLimitData(defaultIncreaseLimitData);
            setInvoiceFiles([]);
            setLoading(false);
            setShowLimitValidationError(false);
          } else {
            setShowLimitValidationError(true);
          }
        }}
      >
        <Spin spinning={loading} tip={t("loading") + "..."}>
          <Row>
            <Col className="limits" span={11}>
              <label>{t("currentLimit")}</label>
              <p>{moneyFormatter(props.currentLimit, props.currencyCode)}</p>
            </Col>
            <Col span={13}>
              <label>{requiredField(t("newLimit"))}</label>

              <CurrencyInput
                maskOptions={{ suffix: ` ${currencyCode}` }}
                className={`currency-number ${showLimitValidationError && "ant-form-item-has-error"}`}
                value={currencyValue}
                placeholder={currencyInputPlaceholder}
                onChange={({ target: { value } }) => {
                  if (!value || value === currencyInputPlaceholder) {
                    setCurrencyValue(currencyInputPlaceholder);
                    return;
                 }
                  onNewLimitChange(value);
                }}
                onBlur={({ target: { value } }) => {
                  if (!value || value === currencyInputPlaceholder) {
                    setCurrencyValue(currencyInputPlaceholder);
                    return;
                 }
                  const amount = parseAmount(value);
                  setCurrencyValue(formatAmount(amount));
                }}
              />

              {showLimitValidationError && (
                <div className="ant-form-item-explain">
                  <div>{t("newLimitValidationText")}</div>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={1}>1</Col>
            <Col span={23}>
              <p>{t("increaseLimitExplanationText")}</p>
              <label>{t("explanation")}</label>
              <TextArea
                placeholder={t("explanation")}
                autoSize
                onChange={onExplanationChange}
                value={increaseLimitData.explanation}
              />
            </Col>
          </Row>
          <Row>
            <Col span={1}>2</Col>

            <Col className="uploadSupportingDocuments" span={20}>
              <p>{t("uploadSupportingDocuments")}</p>
              <span>{t("uploadSupportingDocumentsDescription")}</span>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Spin spinning={fileUploadLoading} tip={t("loading") + "..."}>
                <Dragger
                  name="file"
                  multiple={true}
                  fileList={invoiceFiles}
                  beforeUpload={beforeFilesUpload}
                  action={uploadFilesData.action}
                  headers={uploadFilesData.headers}
                  onChange={onInvoiceFilesChange}
                  onRemove={onFileRemove}
                  showUploadList={{
                    showRemoveIcon: true,
                    removeIcon: <CloseOutlined />,
                  }}
                >
                  <p className="ant-upload-drag-icon"></p>
                  <p className="ant-upload-text">+ {t("uploadFiles")}</p>
                  <p className="ant-upload-hint">{t("or")}</p>
                  <p className="ant-upload-hint">{t("dragDropSupportingFilesDescription")}</p>
                </Dragger>
              </Spin>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default IncreaseLimit;
