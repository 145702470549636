import React from "react";

import { Row, Col } from "antd";

const PersonalInformation = (props) => {
  return (
    <>
      {props.legalTexts &&
        props.legalTexts.map((legalText, i) => (
          <Row key={i} className={""}>
            <Col span={11}>
              <Row>
                <Col span={24}>
                  <Row className={"infoRow"}>
                    <Col span={24}>
                      <p>
                        {legalText?.title}

                        <a
                          className="downloadBtn"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            legalText?.field_files
                              ? legalText?.field_files?.url
                              : ""
                          }
                        >
                          {" "}
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
    </>
  );
};

export default PersonalInformation;
