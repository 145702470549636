import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import createDOMPurify from "dompurify";

import Modal from "components/shared/Modal";

const BillsPaymentStatus = (props) => {
  const { t } = useTranslation();
  const DOMPurify = createDOMPurify(window);
  const closeModal = () => props.onModalClose();

  return (
    <Modal
      className="modal paid-modal grey"
      visible={props.modalVisible}
      title={props.title}
      footer={null}
      closable
      onClickClose={closeModal}
    >
      <img src={props.image} alt="success" className="payment-success" />

      <p
        className="payment-message"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.message),
        }}
      />

      {props.showThankYouText && (
        <div className="payment-thank-you-text">{t("billsPaymentConfirmedModalThankYouText")}</div>
      )}

      <Button type="link" onClick={closeModal}>
        {t("billsPaidModalCloseButton")}
      </Button>
    </Modal>
  );
};

export default BillsPaymentStatus;
