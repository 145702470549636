const InvoiceStatus = {
    SUBMITTED: "invoiceStatus-submitted",
    FINANCED: "invoiceStatus-financed",
    OVERDUE: "invoiceStatus-overdue",
    PARTIALLY_PAID: "invoiceStatus-partiallypaid",
    PAID: "invoiceStatus-paid",
    REJECTED: "invoiceStatus-rejected",
    CLOSED: "invoiceStatus-closed",
    TRANSFERRED: "invoiceStatus-transferred",
};

export default InvoiceStatus;