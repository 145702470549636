import React, { useEffect, useState, useContext, useRef } from "react";
import { Row, Col, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useComponentMountHandler from "../../../hooks/useComponentMountHandler";

import BasicContractInfo from "./basicContractInfo";
import Schedules from "./schedules";
import ContractSelector from "components/contractSelector/contractSelector";
import MiniDonutPie from "components/contract/miniDonutPie";

import { AuthContext } from "App";

import { getLoanList, getLoan, getSchedulesPdfFile, getLoanTotals } from "services/hwService";
import { loansAmounts, loanDataInitBuild } from "services/donutPieService";

import apiWrapper from "services/apiWrapper";
import { productType } from "utils/partiesUtils";

import { defaultEmptyText } from "pages/base/baseConfig";
import { updateRouteIfMounted } from "utils/helpers";

const Contract = (props) => {
  const { t } = useTranslation();

  const donutDataInit = loanDataInitBuild();

  const [loading, setLoading] = useState(false);
  const [contractListLoading, setContractListLoading] = useState(false);
  const [contractList, setContractList] = useState([]);
  const { state: authState, dispatch } = useContext(AuthContext);
  const [selectedContract, setSelectedContract] = useState(null);
  const [donutData, setDonutData] = useState(donutDataInit);
  const isMounted = useRef(false);
  useComponentMountHandler(isMounted);

  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  let location = useLocation();

  const productFid = authState.activeProductFid;

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      setContractList([]);
      setSelectedContract(null);
      setContractListLoading(true);

      const contractListResponse = apiWrapper(await getLoanList(productFid), dispatch);

      if (contractListResponse?.contracts?.length) {
        const contractList = [...contractListResponse.contracts];

        contractList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

        setContractList(contractList);

        let firstContractId = authState.selectedContracts
          ? authState.selectedContracts[0].id
          : contractListResponse.contracts && contractListResponse.contracts.length && contractList[0].id
          ? contractList[0].id
          : 0;

        //TODO: replace with more appropriate solution
        if (location.pathname.length && location.pathname.length > 1) {
          const buyerContractIdParts = location.pathname.split("/");
          const buyerContractId = buyerContractIdParts[buyerContractIdParts.length - 1];

          const contractIndex = contractListResponse.contracts.findIndex((i) => i.id === buyerContractId);

          if (contractIndex > 0) {
            firstContractId = buyerContractId;
          }
        }

        const contractCompanyId = contractListResponse.contracts.find((i) => i.id === firstContractId)?.companyId;
        const contract = apiWrapper(await getLoan(contractCompanyId, firstContractId), dispatch);

        setSelectedContract({ ...contract, companyId: contractCompanyId });
        setContractListLoading(false);

        const loanTotalsData = await getLoanTotals(productFid, contractCompanyId, firstContractId);
        if (loanTotalsData) {
          handleDonutData(loanTotalsData);
        }

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: {
              selectedContracts: [contractList.find((i) => i.id === firstContractId)],
            },
          });
        }
        updateRouteIfMounted(isMounted.current, () =>
          props.history.push(`/${productType.Loan}/contracts/contract/${firstContractId}`)
        );
      } else {
        updateRouteIfMounted(isMounted.current, () => props.history.push(`/${productType.Loan}/dashboard`));
      }
    }

    if (productFid && authState.productType === productType.Loan) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  const contractSelected = async (selectedContractId) => {
    setLoading(true);

    const contractCompanyId = contractList.find((i) => i.id === selectedContractId)?.companyId;

    const contract = apiWrapper(await getLoan(contractCompanyId, selectedContractId), dispatch);

    setSelectedContract({ ...contract, companyId: contractCompanyId });

    const loanTotalsData = await getLoanTotals(productFid, contractCompanyId, contract.id);
    if (loanTotalsData) {
      handleDonutData(loanTotalsData);
    }

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [contractList.find((i) => i.id === selectedContractId)],
        previouslySelectedContracts: [contractList.find((i) => i.id === selectedContract.id)],
      },
    });
    updateRouteIfMounted(isMounted.current, () =>
      props.history.push(`/${productType.Loan}/contracts/contract/${selectedContractId}`)
    );
  };

  const onGetPdfFile = async () => {
    const contractCompanyId = contractList.find((i) => i.id === selectedContract?.id)?.companyId;

    await getSchedulesPdfFile(contractCompanyId, selectedContract?.id);
  };

  const handleDonutData = (loanTotalsData) => {
    donutData[loansAmounts.DISBURSED].amount = loanTotalsData.disbursedAmount ?? 0;
    donutData[loansAmounts.DISBURSED].currency = loanTotalsData.currencyCode;

    donutData[loansAmounts.REPAID].amount = loanTotalsData.repaidAmount ?? 0;
    donutData[loansAmounts.REPAID].currency = loanTotalsData.currencyCode;

    donutData[loansAmounts.OVERDUE].amount = loanTotalsData.overdueAmount ?? 0;
    donutData[loansAmounts.OVERDUE].currency = loanTotalsData.currencyCode;

    donutData[loansAmounts.REPAYMENT].amount = loanTotalsData.repaymentAmount ?? 0;
    donutData[loansAmounts.REPAYMENT].currency = loanTotalsData.currencyCode;

    setDonutData(donutData.map((object) => ({ ...object })));
    setLoading(false);
  };

  return (
    <div className="loan contractListCollapse">
      <Row>
        <Col span={24}>
          <Spin spinning={contractListLoading} tip={spinConfiguration.tip}>
            {!!contractList.length && selectedContract && (
              <ContractSelector
                selectedContract={contractList.find((i) => i.id === selectedContract.id)}
                contractList={contractList}
                contractSelected={contractSelected}
                type="loan"
                productName={t("productLoan")}
                productType="loan"
              />
            )}
          </Spin>

          {!!contractList.length && selectedContract && (
            <Spin spinning={loading} tip={spinConfiguration.tip}>
              <Row>
                <Col span={17} className="section section--mt-1">
                  <BasicContractInfo
                    contract={selectedContract}
                    contractList={contractList}
                    defaultEmptyText={defaultEmptyText}
                  />
                </Col>

                <Col span={7} className="section section--mt-1 section--ml-1">
                  <MiniDonutPie type="loan" showPlate={true} data={donutData} />
                </Col>
              </Row>

              <Row className="section section--mt-1">
                <Col span={24}>
                  <Schedules contract={selectedContract} onGetPdfFile={onGetPdfFile} />
                </Col>
              </Row>
            </Spin>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Contract;
