import React, { useState, useEffect, useContext, useRef } from "react";
import i18next from "i18next";
import { AuthContext } from "App";
import { getActiveParty } from "utils/partiesUtils";
import { waitForGlobal } from "utils/helpers";
import {
  getFromLocalStorage,
  getStoredLanguage,
  saveToLocalStorage,
  changeSelectedLanguage,
  storeLanguage,
} from "services/userService";

const languages = {
  en: {
    abbr: "en",
    title: "English",
  },
  lt: {
    abbr: "lt",
    title: "Lithuanian",
  },
  lv: {
    abbr: "lv",
    title: "Latvian",
  },
  ee: {
    abbr: "ee",
    title: "Estonian",
  },
};

const chatUrls = {
  en: {
    url: "//code.jivosite.com/widget/pa9X5MXzRt",
  },
  lt: {
    url: "//code.jivosite.com/widget/rpCC1p0onR",
  },
  lv: {
    url: "//code.jivosite.com/widget/pv9GDFRPiT",
  },
  ee: {
    url: "//code.jivosite.com/widget/EPhbGxIfw7",
  },
  pl: {
    url: "//code.jivosite.com/widget/em21Ggc6kc",
  },
  ru: {
    url: "//code.jivosite.com/widget/2xPnFzZcKh",
  },
};

const LanguageSwitch = ({
  availableLanguages,
  responsive,
  defaultLang,
  jivochatEnabled = true,
  useLocalStorage = false,
}) => {
  const { state: authState, dispatch } = useContext(AuthContext);
  const [currentLang, setCurrentLang] = useState(languages[i18next.language]);
  const [toggle, setToggle] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (authState.user && authState.user?.language) {
      i18next.changeLanguage(authState.user?.language?.toLowerCase());
      setCurrentLang(languages[authState.user?.language?.toLowerCase()]);

      if (jivochatEnabled) {
        loadChatScript(authState.user?.language?.toLowerCase(), chatUrls[authState.user?.language?.toLowerCase()]?.url);
      }
    } else {
      if (jivochatEnabled) {
        loadChatScript(i18next.language, chatUrls[i18next.language]?.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  const loadChatScript = (lang, url) => {
    const existingScript = document.getElementById("lang" + lang);

    if (!existingScript) {
      const script = document.createElement("script");

      script.id = "lang" + lang;
      script.src = url;

      document.body.appendChild(script);
      return;
    }

    window.jivo_onLoadCallback = () => {
      setJivoData();
    };

    window.jivo_onMessageSent = () => {
      waitForGlobal("jivo_api", () => {
        setJivoData();
      });
    };
  };

  const setJivoData = () => {
    if (!window.jivo_api || !authState.user) return;
    const activeParty = getActiveParty(authState.parties);

    window.jivo_api.setContactInfo({
      name: `${authState.user.firstName} ${authState.user.lastName}`,
      email: authState.user.email,
      phone: authState.user.phone,
    });

    window.jivo_api.setCustomData([
      {
        title: "Name",
        content: authState.user.firstName,
      },
      {
        title: "Surname",
        content: authState.user.lastName,
      },
      ...(activeParty
        ? [
            {
              title: "Company",
              content: activeParty.name,
            },
          ]
        : []),
    ]);
  };

  const removeChatScripts = () => {
    Object.getOwnPropertyNames(chatUrls).forEach((lang) => {
      const existingScript = document.getElementById("lang" + lang);
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    });
  };

  const languageChange = async (lang) => {
    if (authState.isAuthenticated) {
      await changeSelectedLanguage({ language: lang?.toUpperCase() });

      const localStorage = getFromLocalStorage();
      localStorage.user.language = lang?.toUpperCase();
      saveToLocalStorage(localStorage);
    } else {
      storeLanguage(lang?.toUpperCase());
    }

    dispatch({
      type: "SELECT_LANGUAGE",
      payload: {
        language: lang?.toUpperCase(),
      },
    });

    i18next.changeLanguage(lang);
    setCurrentLang(languages[lang]);

    if (jivochatEnabled) {
      removeChatScripts();

      loadChatScript(lang, chatUrls[lang]?.url);
    }
  };

  const listener = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (useLocalStorage && availableLanguages?.length > 1) {
      const storedLanguage = getStoredLanguage();

      if (storedLanguage) {
        i18next.changeLanguage(storedLanguage.toLowerCase());
        setCurrentLang(languages[storedLanguage.toLowerCase()]);
        return;
      }
    }

    if (!authState.user?.language && jivochatEnabled) {
      loadChatScript(i18next.language, chatUrls[i18next.language]?.url);
      return;
    }

    i18next.changeLanguage(authState.user?.language?.toLowerCase() || defaultLang);
    setCurrentLang(languages[authState.user?.language?.toLowerCase() || defaultLang]);

    if (jivochatEnabled) {
      loadChatScript(authState.user?.language?.toLowerCase(), chatUrls[authState.user?.language?.toLowerCase()]?.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user]);

  useEffect(() => {
    if (!toggle) {
      document.removeEventListener("mousedown", listener);
      return;
    }

    document.addEventListener("mousedown", listener, { once: true });
  }, [toggle]);

  if (availableLanguages?.length === 1) return "";

  return (
    <div className={`language ${responsive ? "language--responsive" : ""}`}>
      <div className="language-button-wrapper">
        <button className="language-button" onClick={() => setToggle(!toggle)}>
          {currentLang.abbr}
        </button>
      </div>
      {toggle && (
        <div
          className="language-dropdown"
          onMouseLeave={() => {
            setToggle(false);
          }}
          ref={dropdownRef}
        >
          {Object.keys(languages).map((lang) => {
            if (availableLanguages && !availableLanguages.includes(lang)) {
              return null;
            }

            return (
              <div
                className={`language-dropdown-row ${languages[lang] === currentLang ? "active" : ""}`}
                onClick={() => languageChange(lang)}
                key={lang}
              >
                <span className="language-abbr">{languages[lang].abbr}</span>
                <span className="language-title">{languages[lang].title}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitch;
