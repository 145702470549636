import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Modal from "components/shared/Modal";
import Spin from "components/shared/Spin";
import Checkbox from "components/shared/Checkbox";

import { deleteContact } from "services/buyerService";

const DeleteContact = ({ modalVisible, closeModal, companyName, buyerDetails, contract, data }) => {
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const { name, surname, email, phoneNumber, jobTitle } = data;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onCheckboxChange = (e) => {
    setCheckboxStatus(e.target.checked);
  };

  return (
    <Modal
      width="48.125rem"
      className="delete-contact modal grey"
      title={t("deleteContactTitle")}
      visible={modalVisible}
      okText={t("submit")}
      cancelText={t("cancel")}
      okButtonProps={{
        disabled: !checkboxStatus,
      }}
      onCancel={() => {
        setCheckboxStatus(false);
        closeModal();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(async () => {
            setCheckboxStatus(false);
            setLoading(true);
            const result = await deleteContact({
              contractId: contract.id,
              thirdPartytId: buyerDetails.party.id,
              email,
            });
            setLoading(false);
            result.error || result.code ? closeModal("error") : closeModal("success");
          })
          .catch((e) => {
            console.log("Validate faild: ", e);
          });
      }}
    >
      <Spin spinning={loading} tip={t("loading") + "..."}>
        <Row className="first-row-padding">
          <Col>
            <label>{t("deleteContactCompanyName")}</label>
            <p>{companyName}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{t("deleteContactFullName")}</label>
            <p>{`${name} ${surname}`}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{t("deleteContactEmail")}</label>
            <p>{email}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{t("deleteContactPhone")}</label>
            <p>{phoneNumber ? phoneNumber : "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <label>{t("deleteContactJobTitle")}</label>
            <p>{jobTitle ? jobTitle : "-"}</p>
          </Col>
        </Row>
        <Row>
          <Checkbox className="checkbox-row" checked={checkboxStatus} onChange={onCheckboxChange}>
            {t("deleteContactCheckboxText")}
          </Checkbox>
        </Row>
      </Spin>
    </Modal>
  );
};

export default DeleteContact;
