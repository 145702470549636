import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import i18n from "i18next";

import Empty from "components/shared/Empty";
import Layout from "components/shared/layout/Layout";
import Content from "components/shared/layout/Content";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import TermsConditions from "components/termsAndConditions/termsAndConditions";
import LeftMenu from "./leftMenu";
import Header from "./header";

import { AuthContext } from "App";

import apiWrapper from "services/apiWrapper";

import {
  postSelectedParty,
  getBoParty,
  postTermAndConditionsStatus,
  getUser,
} from "services/userService";
import { getLegalTexts } from "services/legalService";

import { productType, getActiveParty, getActiveProduct, getMainProductTypeLowCase } from "utils/partiesUtils";
import CookiesConsent from "components/shared/CookiesConsent";

const PrivateLayout = (props) => {
  const { state, dispatch } = React.useContext(AuthContext);
  const { t } = useTranslation();

  const language = i18n.language;

  const isAccountMenuItem = props?.location?.pathname?.includes("account");

  const [termsConditionsModalVisible, setTermsConditionsModalVisible] = useState(false);

  const [termsConditionsText, setTermsConditionsText] = useState(false);

  const termsAndConditionsAccepted = state.user?.termsConditionsAccepted;

  const isGeneralDashboard = props.location.pathname.includes("/general/dashboard");

  useEffect(() => {
    const fetchText = async (lang) => {
      const response = await getLegalTexts(lang);

      if (response?.ok) {
        const data = response.find(
          (item) => item.field_type_of_legal_text === "Simple text" && item.field_where_to_show === "Login"
        );

        setTermsConditionsText(data.body[0].value);
      }
    };

    if (state.user && !state.user.termsConditionsAccepted) {
      fetchText(language);

      setTermsConditionsModalVisible(!state.user?.termsConditionsAccepted);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user, language]);

  const changeSelectedParty = async (partyFid) => {
    const selectedParties = await postSelectedParty(partyFid);
    if (!selectedParties) {
      return;
    }

    const activeParty = getActiveParty(selectedParties);
    const activeProduct = getActiveProduct(activeParty);

    // party and product fid are equals, only because it's BO workaround
    const boParty = await getBoParty(
      activeProduct?.type === productType.Factoring.toUpperCase() ? activeProduct?.fid : activeParty?.fid
    );

    dispatch({
      type: "SELECT_PARTY",
      payload: {
        activePartyFid: partyFid,
        activeProductFid: activeProduct?.fid,
        parties: selectedParties,
        productType: activeProduct?.type?.toLowerCase(),
        assignedManager: boParty?.assignedManager,
      },
    });

    changeSelectedProductIfNeeded(selectedParties);
  };

  const changeSelectedProductIfNeeded = async (parties) => {
    const pathnameParts = props.location.pathname.split("/");
    if (!pathnameParts || !pathnameParts.length) {
      return;
    }

    const urlProductName = pathnameParts[1];
    const mainProductTypeLow = getMainProductTypeLowCase(parties);
    if (urlProductName === mainProductTypeLow) {
      return;
    }

    if (!isAccountMenuItem) {
      props.history.push(`/${mainProductTypeLow ?? 'general'}/dashboard`);
    }
  };

  const toggleBurger = (event) => {
    const partyFid = event.target.id;

    if (partyFid) {
      changeSelectedParty(partyFid);
    }
  };

  const saveTermAndCondition = async () => {
    await postTermAndConditionsStatus();

    const userResponse = apiWrapper(await getUser(), dispatch);

    if (!!userResponse?.termsConditionsAccepted) {
      dispatch({
        type: "SET_TERMS_CONDITIONS_ACCEPTED",
        payload: {},
      });

      setTermsConditionsModalVisible(false);
    }
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });
  };

  return (
    <>
      <CookiesConsent />
      <TermsConditions
        termsConditionsModalVisible={termsConditionsModalVisible}
        termsConditionsText={termsConditionsText}
        saveTermAndCondition={saveTermAndCondition}
        closeTermAndCondition={logout}
      />
      <Header
        termsAndConditionsAccepted={termsAndConditionsAccepted}
        toggleBurger={toggleBurger}
        isAuthenticated={state.isAuthenticated}
        user={state.user}
        title={props.title}
        logout={logout}
      />
      <Layout className="main">
        <LeftMenu />

        <Layout className="private-content-wrapper">
          <Content>
            {termsAndConditionsAccepted && (
              <Row className="no-contracts">
                <Col span={22}>
                  {props.children}
                  {state.isEmptySsp && !isAccountMenuItem && !isGeneralDashboard && (
                    <Empty description={t("noSignedContracts")} className="no-contracts__block" />
                  )}
                </Col>
              </Row>
            )}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default withRouter(PrivateLayout);
