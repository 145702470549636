import React, { useEffect, useRef, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { waveStepTypes } from "../../utils/statusUtils";

import { moneyFormatter } from "../../utils/moneyUtils";

const axisTickStyle = { fontSize: 11, fill: "#CCCCCC", fontFamily: "Roboto" };
const maxEmptyChartYValue = 10000;

const RechartsWave = (props) => {
  const dotCursorRef = useRef(null);
  const dotCursor = {};
  const [romanNumbers, setRomanNumbers] = useState(
    props.currentStepType === waveStepTypes.Month
  );

  useEffect(() => {
    setRomanNumbers(props.currentStepType === waveStepTypes.Month); // Change after data fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const CustomTooltip = ({ payload }) => {
    if (
      !payload ||
      payload.length === 0 ||
      payload[0].payload.label === "IGNORE"
    ) {
      if (dotCursor.opacity) {
        // don't affect on first mount
        toggleDotCursorOpacity(false);
      }
      return null;
    }
    toggleDotCursorOpacity();
    const { label } = payload[0].payload;

    if (!label || label.length < 2) {
      return null;
    }
    return (
      <div className="recharts-custom-tooltip-content">
        <p style={{ textTransform: "capitalize" }}>{label[0]}</p>
        <p>{label[1]}</p>
      </div>
    );
  };

  const formatXAxis = (tick) => {
    const convert = (num) => {
      if (num < 1) return "";
      if (num >= 10) return "X" + convert(num - 10);
      if (num >= 9) return "IX" + convert(num - 9);
      if (num >= 5) return "V" + convert(num - 5);
      if (num >= 4) return "IV" + convert(num - 4);
      if (num >= 1) return "I" + convert(num - 1);
    };
    return romanNumbers ? convert(tick) : tick;
  };

  const formatYAxis = (tick) =>
    moneyFormatter(parseInt(tick, 10), props.currency)
      .toString()
      .replace(",00", "");

  const toggleDotCursorOpacity = (show = true) => {
    const opacity = show ? 1 : 0;
    if (dotCursor.opacity !== opacity) {
      const cursor = dotCursorRef?.current;
      if (cursor) {
        cursor.setAttribute("opacity", opacity);
        dotCursor.opacity = opacity;
      }
    }
  };

  const formatYDomain = () => {
    const {
      smallestInRange,
      smallestAllTime,
      largestInRange,
      largestAllTime,
    } = props.data;
    if (props.data.noData) {
      return [0, maxEmptyChartYValue];
    }
    if (!isNaN(smallestInRange) && !isNaN(largestInRange)) {
      if (
        largestInRange === 0 &&
        smallestInRange === 0 &&
        largestAllTime !== 0
      ) {
        return [smallestAllTime, largestAllTime];
      }
      if (smallestInRange >= 0 && largestInRange !== 0) {
        return [0, "auto", largestInRange];
      }
      if (
        props.data.noData ||
        (largestInRange === 0 &&
          smallestInRange === 0 &&
          largestAllTime === 0 &&
          smallestAllTime === 0)
      ) {
        return [0, maxEmptyChartYValue];
      }
      return [smallestInRange, "auto", largestInRange];
    }
    return ["auto", "auto"];
  };

  const yAxisDomain = formatYDomain();

  return (
    <div className="rechart-container">
      <ResponsiveContainer>
        <AreaChart
          onClick={(event) => {
            if (event && props.onChartClick && !isNaN(event.activeLabel)) {
              props.onChartClick({
                value: event.activeLabel,
                month: romanNumbers,
              });
            }
          }}
          data={
            props.data.noData
              ? [...Array(12)].map((x, i) => ({ x: i + 1, y: 0 }))
              : props.data.chartData
          }
          margin={{ top: 5, right: 5, left: 0, bottom: 0 }} // 5 for dot cursor
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#2b63f4" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#2b63f4" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="x"
            axisLine={false}
            tickMargin={30}
            height={60}
            tickLine={false}
            tickFormatter={formatXAxis}
            tick={axisTickStyle}
          />
          <YAxis
            axisLine={false}
            width={Math.max(
              formatYAxis(yAxisDomain[3] || yAxisDomain[2]).length * 6,
              60
            )} // default 60 | 6 for 1 symbol
            tickLine={false}
            domain={yAxisDomain.slice(0, 2)}
            tickFormatter={formatYAxis}
            tick={axisTickStyle}
          />
          <Area
            type="monotone"
            dataKey="y"
            activeDot={(event) => {
              if (dotCursor.key !== event.key) {
                const { cx, cy } = event;
                const cursor = dotCursorRef?.current;
                if (cursor) {
                  cursor.setAttribute("cx", cx);
                  cursor.setAttribute("cy", cy);
                }
                dotCursor.key = event.key;
              }
              return null;
            }}
            stroke={0}
            fill="url(#colorUv)"
          />
          <Tooltip
            offset={14}
            cursor={{ stroke: "#FFFFFF", strokeWidth: 1 }}
            content={<CustomTooltip />}
          />
          <circle
            opacity={0}
            ref={dotCursorRef}
            cx={544}
            cy={50}
            r={4}
            strokeWidth={2}
            stroke="#2b63f4"
            fill="#FFFFFF"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RechartsWave;
