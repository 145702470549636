import { logoutReasons } from "../utils/statusUtils";

const ApiWrapper = (response, dispatch, allwaysLogOut = false) => {
  let isError = false;

  if (dispatch) {
    isError = checkForErrorsAndHandleThem(response, dispatch);
  }

  return !isError ? response : undefined;
};

const checkForErrorsAndHandleThem = (response, dispatch, allwaysLogOut) => {
  if (response?.code === "SME6" || response?.code === "SME5" || allwaysLogOut) {
    dispatch({
      type: "LOGOUT",
      payload: { reason: logoutReasons.SessionExpired },
    });
    return true;
  } else if (response?.code) {
    dispatch({
      type: "SHOW_SERVICE_UNAVAILABLE_MSG",
    });
    return true;
  }

  return false;
};

export default ApiWrapper;
