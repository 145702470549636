import React from "react";
import KebabChart from "./kebabChart";
import "./kebab.scss";
import { moneyFormatter } from "utils/moneyUtils";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import cx from "classnames";
import { IconResponsive, IconSmall } from "components/shared/Icon";
import { getProductTitle, getTotalAmountTitle } from "utils/translationIdUtils";
import { productType } from "utils/partiesUtils";

const LOAN = productType.Loan;

const Kebab = (props) => {
  const { t } = useTranslation();
  const spinConfiguration = {
    tip: t("loading") + "...",
  };
  
  const isBlurred = props.kebab.status === "unavailable" || props.kebab.title.toLowerCase() === LOAN;
  const isLoading = props.kebab.loading;
  const isError = props.kebab.error;
  const currency = props.kebab.total.currency;
  const disbursedHeight =
    props.kebab.total.amount !== 0 ? (props.kebab.used.amount / props.kebab.total.amount) * 100 : 0;
  const overdueHeight =
    props.kebab.total.amount !== 0 ? (props.kebab.overdue.amount / props.kebab.total.amount) * 100 : 0;
  const availableHeight = disbursedHeight !== 0 ? 100 - disbursedHeight : 18;

  return (
    <div
      className={cx("kebab", { "kebab--blurred": isBlurred, "kebab--loading": props.kebab.loading })}
      onClick={props.onClick}
    >
      <div className="kebab__header">
        <div className="kebab__header__title">{t(getProductTitle(props.kebab.title))}</div>
        <div
          className={cx("kebab__header__icon", {
            kebab__header__icon__blurred: isBlurred,
          })}
        >
          <IconSmall type={props.kebab.title.toLowerCase()} />
        </div>
      </div>
      {!isError ? (
        <>
          <div className="kebab__amounts">
            <div className="kebab__amounts__item">
              <div className="kebab__amounts__item__description">{t(getTotalAmountTitle(props.kebab.title))}</div>
              <div className="kebab__amounts__item__amount">{moneyFormatter(props.kebab.total.amount, currency)}</div>
            </div>
          </div>
          <div className={`kebab__chart`}>
            <div className="kebab__chart__spinner">
              <Spin spinning={props.kebab.loading} tip={spinConfiguration.tip} />
            </div>
            {!isBlurred && !isLoading && (
              <KebabChart
                disbursedHeight={Math.ceil(disbursedHeight)}
                overdueHeight={Math.ceil(overdueHeight)}
                availableHeight={Math.ceil(availableHeight)}
                amounts={{
                  available: props.kebab.available.amount,
                  disbursed: props.kebab.used.amount,
                  overdue: props.kebab.overdue.amount,
                }}
                loading={props.kebab.loading}
              />
            )}
            {isBlurred && (
              <div className="no-product">
                <IconResponsive type={"noProduct"} />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="kebab__error">
            <div className="kebab__error__text">{t("serviceUnavailable")}</div>
        </div>
      )}
    </div>
  );
};
export default Kebab;
