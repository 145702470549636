import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import HiddenInput from "components/shared/form/HiddenInput";
import Select from "components/shared/form/CustomSelect";

import Email from "components/forms/formElements/Email";
import PhoneNumber from "components/forms/formElements/PhoneNumber";
import PersonalCode from "components/forms/formElements/PersonalCode";
import MandatoryInputLimited from "components/forms/formElements/MandatoryInputLimited";

import { getDocumentTypes } from "services/classifierService";
import { generateTranslationString } from "utils/helpers";
import { APPLICANT_TYPES } from "services/formService";
import { stripNumbers } from "utils/formUtils";

const PrivateCustomerInformationBlock = ({ formInstance, asRelatedApplicant = false, readOnly, prefill }) => {
  const { t } = useTranslation();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [applicantRole, setApplicantRole] = useState();

  useEffect(() => {
    if (!APPLICANT_TYPES.isGuarantor(applicantRole)) {
      getDocumentTypes().then(setDocumentTypes);
    }
    setApplicantRole(formInstance.getFieldValue(["applicant", "role"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFieldValue = (value, field) => {
    formInstance.setFieldsValue({
      applicant: {
        [field]: stripNumbers(value),
      },
    });
  };

  return (
    <>
      <HiddenInput name={["applicant", "type"]} />
      <HiddenInput name={["applicant", "role"]} />
      {!asRelatedApplicant ? (
        <h3 className="leasing-request-form__heading">
          {prefill ? t("leasingPrefillFormCustomerInformationBlockTitle") : t("g4rFormYourInformation")}
        </h3>
      ) : null}
      <Row>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={
              (APPLICANT_TYPES.isGuarantor(applicantRole) && t("g4rFormGuarantorName")) ||
              (APPLICANT_TYPES.isSpouse(applicantRole) && t("g4rFormName")) ||
              t("g4rFormName")
            }
            name={["applicant", "name"]}
            max={29}
            readOnly={readOnly}
            onChange={(value) => setFieldValue(value, "name")}
          />
        </Col>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={
              (APPLICANT_TYPES.isGuarantor(applicantRole) && t("g4rFormGuarantorSurname")) ||
              (APPLICANT_TYPES.isSpouse(applicantRole) && t("g4rFormSurname")) ||
              t("g4rFormSurname")
            }
            name={["applicant", "surname"]}
            max={40}
            readOnly={readOnly}
            onChange={(value) => setFieldValue(value, "surname")}
          />
        </Col>
      </Row>
      <Row>
        {!APPLICANT_TYPES.isGuarantor(applicantRole) ? (
          <>
            <Col xs={24} sm={12}>
              <PersonalCode
                label={
                  (APPLICANT_TYPES.isSpouse(applicantRole) && t("g4rFormPersonalCode")) || t("g4rFormPersonalCode")
                }
                name={["applicant", "code"]}
                readOnly={asRelatedApplicant || readOnly}
              />
            </Col>
            <Col xs={24} sm={12}>
              <Select
                showSearch
                label={t("leasingFormCustomerDocumentType")}
                name={["applicant", "document_type"]}
                rules={[
                  {
                    required: !prefill,
                    message: t("leasingFormCustomerDocumentType") + " " + t("isRequired"),
                  },
                ]}
                options={documentTypes.map((item) => {
                  return {
                    ...item,
                    title: t(generateTranslationString(item.title, "CustomerDocumentType", "leasingForm")),
                  };
                })}
                disabled={readOnly}
              />
            </Col>
          </>
        ) : (
          <Col span={24}>
            <PersonalCode
              label={APPLICANT_TYPES.isGuarantor(applicantRole) && t("g4rFormGuarantorPersonalCode")}
              name={["applicant", "code"]}
              readOnly={asRelatedApplicant || readOnly}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <PhoneNumber
            form={formInstance}
            label={
              (APPLICANT_TYPES.isGuarantor(applicantRole) && t("g4rFormGuarantorPhoneNumber")) ||
              (APPLICANT_TYPES.isSpouse(applicantRole) && t("g4rFormPhoneNumber")) ||
              t("g4rFormPhoneNumber")
            }
            name={["applicant", "phone"]}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Email
            label={
              (APPLICANT_TYPES.isGuarantor(applicantRole) && t("g4rFormGuarantorEmail")) ||
              (APPLICANT_TYPES.isSpouse(applicantRole) && t("g4rFormEmail")) ||
              t("g4rFormEmail")
            }
            name={["applicant", "email"]}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={t("g4rFormCity")}
            name={["applicant", "city"]}
            max={30}
            readOnly={readOnly}
            required={!prefill}
          />
        </Col>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={t("g4rFormAddress")}
            name={["applicant", "address"]}
            max={100}
            readOnly={readOnly}
            required={!prefill}
          />
        </Col>
      </Row>
    </>
  );
};

export default PrivateCustomerInformationBlock;
