import settings from "../config/config";
import { getSessionToken } from "./userService";
import { getStoredFinancingCompany } from "services/formService";

export const getAuthHeader = (token) => {
  const financingCompany = getStoredFinancingCompany();

  return {
    headers: {
      Authorization: "Bearer " + (token ? token : getSessionToken()),
      ...(financingCompany ? { From: financingCompany } : null),
    },
  };
};

export const getCmsAuth = () => ({
  headers: {
    Authorization: getCmsTokenValue(),
  },
});

export const getCmsTokenValue = () => settings.cmsToken;
