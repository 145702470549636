import React, { useState } from "react";
import { Row, Col, Input, Spin, Form } from "antd";
import { useTranslation } from "react-i18next";

import Modal from "../../../components/shared/Modal";
import FloatFormItem from "../../shared/floatFormItem";

const EditEmail = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Modal
        width="48.125rem"
        className="modal personal-information-action blue"
        title={t("accountEditEmailTitle")}
        visible={props.modalVisible}
        okText={t("save")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        onCancel={() => {
          form.resetFields();
          props.onModalClose(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setLoading(true);
              const result = await props.changeEmail(values);
              setLoading(false);

              if (result.error) {
                form.setFields([
                  {
                    name: "email",
                    errors: [t("accountChangeEmailError")],
                  },
                ]);
              } else {
                props.onModalSave(values);
                form.resetFields();
              }
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Spin spinning={loading} tip={t("loading") + "..."}>
          <Row className="description">
            <Col className="text" span={24}>
              <p>{t("editEmailExplanationText")}</p>
            </Col>
          </Row>
          <Row className="current">
            <Col span={24}>
              <label>{t("accountCurrentEmail")}</label>
              <p>{props.currentValue || "—"}</p>
            </Col>
          </Row>
          <Row className="action">
            <Col span={24}>
              <Form layout="vertical" form={form}>
                <FloatFormItem
                  className="minimal-bmargin"
                  type={Input}
                  label={t("accountNewEmail")}
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: t("isNotValidEmail"),
                      max: 255,
                    },
                  ]}
                />
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </>
  );
};

export default EditEmail;
