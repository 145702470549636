import React from "react";
import { Link } from "react-router-dom";
import { VictoryPie, Slice } from "victory";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import cx from "classnames";
import { useState, useEffect } from "react";
import { moneyFormatter } from "utils/moneyUtils";
import { factoringAmounts, loansAmounts } from "services/donutPieService";
import { useTranslation } from "react-i18next";

const DonutPie = ({ showPlate, type, data }) => {
  const { t } = useTranslation();

  const [donutData, setDonutData] = useState([]);
  const [isOver, setIsOver] = useState(false);
  const [isActive, setIsActive] = useState();
  const [isDonutDataValid, setIsDonutDataValid] = useState(true);

  const DONUT_DEFAULT_COLOR = "#F2F2F2";

  useEffect(() => {
    if (type === "factoring") {
      data[factoringAmounts.USED].donutAmount =
        data[factoringAmounts.USED].amount - data[factoringAmounts.OVERDUE].amount;

      setIsDonutDataValid(data[factoringAmounts.TOTAL].amount);
    }

    if (type === "loan") {
      data[loansAmounts.REPAYMENT].donutAmount =
        data[loansAmounts.REPAYMENT].amount - data[loansAmounts.OVERDUE].amount;

      setIsDonutDataValid(data[loansAmounts.DISBURSED].amount);
    }

    setDonutData(data.map((a) => ({ ...a })));
  }, [data, type]);

  const handleLeave = (e) => {
    setIsOver(false);
    setIsActive(null);

    donutData[factoringAmounts.TOTAL].color = data[factoringAmounts.TOTAL].color;
    donutData[factoringAmounts.USED].color = data[factoringAmounts.USED].color;
    donutData[factoringAmounts.OVERDUE].color = data[factoringAmounts.OVERDUE].color;
    donutData[factoringAmounts.AVAILABLE].color = data[factoringAmounts.AVAILABLE].color;
  };

  const handleOver = (neededChartElemIndex) => {
    if (!showPlate) {
      return;
    }

    setIsOver(true);
    setIsActive(neededChartElemIndex);

    if (type === "factoring" && neededChartElemIndex === factoringAmounts.TOTAL) {
      donutData[factoringAmounts.USED].color = donutData[factoringAmounts.TOTAL].color;
      donutData[factoringAmounts.OVERDUE].color = donutData[factoringAmounts.TOTAL].color;
      donutData[factoringAmounts.AVAILABLE].color = donutData[factoringAmounts.TOTAL].color;
      return;
    }
    if (type === "factoring" && neededChartElemIndex === factoringAmounts.USED) {
      donutData[factoringAmounts.USED].color = data[factoringAmounts.USED].color;
      donutData[factoringAmounts.OVERDUE].color = data[factoringAmounts.USED].color;
      donutData[factoringAmounts.AVAILABLE].color = DONUT_DEFAULT_COLOR;
      return;
    }

    if (type === "loan" && neededChartElemIndex === loansAmounts.DISBURSED) {
      donutData[loansAmounts.REPAYMENT].color = data[loansAmounts.DISBURSED].color;
      donutData[loansAmounts.OVERDUE].color = data[loansAmounts.DISBURSED].color;
      donutData[loansAmounts.REPAID].color = data[loansAmounts.DISBURSED].color;
      return;
    }
    if (type === "loan" && neededChartElemIndex === loansAmounts.REPAYMENT) {
      donutData[loansAmounts.REPAYMENT].color = data[loansAmounts.REPAYMENT].color;
      donutData[loansAmounts.OVERDUE].color = data[loansAmounts.REPAYMENT].color;
      donutData[loansAmounts.REPAID].color = DONUT_DEFAULT_COLOR;
      return;
    }

    donutData[neededChartElemIndex].color = data[neededChartElemIndex].color;
    data.forEach((a, index) => {
      if (index !== neededChartElemIndex) donutData[index].color = DONUT_DEFAULT_COLOR;
    });
  };

  const parseAmount = (amount) => {
    return parseInt(amount?.toString()?.replace(/\s/g, ""));
  };

  const getDonutData = () => {
    return donutData
      .filter((v, index) => v.include !== false)
      .map((v) => ({
        x: v.title,
        y: v.donutAmount >= 0 ? parseAmount(v.donutAmount) : parseAmount(v.amount),
      }));
  };

  const getColors = () => {
    return donutData.filter((v) => v.include !== false).map((v) => v.color);
  };

  return (
    <div className={"DonutPie"}>
      <div
        className={cx({
          "donut-unavailable__content": !isDonutDataValid,
        })}
      >
        <Row className={"donutData"}>
          <Col
            span={12}
            className={cx("legend-cont legend-total total", { blur: isOver, active: isActive === 0 })}
            onMouseLeave={handleLeave}
            onMouseOver={() => {
              handleOver(0);
            }}
          >
            <Row className="row-first">
              <Col span={11}>
                <h4>{t(data[0].title)}</h4>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <span>{moneyFormatter(data[0].amount, data[0].currency)}</span>
              </Col>
            </Row>
            <Row className="row-middle">
              <Col span={24}></Col>
            </Row>
            <Row className="row-last">
              <Col span={11}>
                <p>{t(data[0].description)}</p>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Link to={data[0].action.action || "/"}>
                  {typeof data[0].action.title === "string" ? t(data[0].action.title) : data[0].action.title}
                </Link>
              </Col>
            </Row>
          </Col>
          <Col
            span={12}
            className={cx("legend-cont legend-used used", { blur: isOver, active: isActive === 1 })}
            onMouseLeave={handleLeave}
            onMouseOver={() => {
              handleOver(1);
            }}
          >
            <Row className="row-first">
              <Col span={2}></Col>
              <Col span={10}>
                <span>{moneyFormatter(data[1].amount, data[1].currency)}</span>
              </Col>
              <Col span={12}>
                <h4>{t(data[1].title)}</h4>
              </Col>
            </Row>
            <Row className="row-middle">
              <Col span={24}></Col>
            </Row>
            <Row className="row-last">
              <Col span={2}></Col>
              <Col span={13}>
                {data[1].action.action && <Link to={data[1].action.action}>{t(data[1].action.title)}</Link>}
                {data[1].action.modal && data[1].action.modal}
              </Col>
              <Col span={9}>
                <p>{t(data[1].description)}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="donutData">
          <Col
            span={12}
            className={cx("legend-cont legend-available available", { blur: isOver, active: isActive === 3 })}
            onMouseLeave={handleLeave}
            onMouseOver={() => {
              handleOver(3);
            }}
          >
            <Row className="row-first">
              <Col span={11}>
                <h4>{t(data[3].title)}</h4>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <span>{moneyFormatter(data[3].amount, data[3].currency)}</span>
              </Col>
            </Row>
            <Row className="row-middle">
              <Col span={24}></Col>
            </Row>
            <Row className="row-last">
              <Col span={9}>
                <p>{t(data[3].description)}</p>
              </Col>
              <Col span={4}></Col>
              <Col span={11}>
                <Link to={data[3].action.action || "/"}>{t(data[3].action.title)} </Link>
              </Col>
            </Row>
          </Col>
          <Col
            span={12}
            className={cx("legend-cont legend-overdue overdue", { blur: isOver, active: isActive === 2 })}
            onMouseLeave={handleLeave}
            onMouseOver={() => {
              handleOver(2);
            }}
          >
            <Row className="row-first">
              <Col span={2}></Col>
              <Col span={10}>
                <span>{moneyFormatter(data[2].amount, data[2].currency)}</span>
              </Col>
              <Col span={12}>
                <h4>{t(data[2].title)}</h4>
              </Col>
            </Row>
            <Row className="row-middle">
              <Col span={2}></Col>
              <Col span={12}>
                {data[2].list &&
                  data[2].list.map(
                    (object, index) =>
                      object.amount > 0 && (
                        <div className="list" key={index}>
                          <div className="list-item">{t(object.title)}</div>
                          <div className="list-item">{moneyFormatter(object.amount)}</div>
                        </div>
                      )
                  )}
              </Col>
            </Row>
            <Row className="row-last">
              <Col span={2}></Col>
              <Col span={13}>
                <Link to={data[2].action.action}>{t(data[2].action.title)}</Link>
              </Col>
              <Col span={9}>
                <p>{t(data[2].description)}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        {showPlate && (
          <>
            <div className={"plate"} />
            <svg>
              <defs>
                <filter id="filter" x0="-50%" y0="-50%" width="200%" height="200%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="4" result="blur"></feGaussianBlur>
                  <feOffset dy="2" dx="3"></feOffset>
                  <feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite>

                  <feFlood floodColor="#000" floodOpacity="0.6"></feFlood>
                  <feComposite in2="shadowDiff" operator="in"></feComposite>
                  <feComposite in2="SourceGraphic" operator="over" result="firstfilter"></feComposite>
                </filter>
              </defs>
            </svg>
          </>
        )}

        <div className="donut-wrapper">
          <VictoryPie
            colorScale={getColors()}
            data={getDonutData()}
            innerRadius={85}
            dataComponent={<Slice events={{ onMouseLeave: handleLeave }} />}
            animate={{ easing: "exp", duration: 1000 }}
            style={{
              data: {
                WebkitBoxShadow: "inset 1rem 1rem #000",
                filter: "url(#filter)",
              },
            }}
          />
        </div>
      </div>
      {!isDonutDataValid && <div className="donut-unavailable__message">{t("donutUnavailable")}</div>}
    </div>
  );
};

export default DonutPie;
