import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "App";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Spin from "components/shared/Spin";
import Steps from "components/shared/Steps";
import Affix from "components/shared/Affix";
import ContractSelector from "components/contractSelector/contractSelector";
import UploadDocuments from "components/invoice/uploadDocuments";
import UploadStepActions from "components/invoice/upload/uploadStepActions";
import UploadStatus from "components/invoice/upload/uploadStatus";
import SubmitForFinancingStatus from "components/invoice/upload/submitForFinancingStatus";
import AutomaticUploadStatus from "components/invoice/upload/automaticUploadStatus";
import EditInvoice from "components/invoice/upload/editInvoice";
import InvoiceVerification from "components/invoice/invoiceVerification";
import AddAdditionalAttachments from "components/invoice/upload/AddAdditionalAttachments";

import apiWrapper from "services/apiWrapper";

import {
  postManualInvoiceUpload,
  validateInvoices,
  submitUnrecognizedFiles,
  submitInvoices,
  sendEmailForFinancing,
  removeInvoices,
  getAutomaticInvoiceUploadRequestData,
  getUploadedInvoices,
  getUploadFilesManuallyData,
  getAttachmentFiles,
  deleteInvoiceUploadAttachmentFile,
  getInvoiceSummarry,
  editInvoice,
  postAdditionalAttachmentData,
  deleteAdditionalAttachmentFiles,
  postStoreAdditionalAttachments,
} from "services/invoiceService";
import { getContractList, getContractBuyersList } from "services/contractService";
import { getBuyerContactList } from "services/buyerService";

import { successNotification, errorNotification } from "utils/notificationUtils";
import { productType } from "utils/partiesUtils";

const InvoiceUpload = (props) => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const { state: authState, dispatch } = useContext(AuthContext);

  const productFid = authState.activeProductFid;

  const [contractList, setContractList] = useState([]);

  const [selectedContract, setSelectedContract] = useState(null);

  const [uploadedInvoices, setUploadedInvoices] = useState([]);

  const [erroredInvoices, setErroredIInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [automatiSupportedFiles, setAutomatiSupportedFiles] = useState([]);
  const [isUnrecognizedFiles, setIsUnrecognizedFiles] = useState(false);
  const [uploadStatusModalVisible, setUploadStatusModalVisible] = useState(false);
  const [invoiceEditVisible, setInvoiceEditVisible] = useState(false);
  const [invoiceEditData, setInvoiceEditData] = useState(null);

  const [submitForFinancingStatusModalVisible, setSubmitForFinancingStatusModalVisible] = useState(false);
  const [automaticUploadStatusModalVisible, setAutomaticUploadStatusModalVisible] = useState(false);
  const [addAdditionalAttachmentsModalVisible, setAddAdditionalAttachmentsModalVisible] = useState(false);

  const [uploadInvoiceSummary, setUploadInvoiceSummary] = useState({});

  const [uploadInvoiceStatus, setUploadInvoiceStatus] = useState(null);
  const [submitForFinancingStatus, setSubmitForFinancingStatus] = useState(null);

  const [buyersInvoices, setBuyersInvoices] = useState([]);
  const [buyersContacts, setBuyersContacts] = useState([]);

  const [selectedBuyersContacts, setSelectedBuyersContacts] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [buyersWithEmptyContacts, setBuyersWithEmptyContacts] = useState([]);
  const [showEmptyContactsError, setShowEmptyContactsError] = useState(false);

  const [contractBuyers, setContractBuyers] = useState([]);

  const steps = [
    {
      title: t("selectContract"),
      number: "01",
    },
    {
      title: t("uploadDocuments"),
      number: "02",
    },
    {
      title: t("invoiceVerification"),
      number: "03",
    },
  ];

  const spinConfiguration = {
    tip: t("loading") + "...",
  };

  const [contractListLoading, setContractListLoading] = useState(false);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const currentProductFid = usePrevious(productFid);

  useEffect(() => {
    if (authState.productType !== "factoring") {
      props.history.push("/loan/dashboard");
      return;
    }

    if (currentProductFid && productFid !== currentProductFid) {
      onRedirect();
    }

    async function fetchData() {
      setContractListLoading(true);
      const contractListResponse = apiWrapper(await getContractList(productFid), dispatch);
      setContractListLoading(false);

      if (!contractListResponse) return;
      const contractList = contractListResponse.contracts;

      contractList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

      setContractList(contractList);

      if (!authState.selectedContracts) {
        dispatch({
          type: "SELECT_CONTRACTS",
          payload: {
            selectedContracts: contractList.length ? [contractList[0]] : [],
          },
        });
      }

      if (contractList.length === 1) {
        if (current === 0) {
          const uploadedInvoicesResponse = apiWrapper(
            await getUploadedInvoices(
              (authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id
            ),
            dispatch, true
          );
          setUploadedInvoices(uploadedInvoicesResponse.content);

          const attachedFiles = apiWrapper(
            await getAttachmentFiles(
              (authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id
            ),
            dispatch, true
          );

          setAutomatiSupportedFiles(attachedFiles);
        }

        setCurrent(1);
      }

      setSelectedContract(authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]);

      if (!contractList.length) {
      }
    }
    if (productFid) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid, authState.productType]);

  useEffect(() => {
    automatiSupportedFiles.length > 0
      ? setIsUnrecognizedFiles(
          automatiSupportedFiles.some((item) => item.status === "UNRECOGNIZED" && item.type === "INVOICE_OCR")
        )
      : setIsUnrecognizedFiles(false);
  }, [automatiSupportedFiles]);

  const updateBuyerSelectedContact = (buyerCode, email) => {
    const existingBuyerIndex = selectedBuyersContacts.findIndex((i) => i.buyerCode === buyerCode);

    if (existingBuyerIndex >= 0) {
      const items = [...selectedBuyersContacts];
      items[existingBuyerIndex]["buyerContactEmail"] = email;
      setSelectedBuyersContacts(items);
    } else {
      setSelectedBuyersContacts([...selectedBuyersContacts, { buyerCode: buyerCode, buyerContactEmail: email }]);
    }
  };

  const submit = async () => {
    const validateInvoicesResponse = await validateInvoices(selectedContract.id);

    setUploadInvoiceStatus(validateInvoicesResponse);
    setUploadStatusModalVisible(true);
  };

  const submitUnrecognized = async () => {
    const submitUnrecognizedStatus = apiWrapper(await submitUnrecognizedFiles(selectedContract.id), dispatch, true);

    if (submitUnrecognizedStatus) {
      setSubmitForFinancingStatus(submitUnrecognizedStatus.invoiceUploadedOk);
      setSubmitForFinancingStatusModalVisible(true);
    }

    const attachedFiles = apiWrapper(
      await getAttachmentFiles((authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id),
      dispatch, true
    );

    setAutomatiSupportedFiles(attachedFiles);
  };

  const submitForFinancing = async () => {
    const validateInvoicesResponse = apiWrapper(await submitInvoices(selectedContract.id), dispatch, true);

    if (validateInvoicesResponse) {
      setSubmitForFinancingStatus(validateInvoicesResponse.invoiceUploadedOk);

      if (validateInvoicesResponse.buyerGroupedInvoiceResponse) {
        setUploadStatusModalVisible(false);

        setBuyersInvoices(validateInvoicesResponse?.buyerGroupedInvoiceResponse);

        setEmailData(validateInvoicesResponse.invoiceUploadedOk);

        const contractBuyersResponse = apiWrapper(await getContractBuyersList([selectedContract.id]), dispatch, true);

        if (contractBuyersResponse) {
          setContractBuyers(contractBuyersResponse);

          const getData = async (item) => await reloadContacts(item.buyerCode, contractBuyersResponse);
          const contacts = await Promise.all(validateInvoicesResponse.buyerGroupedInvoiceResponse.map(getData));

          setBuyersContacts(contacts);
        }

        setCurrent(current + 1);
      } else {
        setSubmitForFinancingStatusModalVisible(true);
      }
    }
  };

  const reloadContacts = async (buyerCode, contractBuyersResponse) => {
    const buyer = (contractBuyersResponse ? contractBuyersResponse : contractBuyers).thirdParties.find(
      (j) => j.registrationCode === buyerCode
    );

    if (buyer) {
      const buyerContactsResponse = apiWrapper(
        await getBuyerContactList({
          contractId: selectedContract.id,
          thirdPartytId: buyer.id,
        })
      );

      const existingBuyerIndex = buyersContacts.findIndex((i) => i.code === buyerCode.toString());

      const existingbuyersWithEmptyIndex = buyersWithEmptyContacts.findIndex((i) => i === buyerCode.toString());

      const data = {
        code: buyerCode,
        id: buyer.id,
        name: buyer.name,
        contacts: [...buyerContactsResponse.contacts],
      };
      if (existingBuyerIndex >= 0) {
        const items = [...buyersContacts];
        items[existingBuyerIndex] = data;
        if (!contractBuyersResponse) {
          setBuyersContacts(items);
        }
      } else {
        if (!contractBuyersResponse) {
          setBuyersContacts([...buyersContacts, data]);
        }
      }

      if (existingbuyersWithEmptyIndex >= 0) {
        const buyersWithEmptyContactsItems = [...buyersWithEmptyContacts];
        buyersWithEmptyContactsItems.splice(existingbuyersWithEmptyIndex, 1);
        setBuyersWithEmptyContacts(buyersWithEmptyContactsItems);
      }

      if (buyerContactsResponse.contacts.length) {
        const existingBuyerIndex = selectedBuyersContacts.findIndex((i) => i.buyerCode === buyerCode);

        if (existingBuyerIndex < 0) {
          setSelectedBuyersContacts([
            ...selectedBuyersContacts,
            {
              buyerCode: buyerCode,
              buyerContactEmail: buyerContactsResponse.contacts[0].email,
            },
          ]);
        }
      } else {
        setBuyersWithEmptyContacts([...buyersWithEmptyContacts, buyerCode]);
      }

      return data;
    }
  };

  const [sentEmailBlock, setSentEmailsBlock] = useState(false);
  const sendEmails = async () => {
    if(sentEmailBlock) return;

    setSentEmailsBlock(true)
    if (buyersWithEmptyContacts.length === 0) {
      setShowEmptyContactsError(false);
      apiWrapper(
        await sendEmailForFinancing(selectedContract.id, {
          invoiceGroupList: selectedBuyersContacts,
          invoiceUploadedOk: emailData,
        }),
        dispatch, true
      );

      setSubmitForFinancingStatusModalVisible(true);
      setSentEmailsBlock(false);
    } else {
      setShowEmptyContactsError(true);
    }
  };

  const next = async () => {
    current === 1 ? (uploadedInvoices.length > 0 ? submit() : submitUnrecognized()) : setCurrent(current + 1);

    if (current === 0) {
      const uploadedInvoicesResponse = apiWrapper(await getUploadedInvoices(selectedContract.id), dispatch, true);
      setUploadedInvoices(uploadedInvoicesResponse?.content);

      const attachedFiles = apiWrapper(
        await getAttachmentFiles((authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id),
        dispatch, true
      );

      setAutomatiSupportedFiles(attachedFiles);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const contractSelected = async (selectedContractId) => {
    const contractListItem = contractList.find((i) => i.id === selectedContractId);

    if (current === 0) {
      setUploadedInvoices([]);
      setSelectedInvoices([]);
    }

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [contractListItem],
        previouslySelectedContracts: [selectedContract],
      },
    });

    setSelectedContract(contractListItem);
  };

  const removeSelectedItems = async () => {
    apiWrapper(await removeInvoices(selectedInvoices.map((i) => i.uuid)), dispatch);

    const uploadedInvoicesResponse = apiWrapper(await getUploadedInvoices(selectedContract.id), dispatch, true);

    setUploadedInvoices(uploadedInvoicesResponse ? uploadedInvoicesResponse.content : []);

    setSelectedInvoices([]);

    getInvoiceSummary();
  };

  const addUploadedItems = async (item) => {
    const erroredItems = item.fileList.filter((file) => {
      if (
        file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file.status !== "uploading" &&
        file.status !== "error"
      ) {
        return file.response.invoiceErrors?.length;
      }
      return file.status !== "done";
    });

    if (erroredItems.length > 0) {
      setErroredIInvoices([...erroredItems.map((i) => i.response)]);

      setAutomaticUploadStatusModalVisible(true);
    } else {
      setErroredIInvoices([]);
      successNotification(t("success"), t("invoicesSuccessfullyUploaded"));
      getInvoiceSummary();
    }

    //TODO: do not call if all files and all records are not good.
    const uploadedInvoicesResponse = apiWrapper(await getUploadedInvoices(selectedContract.id), dispatch, true);

    setUploadedInvoices(uploadedInvoicesResponse ? uploadedInvoicesResponse.content : []);
  };

  const addSelectedItems = (items) => {
    setSelectedInvoices(items.filter((i) => i));
  };

  const addAutomatiSupportedFiles = async (items) => {
    const response = apiWrapper(await getAttachmentFiles(selectedContract.id), dispatch, true);

    setAutomatiSupportedFiles(response);
  };

  const onUploadStatusSave = (values) => {
    setUploadStatusModalVisible(false);
  };

  const onUploadStatusClose = async (values) => {
    setUploadStatusModalVisible(false);
    const attachedFiles = apiWrapper(
      await getAttachmentFiles((authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id),
      dispatch, true
    );

    setAutomatiSupportedFiles(attachedFiles);
  };

  const onAutomaticUploadStatusClose = async () => {
    setErroredIInvoices([]);
    setAutomaticUploadStatusModalVisible(false);
    const attachedFiles = apiWrapper(
      await getAttachmentFiles((authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id),
      dispatch, true
    );

    setAutomatiSupportedFiles(attachedFiles);
  };

  const onUploadManuallySuccess = async (status, fileCount) => {
    const uploadedInvoicesResponse = apiWrapper(await getUploadedInvoices(selectedContract.id), dispatch, true);

    setUploadedInvoices(uploadedInvoicesResponse ? uploadedInvoicesResponse.content : []);

    if (uploadedInvoicesResponse) {
      if (fileCount > 0) {
        const attachedFiles = apiWrapper(
          await getAttachmentFiles((authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id),
          dispatch, true
        );

        setAutomatiSupportedFiles(attachedFiles);
      }

      if (status === "success") {
        successNotification(t("success"), t("invoicesSuccessfullyUploaded"));
      } else if (status === "SME14") {
        errorNotification(t("error"), t("invoiceNumberAlreadyExists"));
      } else {
        errorNotification(t("error"), t("failledToUploadInvoice"));
      }

      getInvoiceSummary();
    }
  };

  const onRedirect = () => {
    props.history.push(`/${productType.Factoring}/invoices`);
    window.scrollTo(0, 0);
  };

  const onInvoiceUploadAttachmentFileDelete = async (id) => {
    apiWrapper(await deleteInvoiceUploadAttachmentFile(id), dispatch, true);

    const attachedFiles = apiWrapper(
      await getAttachmentFiles((authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id),
      dispatch, true
    );

    setAutomatiSupportedFiles(attachedFiles);
  };

  const onInvoiceDocumentsListChanged = async (sortFieldOrder) => {
    const uploadedInvoicesResponse = apiWrapper(
      await getUploadedInvoices(selectedContract.id, sortFieldOrder),
      dispatch, true
    );

    setUploadedInvoices(uploadedInvoicesResponse ? uploadedInvoicesResponse.content : []);
  };

  const getInvoiceSummary = useCallback(async () => {
    const summary = apiWrapper(await getInvoiceSummarry(selectedContract?.id));

    setUploadInvoiceSummary({
      total: summary?.totalAmount,
      advance: summary?.advance,
      remainder: summary?.reminder,
      totalFees: summary?.totalFees,
      currency: summary?.currency,
    });
  }, [selectedContract]);

  const openEditInvoice = (data) => {
    setInvoiceEditData(data);
    setInvoiceEditVisible(true);
  };

  const editInvoiceSubmit = async (data) => {
    const status = await editInvoice(selectedContract.id, data);

    setInvoiceEditVisible(false);

    if (status.valid) {
      successNotification(t("invoiceEditSuccess"), t("invoiceEditSuccessMessage"));

      const uploadedInvoicesResponse = apiWrapper(
        await getUploadedInvoices((authState.selectedContracts ? authState.selectedContracts[0] : contractList[0]).id),
        dispatch, true
      );

      setUploadedInvoices(uploadedInvoicesResponse.content);
    } else {
      errorNotification(t("invoiceEditError"), t("invoiceEditErrorMessage"));
    }
  };

  useEffect(() => {
    if (!selectedContract) return;

    getInvoiceSummary(selectedContract.id);
  }, [selectedContract, getInvoiceSummary]);

  useEffect(() => {
    if (current === 0) {
      setUploadedInvoices([]);
    }
  }, [current]);

  return (
    <>
      <UploadStatus
        uploadStatusModalVisible={uploadStatusModalVisible}
        onUploadStatusSave={onUploadStatusSave}
        onUploadStatusClose={onUploadStatusClose}
        uploadInvoiceStatus={uploadInvoiceStatus}
        submitForFinancing={submitForFinancing}
      />
      <SubmitForFinancingStatus
        uploadStatusModalVisible={submitForFinancingStatusModalVisible}
        status={submitForFinancingStatus}
        submitForFinancing={submitForFinancing}
        onRedirect={onRedirect}
      />
      <AutomaticUploadStatus
        uploadStatusModalVisible={automaticUploadStatusModalVisible}
        onUploadStatusClose={onAutomaticUploadStatusClose}
        erroredInvoices={erroredInvoices}
      />
      <EditInvoice
        contract={selectedContract}
        invoiceEditVisible={invoiceEditVisible}
        productFid={productFid}
        getUploadFilesManuallyData={getUploadFilesManuallyData}
        setInvoiceEditVisible={setInvoiceEditVisible}
        invoiceEditData={invoiceEditData}
        setInvoiceEditData={setInvoiceEditData}
        editInvoiceSubmit={editInvoiceSubmit}
        uploadedInvoices={uploadedInvoices}
      />
      <AddAdditionalAttachments
        addAdditionalAttachmentsModalVisible={addAdditionalAttachmentsModalVisible}
        setAddAdditionalAttachmentsModalVisible={setAddAdditionalAttachmentsModalVisible}
        contract={selectedContract}
        postAdditionalAttachmentData={postAdditionalAttachmentData}
        deleteAdditionalAttachmentFiles={deleteAdditionalAttachmentFiles}
        postStoreAdditionalAttachments={postStoreAdditionalAttachments}
      />
      <div className="invoiceUpload">
        <Spin className="mainSpinner" spinning={contractListLoading} tip={spinConfiguration.tip}>
          <Steps
            className="block invoice-upload-steps"
            current={current}
            steps={steps.map((i) => ({ key: i.title, title: i.title, description: i.number }))}
          />
        </Spin>
        <div className="steps-content selector-step-1">
          {current === 0 && contractList && !!contractList.length && selectedContract && (
            <Row className={"selector-container"}>
              <Col span={24}>
                <ContractSelector
                  type={"invoiceUpload"}
                  selectedContract={selectedContract}
                  contractList={contractList}
                  contractSelected={contractSelected}
                />
              </Col>
            </Row>
          )}

          {current === 1 && contractList.length && selectedContract && (
            <>
              <UploadDocuments
                contract={selectedContract}
                addUploadedItems={addUploadedItems}
                addSelectedItems={addSelectedItems}
                invoiceDocumentsList={uploadedInvoices}
                onInvoiceDocumentsListChanged={onInvoiceDocumentsListChanged}
                uploadInvoiceSummary={uploadInvoiceSummary}
                postManualInvoiceUpload={postManualInvoiceUpload}
                addAutomatiSupportedFiles={addAutomatiSupportedFiles}
                automatiSupportedFiles={automatiSupportedFiles}
                uploadedInvoices={uploadedInvoices}
                onUploadManuallySuccess={onUploadManuallySuccess}
                getAutomaticInvoiceUploadRequestData={getAutomaticInvoiceUploadRequestData}
                getUploadFilesManuallyData={getUploadFilesManuallyData}
                productFid={productFid}
                deleteInvoiceUploadAttachmentFile={onInvoiceUploadAttachmentFileDelete}
                uploadInvoiceStatus={uploadInvoiceStatus}
                openEditInvoice={openEditInvoice}
                setAddAdditionalAttachmentsModalVisible={setAddAdditionalAttachmentsModalVisible}
              />
            </>
          )}

          {current === 2 && selectedContract && buyersContacts.length > 0 && (
            <InvoiceVerification
              contract={selectedContract}
              buyersInvoices={buyersInvoices}
              buyersContacts={buyersContacts}
              reloadContacts={reloadContacts}
              updateBuyerSelectedContact={updateBuyerSelectedContact}
              buyersWithEmptyContacts={buyersWithEmptyContacts}
              showEmptyContactsError={showEmptyContactsError}
            />
          )}
        </div>

        {(current === 0 || (uploadedInvoices.length === 0 && !isUnrecognizedFiles)) && (
          <UploadStepActions
            current={current}
            steps={steps}
            prev={prev}
            next={next}
            sendEmails={sendEmails}
            uploadedInvoices={uploadedInvoices}
            selectedInvoices={selectedInvoices}
            removeSelectedItems={removeSelectedItems}
          />
        )}

        {current !== 0 && (uploadedInvoices.length > 0 || isUnrecognizedFiles) && (
          <Affix className="fixedActionPanel" offsetBottom={0}>
            <UploadStepActions
              isUnrecognizedFiles={isUnrecognizedFiles}
              current={current}
              steps={steps}
              prev={prev}
              next={next}
              sendEmails={sendEmails}
              uploadedInvoices={uploadedInvoices}
              selectedInvoices={selectedInvoices}
              removeSelectedItems={removeSelectedItems}
            />
          </Affix>
        )}
      </div>
    </>
  );
};

export default InvoiceUpload;
