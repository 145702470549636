import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";
import createDOMPurify from "dompurify";

import { getLegalTexts } from "services/legalService";
import i18next from "i18next";

const CookiesConsent = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);

  const DOMPurify = createDOMPurify(window);

  const language = i18next.language;

  useEffect(() => {
    const getData = async (lang) => {
      const response = await getLegalTexts(lang);

      if (response && response.ok) {
        const cookiesInfo = response.find((item) => item.field_type_of_legal_text === "Cookies");

        setData(cookiesInfo);
      }
    };

    getData(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return data ? (
    <CookieConsent
      enableDeclineButton={true}
      disableStyles={true}
      debug={false}
      containerClasses="cookies__container cookies__container--theme-sme"
      buttonClasses="button button--primary button--large button--theme-sme button--no-hover cookies__button"
      declineButtonClasses="button button--default button--large button--theme-sme button--no-hover cookies__button"
      buttonWrapperClasses="cookies__button-wrapper"
      buttonText={t("understand")}
      declineButtonText={t("decline")}
    >
      <div
        className="cookies__text-wrapper"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(data?.body?.[0]?.value),
        }}
      />
    </CookieConsent>
  ) : null;
};

export default CookiesConsent;
