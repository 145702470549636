import React from "react";

import { useTranslation } from "react-i18next";

import Col from "components/shared/Col";

import { moneyFormatter } from "utils/moneyUtils";
import { reformatUnderscore } from "utils/helpers";
import { productType } from "utils/partiesUtils";

const ContractHeaderContent = (props) => {
  const { t } = useTranslation();

  // This needed for changing the width of element dynamically regarding from the context.
  let defaultSpanSizes = [];
  switch (props.type) {
    case "compact":
      defaultSpanSizes = [0, 4, 20, 0, 0, 0];
      break;
    case "no-factoring":
      defaultSpanSizes = [0, 3, 4, 6, 5, 5];
      break;
    case "no-factoring-dashboard":
      defaultSpanSizes = [3, 4, 4, 4, 4, 5];
      break;
    case "contract":
      defaultSpanSizes = [0, 4, 4, 5, 5, 5];
      break;
    case "default":
      defaultSpanSizes = [0, 3, 4, 5, 5, 7];
      break;
    case "contracts":
      defaultSpanSizes = [0, 4, 4, 6, 5, 5];
      break;
    case "factoring-overview" : 
      defaultSpanSizes = [4, 4, 4, 5, 5, 5];
      break;
    case "factoring-bill-payment-modal":
      defaultSpanSizes = [7, 8, 0, 9, 0, 0];
      break;
    case "invoiceUpload":
      defaultSpanSizes = [0, 4, 4, 6, 5, 5];
      break;
    case "loan": 
      defaultSpanSizes = [3, 4, 4, 4, 4, 5];
      break;
    default:
      defaultSpanSizes = [4, 2, 3, 4, 4, 6];
  }
  // If props.spanSizes is not provided, script will use default values.
  const usedSpanSizes = props.spanSizes && props.spanSizes.length === 6 ? props.spanSizes : defaultSpanSizes;

  let factoringProduct =
    props.item && props.item.factoringProduct
      ? t(`factoringProduct${props.item.factoringProduct.toLowerCase()}`)
      : props.productName
      ? props.productName
      : "";

  return props.item ? (
    <>
      <Col span={usedSpanSizes[0]}>{factoringProduct}</Col>
      <Col span={usedSpanSizes[1]}>{props.item.contractNumber || "-"}</Col>
      {props.productType === productType.Loan && props.item.status && (
        <>
          <Col span={usedSpanSizes[3]}>
            {props.item.companyName}
          </Col>
          <Col span={4}>
            {t("loanStatus" + reformatUnderscore("_" + props.item.status))}
          </Col>
        </>
      )}

      {(!props.type || (props.type && props.type !== "compact")) &&
        (!props.productType || props.productType !== productType.Loan) && (
          <>
            <Col span={usedSpanSizes[3]}>
              {props.item.factorName}
            </Col>
            <Col span={usedSpanSizes[5]} className={"limit"}>
              <label> {t("limit")} </label> {moneyFormatter(props.item.factoringCreditLimit, "Eur")}
            </Col>
            <Col span={usedSpanSizes[5]} className={"availableLimit"}>
              <label>{t("availableLimit")} </label>{" "}
              {moneyFormatter(props.item.availableFactoringCreditLineAmount, "Eur")}
            </Col>
          </>
        )}
    </>
  ) : null;
};

export default ContractHeaderContent;
