import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Button from "components/shared/Button";
import Select from "components/shared/form/CustomSelect";

import CompanyCode from "components/forms/formElements/CompanyCode";
import MandatoryInputLimited from "components/forms/formElements/MandatoryInputLimited";

const BankStatementForm = ({ formInstance, initialValues = {}, onFinish, onSelectCountry, loading }) => {
  const { t } = useTranslation();

  const [country, setCountry] = useState();

  return (
    <>
      <Form
        className="form"
        form={formInstance}
        onFinish={onFinish}
        scrollToFirstError={true}
        initialValues={initialValues}
      >
        <h3 className="leasing-request-form__heading">{t("bankStatementCompanyInformationHeading")}</h3>
        <Row>
          <Col span={24}>
            <MandatoryInputLimited label={t("leasingFormCompanyName")} name="company_name" max={100} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Select
              label={t("country")}
              name={"country"}
              options={initialValues.countries?.map((item) => {
                return {
                  ...item,
                  key: item,
                  value: item,
                  title: t("country" + item),
                };
              })}
              rules={[
                {
                  required: true,
                  message: t("country") + " " + t("isRequired"),
                },
              ]}
              onChange={(data) => {
                onSelectCountry(data);
                setCountry(data);
              }}
            />
          </Col>
          <Col span={12}>
            <CompanyCode country={country} />
          </Col>
        </Row>
        <Button loading={loading} type="primary" size="large" htmlType="submit">
          {t("bankStatementFormSubmit")}
        </Button>
      </Form>
    </>
  );
};

export default BankStatementForm;
