import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import i18n from "config/i18n";
import uuid from "uuid/v4";

import BankSelector from "components/nordigen/BankSelector";

import {
  getBankList,
  getFromLocalStorage,
  popLocalStorage,
  postBankSelection,
  saveToLocalStorage,
} from "services/formService";

const NordigenSubmitter = ({ toggle, formStartCb, formContinueCb, formExitCb, submitCallFn }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = queryString.parse(useLocation().search);
  const language = i18n.language;

  const [bankSelectorOpen, setBankSelectorOpen] = useState(false);

  useEffect(() => {
    // deal with redirects from auth
    if (!params.ref) {
      formStartCb();
    } else {
      if (params.error) {
        if (params.error?.toLowerCase().startsWith("ssn")){
          return formStartCb(t("g4rFormErrorWrongSsn"));  
        }

        // Nordigen API error
        return formStartCb(t("g4rFormErrorWrongRef"));
      }

      const storedData = popLocalStorage();
      if (!!storedData && storedData.signing?.md5_code === params.ref) {
        (async () => {
          const response = await submitCallFn(storedData);
          if (!response.ok) {
            saveToLocalStorage(storedData);

            if (response.payload?.code === "SME78"){
              return formStartCb(t("g4rFormErrorWrongFullname"));
            }
          }
          formExitCb(!!response.ok);
        })();
      } else {
        // Obsolete ref
        history.push("/404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toggle) {
      setBankSelectorOpen(true);
    }
  }, [toggle]);

  const onBankSelect = async (bankId) => {
    setBankSelectorOpen(false);
    if (!bankId) {
      return formContinueCb();
    }

    const localStorage = getFromLocalStorage();
    const md5Code = uuid().replace(/-/g,"");
    const response = await postBankSelection(bankId, md5Code, language.toUpperCase(), localStorage.applicant?.code);

    if (!response.ok || !response.payload.url) {
      return formContinueCb(t("g4rFormErrorBank"));
    }

    saveToLocalStorage({
      ...localStorage,
      signing: {
        type: "NORDIGEN",
        md5_code: md5Code,
        requisition_id: response.payload.requisition_id,
      },
    });

    window.location.href = response.payload.url;
  };

  return bankSelectorOpen && <BankSelector onFinish={onBankSelect} bankSelectionFn={getBankList("LT")} />;
};

export default NordigenSubmitter;
