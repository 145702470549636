import React from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Select from "components/shared/form/CustomSelect";

const MaritalStatusBlock = ({ value, onValueChange = () => undefined, readOnly = false, prefill }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="leasing-request-form__heading">{t("leasingFormMaritalStatus")}</h3>
      <Row>
        <Col xs={24} sm={12}>
          <Select
            name={["applicant", "marital_status"]}
            label={t("leasingFormMaritalStatus")}
            value={value}
            rules={[
              {
                required: !prefill,
                message: t("leasingFormMaritalStatus") + " " + t("isRequired"),
              },
            ]}
            options={[
              { key: 1, value: "Single", title: t("leasingFormMaritalStatusSingle") },
              { key: 2, value: "Married", title: t("leasingFormMaritalStatusMarried") },
            ]}
            onChange={onValueChange}
            disabled={readOnly}
          />
        </Col>
      </Row>
    </>
  );
};

export default MaritalStatusBlock;
