import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import createDOMPurify from "dompurify";

import Checkbox from "../shared/Checkbox";
import Modal from "../shared/Modal";

const TermsConditions = (props) => {
  const DOMPurify = createDOMPurify(window);

  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [confirmEnabled, setConfirmEnabled] = useState(false);

  useEffect(() => {
    setModalVisible(props.termsConditionsModalVisible);
  }, [props.termsConditionsModalVisible]);

  const onTermsAndConditionsChecked = (event) => {
    setConfirmEnabled(event.target.checked);
  };

  return (
    <>
      <Modal
        width="48.125rem"
        className="modal blue"
        title={t("termsAndConditionsTitle")}
        visible={modalVisible}
        okText={t("termsAndConditionsConfirm")}
        cancelText={t("cancel")}
        cancelButtonProps={{
          className: "button button--default button--large",
        }}
        okButtonProps={{
          disabled: !confirmEnabled,
          className: "button button--primary button--large",
        }}
        onCancel={async () => {
          await props.closeTermAndCondition();
        }}
        onOk={async () => {
          await props.saveTermAndCondition();
        }}
      >
        <div className="terms-and-conditions">
          <div
            id="terms-and-conditions-text"
            className="terms-and-conditions__disclaimer"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.termsConditionsText),
            }}
          ></div>
          <Checkbox className="terms-and-conditions__description" onChange={onTermsAndConditionsChecked}>
            {t("termsAndConditionsDescription")}
          </Checkbox>
        </div>
      </Modal>
    </>
  );
};

export default TermsConditions;
