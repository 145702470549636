import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import GridFilterBlock from "components/shared/GridFilterBlock";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Form from "components/shared/form/Form";
import Select from "components/shared/form/Select";
import InputSearch from "components/shared/form/InputSearch";
import FilterButton from "components/shared/FilterButton";
import Button from "components/shared/Button";
import DatePicker from "components/shared/form/DatePicker";

import { productType } from "utils/partiesUtils";

const BillFilters = (props) => {
  const { t } = useTranslation();

  const [filteredInfo, setFilteredInfo] = useState(props.filteredInfo);

  useEffect(() => {
    setFilteredInfo(props.filteredInfo);
  }, [props.filteredInfo]);

  const numberChanged = (value) => {
    const newFilteredInfo = { ...filteredInfo, invoiceNumber: value };
    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const numberValueChanged = (e) => {
    setFilteredInfo({ ...filteredInfo, invoiceNumber: e.target.value });
  };

  const issueDateChangedFrom = (date, dateString) => {
    const newFilteredInfo = {
      ...filteredInfo,
      dateFrom: dateString,
    };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const issueDateChangedTo = (date, dateString) => {
    const newFilteredInfo = {
      ...filteredInfo,
      dateTo: dateString,
    };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const onReset = () => {
    filteredInfo.number = "";
    setFilteredInfo({});
    props.filterInfoChanged({});
  };

  const statusSelected = (value) => {
    const newFilteredInfo = { ...filteredInfo, status: value };
    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const filterButtonChange = (value) => {
    let newFilteredInfo = { ...filteredInfo, ...value };

    setFilteredInfo(newFilteredInfo);
    props.filterInfoChanged(newFilteredInfo);
  };

  const filterButtons = [{ key: "Contract" }, { key: "Principle" }, { key: "Interest" }, { key: "DefaultInterest" }];

  return (
    <GridFilterBlock className="InvoiceListFilter ReportListFilter BillListFilter">
      <Form>
        {props.product === productType.Factoring && (
          <Row>
            <Col span={24} className="checboxFilters">
              <FilterButton
                name="billsFilterInterest"
                value={props.filterData.interestFee}
                key="INTEREST_FEE"
                onChange={filterButtonChange}
                active={filteredInfo.billsFilterInterest}
              />

              <FilterButton
                name="billsFilterCommisionFee"
                value={props.filterData.commissionFee}
                key="COMMISSION_FEE"
                onChange={filterButtonChange}
                active={filteredInfo.billsFilterCommisionFee}
              />

              <FilterButton
                name="billsFilterLateFee"
                value={props.filterData.lateFee}
                key="LATE_FEE"
                onChange={filterButtonChange}
                active={filteredInfo.billsFilterLateFee}
              />

              <FilterButton
                name="billsFilterOther"
                value={props.filterData.other}
                key="OTHER_FEE"
                onChange={filterButtonChange}
                active={filteredInfo.billsFilterOther}
              />
            </Col>
          </Row>
        )}
        {props.product === productType.Loan && (
          <Row>
            <Col span={24} className="checboxFilters">
              {filterButtons.map((item) => (
                <FilterButton
                  key={item.key}
                  name={`billsFilter${item.key}`}
                  value={props.filterData?.[item.key]}
                  onChange={filterButtonChange}
                  active={filteredInfo[`billsFilter${item.key}`]}
                />
              ))}
            </Col>
          </Row>
        )}
        <Row>
          <Col span={6}>
            <DatePicker
              inlineLabel={true}
              onChange={issueDateChangedFrom}
              label={t("billsFilterDateFrom")}
              value={filteredInfo.dateFrom}
            />
          </Col>
          <Col span={6}>
            <DatePicker
              inlineLabel={true}
              onChange={issueDateChangedTo}
              label={t("billsFilterDateTo")}
              value={filteredInfo.dateTo}
            />
          </Col>
          <Col span={5}>
            <InputSearch
              inlineLabel={true}
              className="byNumberSearch"
              label={t("billsFilterNumber")}
              onSearch={numberChanged}
              onInput={numberValueChanged}
              value={filteredInfo.invoiceNumber}
            />
          </Col>

          <Col span={5}>
              <Select
              inlineLabel={true}
              label={t("Status")}
              onChange={statusSelected}
              value={filteredInfo.status ?? ""}
              options={[
                { key: 1, value: "PAID", title: t("billsFilterPaid") },
                { key: 2, value: "UNPAID", title: t("billsFilterUnpaid") },
                { key: 3, value: "PARTIALLY_PAID", title: t("billsFilterPartiallyPaid") },
                { key: 4, value: "OVERDUE", title: t("billsFilterOverdue") },
                { key: 5, value: "", title: t("billsFilterAll") },
              ]}
              ></Select>
          </Col>

          <Col span={2}>
            <Button className="filter-search" onClick={onReset}>
              {t("clear")}
            </Button>
          </Col>
        </Row>
      </Form>
    </GridFilterBlock>
  );
};

export default BillFilters;
