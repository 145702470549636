import React, { useEffect, useState, Fragment } from "react";
import i18n from "i18next";
import { Collapse } from "antd";
import _ from "lodash";
import createDOMPurify from "dompurify";
import Button from "components/shared/Button";
import { getFaq } from "../services/cmsService";
import IconArrow from "../assets/icons/icon_arrow.svg";
import cx from "classnames";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

const FaqPage = () => {
  const { t } = useTranslation();
  const language = i18n.language;
  const [categories, setCategories] = useState({});
  const [loading, setLoading] = useState(true);

  const DOMPurify = createDOMPurify(window);

  useEffect(() => {
    const getData = async () => {
      const response = await getFaq(language);

      if (response.code) {
        setLoading(false);
        return;
      }

      const groupedCategories = _.groupBy(response, (category) => category.field_category);

      setLoading(false);
      setCategories(groupedCategories);
    };

    getData();
  }, [language]);

  const renderCollapse = (
    <Collapse
      expandIconPosition="right"
      expandIcon={({ isActive }) => (
        <Button
          icon={
            <img
              src={IconArrow}
              shape="round"
              alt="arrow"
              className={cx("faq-page__arrow-icon", {
                "faq-page__arrow-icon--active": isActive,
              })}
            />
          }
        />
      )}
    >
      {Object.keys(categories).map((category, i) => (
        <Panel
          forceRender={false}
          header={<h3 className="faq-page__category-heading">{category}</h3>}
          key={i}
          className="faq-page__category"
        >
          <Collapse
            expandIconPosition="right"
            className="faq-page__topic-collapse"
            expandIcon={({ isActive }) => (
              <Button
                icon={
                  <img
                    src={IconArrow}
                    shape="round"
                    alt="arrow"
                    className={cx("faq-page__arrow-icon", {
                      "faq-page__arrow-icon--active": isActive,
                    })}
                  />
                }
              />
            )}
          >
            {categories[category].map((topic, i) => (
              <Panel
                forceRender={false}
                key={i}
                header={<h4 className="faq-page__topic-heading">{topic.title}</h4>}
                className="faq-page__topic"
              >
                <div
                  className="faq-page__topic-body"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(topic.body[0].processed, {
                      ADD_TAGS: ["iframe"],
                      ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
                    }),
                  }}
                />
              </Panel>
            ))}
          </Collapse>
        </Panel>
      ))}
    </Collapse>
  );

  const renderNoData = (
    <div className="block faq-page__no-data">
      {!loading && (
        <Fragment>
          <p>{t("faqNoDataMessage")}</p>
          <Button type="primary" size="small" to="/" className="faq-page__no-data__button">
            {t("faqNoDataButton")}
          </Button>
        </Fragment>
      )}
    </div>
  );

  return (
    <div className="faq-page">
      <Spin spinning={loading} tip={t("loading") + "..."} wrapperClassName="faq-page__loading-container">
        {Object.keys(categories).length > 0 ? renderCollapse : renderNoData}
      </Spin>
    </div>
  );
};

export default FaqPage;
