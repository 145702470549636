import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Select from "components/shared/form/CustomSelect";
import Checkbox from "components/shared/form/Checkbox";

import FormHeader from "components/forms/formElements/FormHeader";

const AssetInsuranceBlock = ({ formInstance, tooltip, readOnly = false }) => {
  const { t } = useTranslation();
  const [propertyInsuranceSelected, setPropertyInsuranceSelected] = useState();

  useEffect(() => {
    formInstance.resetFields([
      ["lending", "is_insurance_kasko"],
      ["lending", "is_insurance_ca"]
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyInsuranceSelected]);

  return (
    <>
      <FormHeader text={t("leasingFormPropertyInsurance")} tooltip={tooltip} />
      <Row>
        <Col span={12}>
          <Select
            name={["lending", "is_insurance_selected"]}
            value={propertyInsuranceSelected}
            options={[
              { key: 1, value: false, title: t("g4rFormPropertyInsuranceSelectedNo") },
              { key: 2, value: true, title: t("g4rFormPropertyInsuranceSelectedYes") },
            ]}
            onChange={setPropertyInsuranceSelected}
            disabled={readOnly}
          />
        </Col>
      </Row>
      {propertyInsuranceSelected && (
        <>
          <Row>
            <Form.Item name={["lending", "is_insurance_kasko"]} valuePropName="checked">
              <Checkbox className="public-form__checkbox" disabled={readOnly}>
                {t("g4rFormPropertyInsuranceKasko")}
              </Checkbox>
            </Form.Item>
          </Row>
          <Row>
            <Form.Item name={["lending", "is_insurance_ca"]} valuePropName="checked">
              <Checkbox className="public-form__checkbox" disabled={readOnly}>
                {t("g4rFormPropertyInsuranceCA")}
              </Checkbox>
            </Form.Item>
          </Row>
        </>
      )}
    </>
  );
};

export default AssetInsuranceBlock;
