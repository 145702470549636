import React, { useEffect, useState } from "react";
import { moneyFormatter } from "utils/moneyUtils";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import "./_kebabChart.scss";

const KebabChart = (props) => {
  const [disbursedHeight, setDisbursedHeight] = useState(0);
  const [overdueHeight, setOverdueeHeight] = useState(0);
  const [availableHeight, setAvailableHeight] = useState(0);
  const { amounts } = props;
  const { t } = useTranslation();

  useEffect(() => {
    const isOverdueTooSmall = props.overdueHeight > 0 && props.overdueHeight < 18;
    const isDisbursedTooSmall = props.disbursedHeight > 0 && props.disbursedHeight < 18;
    const isAvailableTooSmall = props.availableHeight > 0 && props.availableHeight < 18;

    const calculatedDisbursedHeight = isDisbursedTooSmall
      ? amounts.disbursed > amounts.overdue && isOverdueTooSmall
        ? 20
        : 18
      : isAvailableTooSmall
      ? 82
      : props.disbursedHeight;
    let calculatedOverdueHeight = !props.overdueHeight
      ? 0
      : isOverdueTooSmall
      ? 18
      : isAvailableTooSmall
      ? props.overdueHeight === props.disbursedHeight
        ? 82
        : 80
      : props.overdueHeight;
    const calculatedAvailableHeight = isAvailableTooSmall ? 20 : props.availableHeight;
    if (calculatedDisbursedHeight === calculatedOverdueHeight) {
      calculatedOverdueHeight = calculatedOverdueHeight - 1.4;
    }

    const timeout = setTimeout(() => {
      setAvailableHeight(calculatedAvailableHeight);
      setDisbursedHeight(calculatedDisbursedHeight + calculatedOverdueHeight);
      setOverdueeHeight(calculatedOverdueHeight > 82 ? 82 : calculatedOverdueHeight);
    }, 100);
    return () => clearTimeout(timeout);
  }, [props, amounts]);

  const isAmountZero = (amount) => {
    return amounts[amount] === 0;
  };

  return (
    <div className="charts">
      <div
        className={cx("charts__column charts__column--available", {
          "charts--isEmpty": isAmountZero("available"),
          "charts__column--hidden": isAmountZero("available") && !isAmountZero("disbursed")
        })}
        style={{ height: `${availableHeight}%` }}
      >
        <div className="chart-title">
          <div className="chart-title__text">{t("dashboardAvailable")}</div>
          <div className="chart-title__amount">{moneyFormatter(amounts.available, amounts.available.currency)}</div>
        </div>
      </div>
      <div
        className={cx("charts__column charts__column--disbursed", {
          "charts--isEmpty": isAmountZero("disbursed"),
          "charts__column--hidden": isAmountZero("disbursed")
        })}
        style={{ 
          height: `${disbursedHeight > overdueHeight + 20 ? disbursedHeight : disbursedHeight + overdueHeight}%`,
         }}
      >
        <div className="chart-title">
          <div className="chart-title__text">{t("dashboardDisbursed")}</div>
          <div className="chart-title__amount">{moneyFormatter(amounts.disbursed, amounts.disbursed.currency)}</div>
        </div>
      </div>
      <div className={cx("charts__column charts__column--overdue", {
        "charts__column--hidden": isAmountZero("overdue")
      })} style={{ height: `${overdueHeight}%` }}>
        <div className="chart-title">
          <div className="chart-title__text">{t("dashboardOverdue")}</div>
          <div className="chart-title__amount">{moneyFormatter(amounts.overdue, amounts.available.currency)}</div>
        </div>
      </div>
    </div>
  );
};

export default KebabChart;
