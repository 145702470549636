import React, { useState, useContext, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import TopMenu from "../../layouts/privateLayout/topMenu";
import useRoutes from "hooks/useRoutes";
import ChildRoute from "../../components/routes/childRoute";
import ServiceUnavailableModal from "components/shared/ServiceUnavailableModal";
import { AuthContext } from "App";

const Page = (props) => {
  const routes = useRoutes();
  const pageRoutes = routes[props.page];
  const [isServiceAvailable, setIsServiceAvailable] = useState(false);
  const { state: authState, dispatch } = useContext(AuthContext);

  useEffect(() => {
    setIsServiceAvailable(authState.isServiceUnavailable);
  }, [authState.isServiceUnavailable]);

  const onModelClose = () => {
    dispatch({
      type: "HIDE_SERVICE_UNAVAILABLE_MSG",
    });
  };

  return (
    <>
      {pageRoutes && (
        <div>
          <TopMenu routes={pageRoutes.filter((route) => !route.excluded)} />
          <Switch>
            {pageRoutes.map(({ exact, path, component, isAuthenticated }) => (
              <ChildRoute
                key={path}
                path={path}
                exact={exact}
                component={component}
                isAuthenticated={isAuthenticated}
              />
            ))}
            <Redirect to={pageRoutes[0].path} />
          </Switch>
          <ServiceUnavailableModal
            productType={authState.productType}
            modalVisible={isServiceAvailable}
            closeModal={onModelClose}
          />
        </div>
      )}
    </>
  );
};
export default Page;
