import settings from "config/config";
import fetchWrapper from "./fetchWrapper";

const statementBankSelectionUrl = settings.backendApiUrl + "/statement/institutions";
const statementFileSubmissionUrl = settings.backendApiUrl + "/statement/upload";
const statementCountriesUrl = settings.backendApiUrl + "/statement/countries";

export const getBankList = async (country) => fetchWrapper.getJSON(statementBankSelectionUrl, { country });

export const getRedirectUrl = () => window.location.origin + "/" + window.location.pathname.split("/")[1];

export const postStatementBankSelection = async (institution_id, md5_code, language) =>
  fetchWrapper.postJSONreceiveJSON(statementBankSelectionUrl, { institution_id, md5_code, language }, {
    Link: getRedirectUrl(),
  });

export const postStatementFile = async (formData, md5_code, requisition_id) =>
  fetchWrapper.postJSON(statementFileSubmissionUrl, { ...formData, md5_code, requisition_id });

export const saveToLocalStorage = (data) => localStorage.setItem("statementForm", JSON.stringify(data));

export const getFromLocalStorage = () => JSON.parse(localStorage.getItem("statementForm") || null) || {};

export const cleanLocalStorage = () => localStorage.removeItem("statementForm");

export const getCountries = async () => fetchWrapper.getJSON(statementCountriesUrl);
