import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../App";
import { Layout } from "antd";
import cx from "classnames";
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';

import { productType, hasProductTypeInMainParty, getProductFidByType } from "utils/partiesUtils";
import { getProductTitle } from "utils/translationIdUtils";
import { IconSmall } from "components/shared/Icon";
import { changeSelectedProduct } from 'state/action-creators/productActions';

const LOAN = productType.Loan;

const LeftMenu = () => {
  const { state, dispatch } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { Sider } = Layout;
  const history = useHistory();
  const location = useLocation();

  const displayOverflownText = () => {
    const emailEl = document.getElementById("email-text");
    if (emailEl.clientWidth > 155) {
      emailEl.style.transform = `translateX(calc(155px - ${emailEl.clientWidth}px))`;
    }
  };
  const hideOverflownText = () => {
    const emailEl = document.getElementById("email-text");
    emailEl.style.transform = `translateX(0)`;
  };

  const navigateToFaqPage = () => {
    history.push(`/${state.productType}/faq`);
  };

  const handleProductChange = (productType) => {
    changeSelectedProduct(getProductFidByType(state.parties, productType), productType, dispatch);
  }

  return (
    <>
      <Sider className="left-menu-wrapper" theme="light">
        <>
          <nav className="sideNav">
          <NavLink
                activeClassName="nav-active"
                key={'general'}
                onClick={() => history.push("/general/dashboard")}
                to={`/general/dashboard`}
              >
                <IconSmall type={'general'} />
                <span>{t(getProductTitle('general'))}</span>
              </NavLink>
            {Object.values(productType).map((productType) => (
              <NavLink
                activeClassName="nav-active"
                className={cx({
                  "active nav-active": location.pathname.startsWith(`/${productType}`),
                  "disabled-menu-item": productType === LOAN || !hasProductTypeInMainParty(state.parties, productType),
                })}
                key={productType}
                onClick={() => handleProductChange(productType)}
                to={`/${productType}/dashboard`}
              >
                <IconSmall type={productType} />
                <span>{t(getProductTitle(productType))}</span>
              </NavLink>
            ))}
          </nav>
          {state?.assignedManager?.email && (
            <>
              <div className={`personal-assistance ${state?.assignedManager?.photo ? "with-photo" : ""}`}>
                <div className="assistant-icon-wrapper">
                  <IconSmall type={"assistantHeaderIcon"} />
                </div>
                <div className="personal-assistance__can-we-help-you">
                  <span className="personal-assistance__can-we-help-you__question">
                    {t("personalAssistanceHowCanIHelpYou")}
                  </span>
                </div>
                {state?.assignedManager?.photo && (
                  <img className="assistant-photo" src={state?.assignedManager?.photo} alt="" />
                )}
                <div className="personal-assistance__expandable-top">
                  <p className="personal-assistance__expandable-top__name">{state?.assignedManager?.name}</p>
                  <div className="assistance-expandable-item">
                    <div className="assistance-expandable-item__icon-wrapper">
                      <IconSmall type={"phone"} />
                    </div>
                    <div className="assistance-expandable-item__text">
                      <span>
                        {state?.assignedManager?.phone
                          ? state?.assignedManager?.phone
                          : t("personalAssistancePhoneNumberNotSpecified")}
                      </span>
                    </div>
                  </div>
                  <div className="assistance-expandable-item">
                    <div className="assistance-expandable-item__icon-wrapper">
                      <IconSmall type={"email"} />
                    </div>
                    <div className="assistance-expandable-item__text">
                      <span onMouseOver={displayOverflownText} onMouseLeave={hideOverflownText} id="email-text">
                        {state?.assignedManager?.email}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="personal-assistance__expandable">
                  <p className="personal-assistance__expandable__question">{t("personalAssistanceNeedMoreHelp")}</p>
                  <div className="assistance-expandable-item">
                    <div className="assistance-expandable-item__icon-wrapper">
                      <IconSmall type={"phoneLight"} />
                    </div>

                    <div className="assistance-expandable-item__text">
                      <span>
                        {state?.assignedManager?.phone2
                          ? state?.assignedManager?.phone2
                          : t("personalAssistanceGeneralSmePhoneNumber")}
                      </span>
                    </div>
                  </div>

                  <div
                    className="assistance-expandable-item assistance-expandable-item--FAQ"
                    onClick={navigateToFaqPage}
                  >
                    <div className="assistance-expandable-item__icon-wrapper">
                      <IconSmall type={"faq"} />
                    </div>
                    <div className="assistance-expandable-item__text">
                      <span>{t("personalAssistanceFAQ")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </Sider>
    </>
  );
};

export default LeftMenu;
