import React from "react";
import { useTranslation } from "react-i18next";

import Input from "components/shared/form/Input";

const CompanyCode = ({ country, name = "company_code", label, readOnly = false }) => {
  const { t } = useTranslation();

  const companyCodeRegex = {
    LT: /^\d{9}$/,
    LV: /^\d{11}$/,
    EE: /^\d{8}$/,
    FI: /^\d{7}-?\d$/,
  };

  const validateCompanyCode = (value) => {
    if (value && !companyCodeRegex[country].test(value)) {
      return Promise.reject(t("invalidCompanyCode"));
    }

    return Promise.resolve();
  };

  return (
    <Input
      label={label || t("leasingFormCompanyCode")}
      name={name}
      rules={[
        {
          required: true,
          message: t("leasingFormCompanyCode") + " " + t("isRequired"),
        },
        {
          validator: (_, value) => validateCompanyCode(value),
        },
      ]}
      disabled={readOnly}
    />
  );
};

export default CompanyCode;
