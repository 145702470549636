import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import InvoiceFileTag from "./invoiceFileTag";

const UploadDocumentDetails = props => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col span={6}>
          <Row>
            <Col span={24}>{t("invoiceFiles")}</Col>
          </Row>
          <Row>
            <Col span={24}>
              <InvoiceFileTag
                fileName={props.invoiceFiles.name}
                fileSize={props.invoiceFiles.size}
              />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={24}>{t("additionalDocuments")}</Col>
          </Row>
          <Row>
            <Col span={24}>
              {props.supportingDocuments.map((item, i) => (
                <InvoiceFileTag key={i} fileName={item.name} fileSize={item.size} />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default UploadDocumentDetails;
