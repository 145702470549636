import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Input from "components/shared/form/Input";
import Select from "components/shared/form/CustomSelect";

import {
  getAssetBrands,
  getAssetModels,
  getAssetBodyTypes,
  getAssetFuelTypes,
  getAssetYears,
  getAssetTaxes,
} from "services/classifierService";

import { generateTranslationString } from "utils/helpers";
import { CONTRACT_TYPES } from "services/formService";

const AssetInformationBlock = ({ formInstance, readOnly = false }) => {
  const { t } = useTranslation();
  const formType = formInstance.getFieldValue(["applicant", "type"]);
  const isBusiness = formType === CONTRACT_TYPES.BUSINESS;

  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState();

  const [years, setYears] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [taxes, setTaxes] = useState([]);

  useEffect(() => {
    // load classifs
    getAssetBrands(formType).then(setBrands);
    getAssetYears().then(setYears);
    getAssetFuelTypes().then(setFuelTypes);
    getAssetBodyTypes().then(setBodyTypes);
    getAssetTaxes(formType).then(setTaxes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formInstance.resetFields([["lending", "model"]]);
    setSelectedModel(undefined);
    if (selectedBrand) {
      getAssetModels(selectedBrand).then(setModels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  return (
    <>
      <h3 className="leasing-request-form__heading">{t("g4rFormPropertyInfo")}</h3>
      <Row>
        <Col span="24">
          <Input
            label={t("g4rFormLicensePlate")}
            name={["lending", "license_plate"]}
            rules={[
              {
                max: 20,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Input
            label={t("g4rFormAdvertLink")}
            name={["lending", "ad_link"]}
            rules={[
              {
                max: 250,
                message: t("g4rFormFieldValueTooLong"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Select
            showSearch
            label={t("g4rFormBrand")}
            name={["lending", "brand"]}
            value={selectedBrand}
            options={brands}
            rules={[
              {
                required: true,
                message: t("g4rFormFuelType") + " " + t("isRequired"),
              },
            ]}
            onChange={setSelectedBrand}
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Select
            showSearch
            label={t("g4rFormModel")}
            name={["lending", "model"]}
            value={selectedModel}
            options={models.map((item) => {
              return {
                ...item,
                value: item.key,
              };
            })}
            rules={[
              {
                required: true,
                message: t("g4rFormFuelType") + " " + t("isRequired"),
              },
            ]}
            onChange={setSelectedModel}
            disabled={readOnly}
          />
        </Col>
      </Row>
      {isBusiness && (
        <Row>
          <Col span={24}>
            <Input
              label={t("g4rFormPropertyInfo")}
              name={["lending", "property_information"]}
              rules={[
                {
                  max: 250,
                  message: t("g4rFormFieldValueTooLong"),
                },
              ]}
              disabled={readOnly}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={24} sm={12}>
          <Select
            showSearch
            label={t("g4rFormYearMade")}
            name={["lending", "manufacturing_year"]}
            options={years.map((item) => {
              if (item.key === "other") {
                return {
                  key: "other",
                  value: "0",
                  title: t("g4rFormYearMadeOther"),
                };
              }
              return item;
            })}
            rules={[
              {
                required: true,
                message: t("g4rFormYearMade") + " " + t("isRequired"),
              },
            ]}
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Select
            showSearch
            label={t("g4rFormFuelType")}
            name={["lending", "fuel_type"]}
            options={fuelTypes.map((item) => {
              return {
                ...item,
                title: t(generateTranslationString(item.key, "FuelType")),
              };
            })}
            rules={
              isBusiness
                ? []
                : [
                    {
                      required: true,
                      message: t("g4rFormFuelType") + " " + t("isRequired"),
                    },
                  ]
            }
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Select
            showSearch
            label={isBusiness ? t("leasingFormBusinessBodyType") : t("leasingFormPrivateBodyType")}
            name={["lending", "body_type"]}
            options={bodyTypes.map((item) => {
              return {
                ...item,
                title: t(generateTranslationString(item.key, "BodyType")),
              };
            })}
            disabled={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormCarbonEmission")}
            name={["lending", "emissions"]}
            type="decimal"
            disabled={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <Input
            label={t("g4rFormEnginePower")}
            name={["lending", "engine_power"]}
            type="decimal"
            disabled={readOnly}
          />
        </Col>
        <Col span={12}>
          <Select
            label={t("g4rFormPropertyTaxExcluded")}
            name={["lending", "vat_group"]}
            options={taxes.map((item) => {
              return {
                ...item,
                title: t(generateTranslationString(item.label, "PropertyTaxExcluded")),
              };
            })}
            disabled={readOnly}
          />
        </Col>
      </Row>
      {!isBusiness && (
        <Row>
          <Col span={24}>
            <Input
              label={t("g4rFormPropertyInfo")}
              name={["lending", "property_information"]}
              rules={[
                {
                  max: 250,
                  message: t("g4rFormFieldValueTooLong"),
                },
              ]}
              disabled={readOnly}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default AssetInformationBlock;
