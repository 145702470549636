import settings from "config/config";
import fetchWrapper from "./fetchWrapper";

// Store management

const formStoreId = "financingRequestFormStore";
const formStoredFileId = formStoreId + "File";
export const saveToLocalStorage = (data) => {
  const filesArray = data.applicant.files ? data.applicant.files : [];
  filesArray.forEach((file, index) => {
    localStorage.setItem(formStoredFileId + index, file.fileContent);
  });

  const applicant = {
    ...data.applicant,
    files: filesArray.map((file) => ({
      fileName: file.fileName,
      documentType: file.documentType,
      fileSize: file.fileSize,
    })),
  };

  localStorage.setItem(formStoreId, JSON.stringify({ ...data, applicant: applicant }));
};
export const getFromLocalStorage = () => {
  const data = JSON.parse(localStorage.getItem(formStoreId));
  let filesArray = [];
  if (data) {
    filesArray = data.applicant?.files?.map((file, index) => ({
      fileName: file.fileName,
      documentType: file.documentType,
      fileSize: file.fileSize,
      fileContent: localStorage.getItem(formStoredFileId + index),
    }));

    const applicant = { ...data.applicant, files: filesArray };

    return {
      ...data,
      applicant: applicant,
    };
  }
};
export const cleanLocalStorage = () => {
  const filesArray = JSON.parse(localStorage.getItem(formStoreId))?.applicant?.files;
  if (filesArray) {
    filesArray.forEach((file, index) => localStorage.removeItem(formStoredFileId + index));
  }
  localStorage.removeItem(formStoreId);
};
export const popLocalStorage = () => getFromLocalStorage() || cleanLocalStorage();

// Type definitions

export const CONTRACT_TYPES = {
  PRIVATE: "PRIVATE",
  BUSINESS: "BUSINESS",

  isPrivate: (type) => type === CONTRACT_TYPES.PRIVATE,
  isBusiness: (type) => type === CONTRACT_TYPES.BUSINESS,
};

export const APPLICANT_TYPES = {
  DEFAULT: "PRIMARY APPLICANT",
  PARTNER: "PARTNER",
  SPOUSE: "SPOUSE",
  GUARANTOR: "GUARANTOR",

  isDefault: (mode) => mode === APPLICANT_TYPES.DEFAULT,
  isPartner: (mode) => mode === APPLICANT_TYPES.PARTNER,
  isSpouse: (mode) => mode === APPLICANT_TYPES.SPOUSE,
  isGuarantor: (mode) => mode === APPLICANT_TYPES.GUARANTOR,
};

export const PRODUCT_TYPES = {
  LOAN: "LOAN",
  LEASING: "LEASING",

  isLeasing: (type) => type === PRODUCT_TYPES.LEASING,
  isLoan: (type) => type === PRODUCT_TYPES.LOAN,
};

export const FINANCING_COMPANY = {
  SME_FINANCE: "SME_FINANCE",
  CAPITAL3: "CAPITAL3",
};

// Nordigen

const institutionsUrl = settings.backendApiUrl + "/form/institutions";
export const getBankList = async (country) => fetchWrapper.getJSON(institutionsUrl, { country });

export const getRedirectUrl = () => window.location.origin + "/" + window.location.pathname.split("/")[1];

export const postBankSelection = async (institution_id, md5_code, language, registration_code) =>
  fetchWrapper.postJSONreceiveJSON(
    institutionsUrl,
    { institution_id, md5_code, language, registration_code },
    {
      Link: getRedirectUrl(),
    }
  );

// Dokobit

const dokobitSigningStatusUrl = settings.backendApiUrl + "/form/dokobit/document/status";
export const getDokobitSingingStatus = async (token) => fetchWrapper.getText(dokobitSigningStatusUrl, { token });

// Calculators

const calculatorMontlyPaymentSetupUrl = settings.backendApiUrl + "/v2/calculator/monthly-payment/setup";
const calculatorMontlyPaymentUrl = settings.backendApiUrl + "/v2/calculator/monthly-payment/calculate";

export const getCalculatorMonthlyPaymentSetup = async (productType) =>
  fetchWrapper.getJSON(calculatorMontlyPaymentSetupUrl, { productType });

export const getCalculatorMontlyPayment = async (params) =>
  fetchWrapper.getJSON(calculatorMontlyPaymentUrl, {
    ...params,
  });

// Partners

const partnerTokenValidationUrl = settings.backendApiUrl + "/form/partner/validate";
const partnerPrivateTokenValidationUrl = settings.backendApiUrl + "/form/partner/prefill/validate";

export const validatePartnerToken = (originatorToken) =>
  fetchWrapper.getText(
    new URL(partnerTokenValidationUrl),
    {
      originatorToken,
    },
    {
      From: FINANCING_COMPANY.SME_FINANCE,
    }
  );

export const validatePartnerPrivateToken = (originatorPrivateToken) =>
  fetchWrapper.getJSON(
    new URL(partnerPrivateTokenValidationUrl),
    {
      originatorPrivateToken,
    },
    {
      From: FINANCING_COMPANY.SME_FINANCE,
    }
  );

export const getPartnerLogo = async (originatorToken) =>
  fetchWrapper.getFile(`${settings.backendApiUrl}/form/partner/${originatorToken}/logo`, undefined, undefined, true);

// Financing request

const financingRequestValidationUrl = settings.backendApiUrl + "/form/financial-request/validate";
const prefillFinancingRequestValidationUrl = settings.backendApiUrl + "/form/financial-request/prefill/validate";
const financingRequestUrl = settings.backendApiUrl + "/form/financial-request/apply";
const prefillFinancingRequestUrl = settings.backendApiUrl + "/form/financial-request/prefill/apply";

export const validateFinancingRequest = async (data) => fetchWrapper.postJSON(financingRequestValidationUrl, data);
export const validatePrefillFinancingRequest = async (data) =>
  fetchWrapper.postJSON(prefillFinancingRequestValidationUrl, data);
export const postFinancingRequest = async (data) => fetchWrapper.postJSONreceiveJSON(financingRequestUrl, data);
export const postPrefillFinancingRequest = async (data) =>
  fetchWrapper.postJSONreceiveJSON(prefillFinancingRequestUrl, data);

// Applicant update

export const getApplicantData = (applicationToken, applicantToken, code) =>
  fetchWrapper.getJSON(
    `${settings.backendApiUrl}/form/financial-request/${applicationToken}/applicants/${applicantToken}`,
    { code }
  );
export const getValidatedApplicant = (applicationToken, applicantToken) =>
  fetchWrapper.getJSON(
    `${settings.backendApiUrl}/form/financial-request/${applicationToken}/applicants/${applicantToken}/validate`
  );
export const validateApplicantData = (applicationToken, applicantToken, data) =>
  fetchWrapper.postJSON(
    `${settings.backendApiUrl}/form/financial-request/${applicationToken}/applicants/${applicantToken}/validate`,
    data
  );
export const postApplicantData = (applicationToken, applicantToken, data) =>
  fetchWrapper.postJSONreceiveJSON(
    `${settings.backendApiUrl}/form/financial-request/${applicationToken}/applicants/${applicantToken}`,
    data
  );

// Pipedrive

const dealDataUrl = settings.backendApiUrl + "/public/form/deal-data";
export const getDealData = async (hash) =>
  fetchWrapper.getJSON(dealDataUrl, undefined, {
    Authorization: hash,
  });

export const storeFinancingCompany = (service) => {
  if (!service) {
    return;
  }

  sessionStorage.setItem("financingCompany", service);
};

export const getStoredFinancingCompany = () => {
  return sessionStorage.getItem("financingCompany") || null;
};

export const storeOriginatorToken = (token) => {
  if (!token) {
    return;
  }

  sessionStorage.setItem("originatorToken", token);
};

export const getStoredOriginatorToken = () => {
  return sessionStorage.getItem("originatorToken") || null;
};
