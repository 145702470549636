import React from "react";
import { useTranslation } from "react-i18next";
import ModalListSelect from "components/shared/ModalListSelect";

const NordigenDokobitOptions = ({ onFinish }) => {
  const { t } = useTranslation();

  const processors = [
    {
      id: "dokobit",
      img: "Dokobit",
      name: t("leasingFormConfirmationDokobit"),
      description: t("leasingFormConfirmationDokobitDescription"),
    },
    {
      id: "nordigen",
      img: "Nordigen",
      name: t("leasingFormConfirmationNordigen"),
      description: t("leasingFormPrivateConfirmationNordigenDescription"),
    },
  ];

  return (
    <ModalListSelect
      options={processors}
      onFinish={onFinish}
      headingText={t("leasingFormPrivateConfirmationSelectorHeading")}
      okText={t("leasingFormPrivateConfirmationSelectorOk")}
    />
  );
};

export default NordigenDokobitOptions;
