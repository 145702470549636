import React, { useEffect, useState } from "react";
import i18next from "i18next";

import PartnerReferenceBlock from "components/forms/formBlocks/PartnerReferenceBlock";
import PipedriveMetadataBlock from "components/forms/formBlocks/PipedriveMetadataBlock";
import AdditionalInformationBlock from "components/forms/formBlocks/AdditionalInformationBlock";
import LoanCalculatorBlock from "components/forms/formBlocks/LoanCalculatorBlock";
import DataProcessingConsentBlock from "components/forms/formBlocks/DataProcessingConsentBlock";
import FinancialStateBlock from "components/forms/formBlocks/FinancialStateBlock";
import MaritialBlock from "components/forms/formBlocks/MaritialBlock";
import MarketingConsentBlock from "components/forms/formBlocks/MarketingConsentBlock";
import PoliticalExposureBlock from "components/forms/formBlocks/PoliticalExposureBlock";
import PrivateCustomerInformationBlock from "components/forms/formBlocks/PrivateCustomerInformationBlock";
import RentConsentBlock from "components/forms/formBlocks/RentConsentBlock";
import SubmitButton from "components/forms/formElements/SubmitButton";
import FileUploadBlock from "components/forms/formBlocks/FileUploadBlock";

import { getLegalTexts } from "services/legalService";
import { APPLICANT_TYPES, CONTRACT_TYPES, PRODUCT_TYPES } from "services/formService";

const PrivateLoanForm = ({
  formInstance,
  formMode = undefined,
  prefill = false,
  formModeLoading = false,
  formModePreview = false,
}) => {
  const language = i18next.language;

  const [legalTexts, setLegalTexts] = useState({});
  const [consent, setConsent] = useState();

  useEffect(() => {
    (async () => {
      const legalData = await getLegalTexts(language);
      if (!legalData) {
        return;
      }

      const fetchLegalItem = (title) => {
        return legalData.find((item) => item.title === title)?.body?.[0]?.value;
      };

      setLegalTexts({
        propertyValue: fetchLegalItem("LeasingForm Info Property value"),
        propertyInsurance: fetchLegalItem("LeasingForm Info Property Insurance"),
        confirmation: fetchLegalItem("LeasingForm Confirmation"),
        consentMarketing: fetchLegalItem("LeasingForm Consent Marketing"),
        politicallyExposed: fetchLegalItem("LeasingForm Info politically exposed"),
        marketingConsent: fetchLegalItem("LeasingForm Marketing Consent"),
        monthlyPaymentInfo: fetchLegalItem("LeasingForm Info Monthly Payment"),
        marketingConsentDetailed: fetchLegalItem("LeasingForm Marketing Consent Detailed Private"),
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <>
      <LoanCalculatorBlock
        formInstance={formInstance}
        editable={APPLICANT_TYPES.isPartner(formMode)}
        readOnly={APPLICANT_TYPES.isDefault(formMode) || formModePreview}
        tooltipText={legalTexts.monthlyPaymentInfo}
      />

      <PrivateCustomerInformationBlock formInstance={formInstance} readOnly={formModePreview} prefill={prefill}/>

      <MaritialBlock formInstance={formInstance} readOnly={formModePreview} prefill={prefill}/>
      <FinancialStateBlock formInstance={formInstance} readOnly={formModePreview} prefill={prefill}/>

      <AdditionalInformationBlock readOnly={formModePreview} />

      <FileUploadBlock
        readOnly={formModePreview}
        formInstance={formInstance}
        productType={`${PRODUCT_TYPES.LOAN}_${CONTRACT_TYPES.PRIVATE}`}
      />

      <PoliticalExposureBlock tooltip={legalTexts.politicallyExposed} readOnly={formModePreview} prefill={prefill}/>

      <RentConsentBlock innerHtml={legalTexts.consentMarketing} readOnly={formModePreview} />

      <DataProcessingConsentBlock
        consent={consent}
        onConsentChange={setConsent}
        innerHtml={legalTexts.confirmation}
        readOnly={formModePreview}
      />

      <MarketingConsentBlock
        hidden={APPLICANT_TYPES.isDefault(formMode)}
        innerHtml={legalTexts.marketingConsent}
        infoHtml={legalTexts.marketingConsentDetailed}
        readOnly={formModePreview}
      />

      {APPLICANT_TYPES.isDefault(formMode) && <PipedriveMetadataBlock />}
      {APPLICANT_TYPES.isPartner(formMode) && <PartnerReferenceBlock />}

      {!formModePreview &&
        (prefill ? (
          <SubmitButton textTranslationId="prefillFormSubmitButton" loading={formModeLoading} />
        ) : (
          <SubmitButton enabled={consent} loading={formModeLoading} />
        ))}
    </>
  );
};

export default PrivateLoanForm;
