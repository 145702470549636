import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import moment from "moment";

import { waveStepTypes } from "./../../utils/statusUtils";

const WaveChartControls = (props) => {
  const { t } = useTranslation();

  const [currentStepType, setCurrentStepType] = useState(null);

  useEffect(() => {
    setCurrentStepType(props.currentStepType);
  }, [props.currentStepType]);

  const updateCurrentStepType = (stepType) => {
    setCurrentStepType(stepType);
    props.updateCurrentStepType(stepType);
  };

  const futureOrNoData = (prev = false) => {
    if (props.history.length > 0) {
      if (
        !prev &&
        moment(props.currentWaveDateItem).isSameOrAfter(
          moment([...props.history].pop().date),
          "month"
        )
      ) {
        return true;
      }
      if (
        prev &&
        moment(
          moment(props.currentWaveDateItem).subtract(
            currentStepType === waveStepTypes.Month ? 12 : 1,
            "month"
          )
        ).isBefore(moment(props.history[0].date), "month")
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const isDisabled = (prev = false) => {
    switch (currentStepType) {
      case waveStepTypes.Month:
        return props.history.length <= 12 || futureOrNoData(prev);
      case waveStepTypes.Day:
        return futureOrNoData(prev);
      default:
        return true;
    }
  };

  return (
    <>
      <div className="waveChartControls">
        <strong>{props.currentStepTitle}</strong>
        <div className="waveChartControlsWrapper">
          <Button
            onClick={() => props.changeCurrentWaveDateItem()}
            className="navigation left"
            disabled={isDisabled(true)}
            type="link"
          ></Button>
          <Button
            onClick={() => props.changeCurrentWaveDateItem(true)}
            className="navigation right"
            disabled={isDisabled()}
            type="link"
          ></Button>
        </div>
        <div className="intervalActions">
          <Button
            type="link"
            onClick={() => updateCurrentStepType(waveStepTypes.Day)}
            className={currentStepType === waveStepTypes.Day && "active"}
          >
            {t("day")}
          </Button>
          <Button
            type="link"
            onClick={() => updateCurrentStepType(waveStepTypes.Month)}
            className={currentStepType === waveStepTypes.Month && "active"}
          >
            {t("month")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default WaveChartControls;
