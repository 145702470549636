const locale = {
  lang: {
    placeholder: "Pasirinkite datą",
    yearPlaceholder: "Pasirinkite metus",
    quarterPlaceholder: "Pasirinkite ketvirtį",
    monthPlaceholder: "Pasirinkite mėnesį",
    weekPlaceholder: "Pasirinkite savaitę",
    rangePlaceholder: ["Pradžios data", "Pabaigos data"],
    rangeYearPlaceholder: ["Pradžios metai", "Pabaigos metai"],
    rangeMonthPlaceholder: ["Pradžios mėnesis", "Pabaigos mėnesis"],
    rangeWeekPlaceholder: ["Pradžios savaitė", "Pabaigos savaitė"],
    locale: "lt_LT",
    today: "Šiandien",
    now: "Dabar",
    backToToday: "Rodyti šiandien",
    ok: "Gerai",
    clear: "Išvalyti",
    month: "Mėnesis",
    year: "Metai",
    timeSelect: "Pasirinkti laiką",
    dateSelect: "Pasirinkti datą",
    monthSelect: "Pasirinkti mėnesį",
    yearSelect: "Pasirinkti metus",
    decadeSelect: "Pasirinkti dešimtmetį",
    yearFormat: "YYYY",
    dateFormat: "YYYY-MM-DD",
    dayFormat: "DD",
    dateTimeFormat: "YYYY-MM-DD HH:MM:SS",
    monthBeforeYear: true,
    previousMonth: "Buvęs mėnesis (PageUp)",
    nextMonth: "Sekantis mėnesis (PageDown)",
    previousYear: "Buvę metai (Control + left)",
    nextYear: "Sekantis metai (Control + right)",
    previousDecade: "Buvęs dešimtmetis",
    nextDecade: "Sekantis dešimtmetis",
    previousCentury: "Buvęs amžius",
    nextCentury: "Sekantis amžius",
  },
  timePickerLocale: {
    placeholder: "Pasirinkite laiką",
    rangePlaceholder: ["Pradžios laikas", "Pabaigos laikas"],
  },
};

export default locale;
