import React from "react";
import { useTranslation } from "react-i18next";

import Modal from "components/shared/Modal";
import List from "components/shared/List";

import { moneyFormatter } from "utils/moneyUtils";

const ViewCollaterals = (props) => {
  const { t } = useTranslation();

  const noDataTitle = "noData";

  if (props.pledgeInvega) {
    props.pledges.push(
      `${t("factoringCollateralPledgeInvega")} ${moneyFormatter(props.pledgeInvega, props.currencyCode)}`
    );
  }

  return (
    <Modal
      width="48.125rem"
      className="modal grey view-collaterals-modal"
      title={t("loanCollateralTitle")}
      visible={props.visible}
      okText={t("back")}
      cancelButtonProps={{
        className: "button button--default button--large",
        style: { visibility: "hidden" },
      }}
      okButtonProps={{
        className: "button button--primary button--large",
      }}
      onOk={() => {
        props.onClose();
      }}
    >
      <h4>{t("factoringSureties")}</h4>
      <List className="view-collaterals-modal__list" noData={t(noDataTitle)} size="small" dataSource={props.sureties} />

      <h4>{t("factoringBillsOfExchange")}</h4>
      <List
        className="view-collaterals-modal__list"
        noData={t(noDataTitle)}
        size="small"
        dataSource={props.billOfExchange}
      />

      <h4>{t("factoringOtherCollaterals")}</h4>
      <List className="view-collaterals-modal__list" noData={t(noDataTitle)} size="small" dataSource={props.pledges} />
    </Modal>
  );
};

export default ViewCollaterals;
