import React from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import FeeList from "./feeList";

const Fees = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t("invoiceDetailsFees")}</h3>
      <Row>
        <Col span={12}>
          <FeeList
            list={props.invoiceInterestFees}
            totals={props.invoiceInterestFeesTotals}
            title={t("invoiceDetailsFeesInterest")}
            downloadPdfFile={props.downloadPdfFile}
            showTotals={true}
          />
        </Col>
        <Col span={12}>
          <FeeList
            list={props.invoiceLateFees}
            totals={props.invoiceLateFeesTotals}
            title={t("invoiceDetailsFeesLateFee")}
            downloadPdfFile={props.downloadPdfFile}
            showTotals={true}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <FeeList
            list={props.invoiceCommissionFees}
            title={t("invoiceDetailsCommisionFee")}
            downloadPdfFile={props.downloadPdfFile}
            showTotals={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default Fees;
