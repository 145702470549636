const kebabInitData = {
  factoring: {
    status: "unavailable",
    title: "Factoring",
    loading: false,
    error: false,
    total: {
      amount: 0,
      currency: "Eur",
    },
    available: {
      amount: 0,
      currency: "Eur",
    },
    used: {
      amount: 0,
      currency: "Eur",
    },
    overdue: {
      amount: 0,
      currency: "Eur",
    },
    repaid: {
      amount: 0,
      currency: "Eur",
    },
    toRepay: {
      amount: 0,
      currency: "Eur",
    },
  },
  loan: {
    status: "unavailable",
    loading: false,
    error: false,
    title: "Loan",
    total: {
      amount: 0,
      currency: "Eur",
    },
    available: {
      amount: 0,
      currency: "Eur",
    },
    used: {
      amount: 0,
      currency: "Eur",
    },
    overdue: {
      amount: 0,
      currency: "Eur",
    },
    repaid: {
      amount: 0,
      currency: "Eur",
    },
    toRepay: {
      amount: 0,
      currency: "Eur",
    },
  },
  limit: {
    status: "unavailable",
    title: "Limit",
    loading: false,
    error: false,
    total: {
      amount: 0,
      currency: "Eur",
    },
    available: {
      amount: 0,
      currency: "Eur",
    },
    used: {
      amount: 0,
      currency: "Eur",
    },
    overdue: {
      amount: 0,
      currency: "Eur",
    },
    repaid: {
      amount: 0,
      currency: "Eur",
    },
    toRepay: {
      amount: 0,
      currency: "Eur",
    },
  },
  leasing: {
    status: "unavailable",
    title: "Leasing",
    loading: false,
    error: false,
    total: {
      amount: 0,
      currency: "Eur",
    },
    available: {
      amount: 0,
      currency: "Eur",
    },
    used: {
      amount: 0,
      currency: "Eur",
    },
    overdue: {
      amount: 0,
      currency: "Eur",
    },
    repaid: {
      amount: 0,
      currency: "Eur",
    },
    toRepay: {
      amount: 0,
      currency: "Eur",
    },
  },
};
export default kebabInitData;
