import React, { useEffect, useContext, useState } from "react";
import { Collapse, notification } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import IconArrow from "../assets/icons/icon_arrow.svg";

import Button from "../components/shared/Button";
import PersonalInformation from "../components/account/personalnformation";
import LegalInformation from "../components/account/legalnformation";
import CompanyInformation from "../components/account/companyInformation";
import EmailNotificationSettings from "../components/account/emailNotificationSettings";
import LogginSettings from "../components/account/logginSettings";
import EditCompany from "../components/account/editCompany";

import { permissionTypes } from "utils/statusUtils";
import { hasPermissionInAnyParty } from "utils/partiesUtils";

import { getCountriesWithPhoneCodes, getCountries } from "services/accountService";
import {
  authorizeUser,
  getUserAccount,
  getBoParty,
  getPartyOfficials,
  changeEmail,
  changeAccount,
  changeSmsLoginPhone,
  postNewUser,
  deleteUser,
  changePasswordInternal,
  changeUserPermissions,
} from "../services/userService";
import { getLegalTexts } from "services/legalService";

import { AuthContext } from "App";
import cx from "classnames";
import apiWrapper from "services/apiWrapper";

const { Panel } = Collapse;

const Account = (props) => {
  const alertTimeout = 20;
  const { t } = useTranslation();
  const language = i18next.language;

  const { state: authState, dispatch } = useContext(AuthContext);
  const [account, setAccount] = useState(null);
  const [company, setCompany] = useState(null);
  const [partyOfficials, setPartyOfficials] = useState([]);
  const [editCompanyModalVisible, setEditCompanyModalVisible] = useState(false);

  const [countriesWithPhoneCodes, setCountriesWithPhoneCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [legalTexts, setLegalTexts] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const accountResponse = apiWrapper(await getUserAccount(), dispatch, true);
      setAccount(accountResponse);

      const companyResponse = apiWrapper(await getBoParty(authState.activePartyFid), dispatch, true);
      if (companyResponse?.code !== "SME4") {
        setCompany(companyResponse);
      }

      if (hasPermissionInAnyParty(authState.parties, permissionTypes.ManageUsers)) {
        const partyOfficialsResponse = apiWrapper(await getPartyOfficials(), dispatch, true);

        setPartyOfficials(
          partyOfficialsResponse && partyOfficialsResponse?.code !== "SME4" ? partyOfficialsResponse : null
        );
      } else {
        const currentPartyOfficials = [{ user: authState.user, partyList: [...authState.parties] }];

        setPartyOfficials(authState.parties ? currentPartyOfficials : null);
      }

      const countriesWithPhoneCodesResponse = await getCountriesWithPhoneCodes();

      const countriesWithPhoneCodes = countriesWithPhoneCodesResponse;

      countriesWithPhoneCodes.sort((a, b) => (a.dial_code > b.dial_code ? 1 : -1));

      setCountriesWithPhoneCodes(countriesWithPhoneCodes);

      const countriesResponse = apiWrapper(await getCountries(), dispatch, true);

      if (countriesResponse) {
        const countiesList = countriesResponse?.countries;

        countiesList.sort((a, b) =>
          a.translations[i18next.language.toUpperCase()] > b.translations[i18next.language.toUpperCase()] ? 1 : -1
        );
        setCountries(countiesList);
      }
    }
    if (authState && authState.user) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  useEffect(() => {
    const getData = async (lang) => {
      const response = await getLegalTexts(i18next.language);

      if (response.ok) {
        const info = response.filter((item) => item.field_type_of_legal_text === "File as attachment");

        setLegalTexts(info);
      }
    };

    getData(language);
  }, [language]);

  const emailNotificationSettingsChanged = (value) => {
    console.log("value", value);
  };

  const logginSettingsChanged = (value) => {
    console.log("value", value);
  };

  const showEditCompanyModal = (e) => {
    e.stopPropagation();
    setEditCompanyModalVisible(true);
  };

  const onEditCompanySave = (values) => {
    console.log("onEditCompanySave", values);
  };

  const onEditCompanyClose = (values) => {
    setEditCompanyModalVisible(false);
  };

  const onNewUserSave = async () => {
    notification["success"]({
      message: t("success"),
      description: t("accountCreateNewUserStatusMessage"),
      duration: alertTimeout,
    });

    const partyOfficialsResponse = apiWrapper(await getPartyOfficials(), dispatch, true);

    setPartyOfficials(
      partyOfficialsResponse && partyOfficialsResponse?.code !== "SME4" ? partyOfficialsResponse : null
    );
  };

  const onUserDelete = async (status) => {
    notification[status]({
      message: t(status),
      description: status === "success" ? t("accountDeleteUserStatusMessage") : t("error"),
      duration: alertTimeout,
    });

    const partyOfficialsResponse = apiWrapper(await getPartyOfficials(), dispatch, true);

    setPartyOfficials(
      partyOfficialsResponse && partyOfficialsResponse?.code !== "SME4" ? partyOfficialsResponse : null
    );
  };

  const onUserEdit = async () => {
    notification["success"]({
      message: t("success"),
      description: t("accountEditUserStatusMessage"),
      duration: alertTimeout,
    });

    const partyOfficialsResponse = apiWrapper(await getPartyOfficials(), dispatch, true);

    setPartyOfficials(
      partyOfficialsResponse && partyOfficialsResponse?.code !== "SME4" ? partyOfficialsResponse : null
    );
  };

  const logoutUser = () => {
    dispatch({
      type: "LOGOUT",
    });
    return;
  };
  return (
    <>
      <EditCompany
        onEditCompanySave={onEditCompanySave}
        editCompanyModalVisible={editCompanyModalVisible}
        onEditCompanyClose={onEditCompanyClose}
        countries={countries}
        company={company}
      />

      <div className="account-container">
        <Collapse
          defaultActiveKey={[0, 1, 2, 3, 4]}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <Button
              icon={
                <img
                  src={IconArrow}
                  shape="round"
                  alt="arrow"
                  className={cx("faq-page__arrow-icon", {
                    "faq-page__arrow-icon--active": isActive,
                  })}
                />
              }
            />
          )}
        >
          <Panel forceRender={false} header={<h3 className="account">{t("personalInformation")}</h3>} key="0">
            {account && (
              <PersonalInformation
                person={account}
                authorizeUser={authorizeUser}
                countriesWithPhoneCodes={countriesWithPhoneCodes}
                changeEmail={changeEmail}
                changePhone={changeAccount}
                logoutUser={logoutUser}
                changePassword={changePasswordInternal}
              />
            )}
          </Panel>
          <Panel
            forceRender={false}
            header={
              <h3 className="company">
                {t("companyInformation")}

                <Button onClick={showEditCompanyModal} className="edit hidden"></Button>
              </h3>
            }
            key="1"
          >
            {company && (
              <CompanyInformation
                company={company}
                person={account}
                authorizeUser={authorizeUser}
                partyOfficials={partyOfficials}
                countries={countries}
                countriesWithPhoneCodes={countriesWithPhoneCodes}
                postNewUser={postNewUser}
                deleteUser={deleteUser}
                onNewUserSave={onNewUserSave}
                onUserDelete={onUserDelete}
                onUserEdit={onUserEdit}
                changePassword={changePasswordInternal}
                changeUserPermissions={changeUserPermissions}
              />
            )}
          </Panel>

          <Panel forceRender={false} header={<h3 className="settings">{t("logginSettings")}</h3>} key="2">
            <LogginSettings
              person={account}
              authorizeUser={authorizeUser}
              logginSettingsChanged={logginSettingsChanged}
              countriesWithPhoneCodes={countriesWithPhoneCodes}
              countries={countries}
              changeSmsLoginPhone={changeSmsLoginPhone}
              changeAccount={changeAccount}
              changePassword={changePasswordInternal}
            />
          </Panel>
          <Panel
            forceRender={false}
            className={"hidden"}
            header={<h3 className="settings">{t("emailNotificationSettings")}</h3>}
            key="3"
          >
            <EmailNotificationSettings
              emailNotificationSettings={""}
              emailNotificationSettingsChanged={emailNotificationSettingsChanged}
            />
          </Panel>
          <Panel forceRender={false} header={<h3 className="legal">{t("legalInformation")}</h3>} key="4">
            {legalTexts && <LegalInformation legalTexts={legalTexts} />}
          </Panel>
        </Collapse>
      </div>
    </>
  );
};

export default Account;
