import React from "react";
import cx from "classnames";
import swedbank from "assets/icons/banks/swedbank-logo.svg";
import luminor from "assets/icons/banks/luminor-logo.svg";
import n26 from "assets/icons/banks/n26-logo.svg";
import revolut from "assets/icons/banks/revolut-logo.svg";
import seb from "assets/icons/banks/seb-logo.svg";
import siauliuBankas from "assets/icons/banks/siauliu-bankas-logo.svg";
import citadele from "assets/icons/banks/citadele-logo.svg";
import dokobit from "assets/icons/banks/dokobit-logo.svg";
import nordigen from "assets/icons/banks/nordigen-logo.svg";
import none from "assets/icons/banks/none-logo.svg";

const logos = {
  Swedbank: swedbank,
  Luminor: luminor,
  "N26 Bank": n26,
  Revolut: revolut,
  SEB: seb,
  "Šiaulių bankas": siauliuBankas,
  Citadele: citadele,
  Dokobit: dokobit,
  Nordigen: nordigen,
  None: none,
};

const ExternalLogo = ({ name, externalSrc }) =>
  externalSrc ? (
    <img className={"external-logo"} src={externalSrc} alt={name} />
  ) : name ? (
    <img
      className={cx("external-logo", { "external-logo--small": name === "N26 Bank" || name === "Revolut" })}
      src={logos[name]}
      alt={name}
    />
  ) : null;

export default ExternalLogo;
