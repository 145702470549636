import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      {new Date().getFullYear()}. {t("copyrightInfo")}.
    </footer>
  );
};

export default Footer;
