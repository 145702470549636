import { getAuthHeader } from "./guardService";

const logError = (error, url, params) => {
  // TODO: standartise
  // TODO: consolidate server generated error with non-server errors
  // TODO: show error to user - lack of data may be a process show-stopper
  console.error(`Failed to fetch (${url}${params ? "|" + params : ""}). Error - ${error}`);
  // Dump full error msg 'as-is' to trace to avoid log clutter in case of actual trace data
  console.trace(error);
};

const wrapResponse = async (response, dataMethod = "json", dataWrapper = (data) => data) => {
  try {
    if (response.ok) {
      return {
        ok: true,
        status: response.status,
        payload: dataWrapper(await response[dataMethod]()),
      };
    } else {
      const payload = await response.json();
      return {
        ok: false,
        payload: payload,
        status: response.status,
        message: payload.message,
      };
    }
  } catch (e) {
    return {
      ok: false,
      status: response.status,
      message: e,
    };
  }
};

const get = async (url, params, addHeaders) => {
  const url_ = new URL(url);
  if (params) {
    url_.search = new URLSearchParams(params);
  }

  try {
    return await fetch(url_, {
      headers: {
        ...getAuthHeader().headers,
        ...(addHeaders ? { ...addHeaders } : null),
      },
    });
  } catch (e) {
    logError(e, url, params);
    return {
      ok: false,
      error: true,
      message: e,
    };
  }
};

const getJSON = async (url, params, addHeaders) => wrapResponse(await get(url, params, addHeaders));
const getText = async (url, params, addHeaders) => wrapResponse(await get(url, params, addHeaders), "text");

const fileCache = {};

const getFile = async (url, params, addHeaders, cachable = true) => {
  if (cachable && Object.keys(fileCache).includes(url)) {
    return fileCache[url];
  } else {
    delete fileCache[url];
  }
  const response = await wrapResponse(await get(url, params, addHeaders), "blob", (blob) =>
    blob.size ? URL.createObjectURL(blob) : undefined
  );
  if (response.ok && cachable) {
    fileCache[url] = response;
  }
  return response;
};

const postJSON = async (url, data, addHeaders) => {
  try {
    return await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
        ...(addHeaders ? { ...addHeaders } : null),
      },
      body: data && JSON.stringify(data),
    });
  } catch (e) {
    logError(e, url, data);
    return {
      ok: false,
      error: true,
      message: e,
    };
  }
};

const postJSONreceiveJSON = async (url, data, addHeaders) => wrapResponse(await postJSON(url, data, addHeaders));
const postJSONreceiveText = async (url, data) => wrapResponse(await postJSON(url, data), "text");

const putText = async (url, data) => {
  try {
    return await fetch(url, {
      method: "PUT",
      body: data,
    });
  } catch (e) {
    logError(e, url, data);
    return {
      ok: false,
      error: true,
      message: e,
    };
  }
};

const putJSON = async (url, data) => {
  try {
    return await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: data && JSON.stringify(data),
    });
  } catch (e) {
    logError(e, url, data);
    return {
      ok: false,
      error: true,
      message: e,
    };
  }
};

const putJSONReceiveText = async (url, data) => wrapResponse(await putJSON(url, data), "text");

const fetchWrapper = {
  get,
  getJSON,
  getText,
  getFile,
  postJSON,
  postJSONreceiveJSON,
  postJSONreceiveText,
  putText,
  putJSON,
  putJSONReceiveText,
};

export default fetchWrapper;
