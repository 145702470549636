import React from "react";
import { useTranslation } from "react-i18next";

import ButtonLink from "components/shared/ButtonLink";

import { moneyFormatter } from "utils/moneyUtils";

const TransactionList = (props) => {
  const { t } = useTranslation();

  const payoutName = "PAYOUT";
  const remainderPayoutType = "REBATE";

  const onClick = async (id) => {
    await props.downloadPdfFile(id);
  };

  return (
    <>
      <div className="block TransactionList">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>{props.title}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.list &&
              props.list.map((item, i) => (
                <tr key={i}>
                  <td>{item.paymentCollectionDate || item.payoutPaymentDate}</td>
                  <td className="textAlignRight">
                    <label>
                      {item.payoutType
                        ? item.payoutType === remainderPayoutType
                          ? t("remainder")
                          : t(item.payoutType.toLowerCase())
                        : item.origin === payoutName
                        ? t("reconciliation")
                        : t("bankPayment")}
                    </label>
                  </td>
                  <td className={"textAlignRight smallCol"}>
                    {moneyFormatter(
                      item.invoiceCollectedAmount
                        ? item.invoiceCollectedAmount.amount
                        : item.invoicePaymentAmount.amount,
                      item.invoiceCollectedAmount
                        ? item.invoiceCollectedAmount.currency
                        : item.invoicePaymentAmount.currency
                    )}
                  </td>
                  <td className={"textAlignRight linkButton"}>
                    <ButtonLink
                      onClick={() =>
                        onClick(
                          item.payoutActId ||
                            (item.origin === payoutName ? item.reconciliationActId : item.accountsReceivableActId)
                        )
                      }
                      type="link"
                    ></ButtonLink>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            {!!props.list?.length && (
              <>
                <tr className={"textAlignRight"}>
                  <td colSpan="2">
                    <label>{t("invoiceDetailsTransactionsTotal")}</label>
                  </td>
                  <td>{props.totals && moneyFormatter(props.totals.amount, props.totals.currency)}</td>
                  <td></td>
                </tr>
                <tr className={"textAlignRight"}>
                  <td colSpan="2">
                    <label>{t("invoiceDetailsTransactionsResidual")}</label>
                  </td>
                  <td>{props.residual && moneyFormatter(props.residual.amount, props.residual.currency)}</td>
                  <td></td>
                </tr>
              </>
            )}
            {!props.list?.length && props?.totals?.amount === 0 && (
              <>
                <tr className="empty">
                  <td colSpan="4">—</td>
                </tr>
              </>
            )}
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default TransactionList;
