import React, { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import ExpandableTable from "components/shared/ExpandableTable";
import useComponentMountHandler from "../../../hooks/useComponentMountHandler";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import ContractFilter from "components/contractSelector/contractFilter";
import ReportFilters from "components/report/reportFilters";
import { expConfig, getExpRowKeys, setExpParent, newExpKeys } from "components/shared/expandRowTable";
import ReportListItemDetails from "components/report/ReportListItemDetails";
import MainSpin from "pages/base/MainSpin";

import { AuthContext } from "App";
import cx from "classnames";

import { getPdfFile } from "services/fileService";
import { getActs, getActExcelFile } from "services/invoiceService";
import { getContractList } from "services/contractService";

import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { reformatUnderscore, updateRouteIfMounted } from "utils/helpers";

import {
  tableLocale,
  urlProcessor,
  sortContracts,
  processContracts,
  getFilterParams,
  processFilterParams,
  pageSizes,
  defaultPageSize,
  homePath,
} from "pages/base/baseConfig";

import apiWrapper from "services/apiWrapper";

import { productType } from "utils/partiesUtils";
import ButtonLink from "components/shared/ButtonLink";

const StatementTypes = {
  statementsFilterPayout: "PAYOUT",
  statementsFilterPayment: "PAYMENT",
  statementsFilterInterest: "INTEREST_FEE",
  statementsFilterCommisionFee: "COMMISSION_FEE",
  statementsFilterLateFee: "LATE_FEE",
};

const Report = (props) => {
  const { t } = useTranslation();

  const [contractList, setContractList] = useState([]);
  const { state: authState, dispatch } = useContext(AuthContext);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [contractListLoading, setContractListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);
  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  const productFid = authState.activeProductFid;

  const [pagination, setPagination] = useState({
    defaultPageSize: defaultPageSize,
    showSizeChanger: true,
    pageSizeOptions: pageSizes,
    locale: { items_per_page: "" },
    showTitle: false,
  });

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortDirection, setSortDirection] = useState(null);

  useComponentMountHandler(isMounted);

  const hasExcelFile = (actType) => {
    return actType === "INTEREST_FEE" || actType === "COMMISSION_FEE" || actType === "LATE_FEE";
  };

  const columns = [
    {
      title: t("statementsTableDate"),
      dataIndex: "date",
      render: (value, item) => dateFormatter(value),
      width: 100,
      sorter: reportList?.length,
      defaultSortOrder: !sortDirection && "descend",
    },
    {
      title: t("statementsTableNumber"),
      dataIndex: "number",
      width: 300,
      sorter: reportList?.length,
    },
    {
      title: t("statementsTableContract"),
      dataIndex: "factoringProduct",
      render: (value) => {
        if (value) {
          return value ? t(`factoringProduct${value.toLowerCase()}`) + " " + t("factoring") : "";
        }
        return value;
      },
      width: 175,
      sorter: reportList?.length,
    },
    {
      title: t("statementsTableType"),
      dataIndex: "type",
      render: (value) => (value ? t(reformatUnderscore(value)) : t("interest")),
      width: 250,
      sorter: reportList?.length,
    },
    {
      title: t("statementsTableAmount"),
      dataIndex: "amount",
      className: "dataAlignAmount",
      render: (value) => moneyFormatter(value.amount, value.currency),
      sorter: reportList?.length,
    },
    {
      title: t("download"),
      align: "left",
      dataIndex: "id",
      className: "linkButton",
      render: (value, item) => (
        <>
          <ButtonLink onClick={async () => await getPdfFile(value)}>
            {t("factoringReportPdfLinkText")}
          </ButtonLink>
          {hasExcelFile(item.type) && (
            <>
              <ButtonLink onClick={async () => await getActExcelFile(value)}>
                {t("factoringReportExcelLinkText")}
              </ButtonLink>
            </>
          )}
        </>
      ),
      width: 70,
    },
  ];

  const handleTableChange = async (newPagination, filters, sorter) => {
    const sortFieldOrder = sorter.order ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";

    setSortDirection(sortFieldOrder);

    await fetchReportList(
      selectedContracts,
      newPagination.current,
      newPagination.pageSize,
      filteredInfo,
      sortFieldOrder
    );
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const urlProcessorData = urlProcessor(props.location.search);

    async function fetchData() {
      setContractListLoading(true);
      const contractListResponse = apiWrapper(await getContractList(productFid, signal), dispatch);

      let contractsIds = [];
      let page = 1;
      let size = pagination.defaultPageSize;
      let filter = {};

      if (urlProcessorData) {
        contractsIds = urlProcessorData?.contractsIds;

        const contractsContainsCurrentContract = contractListResponse?.contracts.some((r) =>
          contractsIds.includes(r.id)
        );

        if (contractsContainsCurrentContract) {
          page = urlProcessorData?.page || pagination.current || 1;
          size = urlProcessorData?.size || pagination.defaultPageSize;
          filter = urlProcessorData?.filter;

          setPagination({
            ...pagination,
            current: page ? parseInt(page) : 1,
            defaultPageSize: size,
          });
        }
      }

      setFilteredInfo(filter);

      setContractListLoading(false);

      if (contractListResponse?.contracts?.length) {
        const contractList = sortContracts(contractListResponse.contracts);

        const filteredList = contractList.filter((item) => item.status !== "READY_FOR_SIGNING");

        if (filteredList?.length === 0) {
          dispatch({
            type: "SET_EMPTY_SSP",
            payload: {},
          });
          updateRouteIfMounted(isMounted.current, () => props.history.push(`/factoring/dashboard`));

          return;
        }

        contractsIds = processContracts(contractList, authState.selectedContracts, contractsIds);

        setSelectedContracts(contractsIds);
        setContractList(contractList);

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: {
              selectedContracts: contractsIds.length
                ? [contractListResponse.contracts.find((i) => i.id === contractsIds[0])]
                : [contractListResponse.contracts[0]],
            },
          });
        }

        await fetchReportList(contractsIds, page, size, filter, null);
      } else {
        updateRouteIfMounted(isMounted.current, () => props.history.push(homePath(productType.Factoring)));
      }
    }
    if (productFid && authState.productType === productType.Factoring) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  const contractsSelected = async (contractsIds) => {
    const selectedItems = sortContracts(contractList.filter((i) => contractsIds.includes(i.id)));

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [selectedItems[0]],
      },
    });

    setSelectedContracts(contractsIds);
    fetchReportList(contractsIds, 1, pagination.defaultPageSize, filteredInfo, sortDirection);
  };

  const fetchReportList = async (contractId, page, size, filteredInfo, sortInfo) => {
    const newPagination = {
      ...pagination,
      current: page ? parseInt(page) : 1,
      pageSize: size,
    };

    setLoading(true);

    setExpandedKeys([]);
    updateRouteIfMounted(isMounted.current, () =>
      props.history.push(
        `/${productType.Factoring}/reports/?contractId=${contractId}&page=${page || 1}&size=${size}${getFilterParams(
          filteredInfo
        )}`
      )
    );

    filteredInfo = processFilterParams(filteredInfo, "statements", "actType", StatementTypes);

    const reportListResponse = await getActs(contractId, page, size, productFid, filteredInfo, sortInfo);

    setLoading(false);

    if (reportListResponse?.content) {
      setReportList(reportListResponse.content);

      if (reportListResponse.totalElements) {
        setPagination({
          ...newPagination,
          total: reportListResponse.totalElements,
        });
      } else {
        setPagination({
          ...newPagination,
          pageSize: size,
          total: 0,
        });
      }
    }
  };

  const filterInfoChanged = async (value) => {
    setFilteredInfo(value);
    await fetchReportList(
      selectedContracts,
      1,
      pagination.defaultPageSize,
      !Object.keys(value).length
        ? value
        : {
            ...filteredInfo,
            ...value,
          },
      sortDirection
    );
  };

  const renderExpandableRow = (record) => (
    <ReportListItemDetails
      expandedKeys={expandedKeys}
      pdfText={t("factoringReportPdfLinkText")}
      onGetPdfFile={async (fileId) => await getPdfFile(fileId)}
      record={record}
    />
  );

  const cleanReportsList = [...reportList];

  return (
    <>
      <div className={"Reports-page"}>
        <Row>
          <Col>
            <MainSpin spinning={contractListLoading}>
              {contractList.length && selectedContracts.length && (
                <ContractFilter
                  contractsSelected={contractsSelected}
                  contractList={contractList}
                  currentContract={selectedContracts}
                />
              )}
            </MainSpin>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReportFilters
              selectedContracts={selectedContracts}
              filterInfoChanged={filterInfoChanged}
              filteredInfo={filteredInfo}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ExpandableTable
              locale={tableLocale}
              className={cx("reportTable", "hasCollapsingRows", "hasDownloadButton", {
                noData: cleanReportsList.length === 0,
              })}
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={cleanReportsList}
              onChange={handleTableChange}
              loading={spinConfiguration}
              pagination={cleanReportsList?.length ? pagination : false}
              onExpand={(e, record) => setExpandedKeys(newExpKeys(record.id, expandedKeys))}
              expandedRowRender={cleanReportsList.length > 0 ? renderExpandableRow : null}
              expandedRowKeys={getExpRowKeys(cleanReportsList, expandedKeys, "reconciliationActs")}
              onRow={(record) => setExpParent(record, expandedKeys)}
              rowClassName={(record) => record.reconciliationActs && "expandable-row"}
              expandable={expConfig(expandedKeys, "reconciliationActs")}
              expandRowByClick
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Report;
