import React from "react";
import createDOMPurify from "dompurify";

import { InfoCircleOutlined } from "@ant-design/icons";
import Tooltip from "components/shared/Tooltip";

const FormHeader = ({ text = "", tooltip = undefined }) => {
  const DOMPurify = createDOMPurify(window);

  return (
    <h3 className="leasing-request-form__heading">
      {text}
      {tooltip && (
        <Tooltip
          title={
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(tooltip),
              }}
            />
          }
          placement="right"
        >
          <InfoCircleOutlined className="public-form__comment-icon" />
        </Tooltip>
      )}
    </h3>
  );
};

export default FormHeader;
