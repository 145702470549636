import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectKebabData } from "state/reducers/kebabDataReducer";
import Kebab from "./kebab";
import "./kebabPie.scss";
import { changeSelectedProduct } from "state/action-creators/productActions";
import { getProductFidByType, productType } from "utils/partiesUtils";
import { AuthContext } from "App";

const LOAN = productType.Loan;

const arrayFromObject = (object) => {
  return Object.keys(object).map((key) => object[key]);
};

const compareKebabsByAvailability = (a, b) => {
  if (a.status < b.status) return -1;
  if (a.status > b.status) return 1;
  return 0;
};

const KebabPie = () => {
  const kebabData = arrayFromObject(useSelector(selectKebabData));
  const history = useHistory();
  const { state: authState, dispatch } = useContext(AuthContext);

  const kebabClickHandler = (kebab) => {
    const productType = kebab.title.toLowerCase();
    if (kebab.status === "unavailable" || productType === LOAN) return;
    changeSelectedProduct(getProductFidByType(authState.parties, productType), productType, dispatch);
    history.push(`/${productType}/dashboard`);
  };

  return (
    <div className="kebab-pie-container">
      {kebabData.sort(compareKebabsByAvailability).map((kebab) => (
        <Kebab kebab={kebab} key={kebab.title} onClick={() => kebabClickHandler(kebab)} />
      ))}
    </div>
  );
};

export default KebabPie;
