import { getAuthHeader } from "./guardService";

import settings from "../config/config";
import fetchWrapper from "./fetchWrapper";

const getBillApiUrl = settings.backendApiUrl + "/factor-invoices";

const getUnpaidBillApiUrl = settings.backendApiUrl + "/payment/factor-invoices";

const getInvoicePaymentReceptsUrl = settings.backendApiUrl + "/payment-receipts";

const getBillTotalsUrl = settings.backendApiUrl + "/factor-invoices/totals";

const BillTypes = {
  billsFilterInterest: "INTEREST_FEE",
  billsFilterCommisionFee: "COMMISSION_FEE",
  billsFilterLateFee: "LATE_FEE",
  billsFilterOther: "OTHER_FEE",
};

export const BILL_STATUS = {
  UNPAID: "UNPAID",
};

export const CALLBACK_STATUS = {
  statuses: ["pending", "canceled", "token"],

  getStatus: (params) => CALLBACK_STATUS.statuses.find((status) => params.includes(status)),
};

export const getBillList = async (productFid, contractId, page, size, filter, sort) => {
  try {
    let url = new URL(getBillApiUrl);
    url.search = new URLSearchParams({
      partyId: productFid,
      contractId: contractId,
      page: page,
      size: size,
      sort: sort ? sort : "",
    });

    Object.entries(filter).map(([key, val]) => {
      if (key.includes("bills") && val === true) {
        url.searchParams.append("billType", BillTypes[key]);
      } else {
        url.searchParams.append(key, val);
      }

      return null;
    });

    const response = await fetch(url, getAuthHeader());

    let list = await response.json();

    return list;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getUnpaidBillsList = async (params) =>
  fetchWrapper.getJSON(getUnpaidBillApiUrl, {
    ...params,
  });

export const getInvoicePaymentRecepts = async (invoiceId) => {
  try {
    const response = await fetch(`${getInvoicePaymentReceptsUrl}?factorInvoiceId=${invoiceId}`, getAuthHeader());

    const item = await response.json();
    return item;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getBillTotals = async (productFid, contractId) => {
  try {
    let url = new URL(getBillTotalsUrl);
    url.search = new URLSearchParams({
      partyId: productFid,
      contractId: contractId,
      billType: "",
      dateFrom: "",
      dateTo: "",
      invoiceNumber: "",
      status: "",
    });

    const response = await fetch(url, getAuthHeader());

    let list = await response.json();

    return list;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
