import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "components/shared/language/languageSwitch";
import { AuthContext } from "App";
import { Row, Col } from "antd";
import Logo from "components/Logo";
import { getProductTitle } from "utils/translationIdUtils";
import { DeprecationCard } from "deprecationCard";

const Header = (props) => {
  const [isToggled, setToggled] = useState(false);
  const { state } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const location = useLocation();

  const toggleBurger = (event) => {
    props.toggleBurger(event);
    setToggled(!isToggled);
  };
  const getActiveLink = () => {
    const activeLink = location.pathname.split("/")[1]
    return activeLink.charAt(0).toUpperCase() + activeLink.slice(1);
  }

  return (
    <>
      <DeprecationCard globalBanner />
      <div className="header">
        <Row>
          <Col>
            <Row className="header__wrapper">
              <Col className="logo-wrapper" span="3">
                <Link to="/general/dashboard" className="logo">
                  <Logo />
                </Link>
              </Col>
              <Col span={7} className="dashboard-title">
                {t(getProductTitle(getActiveLink()))}
              </Col>
              {props.termsAndConditionsAccepted && (
                <Col span={12} className={"userTopMenu"}>
                  <LanguageSwitch />
                  {props.isAuthenticated && (
                    <div className={"BurgerMenu active-" + isToggled}>
                      <div className={"CowBell"}></div>
                      <div className={"BurgerMenuFace"} onClick={toggleBurger}>
                        <p>
                          {props.user.firstName} {props.user.lastName}
                        </p>
                        {state.parties &&
                          state.parties
                            .filter((party) => party.fid === state.activePartyFid)
                            .map((party) => <p key={party.fid}>{party?.name}</p>)}
                      </div>
                      <div className={"BurgerMenuRemote"}></div>
                      <div
                        className={"BurgerMenuContent"}
                        onMouseLeave={() => {
                          setToggled(false);
                        }}
                      >
                        <div className={"BurgerHeader"} onClick={toggleBurger}>
                          <p>
                            {props.user.firstName} {props.user.lastName}
                          </p>
                          {state.parties &&
                            state.parties
                              .filter((party) => party.fid === state.activePartyFid)
                              .map((party) => <p key={party.fid}>{party?.name}</p>)}
                        </div>
                        <div className={"BurgerContent"}>
                          <ul>
                            {state.parties &&
                              state.parties
                                .filter((party) => party.fid !== state.activePartyFid)
                                .map((party) => (
                                  <li key={party.fid} id={party.fid} onClick={toggleBurger}>
                                    {party.name}
                                  </li>
                                ))}
                          </ul>
                        </div>
                        <div className={"BurgerFooter"}>
                          <div>
                            <button>
                              <Link to={"/account"} onClick={toggleBurger}>
                                {t("userAccount")}
                              </Link>
                            </button>
                          </div>
                          <div>
                            <button onClick={() => props.logout()}>{t("logout")}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Header;
