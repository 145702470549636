import React, { useState, useEffect } from "react";
import { Form, Select } from "antd";
import i18next from "i18next";

import { requiredField, defaultLabelText } from "../../utils/formUtils";

const { Option } = Select;

const FloatFormItem = (props) => {
  const defaultPlaceholderText =
    props.rules && props.rules[0].required
      ? requiredField(props.label)
      : props.label;
  const [currentValue, setCurrentValue] = useState(null);
  const [labelText, setLabelText] = useState(defaultLabelText);
  const [placeholderText, setPlaceholderText] = useState(
    defaultPlaceholderText
  );
  const [changeLabel, setChangeLabel] = useState(false);

  const language = i18next.language;

  useEffect(() => {
    setPlaceholderText(defaultPlaceholderText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (props.initialValue) {
      setLabelText(
        props.rules && props.rules[0].required
          ? requiredField(props.label)
          : props.label
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue]);

  useEffect(() => {
    if (changeLabel) {
      setLabelText(
        currentValue
          ? props.rules && props.rules[0].required
            ? requiredField(props.label)
            : props.label
          : defaultLabelText
      );

      setPlaceholderText(currentValue ? "" : defaultPlaceholderText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, changeLabel]);

  const updateLabel = (value) => {
    setCurrentValue(value);

    if (value) {
      setChangeLabel(true);
    }
  };

  const CompName = props.type;

  return (
    <Form.Item
      label={labelText}
      name={props.name}
      rules={props.rules}
      className={props.className}
      style={props.style}
    >
      {props.type.name !== "Select" ? (
        <CompName
          placeholder={placeholderText}
          onChange={(event) => {
            updateLabel(event.target.value);
            props.onChange && props.onChange(event);
          }}
          addonBefore={props.addonBefore}
          suffix={props.suffix}
          name={props.name}
          className={props.className}
        />
      ) : (
        <Select
          placeholder={placeholderText}
          onChange={(value) => updateLabel(value)}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{
            width: "100%",
          }}
        >
          {props.items.length &&
            props.items.map((item, i) => (
              <Option key={i} value={item.code}>
                {item.name}
              </Option>
            ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default FloatFormItem;
