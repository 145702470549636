import { postSelectedProduct } from "services/userService";
import apiWrapper from "services/apiWrapper";

export const changeSelectedProduct = async (productFid, productType, dispatch) => {
  if (!productFid) {
    return;
  }

  const selectedParties = apiWrapper(await postSelectedProduct(productFid), dispatch);

  if (!selectedParties || selectedParties?.error) {
    return;
  }

  dispatch({
    type: "SELECT_PRODUCT",
    payload: {
      productType: productType,
      activeProductFid: productFid,
      parties: selectedParties,
    },
  });

  dispatch({
    type: "SELECT_CONTRACTS",
    payload: { selectedContracts: null },
  });
};
