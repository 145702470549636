import { combineReducers } from 'redux';
import  generalDashboardSlice  from './generalDashboardReducer';
import kebabDataSlice from './kebabDataReducer';

const reducers = combineReducers({
    kebabData: kebabDataSlice,
    generalDashboard: generalDashboardSlice,
    
});

export default reducers;