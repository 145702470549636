import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Radio from "components/shared/form/Radio";

import MaritalStatusBlock from "./MaritalStatusBlock";
import SpouseInformationBlock from "./SpouseInformationBlock";

import { generateTranslationString } from "utils/helpers";
import { getLeasePurposes } from "services/classifierService";
import { CONTRACT_TYPES } from "services/formService";

const MaritialBlock = ({ formInstance, readOnly = false, prefill }) => {
  const { t } = useTranslation();

  const [maritalStatus, setMaritalStatus] = useState();

  const [leasePurposes, setLeasePurposes] = useState([]);
  const [leasePurpose, setLeasePurpose] = useState();

  useEffect(() => {
    // load classifs
    getLeasePurposes(CONTRACT_TYPES.PRIVATE).then(setLeasePurposes);
  }, []);

  useEffect(() => {
    if (maritalStatus !== "Married") {
      formInstance.resetFields([
        ["applicant", "lease_purpose"],
        ["co_applicant", "name"],
        ["co_applicant", "surname"],
        ["co_applicant", "code"],
        ["co_applicant", "phone"],
        ["co_applicant", "address"],
      ]);

      setLeasePurpose(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maritalStatus]);

  return (
    <>
      <MaritalStatusBlock
        value={maritalStatus}
        onValueChange={setMaritalStatus}
        readOnly={readOnly}
        prefill={prefill}
      />

      {maritalStatus === "Married" && (
        <>
          <h3 className="leasing-request-form__heading">{t("leasingFormLoanPurpose")}</h3>
          <Row>
            <Col span={24}>
              <Form.Item
                name={["applicant", "lease_purpose"]}
                rules={[
                  {
                    required: true,
                    message: t("leasingFormLoanPurpose") + " " + t("isRequired"),
                  },
                ]}
              >
                <Radio
                  options={leasePurposes.map((item) => {
                    return {
                      ...item,
                      label: t(generateTranslationString(item.label, "LeasePurpose")),
                    };
                  })}
                  onChange={setLeasePurpose}
                  disabled={readOnly}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {maritalStatus === "Married" && leasePurpose === "Family" && (
        <SpouseInformationBlock readOnly={readOnly} formInstance={formInstance} />
      )}
    </>
  );
};

export default MaritialBlock;
