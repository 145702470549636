import React from "react";
import { Layout } from "antd";

const CustomContent = ({ children }) => {
  const { Content } = Layout;

  return <Content>{children}</Content>;
};

export default CustomContent;
