import settings from "../config/config";

import { getAuthHeader } from "./guardService";

const editBuyerUrl =
  settings.backendApiUrl + "/notification/email/contract/edit-buyer";
const deleteBuyerUrl =
  settings.backendApiUrl + "/notification/email/contract/delete-buyer";
const contactListUrl =
  settings.backendApiUrl +
  "/contracts/{contractId}/third-parties/{thirdPartytId}/contacts";
const editContactUrl =
  settings.backendApiUrl +
  "/contracts/{contractId}/third-parties/{thirdPartytId}/contacts/{email}";

export const editBuyer = async (data) => {
  try {
    const response = await fetch(editBuyerUrl, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if (response.status) {
        const errorResponse = await response.json();

        return { error: true, errorCode: errorResponse };
      }

      throw Error(response.statusText);
    }

    const editBuyerResponse = await response.text();
    return editBuyerResponse;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const deleteBuyer = async (data) => {
  try {
    const response = await fetch(deleteBuyerUrl, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      if (response.status) {
        const errorResponse = await response.json();

        return { error: true, errorCode: errorResponse };
      }

      throw Error(response.statusText);
    }

    const editBuyerResponse = await response.text();
    return editBuyerResponse;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getBuyerContactList = async ({ contractId, thirdPartytId }) => {
  let url = contactListUrl
    .replace("{contractId}", contractId)
    .replace("{thirdPartytId}", thirdPartytId);

  try {
    const response = await fetch(url, getAuthHeader());

    const contacts = await response.json();

    return contacts;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const createNewContact = async ({ contractId, thirdPartytId, data }) => {
  let url = contactListUrl
    .replace("{contractId}", contractId)
    .replace("{thirdPartytId}", thirdPartytId);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    return response;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const editContact = async ({
  contractId,
  thirdPartytId,
  email,
  data,
}) => {
  let url = editContactUrl
    .replace("{contractId}", contractId)
    .replace("{thirdPartytId}", thirdPartytId)
    .replace("{email}", email);

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    const contacts = await response.text();

    return contacts;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const deleteContact = async ({ contractId, thirdPartytId, email }) => {
  let url = editContactUrl
    .replace("{contractId}", contractId)
    .replace("{thirdPartytId}", thirdPartytId)
    .replace("{email}", email);

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
    });

    const contacts = await response.text();

    return contacts;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
