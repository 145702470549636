import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import FinancingFormController from "./FinancingFormController";

import {
  CONTRACT_TYPES,
  postFinancingRequest,
  postPrefillFinancingRequest,
  PRODUCT_TYPES,
  validateFinancingRequest,
  validatePrefillFinancingRequest,
  getStoredOriginatorToken,
} from "services/formService";
import { stripStringToDecimal, stripStringToInteger } from "utils/formUtils";

import PrivateConfirmation from "./confirmations/PrivateConfirmation";
import PrivateLoanForm from "./PrivateLoanForm";

const PrivateLoanFormController = ({ values, readOnly, formMode }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const isPrefillForm = location.pathname.includes("/prefill/");

  const initialValues = {
    financial_product_type: "LOAN_PRIVATE",
    ...(values
      ? values // override with pre-set values
      : {
          lending: {
            type: PRODUCT_TYPES.LOAN,
            prefill: isPrefillForm,
            partner_quote: true,
            property_value: stripStringToDecimal(params.vehiclePrice),
            period: stripStringToInteger(params.period),
            monthly_payment: stripStringToDecimal(params.monthPay),
            advance_payment: stripStringToDecimal(params.advancePayment),
            residual_value: stripStringToDecimal(params.residualValue),
            pipedrive_id: params.ID,
            originator_token: params.originatorToken || getStoredOriginatorToken(),
          },
          applicant: {
            type: CONTRACT_TYPES.PRIVATE,
            phone: params.phone?.replace(" ", "+"),
            email: params.email,
            is_marketing_consent: params.marketingAgreement === "true" || params.marketingAgreement === "1",
            marital_status: undefined,
            purpose_of_the_lease: undefined,
          },
          co_applicant: {
            type: CONTRACT_TYPES.PRIVATE,
          },
        }),
  };

  return (
    <>
      <FinancingFormController
        FormContents={PrivateLoanForm}
        FormConfirmator={PrivateConfirmation}
        readOnly={readOnly}
        prefill={isPrefillForm}
        setValues={initialValues}
        formMode={formMode}
        validationFn={isPrefillForm ? validatePrefillFinancingRequest : validateFinancingRequest}
        submitFn={isPrefillForm ? postPrefillFinancingRequest : postFinancingRequest}
      />
    </>
  );
};

export default PrivateLoanFormController;
