import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import FinancingFormController from "./FinancingFormController";
import PrivateLeasingForm from "./PrivateLeasingForm";

import {
  CONTRACT_TYPES,
  postFinancingRequest,
  postPrefillFinancingRequest,
  PRODUCT_TYPES,
  validateFinancingRequest,
  validatePrefillFinancingRequest,
  FINANCING_COMPANY,
  storeFinancingCompany,
  getStoredOriginatorToken,
} from "services/formService";
import { stripStringToDecimal, stripStringToInteger } from "utils/formUtils";

import PrivateConfirmation from "./confirmations/PrivateConfirmation";

const PrivateLeasingFormController = ({ values, readOnly, formMode }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const isPrefillForm = location.pathname.includes("/prefill/");

  const initialValues = {
    financial_product_type: "LEASING_PRIVATE",
    ...(values
      ? values // override with pre-set values
      : {
          lending: {
            type: PRODUCT_TYPES.LEASING,
            prefill: isPrefillForm,
            partner_quote: true,
            property_value: stripStringToDecimal(params.vehiclePrice),
            period: stripStringToInteger(params.period),
            monthly_payment: stripStringToDecimal(params.monthPay),
            advance_payment: stripStringToDecimal(params.advancePayment),
            residual_value: stripStringToDecimal(params.residualValue),
            is_property_selected: false,
            is_insurance_selected: false,
            is_insurance_kasko: true,
            is_insurance_ca: true,
            is_registration_fee_included: false,
            pipedrive_id: params.ID,
            originator_token: params.originatorToken || getStoredOriginatorToken(),
          },
          applicant: {
            type: CONTRACT_TYPES.PRIVATE,
            phone: params.phone?.replace(" ", "+"),
            email: params.email,
            // TODO: collapse to single value
            is_marketing_consent: params.marketingAgreement === "true" || params.marketingAgreement === "1",
            marital_status: undefined,
            purpose_of_the_lease: undefined,
          },
          co_applicant: {
            type: CONTRACT_TYPES.PRIVATE,
          },
          formMode: {
            formMode,
          },
        }),
  };

  storeFinancingCompany(FINANCING_COMPANY.SME_FINANCE);

  return (
    <>
      <FinancingFormController
        FormContents={PrivateLeasingForm}
        FormConfirmator={PrivateConfirmation}
        readOnly={readOnly}
        prefill={isPrefillForm}
        setValues={initialValues}
        formMode={formMode}
        validationFn={isPrefillForm ? validatePrefillFinancingRequest : validateFinancingRequest}
        submitFn={isPrefillForm ? postPrefillFinancingRequest : postFinancingRequest}
      />
    </>
  );
};

export default PrivateLeasingFormController;
