import React, { useEffect, useState, useContext, useRef } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Empty from "components/shared/Empty";
import Spin from "components/shared/Spin";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Table from "components/shared/Table";
import DonutPie from "components/dashboard/donutPie";
import Banners from "components/dashboard/banners";
import ContractSelector from "components/contractSelector/contractSelector";
import { AuthContext } from "App";

import { getBanners } from "services/cmsService";
import { getLoanList, getLoanTotals, getUpcomingPayments } from "services/hwService";

import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { logoutReasons } from "utils/statusUtils";
import { productType } from "utils/partiesUtils";
import { reformatUnderscore, updateRouteIfMounted } from "utils/helpers";
import useComponentMountHandler from "../../../hooks/useComponentMountHandler";

import apiWrapper from "services/apiWrapper";

import { Button } from "antd";
import cx from "classnames";
import { loansAmounts, loanDataInitBuild } from "services/donutPieService";

const Dashboard = (props) => {
  const { t } = useTranslation();

  const donutDataInit = loanDataInitBuild();
  const LIMIT = 5;

  const [contractList, setContractList] = useState([]);
  const [pageBanners, setPageBanners] = useState({});
  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const { state: authState, dispatch } = useContext(AuthContext);
  const productFid = authState.activeProductFid;
  const [selectedContract, setSelectedContract] = useState(null);

  const [loading, setLoading] = useState(false);
  const [upcomingPaymentsLoading, setUpcomingPaymentsLoading] = useState(false);

  const [donutData, setDonutData] = useState(donutDataInit);
  const [showDonut, setShowDonut] = useState(false);
  const [limit, setLimit] = useState(LIMIT);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [upcomingPaymentsTableSort, setUpcomingPaymentsTableSort] = useState();
  const isMounted = useRef(false);
  useComponentMountHandler(isMounted);

  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: upcomingPaymentsLoading,
  };

  const upcomingPaymentsColumns = [
    {
      title: t("loanBillNumber"),
      dataIndex: "billNumber",
      className: "first-column",
      sorter: upcomingPayments?.length,
    },
    {
      title: t("billBillType"),
      dataIndex: "billType",
      className: "billType",
      render: (value) => t("billsFilter" + reformatUnderscore("_" + value)),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("loanBillDueDate"),
      dataIndex: "dueDate",
      className: "billDueDate",
      render: (value, item) => dateFormatter(value),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("loanBillOpenBillAmount"),
      dataIndex: "openAmount",
      className: "unpaid",
      render: (value, item) => moneyFormatter(value || 0, item.currencyCode),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("billsTableStatus"),
      dataIndex: "status",
      className: "billStatus",
      render: (value) => {
        return value ? t(reformatUnderscore(value.toLowerCase())) : null;
      },
      sorter: upcomingPayments?.length,
    },
  ];

  const language = i18n.language;

  let location = useLocation();

  useEffect(() => {
    async function fetchData() {
      setLimit(LIMIT);
      setLoading(true);

      const contractListResponse = apiWrapper(await getLoanList(productFid), dispatch);

      let selectedContractItem = {};

      if (contractListResponse) {
        const contractList = contractListResponse.contracts;

        if (contractList) {
          contractList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

          setContractList(contractList);

          selectedContractItem = authState.selectedContracts ? authState.selectedContracts[0] : contractList[0];
        }

        if (location.search && location.search.includes("contractId")) {
          const contractIdParts = location.search.split("=");
          const contractId = contractIdParts[contractIdParts.length - 1];
          const contract = contractListResponse?.contracts?.find((i) => i.id === contractId);

          if (contract) {
            selectedContractItem = contract;
          }
        }

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: { selectedContracts: [selectedContractItem] },
          });
        }

        setSelectedContract(selectedContractItem);

        if (selectedContractItem?.id) {
          updateRouteIfMounted(isMounted.current, () =>
            props.history.push(`/loan/dashboard/?contractId=${selectedContractItem?.id}`)
          );
        }

        if (selectedContractItem) {
          handleDonutData(productFid, selectedContractItem);
          handleUpcomingPayments(selectedContractItem, limit, null);
        }
      } else {
        setLoading(false);
      }

      if (contractListResponse?.contracts?.length === 0) {
        dispatch({
          type: "SET_EMPTY_SSP",
          payload: {},
        });
      }
    }
    if (
      productFid &&
      authState.productType === productType.Loan &&
      authState.user &&
      authState.user.termsConditionsAccepted
    ) {
      try {
        fetchData();
      } catch {
        dispatch({
          type: "LOGOUT",
          payload: { reason: logoutReasons.SessionExpired },
        });
        return;
      }
    } else if (authState.productType !== productType.Factoring) {
      dispatch({
        type: "SHOW_SERVICE_UNAVAILABLE_MSG",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  useEffect(() => {
    const fetchBanners = async (lang) => {
      const bannersResponse = await getBanners(lang);
      if (!bannersResponse?.code) {
        setPageBanners(bannersResponse);
      }
    };
    fetchBanners(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const contractSelected = async (selectedContractId) => {
    setLoading(true);
    setIsLimitReached(false);

    const selectedItem = contractList.find((i) => i.id === selectedContractId);

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [selectedItem],
        previouslySelectedContracts: [selectedContract],
      },
    });

    setSelectedContract(selectedItem);
    updateRouteIfMounted(isMounted.current, () =>
      props.history.push(`/loan/dashboard/?contractId=${selectedItem?.id}`)
    );

    handleDonutData(productFid, selectedItem);
    handleUpcomingPayments(selectedItem, LIMIT, null);
  };

  const handleUpcomingPayments = async (selectedContract, limit, sort) => {
    setUpcomingPaymentsLoading(true);

    const upcomingPaymentsResponse = await getUpcomingPayments(selectedContract?.companyId, selectedContract?.id, limit, sort);
      
    if (upcomingPaymentsResponse?.upcomingPayments) {
      setUpcomingPayments(upcomingPaymentsResponse.upcomingPayments);

      if (upcomingPaymentsResponse?.total <= limit) {
        setIsLimitReached(true);
      }
    }
    setUpcomingPaymentsLoading(false);
  };

  const onMoreUpcomingPaymentsClicked = async () => {
    const newLimit = limit + 5;
    await setLimit(newLimit);

    handleUpcomingPayments(selectedContract, newLimit, upcomingPaymentsTableSort);
  };

  const handleDonutData = async (productFid, selectedContract) => {
    const loanTotalsData = await getLoanTotals(productFid, selectedContract.companyId, selectedContract.id);

    if (loanTotalsData) {
      fillDonutData(loanTotalsData, selectedContract.id);

      setDonutData(donutData.map((object) => ({ ...object })));
      setShowDonut(true);
    }

    setLoading(false);
  };

  const fillDonutData = (loanTotalsData, contractId) => {
    donutData[loansAmounts.DISBURSED].amount = loanTotalsData.disbursedAmount ?? 0;
    donutData[loansAmounts.DISBURSED].currency = loanTotalsData.currencyCode;
    donutData[loansAmounts.DISBURSED].action.action = donutDataInit[loansAmounts.DISBURSED].action.action.replace(
      "{contractId}",
      contractId
    );
    donutData[loansAmounts.DISBURSED].action.title = getAvailableAmount(
      loanTotalsData.availableAmount,
      loanTotalsData.currencyCode
    );

    donutData[loansAmounts.REPAID].amount = loanTotalsData.repaidAmount ?? 0;
    donutData[loansAmounts.REPAID].currency = loanTotalsData.currencyCode;
    donutData[loansAmounts.REPAID].action.action = donutDataInit[loansAmounts.REPAID].action.action.replace(
      "{contractId}",
      contractId
    );

    donutData[loansAmounts.REPAYMENT].amount = loanTotalsData.repaymentAmount ?? 0;
    donutData[loansAmounts.REPAYMENT].currency = loanTotalsData.currencyCode;

    donutData[loansAmounts.OVERDUE].amount = loanTotalsData.overdueAmount ?? 0;
    donutData[loansAmounts.OVERDUE].currency = loanTotalsData.currencyCode;
    donutData[loansAmounts.OVERDUE].action.action = donutDataInit[loansAmounts.OVERDUE].action.action.replace(
      "{contractId}",
      contractId
    );
    donutData[loansAmounts.OVERDUE].list.splice(0, donutData[loansAmounts.OVERDUE].list.length);
    donutData[loansAmounts.OVERDUE].list.push({
      title: "interest",
      amount: loanTotalsData.overdueAmountInterest ?? 0,
    });
    donutData[loansAmounts.OVERDUE].list.push({
      title: "defaultInterest",
      amount: loanTotalsData.overdueAmountDefaultInterest ?? 0,
    });
    donutData[loansAmounts.OVERDUE].list.push({
      title: "contract",
      amount: loanTotalsData.overdueAmountContract ?? 0,
    });
  };

  const getAvailableAmount = (availableAmount, currency) => {
    return availableAmount > 0 ? (
      <>
        <p className="text-grey">{t("loanAvailable")} :</p> {moneyFormatter(availableAmount, currency)}
      </>
    ) : null;
  };

  const sortUpcomingPaymentsTable = async (pagination, filters, sorter) => {
    const sortFieldOrder = sorter.order ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";
    setUpcomingPaymentsTableSort(sortFieldOrder);
    handleUpcomingPayments(selectedContract, limit, sortFieldOrder);
  };

  const emptyData = (type) => ({
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={type === "payments" ? t("emptyUpcommingPayments") : t("hadNoActivities")}
      />
    ),
  });

  return (
    <>
      {!authState.isEmptySsp && (
        <div className="loan dashboard-container">
          <Row>
            <Col span={20}>
              <Spin spinning={loading} tip={spinConfiguration.tip}>
                {!!contractList?.length && selectedContract && showDonut && (
                  <>
                    <Row>
                      <Col>
                        <ContractSelector
                          selectedContract={selectedContract}
                          contractList={contractList}
                          contractSelected={contractSelected}
                          productName={t("productLoan")}
                          productType="loan"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="noPaddings">
                        <DonutPie type="loan" showPlate={true} data={donutData} />
                      </Col>
                    </Row>
                  </>
                )}
              </Spin>

              <Row>
                <Col>
                  <div className="block block-upcomingPayments table-wrapper">
                    <h3>{t("upcomingPayments")}</h3>
                    <Table
                      rowClassName={(record) =>
                        cx("table-row-right-border", {
                          paid: record.status === "PAID",
                          unpaid: record.status === "UNPAID",
                          overdue: record.status === "OVERDUE",
                          "partially-paid": record.status === "PARTIALLY_PAID",
                        })
                      }
                      locale={emptyData("payments")}
                      loading={spinConfiguration}
                      pagination={false}
                      columns={upcomingPaymentsColumns}
                      dataSource={upcomingPayments}
                      rowKey={(record) => record.billNumber}
                      onChange={sortUpcomingPaymentsTable}
                    />
                    <div className={"buttonContainer"}>
                      {!isLimitReached && upcomingPayments.length > 0 && (
                        <Button onClick={onMoreUpcomingPaymentsClicked} className={"loadMore"} type="default">
                          {t("loadMore")}
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="banners" span={4}>
              <Banners data={pageBanners} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Dashboard;
