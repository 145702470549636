import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import createDOMPurify from "dompurify";

import Modal from "components/shared/Modal";
import Row from "components/shared/Row";
import ContractHeaderContent from "components/contractSelector/contractHeaderContent";
import CurrencyInput from "components/shared/CurrencyInput";
import { IconSmall } from "components/shared/Icon";

import { parseAmount, formatAmount } from "utils/moneyUtils";
import { percentageNumberFormatter } from "utils/numberUtils";
import { errorNotification } from "utils/notificationUtils";
import { getLegalTexts } from "services/legalService";
import { postPaymentCreation } from "services/paymentService";

const CURRENCY_EUR = "Eur";
const LEGAL_TEXT_CMS_TITLE = "Bills Payment Modal Legal Text";
const NOTIFICATION_DURATION_IN_SECONDS = 5;

const BillsPayment = (props) => {
  const { t } = useTranslation();
  const language = i18next.language;
  const DOMPurify = createDOMPurify(window);

  const [selectedContractId, setSelectedContractId] = useState("");
  const [initialUnpaidAmount, setInitialUnpaidAmount] = useState(0);
  const [unpaidAmount, setUnpaidAmount] = useState(0);
  const [rerenderFlag, setRerenderFlag] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [legalText, setLegalText] = useState();

  useEffect(() => {
    if (props.contractList && props.modalVisible) {
      selectContract(props.contractList[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalVisible]);

  useEffect(() => {
    const fetchLegalText = async (language) => {
      const legalData = await getLegalTexts(language);
      if (!legalData || legalData.code) {
        return;
      }

      const billsPaymentLegalText = () => {
        return legalData.find((item) => item.title === LEGAL_TEXT_CMS_TITLE)?.body?.[0]?.value;
      };
      setLegalText(billsPaymentLegalText);
    };

    fetchLegalText(language);
  }, [language]);

  const selectContract = (selectedContractId) => {
    setSelectedContractId(selectedContractId);
    setIsButtonDisabled(false);

    const filteredContractBills = props.unpaidBills.filter((contract) => contract.contractId === selectedContractId);
    const unpaidAmount = percentageNumberFormatter(
      filteredContractBills.reduce((previousValue, currentValue) => previousValue + currentValue.amountUnpaid.amount, 0)
    );
    if (unpaidAmount === 0) {
      setIsButtonDisabled(true);
    }
    setInitialUnpaidAmount(unpaidAmount);
    setUnpaidAmount(unpaidAmount);
  };

  const validateInputAmount = (value) => {
    const amount = parseFloat(parseAmount(value));
    if (amount === 0 || amount > initialUnpaidAmount) {
      //Flag purpose is to force component rerender to reset initial unpaid amount
      setRerenderFlag(!rerenderFlag);
      setUnpaidAmount(initialUnpaidAmount);
    }
  };

  const setAmountToPay = (value) => {
    setUnpaidAmount(parseFloat(parseAmount(value)));
  };

  const makePayment = async () => {
    const paymentRequestData = {
      contract_id: selectedContractId,
      amount: unpaidAmount,
      currency: CURRENCY_EUR.toUpperCase(),
      client_redirect_url: window.location.href.split("/contractId")[0],
    };

    const response = await postPaymentCreation(paymentRequestData);
    if (response.ok) {
      window.location.assign(response.payload.redirect_url); //redirect to Neopay to proceed payment
    } else {
      errorNotification(t("error"), t("billsPaymentModalPaymentError"), NOTIFICATION_DURATION_IN_SECONDS);
    }
  };

  const closeModal = () => props.onModalClose();

  return (
    <>
      <Modal
        className="modal payment-modal grey"
        visible={props.modalVisible}
        title={t("billsPaymentModalTitle")}
        okText={t("billsPaymentModalMakePaymentButton")}
        okButtonProps={{
          disabled: isButtonDisabled,
        }}
        onOk={makePayment}
        cancelText={t("billsPaymentModalCancelButton")}
        onCancel={closeModal}
        closable
        onClickClose={closeModal}
      >
        <div className={"block ContractFilter contractFilterSingle"}>
          <div className={"contractsSelectMenu"}>
            {props.contractList.map((item) => (
              <Row
                className={selectedContractId === item.id ? "selected" : "not-selected"}
                key={item.id}
                onClick={() => selectContract(item.id)}
              >
                <ContractHeaderContent type={"factoring-bill-payment-modal"} key={item.id} item={item} />
              </Row>
            ))}
          </div>
        </div>

        <CurrencyInput
          className={"currency-number"}
          maskOptions={{
            suffix: ` ${CURRENCY_EUR}`,
            allowNegative: false,
          }}
          value={formatAmount(unpaidAmount)}
          onChange={(event) => validateInputAmount(event.target.value)}
          onBlur={(event) => setAmountToPay(event.target.value)}
        />

        <p
          className={"payment-modal-legal-text"}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(legalText),
          }}
        />

        <div className="payment-modal-info">
          <IconSmall type={"infoBlueIcon"} /> {t("billsPaymentModalInfoText")}
        </div>
      </Modal>
    </>
  );
};

export default BillsPayment;
