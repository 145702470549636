import React from "react";
import { useTranslation } from "react-i18next";

import Spin from "components/shared/Spin";

const MainSpin = (props) => {
  const { t } = useTranslation();

  const spinConfiguration = {
    tip: t("loading") + "...",
  };

  return (
    <Spin className="mainSpinner" spinning={props.spinning} tip={spinConfiguration.tip}>
      {props.children}
    </Spin>
  );
};

export default MainSpin;
