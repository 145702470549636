import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import HiddenInput from "components/shared/form/HiddenInput";

import MandatoryInputLimited from "components/forms/formElements/MandatoryInputLimited";
import Email from "components/forms/formElements/Email";
import PhoneNumber from "components/forms/formElements/PhoneNumber";
import CompanyCode from "components/forms/formElements/CompanyCode";
import PersonalCode from "components/forms/formElements/PersonalCode";
import { APPLICANT_TYPES } from "services/formService";

const BusinessCustomerInformationBlock = ({ formInstance, asRelatedApplicant = false, readOnly, prefill }) => {
  const { t } = useTranslation();
  const [applicantRole, setApplicantRole] = useState();

  useEffect(() => {
    setApplicantRole(formInstance.getFieldValue(["applicant", "role"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HiddenInput name={["applicant", "type"]} />
      <HiddenInput name={["applicant", "role"]} />
      {!asRelatedApplicant && (
        <>
          <h3 className="leasing-request-form__heading">{t("g4rFormYourInformation")}</h3>
          <Row>
            <Col xs={24} sm={12}>
              <Email label={t("leasingFormCompanyEmail")} name={["applicant", "email"]} readOnly={readOnly} />
            </Col>
            <Col xs={24} sm={12}>
              <PhoneNumber
                form={formInstance}
                label={t("leasingFormCompanyPhoneNumber")}
                name={["applicant", "phone"]}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={
              (APPLICANT_TYPES.isGuarantor(applicantRole) && t("leasingFormGuarantorCompanyName")) ||
              t("leasingFormCompanyName")
            }
            name={["applicant", "name"]}
            max={40}
            readOnly={readOnly}
          />
        </Col>
        <Col xs={24} sm={12}>
          <CompanyCode
            label={APPLICANT_TYPES.isGuarantor(applicantRole) && t("leasingFormGuarantorCompanyCode")}
            name={["applicant", "code"]}
            readOnly={asRelatedApplicant || readOnly}
            country={"LT"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={
              (APPLICANT_TYPES.isGuarantor(applicantRole) && t("leasingFormGuarantorCompanyRepresentativeName")) ||
              t("leasingFormCompanyRepresentativeName")
            }
            name={["applicant", "representative_name"]}
            max={24}
            readOnly={readOnly}
            required={!prefill}
          />
        </Col>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={
              (APPLICANT_TYPES.isGuarantor(applicantRole) && t("leasingFormGuarantorCompanyRepresentativeSurname")) ||
              t("leasingFormCompanyRepresentativeSurname")
            }
            name={["applicant", "representative_surname"]}
            max={24}
            readOnly={readOnly}
            required={!prefill}
          />
        </Col>
      </Row>
      {asRelatedApplicant && (
        <>
          <Row>
            <Col span={24}>
              <PersonalCode
                label={t("leasingFormGuarantorCompanyRepresentativePersonalCode")}
                name={["applicant", "representative_code"]}
                readOnly={readOnly}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Email label={t("g4rFormGuarantorEmail")} name={["applicant", "email"]} readOnly={readOnly} />
            </Col>
            <Col xs={24} sm={12}>
              <PhoneNumber
                form={formInstance}
                label={t("g4rFormGuarantorPhoneNumber")}
                name={["applicant", "phone"]}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={t("leasingFormCompanyHeadquartersCity")}
            name={["applicant", "city"]}
            max={30}
            readOnly={readOnly}
            required={!prefill}
          />
        </Col>
        <Col xs={24} sm={12}>
          <MandatoryInputLimited
            label={t("leasingFormCompanyHeadquartersAddress")}
            name={["applicant", "address"]}
            max={100}
            readOnly={readOnly}
            required={!prefill}
          />
        </Col>
      </Row>
    </>
  );
};

export default BusinessCustomerInformationBlock;
