import { createSlice } from "@reduxjs/toolkit";

export const generalDashboardSlice = createSlice({
  name: "generalDashboard",
  initialState: {
    chartLoading: false,
    upcomingPayments: [],
    loanContracts: [],
    factoringContracts: [],
    loanProductFid: "",
    factoringProductFid: ""
  },
  reducers: {
    setChartLoading: (state, action) => {
      state.chartLoading = action.payload;
    },
    setUpcomingPayments: (state, action) => {
      state.upcomingPayments = action.payload;
    },
    setLoansContracts: (state, action) => {
      state.loanContracts = action.payload;
    },
    setLoanProductFid: (state, action) => {
      state.loanProductFid = action.payload;
    },
    setFactoringProductFid: (state, action) => {
      state.factoringProductFid = action.payload;
    },
  },
});

export default generalDashboardSlice.reducer;

export const selectChartLoadingState = (state) => state.generalDashboard.chartLoading;
export const selectLoanContracts = (state) => state.generalDashboard.loanContracts;
export const selectGeneralDashboardState = (state) => state.generalDashboard;

export const { setChartLoading, setLoansContracts, setUpcomingPayments, setFactoringProductFid, setLoanProductFid } = generalDashboardSlice.actions;
