import React, { useState, useEffect, useContext } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { SafetyOutlined, LineOutlined } from "@ant-design/icons";

import NewBuyer from "components/buyer/newBuyer";
import Button from "components/shared/Button";

import { moneyFormatter } from "utils/moneyUtils";
import { percentageNumberFormatter } from "utils/numberUtils";
import { factoringProductTypes, contractFunctionIsActive } from "utils/statusUtils";

import { getCountriesWithPhoneCodes, getCountries } from "services/accountService";

import { AuthContext } from "App";

const ContractBuyers = (props) => {
  const { t } = useTranslation();
  const { state: authState } = useContext(AuthContext);

  const [newBuyerModalVisible, setNewBuyerModalVisible] = useState(false);
  const [countriesWithPhoneCodes, setCountriesWithPhoneCodes] = useState([]);
  const [countries, setCountries] = useState([]);

  const language = i18next.language;

  useEffect(() => {
    async function fetchData() {
      const countriesWithPhoneCodesResponse = await getCountriesWithPhoneCodes();
      const countriesWithPhoneCodes = countriesWithPhoneCodesResponse;

      countriesWithPhoneCodes.sort((a, b) => (a.dial_code > b.dial_code ? 1 : -1));

      setCountriesWithPhoneCodes(countriesWithPhoneCodes);

      const countriesResponse = await getCountries();
      const countiesList = countriesResponse?.countries;

      countiesList.sort((a, b) =>
        a.translations[i18next.language.toUpperCase()] > b.translations[i18next.language.toUpperCase()] ? 1 : -1
      );
      setCountries(countiesList);
    }
    if (authState.user) {
      fetchData();
    }
  }, [authState]);

  useEffect(() => {
    countries.sort((a, b) =>
      a.translations[i18next.language.toUpperCase()] > b.translations[i18next.language.toUpperCase()] ? 1 : -1
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const onNewBuyerSave = (data) => {
    props.addNewBuyerSubmit(data);
    setNewBuyerModalVisible(false);
  };

  return (
    <>
      <NewBuyer
        contract={props.contract}
        addBuyer={props.addBuyer}
        onModalSave={onNewBuyerSave}
        modalVisible={newBuyerModalVisible}
        onModalClose={() => setNewBuyerModalVisible(false)}
        countriesWithPhoneCodes={countriesWithPhoneCodes}
        countries={countries}
        isBuyer={props.contract.factoringProduct !== "REVERSE"}
      />
      <div className="block buyersList">
        <h3>{t(props.contract.factoringProduct !== "REVERSE" ? "buyers" : "suppliers")}</h3>
        {contractFunctionIsActive(props.contract.status) && props.newBuyerEnabled && (
          <Button type="default" size="small" onClick={() => setNewBuyerModalVisible(true)}>
            {t(props.contract.factoringProduct !== "REVERSE" ? "AddBuyer" : "addSupplier")}
          </Button>
        )}
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className={"companyName"}>{t("contractBuyersCompany")}</th>
              <th className={"insuredFactoringLimit"}>
                {props.contract.factoringProduct !== factoringProductTypes.Reverse
                  ? t("contractBuyersInsuredLimit")
                  : t("factoringCreditLimit")}
              </th>
              {props.contract.factoringProduct !== factoringProductTypes.Reverse && (
                <th className={"uninsuredFactoringLimit"}>{t("contractBuyersUninsuredLimit")}</th>
              )}
              <th className={"used"}>{t("contractBuyersUsed")}</th>
              <th className={"availableLimit"}>{t("contractBuyersAvailable")}</th>
              <th className="dueTerm">{t("contractBuyersDueDays")}</th>
              <th className={"interestRate"}>{t("contractBuyersInterest")}</th>
              {props.contract.factoringProduct !== "REVERSE" && (
                <th className={"textAlignRight insurance"}>{t("contractBuyersInsurance")}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {props.contract.thirdParties.length > 0 ? (
              props.contract.thirdParties.map((item) => (
                <tr key={item.party.id} onClick={() => props.showBuyerDetails(item)}>
                  <td>{item.party.name}</td>
                  <td>
                    {props.contract.factoringProduct !== factoringProductTypes.Reverse
                      ? moneyFormatter(item.insuredFactoringCreditLimit, "Eur")
                      : moneyFormatter(item.factoringCreditLimit, "Eur")}
                  </td>
                  {props.contract.factoringProduct !== factoringProductTypes.Reverse && (
                    <td>{moneyFormatter(item.securedFactoringCreditLimit, "Eur")}</td>
                  )}
                  <td>{moneyFormatter(item.usedFactoringCreditLimit, "Eur")}</td>
                  <td>{moneyFormatter(item.availableFactoringCreditLimit, "Eur")}</td>
                  <td>{item.maxInvoicePaymentTerm}</td>
                  <td>{percentageNumberFormatter(item.interestRate * 100)} %</td>

                  {props.contract.factoringProduct !== "REVERSE" && (
                    <td className={"textAlignCenter"}>
                      {item.insuredFactoringCreditLimit === 0 ? (
                        <LineOutlined
                          style={{
                            color: "#b0b0b0",
                            fontSize: "19px",
                          }}
                        />
                      ) : (
                        <SafetyOutlined
                          style={{
                            color: "#2b63f4",
                            fontSize: "19px",
                          }}
                        />
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr className={"ant-table-placeholder"}>
                <td colspan="8">
                  <div class="ant-empty ant-empty-normal">
                    <div class="ant-empty-image">
                      <svg
                        class="ant-empty-img-simple"
                        width="64"
                        height="41"
                        viewBox="0 0 64 41"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                          <ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse>
                          <g class="ant-empty-img-simple-g" fill-rule="nonzero">
                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                            <path
                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                              class="ant-empty-img-simple-path"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <p class="ant-empty-description">{t("noData")}</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ContractBuyers;
