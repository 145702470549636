import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Select from "components/shared/form/CustomSelect";

import AssetInsuranceBlock from "./AssetInsuranceBlock";
import AssetFeeBlock from "./AssetFeeBlock";
import AssetInformationBlock from "./AssetInformationBlock";

const AssetBlock = ({
  formInstance,
  readOnly,
  feeTooltip,
  insuranceTooltip
}) => {
  const { t } = useTranslation();

  const [propertySelected, setPropertySelected] = useState();

  useEffect(() => {
    formInstance.resetFields([
      ["lending", "lithuanian_license_plate"],
      ["lending", "ad_link"],
      ["lending", "brand"],
      ["lending", "model"],
      ["lending", "manufacturing_year"],
      ["lending", "fuel_type"],
      ["lending", "body_type"],
      ["lending", "engine_power"],
      ["lending", "emissions"],
      ["lending", "vat_group"],
      ["lending", "property_information"],
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertySelected]);

  return (
    <>
      <h3 className="leasing-request-form__heading">{t("g4rFormPropertySelected")}</h3>
      <Row>
        <Col span={12}>
          <Select
            name={["lending", "is_property_selected"]}
            value={propertySelected}
            options={[
              { key: 1, value: false, title: t("g4rFormPropertySelectedNo") },
              { key: 2, value: true, title: t("g4rFormPropertySelectedYes") },
            ]}
            onChange={setPropertySelected}
            disabled={readOnly}
          />
        </Col>
      </Row>
      {propertySelected && (
        <>
          <AssetInformationBlock formInstance={formInstance} readOnly={readOnly} />
          <AssetFeeBlock formInstance={formInstance} tooltip={feeTooltip} readOnly={readOnly} />
          <AssetInsuranceBlock formInstance={formInstance} tooltip={insuranceTooltip} readOnly={readOnly} />
        </>
      )}
    </>
  );
};

export default AssetBlock;
