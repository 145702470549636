import React, { useEffect, useState, useContext, useRef } from "react";
import { Table, Row, Col, Button, Alert, Spin, Empty } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useLocation } from "react-router-dom";
import useComponentMountHandler from "../../../hooks/useComponentMountHandler";

import { setExpOffsets, expConfig, getExpRowKeys, setExpParent, newExpKeys } from "components/shared/expandRowTable";
import ReportListItemDetails from "components/report/ReportListItemDetails";
import DonutPie from "components/dashboard/donutPie";
import WaveChart from "components/dashboard/waveChart";
import Banners from "components/dashboard/banners";
import DownloadButton from "components/shared/DownloadButton";
import ContractSelector from "components/contractSelector/contractSelector";

import cx from "classnames";
import { AuthContext } from "App";

import { getBanners } from "services/cmsService";
import { getPdfFile } from "services/fileService";
import { getContractList, getContractCreditLimitHistory } from "services/contractService";
import { hasPermission } from "services/userService";
import { getUpcommingPayments, getActs, getContractLimits } from "services/invoiceService";

import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { reformatUnderscore, updateRouteIfMounted } from "utils/helpers";
import { invoiceStatuses, logoutReasons, waveStepTypes, permissionTypes } from "utils/statusUtils";
import { productType } from "utils/partiesUtils";
import { processFilterParams } from "pages/base/baseConfig";

import apiWrapper from "services/apiWrapper";

import ContractSign from "components/contractSign/contractSign";

import { factoringAmounts } from "services/donutPieService";

const getStartEnd = (selected, type) => moment(selected)[type]("month").format("YYYY-MM-DD");
const statementsDateRange = {};

const Dashboard = (props) => {
  const { t } = useTranslation();

  const pageSize = 5;
  const LIMIT = 5;
  const CURRENCY = "EUR";

  const donutActionPrefix = `/factoring/invoices/?contractId={contractId}&page=1&size=10`;

  const donutInitData = [
    {
      title: "total",
      amount: 0,
      description: "donutTotalMsg",
      action: {
        title: "increaseLimit",
        action: "/factoring/contracts?openIncreaseLimitView",
      },
      color: "#D0D0D0",
      include: false,
    },
    {
      title: "used",
      amount: 0,
      description: "donutUsedMsg",
      action: {
        title: "view",
        action: donutActionPrefix + `&invoiceStatus=${invoiceStatuses.Financed},${invoiceStatuses.Overdue}`,
      },
      color: "#2B64F5",
      include: true,
    },
    {
      title: "overdue",
      amount: 0,
      description: "donutOverdueMsg",
      action: {
        title: "view",
        action: donutActionPrefix + `&invoiceStatus=${invoiceStatuses.Overdue}`,
      },
      color: "#FF555E",
      include: true,
    },
    {
      title: "available",
      amount: 0,
      description: "donutAvailableMsg",
      action: {
        title: "uploadInvoice",
        action: "/invoiceUpload",
      },
      color: "#B5F09C",
      include: true,
    },
  ];

  const [contractList, setContractList] = useState([]);

  const [pageBanners, setPageBanners] = useState({});

  const [upcomingPayments, setUpcomingPayments] = useState([]);
  const [statements, setStatements] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const { state: authState, dispatch } = useContext(AuthContext);
  const productFid = authState.activeProductFid;
  const [selectedContract, setSelectedContract] = useState(null);
  const [alertDetails, setAlertDetails] = useState(null);

  const [contractListLoading, setContractListLoading] = useState(false);

  const [statementsLoading, setStatementsLoading] = useState(false);
  const [upcommingPaymentsLoading, setUpcommingPaymentsLoading] = useState(false);

  const [showMoreStatementsButton, setShowMoreStatementsButton] = useState(true);

  const [contractCreditLimitHistory, setContractCreditLimitHistory] = useState(null);

  const [donutData, setDonutData] = useState(donutInitData);
  const [showDonut, setShowDonut] = useState(false);

  const [contractCreditLimitHistoryPeriodicity, setContractCreditLimitHistoryPeriodicity] = useState(
    waveStepTypes.Month
  );

  const [limit, setLimit] = useState(LIMIT);
  const [showMoreUpcomingPaymentsButton, setShowMoreUpcomingPaymentsButton] = useState(true);

  const [upcomingPaymentsSortDirection, setUpcomingPaymentsSortDirection] = useState(null);
  const [statementssSortDirection, setStatementssSortDirection] = useState(null);
  const [selectedWaveMonth, setSelectedWaveMonth] = useState(null);
  const isMounted = useRef(false);

  const spinConfiguration = {
    tip: t("loading") + "...",
  };

  const statementsSpinConfiguration = {
    tip: spinConfiguration.tip,
    spinning: statementsLoading,
  };

  const upcommingPaymentsSpinConfiguration = {
    tip: spinConfiguration.tip,
    spinning: upcommingPaymentsLoading,
  };

  const [statementsPage, setStatementsPage] = useState(1);
  const [currentWaveDateItem, setCurrentWaveDateItem] = useState(moment());
  useComponentMountHandler(isMounted);

  const statementsColumns = [
    {
      title: t("waveTableDate"),
      dataIndex: "date",
      render: (value, item) => dateFormatter(value),
      width: 100,
      sorter: statements?.length,
    },
    {
      title: t("waveTableNumber"),
      dataIndex: "number",
      align: "left",
      sorter: statements?.length,
    },
    {
      title: t("waveTableType"),
      dataIndex: "type",
      render: (value) => (value ? t(reformatUnderscore(value)) : t("interest")),
      width: 125,
      sorter: statements?.length,
    },
    {
      title: t("waveTableAmount"),
      dataIndex: "amount",
      key: "amount",
      className: "dataAlignAmount",
      render: (value, item) => moneyFormatter(value.amount, value.currency),
      width: 125,
      sorter: statements?.length,
    },
    {
      title: t("download"),
      align: "right",
      dataIndex: "id",
      className: "linkButton",
      width: 75,
      render: (value) => <DownloadButton onClick={async () => await getPdfFile(value)} />,
    },
  ];

  const upcomingPaymentsColumns = [
    {
      title: t("upcomingPaymentsCompany"),
      dataIndex: "companyName",
      key: "companyName",
      className: "table-row-cell__companyName",
      render: (value, item) => (value ? value : item.billType ? t(reformatUnderscore(item.billType)) : ""),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("upcomingPaymentsNumber"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      className: "table-row-cell__invoiceNumber",
      sorter: upcomingPayments?.length,
    },
    {
      title: t("loanBillType"),
      dataIndex: "billType",
      key: "billType",
      className: "table-row-cell__billType",
      render: (value) => t("billsFilter" + reformatUnderscore("_" + value)),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("upcomingPaymentsDueDate"),
      dataIndex: "dueDate",
      key: "dueDate",
      className: "table-row-cell__dueDate",
      render: (value, item) => dateFormatter(value),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("upcomingPaymentsUnpaidAmount"),
      dataIndex: "amountOutstanding",
      key: "amountOutstanding",
      className: "table-row-cell__amountOutstanding",
      render: (value, item) => moneyFormatter(value, CURRENCY),
      sorter: upcomingPayments?.length,
    },
    {
      title: t("upcomingPaymentsStatus"),
      dataIndex: "billStatus",
      key: "billStatus",
      className: "table-row-cell__billStatus",
      render: (value) => (value ? t(reformatUnderscore(value)) : value),
      sorter: upcomingPayments?.length,
    },
  ];

  const language = i18n.language;

  let location = useLocation();

  useEffect(() => {
    async function fetchData() {
      setContractListLoading(true);
      const contractListResponse = apiWrapper(await getContractList(productFid), dispatch);

      setContractListLoading(false);

      let selectedContractItem = {};
      const contractList = contractListResponse?.contracts;
      const filteredList = contractList?.filter((item) => item.status !== "READY_FOR_SIGNING");

      if (contractListResponse) {
        if (contractList) {
          contractList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

          setContractList(contractList);

          selectedContractItem = authState.selectedContracts ? authState.selectedContracts[0] : contractList[0];
        }

        if (location.search && location.search.includes("contractId")) {
          const contractIdParts = location.search.split("=");
          const contractId = contractIdParts[contractIdParts.length - 1];
          const contract = contractListResponse?.contracts?.find((i) => i.id === contractId);

          if (contract) {
            selectedContractItem = contract;
          }
        }

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: { selectedContracts: [selectedContractItem] },
          });
        }

        setSelectedContract(selectedContractItem);

        if (selectedContractItem?.id) {
          updateRouteIfMounted(isMounted.current, () =>
            props.history.push(`/factoring/dashboard/?contractId=${selectedContractItem?.id}`)
          );
        }

        if (selectedContractItem) {
          const limitData = await getContractLimits(selectedContractItem?.id);

          if (limitData) {
            handleDonutData(limitData, selectedContractItem);
          }
        }

        await reloadChartData(selectedContractItem?.id);
      }

      if (contractListResponse && contractListResponse?.contracts?.length) {
        if (filteredList?.length === 0) {
          dispatch({
            type: "SET_EMPTY_SSP",
            payload: {},
          });
          return;
        }

        await reloadStatements({
          contract: selectedContractItem ? selectedContractItem.id : 0,
        });

        setLimit(LIMIT);
        handleUpcomingPayments(selectedContractItem.id, LIMIT, upcomingPaymentsSortDirection);
      } else {
        if (contractListResponse?.contracts?.length === 0) {
          dispatch({
            type: "SET_EMPTY_SSP",
            payload: {},
          });
        }
      }
    }

    if (
      productFid &&
      authState.productType === productType.Factoring &&
      authState.user &&
      authState.user.termsConditionsAccepted
    ) {
      try {
        fetchData();
      } catch {
        dispatch({
          type: "LOGOUT",
          payload: { reason: logoutReasons.SessionExpired },
        });
        return;
      }
    } else if (authState.productType !== productType.Loan) {
      dispatch({
        type: "SHOW_SERVICE_UNAVAILABLE_MSG",
      });
    }

    if (props.location.state && props.location.state.detail) {
      setAlertDetails(props.location.state.detail);
      setTimeout(() => {
        setAlertDetails(null);
        updateRouteIfMounted(isMounted.current, () => props.history.replace("", null));
        updateRouteIfMounted(isMounted.current, () => props.history.push("/factoring/invoices"));
      }, 10000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  useEffect(() => {
    const fetchBanners = async (lang) => {
      const bannersResponse = await getBanners(lang);
      if (!bannersResponse?.code) {
        setPageBanners(bannersResponse);
      }
    };
    fetchBanners(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const contractSelected = async (selectedContractId) => {
    const selectedItem = contractList.find((i) => i.id === selectedContractId);
    setContractListLoading(true);

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [selectedItem],
        previouslySelectedContracts: [selectedContract],
      },
    });

    setSelectedContract(selectedItem);
    updateRouteIfMounted(isMounted.current, () =>
      props.history.push(`/factoring/dashboard/?contractId=${selectedItem?.id}`)
    );

    const limits = await getContractLimits(selectedContractId);

    if (limits) {
      handleDonutData(limits, selectedItem);
    }

    await reloadChartData(selectedContractId);

    setContractListLoading(false);

    await reloadStatements({ contract: selectedContractId });

    setLimit(LIMIT);
    handleUpcomingPayments(selectedContractId, LIMIT, upcomingPaymentsSortDirection);

    setStatementsPage(1);
  };

  const onMoreUpcomingPaymentsClicked = async () => {
    const newLimit = limit + 5;
    await setLimit(newLimit);

    handleUpcomingPayments(selectedContract.id, newLimit, upcomingPaymentsSortDirection);
  };

  const handleUpcomingPayments = async (selectedContract, limit, sort) => {
    setUpcommingPaymentsLoading(true);

    const upcomingPaymentsResponse = await getUpcommingPayments(selectedContract, null, limit, sort);

    if (upcomingPaymentsResponse) {
      setUpcomingPayments(upcomingPaymentsResponse?.upcomingPayments);
      setShowMoreUpcomingPaymentsButton(upcomingPaymentsResponse?.total > limit);
    }

    setUpcommingPaymentsLoading(false);
  };

  const sortUpcomingPaymentsTable = async (pagination, filters, sorter) => {
    const sortFieldOrder = sorter.order ? sorter.columnKey + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";

    setUpcomingPaymentsSortDirection(sortFieldOrder);

    handleUpcomingPayments(authState.selectedContracts ? authState.selectedContracts[0].id : 0, upcomingPayments.length, sortFieldOrder);
  };

  const statementsTableChanged = async (pagination, filters, sorter) => {
    setStatementsLoading(true);

    const sortFieldOrder = sorter.order ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";

    setStatementssSortDirection(sortFieldOrder);

    setStatementsLoading(true);
    const statementsResponse = await getActs(selectedContract?.id, 1, statementsPage * pageSize, productFid, selectedWaveMonth, sortFieldOrder);

    if (statementsResponse) {
      setStatements(statementsResponse.content);
    }
    setStatementsLoading(false);
  };

  const changeCurrentWaveDateItem = (next = false) => {
    setCurrentWaveDateItem(moment(currentWaveDateItem).add(next ? 1 : -1, "months"));
  };

  const emptyData = (type) => ({
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={type === "payments" ? t("emptyUpcommingPayments") : t("hadNoActivities")}
      />
    ),
  });

  const reloadStatements = async ({ contract, selectedDate, loadMore }) => {
    let dateFrom = getStartEnd(currentWaveDateItem, "startOf");
    let dateTo = getStartEnd(currentWaveDateItem, "endOf");
    if (selectedDate) {
      const { value, month } = selectedDate;
      if (month) {
        dateFrom = getStartEnd(moment(currentWaveDateItem).set("month", value - 1), "startOf");
        dateTo = getStartEnd(moment(currentWaveDateItem).set("month", value - 1), "endOf");
      } else {
        dateFrom = moment(currentWaveDateItem).set("date", value).format("YYYY-MM-DD");
        dateTo = dateFrom;
      }
    }
    if (loadMore) {
      dateFrom = statementsDateRange.dateFrom;
      dateTo = statementsDateRange.dateTo;
    } else {
      statementsDateRange.dateFrom = dateFrom;
      statementsDateRange.dateTo = dateTo;
    }
    setStatementsLoading(true);

    setExpandedKeys([]);

    const filter = processFilterParams({ dateFrom, dateTo });

    setSelectedWaveMonth(filter);

    const statementsResponse = 
      await getActs(
        contract,
        loadMore ? statementsPage + 1 : 1,
        pageSize,
        productFid,
        filter,
        statementssSortDirection
      );

    if (statementsResponse) {
      if (loadMore) {
        setStatements([...statements, ...statementsResponse.content]);
        setStatementsPage(statementsPage + 1);
      } else {
        if (statementsPage !== 1) {
          setStatementsPage(1);
        }

        setStatements(statementsResponse.content || []);
      }

      setShowMoreStatementsButton(!statementsResponse.last && !statementsResponse?.error);
    }
    setStatementsLoading(false);

    setExpOffsets();
  };

  const reloadChartData = async (contract, stepType, updateStatementsByRange = false) => {
    if (!stepType && contractCreditLimitHistoryPeriodicity !== waveStepTypes.Month) {
      setContractCreditLimitHistoryPeriodicity(waveStepTypes.Month);
    }

    const creditLimitHistory = await getContractCreditLimitHistory(contract, stepType || waveStepTypes.Month);

    if (creditLimitHistory?.history && creditLimitHistory.history.length !== 0) {
      const lastDate = moment([...creditLimitHistory.history].pop().date || new Date());
      if (!moment(currentWaveDateItem).isSame(lastDate, "month")) {
        if (creditLimitHistory.history.length <= 12) {
          setCurrentWaveDateItem(lastDate);
        } else if (
          moment(moment(currentWaveDateItem).subtract(12, "month")).isBefore(
            moment(creditLimitHistory.history[0].date),
            "month"
          )
        ) {
          setCurrentWaveDateItem(moment(creditLimitHistory.history[11].date));
        }
      }
      if (updateStatementsByRange) {
        const lastDateInRange = [
          ...creditLimitHistory.history.filter((item) => moment(currentWaveDateItem).isSame(item.date, "month")),
        ].pop().date;
        const month = updateStatementsByRange === waveStepTypes.Month;
        const value = month ? moment(lastDateInRange).format("M") : moment(lastDateInRange).format("D");
        reloadStatements({
          contract,
          selectedDate: { month, value: parseInt(value, 10) },
        });
      }
    }

    setContractCreditLimitHistory(creditLimitHistory);
  };

  const updateCurrentStepType = (stepType) => {
    setContractCreditLimitHistoryPeriodicity(stepType);
    reloadChartData(selectedContract.id, stepType);
  };

  const renderExpandableRow = (record) => (
    <ReportListItemDetails
      expandedKeys={expandedKeys}
      onGetPdfFile={async (fileId) => await getPdfFile(fileId)}
      record={record}
    />
  );

  const cleanReportsList = statements ? [...statements] : [];

  const handleDonutData = (limitData, selectedContractItem) => {
    donutData[factoringAmounts.AVAILABLE].action = hasPermission(authState.parties, permissionTypes.UploadInvoices)
      ? donutInitData[factoringAmounts.AVAILABLE].action
      : {};

    donutData[factoringAmounts.TOTAL].action = hasPermission(authState.parties, permissionTypes.ManageContract)
      ? donutInitData[factoringAmounts.TOTAL].action
      : {};

    donutData[factoringAmounts.OVERDUE].action.action = donutInitData[factoringAmounts.OVERDUE].action.action.replace(
      "{contractId}",
      selectedContractItem?.id
    );

    donutData[factoringAmounts.USED].action.action = donutInitData[factoringAmounts.USED].action.action.replace(
      "{contractId}",
      selectedContractItem?.id
    );

    donutData[factoringAmounts.TOTAL].amount = limitData.factoringCreditLimit;
    donutData[factoringAmounts.USED].amount = limitData.usedFactoringCreditLineAmount;
    donutData[factoringAmounts.OVERDUE].amount = limitData.overdueInvoicesAmount;
    donutData[factoringAmounts.AVAILABLE].amount = limitData.availableFactoringCreditLineAmount;

    setDonutData(donutData.map((object) => ({ ...object })));
    setShowDonut(true);
  };

  return (
    <>
      {!authState.isEmptySsp ? (
        <div className={"dashboard-container"}>
          {alertDetails && (
            <Row className="alertWrapper">
              <Col span={20}>
                <Alert
                  message={t(alertDetails.status)}
                  description={alertDetails.message}
                  type={alertDetails.status === "success" ? "success" : "error"}
                  showIcon
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={20}>
              <Row>
                <Col>
                  {!!contractList?.length && selectedContract && <ContractSign contractList={contractList} />}

                  <Spin className="mainSpinner" spinning={contractListLoading} tip={spinConfiguration.tip}>
                    {!!contractList?.length && selectedContract && (
                      <>
                        <ContractSelector
                          selectedContract={selectedContract}
                          contractList={contractList}
                          contractSelected={contractSelected}
                          type="factoring-overview"
                        />
                      </>
                    )}
                  </Spin>
                </Col>
              </Row>
              <Row>
                <Col className={"noPaddings"}>
                  {showDonut && <DonutPie showPlate={true} type="factoring" data={donutData} />}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="block wave">
                    <h3>{t("dashboardLimitUsage")}</h3>
                    <div className="block-wave-chart">
                      <WaveChart
                        onChartClick={(selectedDate) =>
                          reloadStatements({
                            contract: selectedContract.id,
                            selectedDate,
                          })
                        }
                        data={contractCreditLimitHistory}
                        currentStepType={contractCreditLimitHistoryPeriodicity}
                        updateCurrentStepType={updateCurrentStepType}
                        changeCurrentWaveDateItem={changeCurrentWaveDateItem}
                        currentWaveDateItem={currentWaveDateItem}
                        currentStepTitle={
                          new Intl.DateTimeFormat(i18n.language === "ee" ? "et" : i18n.language, {
                            month: "long",
                          }).format(currentWaveDateItem) +
                          ", " +
                          moment(currentWaveDateItem).format("YYYY")
                        }
                      />
                    </div>

                    <Table
                      className={cx("tableBelowWave", "hasCollapsingRows", {
                        noData: statements?.length === 0,
                      })}
                      rowKey={(record) => record.id}
                      locale={emptyData("statements")}
                      pagination={false}
                      columns={statementsColumns}
                      dataSource={statements}
                      onChange={statementsTableChanged}
                      loading={statementsSpinConfiguration}
                      onExpand={(e, record) => setExpandedKeys(newExpKeys(record.id, expandedKeys))}
                      expandedRowRender={statements.length > 0 ? renderExpandableRow : null}
                      expandedRowKeys={getExpRowKeys(cleanReportsList, expandedKeys, "reconciliationActs")}
                      rowClassName={(record) => record.reconciliationActs && "expandable-row"}
                      onRow={(record) => setExpParent(record, expandedKeys)}
                      expandable={expConfig(expandedKeys, "reconciliationActs")}
                      expandRowByClick
                    />
                    <div className={"buttonContainer buttonContainerBottom"}>
                      {!!showMoreStatementsButton && (
                        <Button
                          onClick={() =>
                            reloadStatements({
                              contract: authState.selectedContracts ? authState.selectedContracts[0].id : 0,
                              loadMore: true,
                            })
                          }
                          className={"loadMore"}
                          type="default"
                        >
                          {t("loadMore")}
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="block block-upcomingPayments factoring table-wrapper">
                    <h3>{t("upcomingPayments")}</h3>
                    <Table
                      rowClassName={(record) =>
                        cx("table-row-right-border", {
                          paid: record.billStatus === "PAID",
                          unpaid: record.billStatus === "UNPAID",
                          overdue: record.billStatus === "OVERDUE",
                          "partially-paid": record.billStatus === "PARTIALLY_PAID",
                        })
                      }
                      locale={emptyData("payments")}
                      loading={upcommingPaymentsSpinConfiguration}
                      pagination={false}
                      columns={upcomingPaymentsColumns}
                      dataSource={upcomingPayments}
                      rowKey={(record) => record.invoiceNumber}
                      onChange={sortUpcomingPaymentsTable}
                    />
                    <div className={"buttonContainer"}>
                      {!!showMoreUpcomingPaymentsButton && (
                        <Button onClick={onMoreUpcomingPaymentsClicked} className={"loadMore"} type="default">
                          {t("loadMore")}
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="banners" span={4}>
              <Banners data={pageBanners} />
            </Col>
          </Row>
        </div>
      ) : (
        <Row>
          <Col span={20}>{contractList.length > 0 && <ContractSign contractList={contractList} />}</Col>
        </Row>
      )}
    </>
  );
};

export default Dashboard;
