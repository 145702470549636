import settings from "config/config";
import { getCmsAuth } from "./guardService";

export const getLegalTexts = async (lang) => {
  try {
    const response = await fetch(
      `${settings.backendApiUrl}/cms/legal-text?language=${lang}`,
      getCmsAuth()
    );

    return response.json();
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
