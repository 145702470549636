import React from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Radio from "components/shared/form/Radio";

import FormHeader from "components/forms/formElements/FormHeader";

const PoliticalExposureBlock = ({ readOnly, tooltip, prefill }) => {
  const { t } = useTranslation();

  return (
    <>
      <FormHeader text={t("leasingFormPoliticallyExposedPerson") + (prefill ? "" : "*")} tooltip={tooltip} />
      <Row>
        <Col span={24}>
          <Form.Item
            name={["applicant", "is_politically_exposed_person"]}
            rules={[{ required: !prefill, message: t("leasingFormPoliticallyExposedPerson") + " " + t("isRequired") }]}
          >
            <Radio
              options={[
                { key: 1, value: true, label: t("leasingFormPoliticallyExposedPersonYes") },
                { key: 2, value: false, label: t("leasingFormPoliticallyExposedPersonNo") },
              ]}
              disabled={readOnly}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PoliticalExposureBlock;
