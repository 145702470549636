import React, { useEffect, useState, useContext } from "react";
import { Table, Row, Col, Spin, Empty } from "antd";
import { useTranslation } from "react-i18next";

import ContractFilter from "components/contractSelector/contractFilter";
import ReportFilters from "components/report/reportFilters";
import { expConfig, getExpRowKeys, setExpParent, newExpKeys } from "components/shared/expandRowTable";
import ReportListItemDetails from "components/report/ReportListItemDetails";
import DownloadButton from "components/shared/DownloadButton";

import { AuthContext } from "App";
import cx from "classnames";

import { getPdfFile } from "services/fileService";
import { getActs } from "services/invoiceService";
import { getContractList } from "services/contractService";

import { moneyFormatter } from "utils/moneyUtils";
import { dateFormatter } from "utils/dateUtils";
import { reformatUnderscore } from "utils/helpers";

import apiWrapper from "services/apiWrapper";

import { productType } from "utils/partiesUtils";

const getFilterParams = (filters) => {
  const params = [];
  Object.entries(filters).forEach(([key, val]) => {
    if (val) {
      params.push(`${key}=${val}`);
    }
  });
  if (params.length !== 0) {
    return `&${params.join("&")}`;
  }
  return "";
};

const Report = (props) => {
  const { t } = useTranslation();
  const pageSizes = ["10", "20", "30"];
  const defaultPageSize = parseInt(pageSizes[0]);
  const [contractList, setContractList] = useState([]);
  const { state: authState, dispatch } = useContext(AuthContext);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [contractListLoading, setContractListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  const productFid = authState.activeProductFid;

  const [pagination, setPagination] = useState({
    defaultPageSize: defaultPageSize,
    showSizeChanger: true,
    pageSizeOptions: pageSizes,
    locale: { items_per_page: "" },
    showTitle: false,
  });

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortDirection, setSortDirection] = useState(null);

  const columns = [
    {
      title: t("statementsTableDate"),
      dataIndex: "date",
      render: (value, item) => dateFormatter(value),
      width: 100,
      sorter: reportList?.length,
      defaultSortOrder: !sortDirection && "descend",
    },
    {
      title: t("statementsTableNumber"),
      dataIndex: "number",
      width: 300,
      sorter: reportList?.length,
    },
    {
      title: t("statementsTableContract"),
      dataIndex: "factoringProduct",
      render: (value) => {
        if (value) {
          return value ? t(`factoringProduct${value.toLowerCase()}`) + " " + t("factoring") : "";
        }
        return value;
      },
      width: 175,
      sorter: reportList?.length,
    },
    {
      title: t("statementsTableType"),
      dataIndex: "type",
      render: (value) => (value ? t(reformatUnderscore(value)) : t("interest")),
      width: 250,
      sorter: reportList?.length,
    },
    {
      title: t("statementsTableAmount"),
      dataIndex: "amount",
      className: "dataAlignAmount",
      render: (value) => moneyFormatter(value.amount, value.currency),
      sorter: reportList?.length,
    },
    {
      title: t("download"),
      align: "right",
      dataIndex: "id",
      className: "linkButton",
      render: (value) => <DownloadButton onClick={async () => apiWrapper(await getPdfFile(value), dispatch)} />,
    },
  ];

  const handleTableChange = async (newPagination, filters, sorter) => {
    const sortFieldOrder = sorter.order ? sorter.field + "," + (sorter.order === "ascend" ? "ASC" : "DESC") : "";

    setSortDirection(sortFieldOrder);

    await fetchReportList(
      selectedContracts,
      newPagination.current,
      newPagination.pageSize,
      filteredInfo,
      sortFieldOrder
    );
  };

  useEffect(() => {
    let contractsIds = [];
    let page = pagination.current || 1;
    let size = pagination.defaultPageSize;
    let filter = filteredInfo;

    const abortController = new AbortController();
    const signal = abortController.signal;

    if (props.match.params.filter) {
      const searchParams = new URLSearchParams(props.match.params.filter);

      contractsIds = searchParams.get("contractId").split(",");
      page = searchParams.get("page");
      size = searchParams.get("size");

      // if (searchParams.get("buyerId") !== "undefined") {
      //   filter.buyerId = searchParams.get("buyerId");
      // }

      for (const key of searchParams.keys()) {
        if (searchParams.get(key) !== "undefined" && key !== "contractId" && key !== "page" && key !== "size") {
          filter[key] = searchParams.get(key);
        }
      }

      setPagination({
        ...pagination,
        current: page ? parseInt(page) : 1,
        defaultPageSize: size,
      });

      setFilteredInfo(filter);
    }

    async function fetchData() {
      setContractListLoading(true);
      const contractListResponse = apiWrapper(await getContractList(productFid, signal), dispatch);

      setContractListLoading(false);

      if (contractListResponse?.contracts && contractListResponse?.contracts?.length) {
        const contractList = contractListResponse.contracts;
        contractList.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

        contractsIds = contractsIds.filter((i) => contractListResponse.contracts.map((i) => i.id).includes(i));

        if (contractList.length !== 0) {
          if (authState.selectedContracts) {
            contractsIds = [...new Set([...contractsIds, ...authState.selectedContracts.map((i) => i.id)])];
          } else {
            if (!contractsIds.length) {
              contractsIds.push(contractList[0].id);
            }
          }

          contractsIds = contractsIds.filter((i) => contractListResponse.contracts.map((i) => i.id).includes(i));

          setSelectedContracts(contractsIds);
          setContractList(contractList);
        }

        if (!authState.selectedContracts) {
          dispatch({
            type: "SELECT_CONTRACTS",
            payload: {
              selectedContracts: contractsIds.length
                ? [contractListResponse.contracts.find((i) => i.id === contractsIds[0])]
                : [contractListResponse.contracts[0]],
            },
          });
        }

        await fetchReportList(contractsIds, page, size, filter, null);
      } else {
        props.history.push(`/${productType.Loan}/dashboard`);
      }
    }
    if (productFid && authState.productType === productType.Loan) {
      fetchData();
    }

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFid]);

  const contractsSelected = async (contractsIds) => {
    const selectedItems = contractList.filter((i) => contractsIds.includes(i.id));

    selectedItems.sort((a, b) => (a.contractNumber > b.contractNumber ? 1 : -1));

    dispatch({
      type: "SELECT_CONTRACTS",
      payload: {
        selectedContracts: [selectedItems[0]],
      },
    });

    setSelectedContracts(contractsIds);
    fetchReportList(contractsIds, 1, pagination.defaultPageSize, filteredInfo, sortDirection);
  };

  const fetchReportList = async (contractId, page, size, filteredInfo, sortInfo) => {
    const newPagination = {
      ...pagination,
      current: page ? parseInt(page) : 1,
      pageSize: size,
    };

    setLoading(true);

    setExpandedKeys([]);

    props.history.push(
      `/${productType.Loan}/reports/contractId=${contractId}&page=${page || 1}&size=${size}${getFilterParams(
        filteredInfo
      )}`
    );

    const reportListResponse = apiWrapper(
      await getActs(contractId, page, size, productFid, filteredInfo, sortInfo),
      dispatch
    );

    setLoading(false);

    if (reportListResponse) {
      setReportList(reportListResponse.content);

      if (reportListResponse.totalElements) {
        setPagination({
          ...newPagination,
          total: reportListResponse.totalElements,
        });
      } else {
        setPagination({
          ...newPagination,
          pageSize: size,
          total: 0,
        });
      }
    }
  };

  const filterInfoChanged = async (value) => {
    setFilteredInfo(value);
    await fetchReportList(
      selectedContracts,
      1,
      pagination.defaultPageSize,
      !Object.keys(value).length
        ? value
        : {
            ...filteredInfo,
            ...value,
          },
      sortDirection
    );
  };

  const renderExpandableRow = (record) => (
    <ReportListItemDetails
      expandedKeys={expandedKeys}
      onGetPdfFile={async (fileId) => await getPdfFile(fileId)}
      record={record}
    />
  );

  const cleanReportsList = [...reportList];

  const tableLocale = {
    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noData")} />,
  };

  return (
    <div className={"Reports-page"}>
      <Row>
        <Col>
          <Spin className="mainSpinner" spinning={contractListLoading} tip={spinConfiguration.tip}>
            {contractList.length && selectedContracts.length && (
              <ContractFilter
                contractsSelected={contractsSelected}
                contractList={contractList}
                currentContract={selectedContracts}
              />
            )}
          </Spin>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReportFilters
            selectedContracts={selectedContracts}
            filterInfoChanged={filterInfoChanged}
            filteredInfo={filteredInfo}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            locale={tableLocale}
            className={cx("reportTable", "hasCollapsingRows", "hasDownloadButton", {
              noData: cleanReportsList.length === 0,
            })}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={cleanReportsList}
            onChange={handleTableChange}
            loading={spinConfiguration}
            pagination={cleanReportsList?.length ? pagination : false}
            onExpand={(e, record) => setExpandedKeys(newExpKeys(record.id, expandedKeys))}
            expandedRowRender={cleanReportsList.length > 0 ? renderExpandableRow : null}
            expandedRowKeys={getExpRowKeys(cleanReportsList, expandedKeys, "reconciliationActs")}
            onRow={(record) => setExpParent(record, expandedKeys)}
            rowClassName={(record) => record.reconciliationActs && "expandable-row"}
            expandable={expConfig(expandedKeys, "reconciliationActs")}
            expandRowByClick
          />
        </Col>
      </Row>
    </div>
  );
};

export default Report;
