import React, { useState, useContext } from "react";
import Modal from "components/shared/Modal";
import ContractSignItem from "./contractSignItem";
import ContractSignTable from "./contractSignTable";
import DokobitModal from "../dokobit/DokobitModal";
import { AuthContext } from "App";
import { useTranslation } from "react-i18next";
import { getContractFiles, getContractSigningData } from "services/contractService";
import { hasPermission } from "services/userService";
import { permissionTypes } from "utils/statusUtils";
import { errorNotification } from "utils/notificationUtils";
import { contractListType } from "types/contract";

const ContractSign = ({ contractList }) => {
  const { t } = useTranslation();
  const { state } = useContext(AuthContext);

  const [contract, setContract] = useState({});
  const [documents, setDocuments] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDokobitVisible, setModalDokobitVisible] = useState(false);
  const [dokobitUrl, setDokobitUrl] = useState("");
  const [loadingDokobit, setLoadingDokobit] = useState(false);

  const getDocuments = async (contractId, contractVersion) => {
    const files = await getContractFiles(contractId, contractVersion);
    setDocuments(files?.documents);
  };

  const openModal = async (contract) => {
    setContract(contract);
    await getDocuments(contract.id, contract.contractVersion);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setContract({});
  };

  const openDokobit = async (record) => {
    setLoadingDokobit(true);
    const response = await getContractSigningData(record.contractId, record.id);

    if (!response.ok) {
      setLoadingDokobit(false);
      errorNotification(t("error"), t("contractSignError"), 10);
      return;
    }

    const responseUrl = await response.text();

    setDokobitUrl(responseUrl);
    setModalDokobitVisible(true);
    setLoadingDokobit(false);
  };

  const closeDokobit = async () => {
    setDokobitUrl("");
    setModalDokobitVisible(false);
    setLoadingDokobit(true);

    await getDocuments(contract.id, contract.contractVersion);
    setLoadingDokobit(false);
  };

  const permissionCheck = hasPermission(state.parties, permissionTypes.SignContract);

  return (
    <div className="contract-sign">
      {contractList
        .filter((item) => item.status === "READY_FOR_SIGNING")
        .map((contract) => (
          <ContractSignItem
            key={contract.id}
            contract={contract}
            permissionCheck={permissionCheck}
            openModal={openModal}
          />
        ))}
      <Modal
        width="65rem"
        className="modal grey contract-sign__modal"
        title={t("contractSignDocuments")}
        okText={t("back")}
        cancelButtonProps={{
          className: "button button--default button--large",
          style: { visibility: "hidden" },
        }}
        okButtonProps={{
          className: "button button--primary button--large",
        }}
        visible={modalVisible}
        onOk={closeModal}
      >
        <ContractSignTable data={documents ?? []} openDokobit={openDokobit} loading={loadingDokobit} />
      </Modal>
      <DokobitModal visible={modalDokobitVisible} onClose={closeDokobit} url={dokobitUrl} />
    </div>
  );
};

ContractSign.propTypes = {
  contractList: contractListType.isRequired,
};

export default ContractSign;
