import React from "react";
import { useTranslation } from "react-i18next";

import ButtonLink from "components/shared/ButtonLink";
import cx from "classnames";

import { getLoanBillPaymentType } from "utils/translationIdUtils";

import { moneyFormatter } from "utils/moneyUtils";

const ReportListItemDetails = ({ record, onGetPdfFile, expandedKeys, pdfText }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cx("table-row-expanded expanded-loan-bills-row", {
        expanded: expandedKeys.includes(record.id),
      })}
    >
      <table>
        <colgroup>
          <col style={{ width: "145px" }} />
          <col style={{ width: "125px" }} />
          <col style={{ width: "175px" }} />
          <col style={{ width: "120px" }} />
          <col />
        </colgroup>
        <tbody>
          {record?.payments?.length &&
            record?.payments.map((item, i) => (
              <tr
                className={cx("table-expanded-row-right-border", {
                  "paid": record.status === "PAID",
                  "unpaid": record.status === "UNPAID",
                  "overdue": record.status === "OVERDUE",
                  "partially-paid": record.status === "PARTIALLY_PAID",
                })}
                key={`${item.number}-${i.toString()}`}
              >
                <td>{t(getLoanBillPaymentType(item.paymentType))}</td>
                <td>{item.date}</td>
                <td className="dataAlignAmount">{moneyFormatter(item.sum)}</td>
                {record.hasPdf && (
                  <td className="linkButton">
                    <ButtonLink onClick={() => onGetPdfFile(item.number)}>{pdfText}</ButtonLink>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportListItemDetails;
