import React from "react";
import { useTranslation } from "react-i18next";

import Input from "components/shared/form/Input";

const PersonalCode = ({ name, label, readOnly = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <Input
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message: label + " " + t("isRequired"),
          },
          {
            pattern: /^[3-6]\d{2}[0-1]\d[0-3]\d{5}$/,
            message: t("g4rFormPersonalCodeInvalid"),
          },
          {
            max: 11,
            message: t("g4rFormFieldValueTooLong"),
          },
          {
            min: 11,
            message: t("g4rFormFieldValueTooShort"),
          },
        ]}
        disabled={readOnly}
      />
    </>
  );
};

export default PersonalCode;
