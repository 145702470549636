import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import queryString from "query-string";
import { Form, Result } from "antd";

import { errorNotification } from "utils/notificationUtils";
import { SmileOutlined, FrownOutlined } from "@ant-design/icons";

import Button from "components/shared/Button";
import LoadingSpin from "components/shared/LoadingSpin";

import FormHeader from "components/forms/formElements/FormHeader";
import SupportInfoBlock from "components/forms/formElements/SupportInfoBlock";
import PartnerLogo from "./partners/PartnerLogo";

import config from "config/config";
import { getFormSuccessStateMessage } from "utils/translationIdUtils";
import { APPLICANT_TYPES, saveToLocalStorage, cleanLocalStorage } from "services/formService";

const FinancingFormController = ({
  FormContents,
  FormConfirmator,
  readOnly = false,
  prefill,
  setValues = {},
  formMode,
  validationFn,
  submitFn,
  productType,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { originatorPrivateToken } = queryString.parse(location.search);
  const history = useHistory();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(setValues);

  const [formDisplayMode, setFormDisplayMode] = useState({
    error: false,
    success: false,
    submission: false,
    validation: false,
    render: false,
  }); // TODO: DO useReducer

  const doRenderForm = () => {
    setFormDisplayMode({
      ...formDisplayMode,
      render: true,
    });
  };

  const cancelSubmissionProcess = () => {
    cleanLocalStorage();
    setFormDisplayMode({
      ...formDisplayMode,
      submission: false,
    });
  };

  const displayError = (errorMessage) => {
    if (errorMessage) {
      errorNotification(t("error"), errorMessage, 5);
    }
  };

  const setFinalState = (isSuccess) => {
    if (isSuccess) {
      cleanLocalStorage();
      setFormDisplayMode({
        ...formDisplayMode,
        success: true,
        render: false,
      });
    } else {
      setFormDisplayMode({
        ...formDisplayMode,
        error: true,
        render: false,
      });
    }
  };

  const startSubmissionProcess = async (values) => {
    if (formDisplayMode.validation) {
      return; // debounce
    }
    setFormValues(values);
    saveToLocalStorage(values);
    setFormDisplayMode({
      ...formDisplayMode,
      validation: true,
    });
    (async () => {
      const response = await validationFn(values);
      if (response.ok) {
        if (prefill) {
          submitPrefillData(values);
        } else {
          setFormDisplayMode({
            ...formDisplayMode,
            validation: false,
            submission: true,
          });
        }
      } else {
        displayError(t("g4rFormValidateFieldError"));
        setFormDisplayMode({
          ...formDisplayMode,
          validation: false,
        });
      }
    })();
  };

  const submitPrefillData = async (values) => {
    const response = await submitFn(values);
    setFinalState(!!response.ok);
  };

  const redirectBack = () => {
    setFormDisplayMode({
      ...formDisplayMode,
      submission: false,
      error: false,
      render: true,
    });
    history.push(window.location.pathname);
  };

  const redirectHome = () => {
    prefill ? history.push(`/partner/prefill/${originatorPrivateToken}`) : (window.location.href = config.homeUrl);
  };

  const getFormHeader = () => {
    if (formDisplayMode.render) {
      // Show something only when the form is visible ...
      if (APPLICANT_TYPES.isDefault(formMode) && !readOnly) {
        // ... when WEB-initiated and editable, ...
        return <h1 className="public-form__heading">{t("leasingFormTitle")}</h1>;
      } else if (APPLICANT_TYPES.isPartner(formMode)) {
        // ... or partner logo, if partner mode, ...
        return <PartnerLogo partnerToken={setValues.lending.originator_token} />;
      }
    }
    // ... nothing otherwise
    return <>&nbsp;</>;
  };

  const getFormTitle = () => {
    if (!readOnly) {
      if (prefill && APPLICANT_TYPES.isPartner(formMode)) {
        return <FormHeader text={t("leasingPrefillFormPartnerTitle")} />;
      } else if (APPLICANT_TYPES.isPartner(formMode)) {
        return <FormHeader text={t("leasingFormPartnerTitle")} />;
      } else if (APPLICANT_TYPES.isSpouse(formMode)) {
        return <FormHeader text={t("leasingFormRelatedApplicantTitleSpouse")} />;
      } else if (APPLICANT_TYPES.isGuarantor(formMode)) {
        return <FormHeader text={t("leasingFormRelatedApplicantTitleGuarantor")} />;
      }
    }

    return <FormHeader text={t("g4rFormCalculator")} />;
  };

  return (
    <>
      <div className="public-form">
        <div className="public-form__header">
          {getFormHeader()}
          <SupportInfoBlock />
        </div>
        <div className="public-form__form-block block">
          {formDisplayMode.error && (
            <div>
              <Result
                icon={<FrownOutlined className="public-form__state-icon" />}
                title={t(prefill ? "prefillFormErrorState" : "g4rFormErrorState")}
                extra={
                  <div className="public-form__state-action-buttons">
                    <Button type="primary" size="large" theme="sme" onClick={redirectBack}>
                      {t(prefill ? "prefillFormErrorStateButton" : "g4rFormErrorStateButton")}
                    </Button>
                  </div>
                }
              />
            </div>
          )}

          {formDisplayMode.success && (
            <div>
              <Result
                icon={<SmileOutlined className="public-form__state-icon" />}
                title={t(prefill ? "prefillFormSuccessState" : getFormSuccessStateMessage(formMode))}
                extra={
                  <div className="public-form__state-action-buttons">
                    <Button type="primary" size="large" theme="sme" onClick={redirectHome}>
                      {t(prefill ? "prefillFormSuccessStateButton" : "g4rFormSuccessStateButton")}
                    </Button>
                  </div>
                }
              />
            </div>
          )}
          {!formDisplayMode.error &&
            !formDisplayMode.success &&
            ((!formDisplayMode.render && <LoadingSpin />) || (
              <Form
                className="form leasing-request-form"
                form={form}
                onFinish={startSubmissionProcess}
                scrollToFirstError={true}
                initialValues={formValues}
              >
                {getFormTitle()}
                <FormContents
                  formInstance={form}
                  formMode={formMode}
                  prefill={prefill}
                  formModeLoading={formDisplayMode.validation || formDisplayMode.submission}
                  formModePreview={readOnly}
                  doRenderForm={doRenderForm}
                  productType={productType}
                  // TODO: put legal texts at corresponding components
                  // TODO: resolve marketing vs g4r insurance marketing consent - drupal
                />
              </Form>
            ))}
          {FormConfirmator && (
            <FormConfirmator
              toggle={formDisplayMode.submission}
              formStartCb={(error) => {
                if (error) {
                  displayError(error);
                }
                doRenderForm();
              }}
              formContinueCb={(error) => {
                if (error) {
                  displayError(error);
                }
                cancelSubmissionProcess();
              }}
              formExitCb={(state) => setFinalState(state)}
              formMode={formMode}
              submitFn={submitFn}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FinancingFormController;
