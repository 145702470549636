import React from "react";
import { useTranslation } from "react-i18next";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Input from "components/shared/form/Input";
import { CONTRACT_TYPES, APPLICANT_TYPES } from "services/formService";

const RelatedApplicantBlock = ({ formInstance }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className="leasing-request-form__heading">{t("leasingFormMainApplicantTitle")}</h3>
      {formInstance.getFieldValue(["co_applicant", "type"]) === CONTRACT_TYPES.PRIVATE ? (
        <>
          <Row>
            <Col xs={24} sm={12}>
              <Input label={t("g4rFormName")} name={["co_applicant", "name"]} disabled />
            </Col>
            <Col xs={24} sm={12}>
              <Input label={t("g4rFormSurname")} name={["co_applicant", "surname"]} disabled />
            </Col>
          </Row>
          {APPLICANT_TYPES.isGuarantor(formInstance.getFieldValue(["applicant", "role"])) && (
            <Row>
              <Col xs={24} sm={12}>
                <Input label={t("g4rFormPersonalCode")} name={["co_applicant", "code"]} disabled />
              </Col>
            </Row>
          )}
        </>
      ) : (
        <Row>
          <Col xs={24} sm={12}>
            <Input label={t("g4rFormName")} name={["co_applicant", "name"]} disabled />
          </Col>
          <Col xs={24} sm={12}>
            <Input label={t("leasingFormCompanyCode")} name={["co_applicant", "code"]} disabled />
          </Col>
        </Row>
      )}
    </>
  );
};

export default RelatedApplicantBlock;
