import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Row, Col, Button, notification } from "antd";

import CheckPassword from "./personalInfoActions/checkPassword";
import EditSmartIdLogin from "./personalInfoActions/editSmartIdLogin";
import EditMobileIdLogin from "./personalInfoActions/editMobileIdLogin";

const LogginSettings = (props) => {
  const alertTimeout = 20;
  const { t } = useTranslation();

  const [type, setType] = useState(null);

  const [checkPasswordModalVisible, setCheckPasswordModalVisible] = useState(
    false
  );

  const [
    editSmartIdLoginModalVisible,
    setEditSmartIdLoginModalVisible,
  ] = useState(false);
  const [
    editMobileIdLoginModalVisible,
    setEditMobileIdLoginModalVisible,
  ] = useState(false);

  //    props.logginSettingsChanged({ smsLogin: event.target.value });

  const onPasswordModalClose = () => {
    setCheckPasswordModalVisible(false);
  };

  const onPasswordModalSave = async () => {
    setCheckPasswordModalVisible(false);

    if (type === "sms") {
      const response = await props.changeSmsLoginPhone();

      onEditSmsLoginModalSave(response?.error ? "error" : "success");
    } else if (type === "smartid") {
      setEditSmartIdLoginModalVisible(true);
    } else {
      setEditMobileIdLoginModalVisible(true);
    }
  };

  const onEditSmsLoginModalSave = (status) => {
    notification[status]({
      message: t(status),
      description:
        status === "success"
          ? t("accountChangeSmsLoginPhoneStatusMessage")
          : t(status),
      duration: alertTimeout,
    });
  };

  const onEditSmartIdLoginModalSave = () => {
    notification["success"]({
      message: t("success"),
      description: t("accountChangeSmartIdStatusMessage"),
      duration: alertTimeout,
    });

    setEditSmartIdLoginModalVisible(false);
  };

  const onEditMobileIdLoginModalSave = (status) => {
    notification["success"]({
      message: t("success"),
      description: t("accountChangeMobileIdStatusMessage"),
      duration: alertTimeout,
    });

    setEditMobileIdLoginModalVisible(false);
  };

  return (
    <>
      <CheckPassword
        type={type}
        modalVisible={checkPasswordModalVisible}
        onModalClose={onPasswordModalClose}
        onModalSave={onPasswordModalSave}
        authorizeUser={props.authorizeUser}
        changePassword={props.changePassword}
        email={props?.person?.email}
      />
      <EditSmartIdLogin
        modalVisible={editSmartIdLoginModalVisible}
        onModalClose={() => {
          setEditSmartIdLoginModalVisible(false);
        }}
        onModalSave={onEditSmartIdLoginModalSave}
        currentCountry={props?.person?.user_metadata?.country}
        currentPersonalCode={props?.person?.user_metadata?.personal_code}
        changeAccount={props.changeAccount}
        countries={props.countries}
      />
      <EditMobileIdLogin
        modalVisible={editMobileIdLoginModalVisible}
        onModalClose={() => {
          setEditMobileIdLoginModalVisible(false);
        }}
        onModalSave={onEditMobileIdLoginModalSave}
        currentPhone={props?.person?.user_metadata?.mobile_id_phone_number}
        currentPersonalCode={props?.person?.user_metadata?.personal_code}
        changeAccount={props.changeAccount}
        countriesWithPhoneCodes={props.countriesWithPhoneCodes}
      />
      <Row className="loginSettings">
        <Col span={8}>
          <label>
            {t("smartIDLogin")}
            <Button
              type="link"
              className="edit"
              onClick={() => {
                setType("smartid");
                setCheckPasswordModalVisible(true);
              }}
            ></Button>
          </label>
        </Col>
        <Col span={8}>
          <label>
            {t("mobileIDLogin")}
            <Button
              type="link"
              className="edit"
              onClick={() => {
                setType("mobileid");
                setCheckPasswordModalVisible(true);
              }}
            ></Button>
          </label>
        </Col>
      </Row>
    </>
  );
};

export default LogginSettings;
