import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";

import {
  cleanLocalStorage,
  popLocalStorage,
  APPLICANT_TYPES,
  CONTRACT_TYPES,
  PRODUCT_TYPES,
  FINANCING_COMPANY,
  storeFinancingCompany,
  storeOriginatorToken,
  validatePartnerToken,
  validatePartnerPrivateToken,
} from "services/formService";

import PrivateLeasingFormController from "components/forms/leasing/PrivateLeasingFormController";
import BusinessLeasingFormController from "components/forms/leasing/BusinessLeasingFormController";

import LoadingSpin from "components/shared/LoadingSpin";
import FinancingCoApplicantFormController from "components/forms/leasing/FinancingCoApplicantFormController";
import PrivateLoanFormController from "components/forms/leasing/PrivateLoanFormController";
import ApplicantAccessOverlay from "components/forms/leasing/ApplicantAccessOverlay";

const FinancingRequestFormRouter = ({ formType, productType }) => {
  const location = useLocation();
  const history = useHistory();
  const {
    token: applicantToken,
    ID: pipedriveId,
    originatorToken,
    originatorPrivateToken,
  } = queryString.parse(location.search);
  const { applicationToken, formType: externalFormType } = useParams();

  const [data, setData] = useState(null);
  const [validatingAccess, setValidatingAccess] = useState(false);

  useEffect(() => {
    const validatePartnerTokens = async () => {
      if (location.pathname.includes("/prefill/")) {
        const response = await validatePartnerPrivateToken(originatorPrivateToken);
        if (!response.ok) {
          history.push("/404");
        }

        storeOriginatorToken(response.payload?.originator_token);
      } else {
        const response = await validatePartnerToken(originatorToken);
        if (!response.ok) {
          history.push("/404");
        }
      }
    };

    if (applicationToken && applicantToken) {
      // readonly submitted form
      setValidatingAccess(true);
    } else if (Object.values(CONTRACT_TYPES).includes(externalFormType)) {
      // partners
      validatePartnerTokens();
      cleanLocalStorage();
      setData({
        values: undefined,
        formMode: APPLICANT_TYPES.PARTNER,
        formType: externalFormType,
        productType,
      });
    } else if (pipedriveId) {
      // new WEB request
      cleanLocalStorage();
      setData({
        values: undefined,
        formType,
        formMode: APPLICANT_TYPES.DEFAULT,
        productType,
      });

      storeFinancingCompany(
        formType === CONTRACT_TYPES.BUSINESS ? FINANCING_COMPANY.CAPITAL3 : FINANCING_COMPANY.SME_FINANCE
      );
    } else {
      const storedData = popLocalStorage();
      if (storedData) {
        setData({
          values: storedData,
          formType: storedData.applicant?.type,
          formMode: storedData.lending?.partner_quote
            ? APPLICANT_TYPES.PARTNER
            : storedData.applicant?.role ?? APPLICANT_TYPES.DEFAULT,
          productType: storedData.lending?.type,
          readOnly: false,
        });
      } else {
        bail();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateAccessedData = (formData) => {
    formData.applicant.token = applicantToken;
    if (formData?.lending?.prefill && !formData.applicant.is_politically_exposed_person) {
      formData.applicant.is_politically_exposed_person = undefined;
    }
    setData({
      values: {
        ...formData,
        meta: {
          application_token: applicationToken,
          applicant_token: applicantToken,
        },
        co_applicant: {
          ...formData.co_applicant,
          type: CONTRACT_TYPES.PRIVATE,
        },
      },
      readOnly: APPLICANT_TYPES.isDefault(formData?.applicant?.role) && !formData?.lending?.prefill,
      formMode:
        formData?.is_partner || formData?.lending?.partner_quote ? APPLICANT_TYPES.PARTNER : formData.applicant?.role,
      formType: formData?.applicant?.type,
      productType: formData?.lending?.type,
    });
    setValidatingAccess(false);
  };

  const bail = () => {
    history.push("/404");
  };

  const getApplicantRole = () => {
    return data?.values?.applicant?.role ?? "";
  };

  return (
    <>
      {(!data &&
        ((validatingAccess && (
          <ApplicantAccessOverlay
            applicationToken={applicationToken}
            applicantToken={applicantToken}
            onSuccess={populateAccessedData}
            onFailure={bail}
          />
        )) || <LoadingSpin />)) || (
        <>
          {((APPLICANT_TYPES.isSpouse(getApplicantRole()) || APPLICANT_TYPES.isGuarantor(getApplicantRole())) && (
            <FinancingCoApplicantFormController
              values={data.values}
              formMode={getApplicantRole()}
              productType={productType}
            />
          )) ||
            (PRODUCT_TYPES.isLoan(data.productType) && (
              <PrivateLoanFormController values={data.values} readOnly={data.readOnly} formMode={data.formMode} />
            )) ||
            (CONTRACT_TYPES.isPrivate(data.formType) && (
              <PrivateLeasingFormController values={data.values} readOnly={data.readOnly} formMode={data.formMode} />
            )) ||
            (CONTRACT_TYPES.isBusiness(data.formType) && (
              <BusinessLeasingFormController values={data.values} readOnly={data.readOnly} formMode={data.formMode} />
            ))}
        </>
      )}
    </>
  );
};

export default FinancingRequestFormRouter;
