import React from "react";
import { DatePicker } from "antd";
import ltLocale from "translations/datepickerLocale";
import enLocale from "antd/es/date-picker/locale/en_US";
import eeLocale from "translations/datepickerLocale-ee";
import lvLocale from "translations/datepickerLocale-lv";
import i18next from "i18next";

const CustomDatePicker = ({ disabledDate, onChange, placeholder, style, ...props }) => {
  const language = i18next.language;

  const locales = {
    en: enLocale,
    lt: ltLocale,
    ee: eeLocale,
    lv: lvLocale,
  };

  return (
    <DatePicker
      locale={locales[language]}
      disabledDate={disabledDate}
      onChange={onChange}
      placeholder={placeholder}
      style={style}
      {...props}
    />
  );
};

export default CustomDatePicker;
