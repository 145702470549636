import React, { useEffect, useContext, useState } from "react";
import Button from "../components/shared/Button";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { AuthContext } from "../App";

import { loginUser, loginUserBySms, getUser, getParties, getBoParty } from "./../services/userService";

import Sms from "../components/login/sms";
import SmartId from "../components/login/smartId";
import MobileId from "../components/login/mobileId";

import { DeprecationCard } from '../deprecationCard';

import {
  postMobileIdAuthenticateRequest,
  postMobileIdAuthenticate,
  postSmartIdAuthenticateRequest,
  postSmartIdAuthenticate,
} from "../services/accountService";

const tabsTypes = {
  Sms: "sms",
  SmartId: "smartId",
  MobileId: "mobileId",
};

const countries = [
  {
    code: "EE",
    dial_code: "+372",
    latitude: 59,
    longitude: 26,
    name: "Estonia",
  },
  {
    code: "LV",
    dial_code: "+371",
    latitude: 57,
    longitude: 25,
    name: "Latvia",
  },
  {
    code: "LT",
    dial_code: "+370",
    latitude: 56,
    longitude: 24,
    name: "Lithuania",
  },
];

const NormalLoginForm = (props) => {
  const [activeItem, setActiveItem] = useState(tabsTypes.Sms);
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AuthContext);
  const [logoutReason, setLogoutReason] = useState(null);

  useEffect(() => {
    const { user, token, parties } = state;

    if (state.reason) {
      setLogoutReason(state.reason);
    }

    if (user && token && parties) {
      props.history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSwitch = () => {
    switch (activeItem) {
      case tabsTypes.Sms:
        return (
          <Sms
            login={loginUser}
            loginBySms={loginUserBySms}
            getUser={getUser}
            getParties={getParties}
            getBoParty={getBoParty}
            loginSuccess={loginSuccess}
            logoutReason={logoutReason}
          />
        );
      case tabsTypes.SmartId:
        return (
          <SmartId
            countries={countries}
            postSmartIdAuthenticateRequest={postSmartIdAuthenticateRequest}
            postSmartIdAuthenticate={postSmartIdAuthenticate}
            loginSuccess={loginSuccess}
            getUser={getUser}
            getParties={getParties}
            getBoParty={getBoParty}
          />
        );
      case tabsTypes.MobileId:
        return (
          <MobileId
            countries={countries}
            postMobileIdAuthenticateRequest={postMobileIdAuthenticateRequest}
            postMobileIdAuthenticate={postMobileIdAuthenticate}
            loginSuccess={loginSuccess}
            getUser={getUser}
            getParties={getParties}
            getBoParty={getBoParty}
          />
        );
      default:
        return null;
    }
  };

  const loginSuccess = (result) => {
    dispatch({
      type: "LOGIN",
      payload: result,
    });
    props.history.push("/");
  };

  return (
    <>
      <div className={`block loginForm ${activeItem && activeItem.toString()}`}>
      <DeprecationCard />
        <div className="form-wrapper">
          <h1>{t("loginText")}</h1>
          <div className="loginTabs">
            <Button
              type="secondary"
              size="large"
              className={cx({
                "button--secondary--active": activeItem === tabsTypes.Sms,
              })}
              value={tabsTypes.Sms}
              onClick={(e) => setActiveItem(e.target.value)}
            >
              {t("userId")}
            </Button>
            <Button
              type="secondary"
              size="large"
              className={cx({
                "button--secondary--active": activeItem === tabsTypes.SmartId,
              })}
              value={tabsTypes.SmartId}
              onClick={(e) => setActiveItem(e.target.value)}
            >
              {t("smartId")}
            </Button>

            <Button
              type="secondary"
              size="large"
              className={cx({
                "button--secondary--active": activeItem === tabsTypes.MobileId,
              })}
              value={tabsTypes.MobileId}
              onClick={(e) => setActiveItem(e.target.value)}
            >
              {t("mobileId")}
            </Button>
          </div>

          {renderSwitch(activeItem)}
        </div>
      </div>
      <div className="block registerForm">
        <h1>{t("register")}</h1>
        <Button type="default" disabled htmlType="submit" className="login-form-button">
          {t("apply")}
        </Button>
      </div>
    </>
  );
};

export default NormalLoginForm;
