import React, { useState, useEffect } from "react";
import { Input } from "antd";
import i18next from "i18next";

import { requiredField, defaultLabelText } from "utils/formUtils";

const InlineInput = (props) => {
  // Move currently to a separate component to avoid breaking existing elements
  // TODO: refactor
  const isRequired = !!props?.rules?.filter((i) => !!i.required).length;
  const defaultPlaceholderText = isRequired ? requiredField(props.label) : props.label;

  const [currentValue, setCurrentValue] = useState(props.defaultValue ? props.defaultValue : null);
  const [labelText, setLabelText] = useState(defaultLabelText);
  const [placeholderText, setPlaceholderText] = useState(defaultPlaceholderText);

  const language = i18next.language;

  useEffect(() => {
    setLabelText(currentValue ? defaultPlaceholderText : defaultLabelText);

    setPlaceholderText(currentValue ? "" : defaultPlaceholderText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentValue]);

  return (
    <>
      {props.label && <label>{labelText}</label>}
      <Input
        className={`input ${currentValue && "input--active"}`}
        placeholder={placeholderText}
        onChange={(event) => {
          setCurrentValue(event.target.value);
          props.onValueChange && props.onValueChange(event.target.value);
        }}
        onInput={props.onInput}
        value={currentValue}
        defaultValue={props.defaultValue}
      />
    </>
  );
};

export default InlineInput;
