import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "App";
import FactoringDashboard from "../pages/factoring/dashboard/dashboard";
import FactoringContract from "../pages/factoring/contract/contract";
import FactoringBill from "../pages/factoring/bill/bill";
import FactoringReport from "../pages/factoring/act/report";
import LoanDashboard from "../pages/loan/dashboard/dashboard";
import LoanContract from "../pages/loan/contract/contract";
import LoanBill from "../pages/loan/bill/bill";
import LoanReport from "../pages/loan/act/report";

import Invoice from "../pages/factoring/invoice/invoice";
import InvoiceDetail from "../pages/factoring/invoice-detail/invoiceDetail";
import { productType } from "utils/partiesUtils";
import FaqPage from "pages/FaqPage";

const useRoutes = () => {
  const { t } = useTranslation();
  const { state } = useContext(AuthContext);
  return {
    factoring: [
      {
        path: `/${productType.Factoring}/dashboard`,
        title: t("Overview"),
        authed: state.isAuthenticated,
        component: FactoringDashboard,
      },
      {
        authed: state.isAuthenticated,
        title: t("invoices"),
        path: `/${productType.Factoring}/invoices`,
        component: Invoice,
      },
      {
        authed: state.isAuthenticated,
        title: t("reports"),
        path: `/${productType.Factoring}/reports`,
        component: FactoringReport,
      },
      {
        authed: state.isAuthenticated,
        title: t("bills"),
        path: `/${productType.Factoring}/bills`,
        component: FactoringBill,
      },
      {
        authed: state.isAuthenticated,
        title: t("contracts"),
        path: `/${productType.Factoring}/contracts`,
        component: FactoringContract,
      },
      {
        authed: state.isAuthenticated,
        title: t("invoices"),
        path: `/${productType.Factoring}/invoice/:id/:filter`,
        component: InvoiceDetail,
        excluded: true,
      },
      {
        authed: state.isAuthenticated,
        title: t('faq'),
        path:  `/${productType.Factoring}/faq`,
        component: FaqPage
      },
    ],
    loan: [
      {
        path: "/loan/dashboard",
        title: t("Overview"),
        authed: state.isAuthenticated,
        component: LoanDashboard,
      },
      {
        authed: state.isAuthenticated,
        title: t("bills"),
        path: `/${productType.Loan}/bills`,
        component: LoanBill,
      },
      {
        authed: state.isAuthenticated,
        title: t("contracts"),
        path: `/${productType.Loan}/contracts`,
        component: LoanContract,
      },
      {
        authed: state.isAuthenticated,
        title: t("reports"),
        path: `/${productType.Loan}/reports/:filter?`,
        component: LoanReport,
        excluded:true
      },
      {
        authed: state.isAuthenticated,
        title: t('faq'),
        path:  `/${productType.Loan}/faq`,
        component: FaqPage
      },
    ],
  };
};
export default useRoutes;
