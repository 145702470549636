import React, { Fragment, useState } from "react";
import FloatFormItem from "../shared/floatFormItem";
import { Form, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { requiredField, defaultLabelText } from "../../utils/formUtils";
import { currencyCodeToSign, parseAmount, formatAmount } from "../../utils/moneyUtils";
import { filterArrOfObject } from "../../utils/helpers";
import CurrencyInput from "../shared/CurrencyInput";
import Button from "../shared/Button";
import i18next from "i18next";

const { Option } = Select;

const NewBuyerForm = ({
  panelHeaders,
  setPanelHeaders,
  add,
  remove,
  field,
  countries,
  countriesWithPhoneCodes,
  form,
  isBuyer,
  addPanel,
}) => {
  const { t } = useTranslation();
  const [countryLabelText, setCountryLabelText] = useState(defaultLabelText);
  const [limitText, setLimitText] = useState(defaultLabelText);
  const [phoneText, setPhoneText] = useState(defaultLabelText);
  const currencyCode = currencyCodeToSign("Eur");

  const prefixSelector = (
    <Form.Item name={[field.name, "phoneCode"]} noStyle>
      <Select
        showSearch
        optionFilterProp="children"
        defaultValue={["+370"]}
        filterOption={(input, option) => option.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        style={{
          width: 90,
        }}
      >
        {countriesWithPhoneCodes.length &&
          filterArrOfObject(countriesWithPhoneCodes, "dial_code").map((item, i) => (
            <Option key={i} value={item.dial_code}>
              {item.dial_code}
            </Option>
          ))}
      </Select>
    </Form.Item>
  );

  const removePanel = (name) => {
    const newPanels = panelHeaders.filter((panel) => panel.index !== parseInt(name));

    setPanelHeaders(newPanels);
    remove(name);
  };

  return (
    <Fragment>
      <FloatFormItem
        type={Input}
        label={t("buyersCompanyName")}
        name={[field.name, "buyersCompanyName"]}
        rules={[
          {
            required: true,
            message: t("buyersCompanyName") + " " + t("isRequired"),
          },
        ]}
        onChange={({ target: { value } }) => {
          const newPanels = panelHeaders.map((panel) => {
            if (panel.index === field.key) {
              panel.buyer = value;
            }

            return panel;
          });

          setPanelHeaders(newPanels);
        }}
      />

      <Form.Item
        label={countryLabelText}
        name={[field.name, "country"]}
        rules={[
          {
            required: true,
            message: t("country") + " " + t("isRequired"),
          },
        ]}
        style={{
          display: "inline-block",
          width: "calc(40% - 12px)",
        }}
      >
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          style={{
            width: "100%",
          }}
          onChange={(value) => setCountryLabelText(value ? requiredField(t("country")) : defaultLabelText)}
          placeholder={requiredField(t("country"))}
        >
          {countries.length &&
            countries.map((item, i) => (
              <Option key={i} value={item.codeAlpha2}>
                {item.translations[i18next.language.toUpperCase()]}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <FloatFormItem
        type={Input}
        label={t("companyCode")}
        name={[field.name, "companyCode"]}
        rules={[
          {
            required: true,
            message: t("companyCode") + " " + t("isRequired"),
          },
        ]}
        style={{ display: "inline-block", width: "calc(62%)" }}
      />

      <Form.Item
        label={limitText}
        className="limit"
        name={[field.name, "limit"]}
        rules={[
          {
            required: true,
            message: t("limit") + " " + t("isRequired"),
          },
        ]}
      >
        <CurrencyInput
          maskOptions={{ suffix: ` ${currencyCode}` }}
          className="ant-input-number-input"
          placeholder={requiredField(t("limit"))}
          onChange={({ target: { value } }) => {
            if (!value) return;

            const newPanels = panelHeaders.map((panel) => {
              if (panel.index === field.key) {
                panel.limit = value;
              }

              return panel;
            });

            setPanelHeaders(newPanels);

            setLimitText(value ? requiredField(t("limit")) : defaultLabelText);
          }}
          onBlur={({ target: { value } }) => {
            const amount = parseAmount(value);

            let otherValues = form.getFieldValue("buyers");

            if (typeof otherValues[field.key] !== "object") return;

            otherValues[field.key].limit = formatAmount(amount);

            form.setFieldsValue({
              buyers: otherValues,
            });
          }}
        />
      </Form.Item>

      <h3>{isBuyer ? t("buyersAddress") : t("suppliersAddress")}</h3>
      <FloatFormItem
        type={Input}
        label={t("street")}
        name={[field.name, "street"]}
        rules={[
          {
            required: true,
            message: t("street") + " " + t("isRequired"),
          },
        ]}
        style={{
          display: "inline-block",
          width: "calc(65% - 12px)",
        }}
      />
      <FloatFormItem
        type={Input}
        label={t("houseNo")}
        name={[field.name, "houseNo"]}
        rules={[
          {
            required: true,
            message: t("houseNo") + " " + t("isRequired"),
          },
        ]}
        style={{ display: "inline-block", width: "calc(37%)" }}
      />
      <FloatFormItem
        type={Input}
        label={t("city")}
        name={[field.name, "city"]}
        rules={[
          {
            required: true,
            message: t("city") + " " + t("isRequired"),
          },
        ]}
        style={{
          display: "inline-block",
          width: "calc(65% - 12px)",
        }}
      />
      <FloatFormItem
        type={Input}
        label={t("postalCode")}
        name={[field.name, "postalCode"]}
        rules={[
          {
            required: true,
            message: t("postalCode") + " " + t("isRequired"),
          },
        ]}
        style={{ display: "inline-block", width: "calc(37%)" }}
      />
      <h3>{isBuyer ? t("buyersContactPerson") : t("suppliersContactPerson")}</h3>
      <FloatFormItem
        type={Input}
        label={t("fullPersonName")}
        name={[field.name, "fullPersonName"]}
        rules={[
          {
            message: t("fullPersonName"),
          },
        ]}
        style={{
          display: "inline-block",
          width: "calc(50% - 12px)",
        }}
      />
      <FloatFormItem
        type={Input}
        label={t("email")}
        name={[field.name, "email"]}
        rules={[
          {
            required: true,
            type: "email",
          },
        ]}
        style={{ display: "inline-block", width: "calc(52%)" }}
      />

      <Form.Item
        label={phoneText}
        name={[field.name, "phoneNumber"]}
        rules={[
          {
            required: true,
            message: t("phoneNumber") + " " + t("isRequired"),
          },
        ]}
      >
        <Input
          onChange={(event) => {
            const { value } = event.target;
            const reg = /^-?\d*(\.\d*)?$/;

            let formValues = form.getFieldValue("buyers");

            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
              formValues[field.key].phoneNumber = value;

              form.setFieldsValue({
                buyers: formValues,
              });

              setPhoneText(value ? requiredField(t("phoneNo")) : defaultLabelText);
            } else {
              formValues[field.key].phoneNumber = "";

              form.setFieldsValue({
                buyers: formValues,
              });
            }
          }}
          addonBefore={prefixSelector}
          placeholder={requiredField(t("phoneNo"))}
        />
      </Form.Item>
      <div className="panel-footer">
        <Button onClick={() => addPanel(add)} type="primary">
          {t("newBuyerFormAddBuyer")}
        </Button>
        {panelHeaders.length > 1 && (
          <Button onClick={() => removePanel(field.name)} type="default">
            {t("newBuyerFormRemoveBuyer")}
          </Button>
        )}
      </div>
    </Fragment>
  );
};

export default NewBuyerForm;
