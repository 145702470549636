import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";

import { useTranslation } from "react-i18next";

import { defaultLabelText } from "./../../utils/formUtils";

const SmsConfirm = (props) => {
  const alertTimeout = 20;
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [labelText, setLabelText] = useState({
    codeFromSms: defaultLabelText,
  });

  const updateLabel = (name, value) => {
    setLabelText({ ...labelText, [name]: value ? t(name) : defaultLabelText });
  };

  const onFinish = async (values) => {
    const result = await props.sendSms(values);

    if (!result || result.error) {
      form.setFields([
        {
          name: "codeFromSms",
          errors: [t("2FAerror")],
        },
      ]);
    } else {
      props.login(result);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const resendSms = async () => {
    const result = await props.resendSms(form.getFieldValue("codeFromSms"));

    if (!result || result.error) {
      form.setFields([
        {
          name: "codeFromSms",
          errors: [t("2FAerror")],
        },
      ]);
    } else {
      notification["success"]({
        message: t("smsCodeSuccessMessage"),
        description: t("smsCodeSuccessMessage"),
        duration: alertTimeout,
      });
    }
  };

  return (
    <>
      <Form
        name="sms_login_confirm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="login-form"
        form={form}
      >
        <Form.Item
          label={labelText["codeFromSms"]}
          name="codeFromSms"
          rules={[
            { required: true, message: t("codeFromSms-empty-validation") },
          ]}
        >
          <Input.Password
            placeholder={t("codeFromSms")}
            onChange={(event) => updateLabel("codeFromSms", event.target.value)}
          />
        </Form.Item>

        <div className="align-right">
          <Button type="link" onClick={resendSms} className="login-form-forgot">
            {t("resendSms")}
          </Button>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default SmsConfirm;
