import settings from "../config/config";
import { getCmsAuth } from "./guardService";

export const getBanners = async (language) => {
  try {
    const response = await fetch(
      `${settings.backendApiUrl}/cms/banners?language=${language}`,
      getCmsAuth()
    );

    const bannersResponse = await response.json();
    return bannersResponse;
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getFaq = async (language) => {
  try {
    const response = await fetch(
      `${settings.backendApiUrl}/cms/faq?language=${language}`,
      getCmsAuth()
    );

    const faqResponse = await response.json();
    return faqResponse;
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
