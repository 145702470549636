import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Form } from "antd";

import Row from "components/shared/Row";
import Col from "components/shared/Col";
import Spin from "components/shared/Spin";
import Modal from "components/shared/Modal";

import { deleteBuyer } from "services/buyerService";

const DeleteBuyer = ({ modalVisible, closeModal, buyerDetails, contract, currentLimit }) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      width="48.125rem"
      className="delete-buyer modal grey medium"
      title={t("deleteBuyerTitle")}
      visible={modalVisible}
      okText={t("submit")}
      cancelText={t("cancel")}
      cancelButtonProps={{
        className: "button button--default button--large",
      }}
      okButtonProps={{
        className: "button button--primary button--large",
      }}
      onCancel={() => {
        closeModal();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(async ({ reason }) => {
            setLoading(true);
            const result = await deleteBuyer({
              contract_id: contract.id,
              explanation: reason,
              third_party_id: buyerDetails.party.id,
            });
            setLoading(false);
            result.error || result.code ? closeModal("error") : closeModal("success");
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Spin spinning={loading} tip={t("loading") + "..."}>
        <Form form={form}>
          <Row className="first-row-padding">
            <Col>
              <label>{t("deleteBuyerCompanyName")}</label>
              <p>{buyerDetails.party.name}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>{t("deleteBuyerCompanyCode")}</label>
              <p>{buyerDetails.party.registrationCode}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>{t("deleteBuyerCountry")}</label>
              <p>{buyerDetails.party.country}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>{t("deleteBuyerCurrentLimit")}</label>
              <p>{currentLimit}</p>
            </Col>
          </Row>
          <Row>
            <Col className="reason">
              <label>{t("deleteBuyerReason")}</label>
              <Form.Item name="reason">
                <TextArea placeholder={t("deleteBuyerTextAreaPlaceholder")} autoSize maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DeleteBuyer;
