import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Row, Col, Button } from "antd";
import { moneyFormatter, currencyCodeToSign } from "../../../utils/moneyUtils";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { QuestionMarkIcon } from 'components/icons/QuestionMarkIcon'

const UploadSummary = (props) => {
  const { t } = useTranslation();
  const language = i18n.language;

  const [uploadSummary, setUploadSummary] = useState({});
  const [expanded, setExpanded] = useState(true);
  const [showFeesMesg, setShowFeesMesg] = useState(false)

  const getFeeMesg = () => {
    if(language === "lt") return "Dėl techninių kliūčių, šiuo metu negalime parodyti palūkanų reikšmės.";

    return "Interest value cannot be shown due to technical issues.";
  }

  useEffect(() => {
    setUploadSummary(props.uploadSummary);
  }, [props.uploadSummary]);

  return (
    <>
      <h4>{t("summary")}</h4>

      <Row>
        <Col span="22">
          <div style={{ float: "left" }}>
            {t("invoiceUploadSummaryTotalAmount")},
            {currencyCodeToSign(uploadSummary?.currency)}
          </div>

          <div style={{ float: "right" }}>
            {moneyFormatter(uploadSummary.total, uploadSummary?.currency)}
          </div>
        </Col>
      </Row>

      <Row>
        <Col span="22">
          <div style={{ float: "left" }}>
            {t("invoiceUploadSummaryAdvance")},{" "}
            {currencyCodeToSign(uploadSummary?.currency)}
          </div>

          <div style={{ float: "right" }}>
            {moneyFormatter(uploadSummary.advance, uploadSummary?.currency)}
          </div>
        </Col>
      </Row>

      <Row>
        <Col span="22">
          <div style={{ float: "left" }} className="totalFees">
            {t("totalFees")}, {currencyCodeToSign(uploadSummary?.currency)}
            <Button onClick={() => setExpanded(!expanded)} type="link">
              {expanded ? <DownOutlined /> : <UpOutlined />}
            </Button>
          </div>

          <div style={{ float: "right" }}>
            {moneyFormatter(
              uploadSummary.totalFees?.total,
              uploadSummary?.currency
            )}
          </div>
        </Col>

        {expanded && (
          <Row className="totalFeesContainer">
            <Col className="totalFeesItem" span="14">
              {t("feesInterest")}
            </Col>
            <Col className="totalFeesItem feeItem" span="8">
              <div 
                onMouseEnter={() => setShowFeesMesg(true)}
                onMouseLeave={() => setShowFeesMesg(false)}
              >
                <QuestionMarkIcon color="#2b63f5" width="18px" height="18px"/>
              </div>
            </Col>
            {showFeesMesg && <Col className="totalFeesItemMesg" span="22">
              <span>{getFeeMesg()}</span>
            </Col>}
            <Col className="totalFeesItem" span="14">
              {t("feesCommissions")}
            </Col>
            <Col className="totalFeesItem feeItem" span="8">
              {uploadSummary.totalFees?.commission !== ""
                ? moneyFormatter(
                    uploadSummary.totalFees?.commission,
                    uploadSummary?.currency
                  )
                : "—"}
            </Col>
          </Row>
        )}
      </Row>

      <Row>
        <Col span="22">
          <div style={{ float: "left" }}>
            {t("invoiceUploadSummaryRemainder")},
            {currencyCodeToSign(uploadSummary?.currency)}
          </div>

          <div style={{ float: "right" }}>
            {moneyFormatter(uploadSummary.remainder, uploadSummary?.currency)}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UploadSummary;
