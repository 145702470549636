import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import cx from "classnames";

import { Form, Select } from "antd";

import IconArrow from "../../../assets/icons/icon_arrow.svg";
import { requiredField, defaultLabelText as emptyLabelText } from "utils/formUtils";

const { Option } = Select;

const CustomSelect = ({
  options,
  name,
  rules,
  label = " ",
  value,
  onChange = () => undefined,
  disabled = false,
  className,
  ...rest
}) => {
  const selectRef = useRef();

  const isRequired = !!rules?.filter((i) => !!i.required).length;
  const defaultLabelText = isRequired ? requiredField(label) : label;
  const [labelText, setLabelText] = useState();
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    // catch initial value on render
    setCurrentValue(selectRef.current.props.value);
    onChange(selectRef.current.props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // intercept value from parent
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (undefined !== currentValue) {
      setLabelText(defaultLabelText);
    } else {
      setLabelText(emptyLabelText);
    }
  }, [currentValue, defaultLabelText]);

  const onChangeWrapper = (value_) => {
    setCurrentValue(value_);
    onChange && onChange(value_);
  };

  return (
    <Form.Item
      label={labelText}
      name={name}
      rules={rules}
      className={cx(className, "custom-select", {
        "custom-select--active": undefined !== currentValue && !disabled,
      })}
    >
      <Select
        placeholder={defaultLabelText}
        suffixIcon={<img src={IconArrow} alt="arrow" />}
        value={currentValue}
        onChange={onChangeWrapper}
        disabled={disabled || !(options && options.length)}
        ref={selectRef}
        {...rest}
      >
        {options?.map((item) => (
          <Option key={item.key} value={item.value}>
            {item.title}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default CustomSelect;
