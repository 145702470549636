import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalListSelect from "components/shared/ModalListSelect";

const BankSelector = ({
  onFinish,
  preselectedBank = undefined,
  bankSelectionFn = async () => undefined,
  visitedBanks = [],
}) => {
  const { t } = useTranslation();
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    bankSelectionFn.then((response) => {
      if (response.ok) {
        setBankList(
          response.payload.map((item) => ({
            ...item,
            img: item.name,
            externalImg: item.logo,
          }))
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalListSelect
      options={bankList}
      defaultValue={preselectedBank}
      visited={visitedBanks}
      onFinish={onFinish}
      headingText={t("g4rBankSelectorChooseBank")}
      okText={t("g4rBankSelectorSelect")}
    />
  );
};

export default BankSelector;
