import React, { useEffect, useState } from "react";

import DokobitSubmitter from "./submitters/DokobitSubmitter";
import NordigenSubmitter from "./submitters/NordigenSubmitter";
import NordigenDokobitOptions from "./options/NordigenDokobitOptions";

const PrivateConfirmation = ({ toggle, formStartCb, formContinueCb, formExitCb, submitFn }) => {
  const [submitNordigen, setSubmitNordigen] = useState(false);
  const [submitDokobit, setSubmitDokobit] = useState(false);
  const [toggleWrap, setToggleWrap] = useState(toggle);

  const openSubmittor = (confirmationProcessorId) => {
    if (!confirmationProcessorId) {
      // cancelled
      return formContinueCb();
    }

    setToggleWrap(false);
    if ("nordigen" === confirmationProcessorId) {
      setSubmitNordigen(true);
    } else if ("dokobit" === confirmationProcessorId) {
      setSubmitDokobit(true);
    }
  };

  useEffect(() => {
    setToggleWrap(toggle);
  }, [toggle]);

  return (
    <>
      {toggleWrap && <NordigenDokobitOptions onFinish={openSubmittor} />}
      <NordigenSubmitter
        toggle={submitNordigen}
        formStartCb={(error) => {
          setSubmitNordigen(false);
          formStartCb(error);
        }}
        formContinueCb={(error) => {
          setSubmitNordigen(false);
          formContinueCb(error);
        }}
        formExitCb={(state) => {
          setSubmitNordigen(false);
          formExitCb(state);
        }}
        submitCallFn={submitFn}
      />
      <DokobitSubmitter
        toggle={submitDokobit}
        formContinueCb={(error) => {
          setSubmitDokobit(false);
          formContinueCb(error);
        }}
        formExitCb={(state) => {
          setSubmitDokobit(false);
          formExitCb(state);
        }}
        submitCallFn={submitFn}
      />
    </>
  );
};

export default PrivateConfirmation;
