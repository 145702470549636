import React, { useState } from "react";
import { Form, Input, Spin } from "antd";
import Button from "../shared/Button";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import FloatFormItem from "../shared/floatFormItem";

import { logoutReasons } from "../../utils/statusUtils";
import { productType, getActiveParty, getActiveProduct } from "utils/partiesUtils";
import { getCorrectLanguage } from "services/userService";

const SmsStart = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [loginIsActive, setLoginIsActive] = useState(true);
  const [loading, setLoading] = useState(false);

  const errorText = (errorCode) => {
    let message = "";

    switch (errorCode) {
      case "SME3":
        message = t("boServiceIsNotWorkingMessage");
        break;
      case "SME6":
        message = t("sessionExpiredMessage");
        break;
      case "SME12":
        message = t("noCompanyAssignedMessage");
        break;
      case "SME13":
        message = t("userDataIncorrectMessage");
        break;
      case "SME47":
        message = t("SME47");
        break;
      default:
        message = t("password-incorrect-validation");
    }

    return message;
  };

  const spinConfiguration = {
    tip: t("loading") + "...",
    spinning: loading,
  };

  if (props.logoutReason && props.logoutReason === logoutReasons.SessionExpired) {
    form.setFields([
      {
        name: "username",
        errors: [errorText("SME6")],
      },
    ]);
  }

  const onFinish = async (values) => {
    if (loginIsActive) {
      setLoading(true);
      const result = await props.login(values);
      setLoading(false);

      if (!result || result.error) {
        form.setFields([
          {
            name: "username",
            errors: [errorText(result.errorCode || "")],
          },
          {
            name: "password",
            errors: [""],
          },
        ]);
      } else {
        const userInfo = await props.getUser(result.token);

        result["user"] = {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          accountId: userInfo.id,
          language: getCorrectLanguage(result.token, userInfo.language),
          country: userInfo.country,
          phone: userInfo.phoneNumber,
          email: userInfo.email,
          termsConditionsAccepted: userInfo.termsConditionsAccepted,
        };

        const userParties = await props.getParties(result.token);
        result["parties"] = userParties;

        const activeParty = getActiveParty(userParties);
        const activeProduct = getActiveProduct(activeParty);

        // party and product fid are equals, only because it's BO workaround
        const boParty = await props.getBoParty(
          activeProduct?.type === productType.Factoring.toUpperCase() ? activeProduct?.fid : activeParty?.fid,
          result.token
        );

        result["assignedManager"] = boParty?.assignedManager;

        props.loginSuccess(result);
      }
    } else {
      setLoading(true);
      const result = await props.loginBySms(values);
      setLoading(false);

      if (!result || result.error) {
        form.setFields([
          {
            name: "username",
            errors: [errorText(result.errorCode || "")],
          },
        ]);
      } else {
        props.valuesSubmitted({ ...values.userName, ...result });
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Spin spinning={loading} tip={spinConfiguration.tip}>
        <Form name="sms_login" onFinish={onFinish} onFinishFailed={onFinishFailed} className="login-form" form={form}>
          <FloatFormItem
            className="input-text"
            type={Input}
            label={t("username")}
            name="username"
            rules={[{ required: true, message: t("username-empty-validation") }]}
          />

          <FloatFormItem
            className="minimal-bmargin input-text"
            type={Input.Password}
            label={t("password")}
            name="password"
            rules={[{ required: true, message: t("password-empty-validation") }]}
          />

          <div className="align-right">
            <Link className="login-form-forgot" to="/resetPassword">
              {t("remindPassword")}
            </Link>
          </div>
          <Form.Item className="align-right">
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setLoginIsActive(true);
              }}
              htmlType="submit"
              className="login-form-button"
            >
              {t("login")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default SmsStart;
