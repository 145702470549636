import React from "react";
import { useTranslation } from "react-i18next";

import ButtonLink from "components/shared/ButtonLink";

import { moneyFormatter } from "utils/moneyUtils";

const FeeList = (props) => {
  const { t } = useTranslation();

  const onClick = async (id) => {
    await props.downloadPdfFile(id);
  };

  return (
    <>
      <div className="block TransactionList FeesList">
        {((!props.showTotals && props.list?.length) || props.showTotals) && (
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>{props.title}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.list &&
                props.list.map((item, i) => (
                  <tr key={i}>
                    <td>{item.calculationDate}</td>
                    <td className={"textAlignRight smallCol"}>
                      {moneyFormatter(item.calculatedInvoiceAmount.amount, item.calculatedInvoiceAmount.currency)}
                    </td>
                    <td className={"textAlignRight linkButton"}>
                      <ButtonLink onClick={() => onClick(item.id)} type="link"></ButtonLink>
                    </td>
                  </tr>
                ))}
            </tbody>
            {props.showTotals && (
              <tfoot>
                {!!props.list?.length && (
                  <tr>
                    <td className={"textAlignRight"}>
                      <label>{t("invoiceDetailsFeesTotal")}</label>
                    </td>
                    <td className={"textAlignRight"}>
                      {props.totals && moneyFormatter(props.totals.amount, props.totals.currency)}
                    </td>
                    <td></td>
                  </tr>
                )}
                {!props.list?.length && props?.totals?.amount === 0 && (
                  <tr className="empty">
                    <td colSpan="3">—</td>
                  </tr>
                )}
              </tfoot>
            )}
          </table>
        )}
      </div>
    </>
  );
};

export default FeeList;
