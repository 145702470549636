import { getAuthHeader } from "./guardService";

import settings from "../config/config";

const getLoanListApiUrl = settings.backendApiUrl + "/hw/loans";
const getLoanApiUrl = settings.backendApiUrl + "/hw/loan";
const getLoanTotalsApiUrl = settings.backendApiUrl + "/hw/loan/totals";
const getSchedulesListApiUrl = settings.backendApiUrl + "/hw/schedules";
const getSchedulesPdfFileApiUrl = settings.backendApiUrl + "/hw/schedules/pdf";
const getUpcomingPaymentsApiUrl = settings.backendApiUrl + "/hw/upcoming-payments";
const getLoanTotalsMultipleContractsApiUrl = settings.backendApiUrl + "/general-dashboard/loan/totals-multiple-contracts";
const getUpcomingPaymentsForMultipleContractsUrl = settings.backendApiUrl + "/general-dashboard/upcoming-payments-multiple-contracts";
const getBillsListApiUrl = settings.backendApiUrl + "/hw/bills";
const getBillPdfFileApiUrl = settings.backendApiUrl + "/hw/invoices/pdf";

export const getLoanList = async (productFid, signal) => {
  try {
    let url = new URL(getLoanListApiUrl);
    url.search = new URLSearchParams({
      customerCode: productFid,
    });

    const response = await fetch(url, {
      ...getAuthHeader(),
      signal,
    });

    const list = await response.json();

    return list;
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getLoanTotalsForMultipleContracts = async (loansCustomerCode) => {
  try {
    const url = new URL(getLoanTotalsMultipleContractsApiUrl);

    url.search = new URLSearchParams({
      loansCustomerCode: loansCustomerCode
    });
    const response = await fetch(url, {
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
    });
    const data = await response.json();

    return data;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getUpcomingPaymentsForMultipleContracts = async (customerCode, partyId, limit, sort) => {
 
  try {
    let sortItem, sortType;
     if(sort){
     [sortItem,sortType] = sort.split(",")
  }
    const url = new URL(getUpcomingPaymentsForMultipleContractsUrl);
    url.search = new URLSearchParams({
      partyId: partyId ? partyId : "",
      customerCode: customerCode ? customerCode : "",
      limit: limit,
      sortItem: sortItem ? sortItem : "",
      sortType: sortType ? sortType : ""
      
    });
    const response = await fetch(url, {
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
    });
    const data = await response.json();

    return data;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getLoan = async (productFid, contractId) => {
  try {
    let url = new URL(getLoanApiUrl);
    url.search = new URLSearchParams({
      companyNumber: productFid,
      contractNumber: contractId,
    });
    const response = await fetch(url, getAuthHeader());

    const item = await response.json();

    return item;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getLoanTotals = async (customerCode, companyId, contractId) => {
  try {
    let url = new URL(getLoanTotalsApiUrl);
    url.search = new URLSearchParams({
      customerCode: customerCode,
      companyNumber: companyId,
      contractNumber: contractId,
    });
    const response = await fetch(url, getAuthHeader());

    const item = await response.json();

    return item;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getSchedulesPdfFile = async (productFid, contractId) => {
  try {
    let url = new URL(getSchedulesPdfFileApiUrl);
    url.search = new URLSearchParams({
      companyNumber: productFid,
      contractNumber: contractId,
    });
    const response = await fetch(url, getAuthHeader());

    const blob = await response.blob();

    if (blob.size) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.setAttribute("download", `${contractId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getSchedulesList = async (companyNumber, contractId, historyOnly, page, pageSize, sort, sortType) => {
  try {
    let url = new URL(getSchedulesListApiUrl);
    url.search = new URLSearchParams({
      companyNumber: companyNumber,
      contractNumber: contractId,
      historyOnly: historyOnly,
      page,
      size: pageSize,
      sort: sort ?? "",
      sortType: sortType ?? "",
    });

    const response = await fetch(url, getAuthHeader());

    const list = await response.json();

    return list;
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getUpcomingPayments = async (productFid, contractId, limit, sort) => {
  try {
    let sortItem, sortType;
    if (sort) {
      [sortItem, sortType] = sort.split(",");
    }

    let url = new URL(getUpcomingPaymentsApiUrl);
    url.search = new URLSearchParams({
      companyNumber: productFid,
      contractNumber: contractId,
      limit: limit,
      sort: sortItem ? sortItem : "",
      sortType: sortType ? sortType : ""
    });

    const response = await fetch(url, getAuthHeader());

    const list = await response.json();

    return list;
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getBillsList = async (loanNumbers, page, size, filter, sort) => {
  try {
    let url = new URL(getBillsListApiUrl);
    let sortItem, sortType;

    if (sort) {
      [sortItem, sortType] = sort.split(",");
    }

    url.search = new URLSearchParams({
      "contract.companyNumber": loanNumbers[0].companyId,
      "contract.contractNumber": loanNumbers[0].id,
      page: page,
      size: size,
      dateFrom: filter?.dateFrom || "",
      dateTo: filter?.dateTo || "",
      invoiceNumber: filter?.invoiceNumber || "",
      billType: filter?.billType || "",
      sort: sortItem ?? "",
      sortType: sortType ? sortType.toUpperCase() : "",
      status: filter?.status ?? "" 
    });

    const response = await fetch(url, getAuthHeader());

    const list = await response.json();

    return list;
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const geBillPdfFile = async (productFid, billId) => {
  try {
    let url = new URL(getBillPdfFileApiUrl);
    url.search = new URLSearchParams({
      companyNumber: productFid,
      contractNumber: billId,
    });
    const response = await fetch(url, getAuthHeader());

    const blob = await response.blob();

    if (blob.size) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.setAttribute("download", `${billId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      return { isEmpty: true };
    }
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
