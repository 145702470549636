import { getAuthHeader } from "./guardService";

import settings from "../config/config";

const getContractApiUrl = settings.backendApiUrl + "/contracts";
const getInvoiceBuyersListApiUrl = settings.backendApiUrl + "/contracts/third-parties";

const getContractLimitHistoryApiUrl =
  settings.backendApiUrl + "/contracts/{contractId}/credit-limit-history?periodicity={periodicity}";

const postIncreaseLimitApiUrl = settings.backendApiUrl + "/notification/email/contract/increase-limit";
const postProlongApiUrl = settings.backendApiUrl + "/notification/email/contract/prolong";
const postAddBuyerApiUrl = settings.backendApiUrl + "/notification/email/contract/add-buyer";

const increaseLimitFileUploadApiUrl = settings.backendApiUrl + "/contract/increase-limit/attachment-upload";
const prolongFileUploadApiUrl = settings.backendApiUrl + "/contract/prolong/attachment-upload";
const deleteIncreaseLimitUploadedFileApiUrl = settings.backendApiUrl + "/contract/increase-limit/attachment-upload";
const deleteProlongUploadedFileApiUrl = settings.backendApiUrl + "/contract/prolong/attachment-upload";
const getUploadedFilesApiUrl = settings.backendApiUrl + "/files";

const getContractFilesUrl = settings.backendApiUrl + "/contract-documents";
const getContractFileUrl = settings.backendApiUrl + "/contract-documents";
const getContractDocumentSigningUrl = settings.backendApiUrl + "/contract/document/signing-url";

export const getContractList = async (productFid, signal) => {
  try {
    const status = settings.includedContractStatuses
      .split(",")
      .map((i) => "status=" + i)
      .join("&");

    let url = new URL(getContractApiUrl);
    url.search = new URLSearchParams({
      partyId: productFid,
    });

    const response = await fetch(url + "&" + status, {
      ...getAuthHeader(),
      signal,
    });

    const constractsList = await response.json();

    return constractsList;
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getContractCreditLimitHistory = async (contractId, periodicity) => {
  let url = getContractLimitHistoryApiUrl.replace("{contractId}", contractId).replace("{periodicity}", periodicity);
  try {
    const response = await fetch(`${url}`, getAuthHeader());

    const contract = await response.json();

    return contract;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getContract = async (contractId) => {
  try {
    const response = await fetch(`${getContractApiUrl}/${contractId}`, getAuthHeader());

    const contract = await response.json();

    return contract;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getContractBuyersList = async (contractId) => {
  try {
    let url = new URL(getInvoiceBuyersListApiUrl);
    url.search = new URLSearchParams({
      contractIds: contractId.join(","),
    });

    const response = await fetch(url, getAuthHeader());

    const invoiceBuyersList = await response.json();
    return invoiceBuyersList;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getIncreaseLimitFileUploadData = (contractId) => {
  return {
    action: increaseLimitFileUploadApiUrl + (contractId ? "?contractId=" + contractId : ""),
    ...getAuthHeader(),
  };
};

export const getProlongFileUploadData = (contractId) => {
  return {
    action: prolongFileUploadApiUrl + (contractId ? "?contractId=" + contractId : ""),
    ...getAuthHeader(),
  };
};

export const getUploadedFiles = async (contractId) => {
  try {
    let url = new URL(getUploadedFilesApiUrl);
    url.search = new URLSearchParams({
      contractId: contractId,
    });

    url.searchParams.append("fileTypes", "CONTRACT_INCREASE_LIMIT_ATTACHMENT");
    url.searchParams.append("fileTypes", "CONTRACT_PROLONG_ATTACHMENT");

    const response = await fetch(`${url}`, getAuthHeader());

    const list = await response.json();

    return list;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const deleteIncreaseLimitUploadedFile = async (uuids) => {
  try {
    const url = new URL(deleteIncreaseLimitUploadedFileApiUrl);
    url.search = new URLSearchParams({
      uuids: uuids,
    });

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        ...getAuthHeader().headers,
      },
    });

    const invoice = await response.text();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const deleteProlongUploadedFile = async (uuids) => {
  try {
    const url = new URL(deleteProlongUploadedFileApiUrl);
    url.search = new URLSearchParams({
      uuids: uuids,
    });

    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        ...getAuthHeader().headers,
      },
    });

    const invoice = await response.text();

    return invoice;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postIncreaseLimit = async (data) => {
  try {
    const url = new URL(postIncreaseLimitApiUrl);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok && response.status !== 400) {
      throw Error(response.statusText);
    }

    const responseData = await response.text();
    return responseData;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postProlong = async (data) => {
  try {
    const url = new URL(postProlongApiUrl);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok && response.status !== 400) {
      throw Error(response.statusText);
    }

    const responseData = await response.text();
    return responseData;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const postAddBuyer = async (contractId, data) => {
  try {
    const url = new URL(postAddBuyerApiUrl);
    url.search = new URLSearchParams({
      contractId: contractId,
    });

    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...{
          "Content-Type": "application/json",
        },
        ...getAuthHeader().headers,
      },
      body: JSON.stringify(data),
    });

    console.log(response)
    if (!response.ok && response.status !== 400) {
      throw Error(response.statusText);
    }

    return response.ok;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getContractFiles = async (contractId, contractVersion) => {
  try {
    let url = new URL(getContractFilesUrl);
    url.search = new URLSearchParams({
      contractId: contractId,
      contractVersion: contractVersion,
    });

    const response = await fetch(url, {
      ...getAuthHeader(),
    });

    const constractsList = await response.json();

    return constractsList;
  } catch (e) {
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getContractFile = async (fileId) => {
  try {
    const response = await fetch(`${getContractFileUrl}/${fileId}`, getAuthHeader());

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileId}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};

export const getContractSigningData = async (contractId, documentId) => {
  try {
    let url = new URL(getContractDocumentSigningUrl);
    url.search = new URLSearchParams({
      contractId: contractId,
      documentId: documentId,
    });

    const response = await fetch(url, getAuthHeader());

    return response;
  } catch (e) {
    console.log(e);
    return { error: true, code: "SYSTEM_ERROR", message: e };
  }
};
