import React, { useEffect, useState } from "react";
import i18next from "i18next";

import { APPLICANT_TYPES, CONTRACT_TYPES } from "services/formService";
import { getLegalTexts } from "services/legalService";
import AdditionalInformationBlock from "../formBlocks/AdditionalInformationBlock";

import BusinessCustomerInformationBlock from "../formBlocks/BusinessCustomerInformationBlock";
import DataProcessingConsentBlock from "../formBlocks/DataProcessingConsentBlock";
import FinancialStateBlock from "../formBlocks/FinancialStateBlock";
import MarketingConsentBlock from "../formBlocks/MarketingConsentBlock";
import PrivateCustomerInformationBlock from "../formBlocks/PrivateCustomerInformationBlock";
import RelatedApplicantBlock from "../formBlocks/RelatedApplicantBlock";
import SubmitButton from "../formElements/SubmitButton";
import AssetInformationDisplayBlock from "components/forms/formBlocks/AssetInformationDisplayBlock";
import HiddenInput from "components/shared/form/HiddenInput";
import FileUploadBlock from "../formBlocks/FileUploadBlock";

const LeasingCoApplicantForm = ({ formInstance, formMode, formModeLoading = false, productType }) => {
  const language = i18next.language;
  const [legalTexts, setLegalTexts] = useState({});
  const [consent, setConsent] = useState();

  useEffect(() => {
    (async () => {
      const legalData = await getLegalTexts(language);
      if (!legalData) {
        return;
      }

      const fetchLegalItem = (title) => {
        return legalData.find((item) => item.title === title)?.body?.[0]?.value;
      };

      setLegalTexts({
        propertyValue: fetchLegalItem("LeasingForm Info Property value"),
        propertyInsurance: fetchLegalItem("LeasingForm Info Property Insurance"),
        confirmation: fetchLegalItem("LeasingForm Confirmation"),
        consentMarketing: fetchLegalItem("LeasingForm Consent Marketing"),
        politicallyExposed: fetchLegalItem("LeasingForm Info politically exposed"),
        marketingConsent: fetchLegalItem("LeasingForm Marketing Consent"),
        incomeInfo: APPLICANT_TYPES.isSpouse(formMode) ? fetchLegalItem("LeasingForm Spouse Income") : undefined,
        commitmentInfo: APPLICANT_TYPES.isSpouse(formMode)
          ? fetchLegalItem("LeasingForm Spouse Commitments")
          : undefined,
        monthlyPaymentInfo: fetchLegalItem("LeasingForm Info Monthly Payment"),
        marketingConsentDetailed: fetchLegalItem("LeasingForm Marketing Consent Detailed Private"),
      });
    })();
  }, [language, formMode]);

  return (
    <>
      {CONTRACT_TYPES.isPrivate(formInstance.getFieldValue(["applicant", "type"])) ? (
        <>
          <PrivateCustomerInformationBlock formInstance={formInstance} asRelatedApplicant />
          {APPLICANT_TYPES.isSpouse(formMode) && (
            <FinancialStateBlock
              formInstance={formInstance}
              incomeTooltip={legalTexts.incomeInfo}
              liabilitiesTooltip={legalTexts.commitmentInfo}
            />
          )}
        </>
      ) : (
        <BusinessCustomerInformationBlock formInstance={formInstance} asRelatedApplicant />
      )}
      <RelatedApplicantBlock formInstance={formInstance} />
      <HiddenInput name={["meta", "application_token"]} />
      <HiddenInput name={["meta", "applicant_token"]} />

      <AssetInformationDisplayBlock formInstance={formInstance} productType={productType} />
      <AdditionalInformationBlock />

      <FileUploadBlock
        readOnly={false}
        formInstance={formInstance}
        productType={`${formInstance.getFieldValue(["lending", "type"])}_${formInstance.getFieldValue(["applicant","type"])}`}
      />

      <DataProcessingConsentBlock consent={consent} onConsentChange={setConsent} innerHtml={legalTexts.confirmation} />
      <MarketingConsentBlock innerHtml={legalTexts.marketingConsent} infoHtml={legalTexts.marketingConsentDetailed} />
      <SubmitButton enabled={consent} loading={formModeLoading} />
    </>
  );
};

export default LeasingCoApplicantForm;
