import PropTypes from "prop-types";

const contractStatuses = ["ACTIVE", "MANUALLY_SUSPENDED", "EXPIRED", "READY_FOR_SIGNING"];

const documentStatuses = ["SIGNED", "UNSIGNED"];

export const contractListType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    administrationLevel: PropTypes.string,
    availableFactoringCreditLineAmount: PropTypes.number,
    contractNumber: PropTypes.string,
    contractVersion: PropTypes.number,
    factorName: PropTypes.string,
    factoringCreditLimit: PropTypes.number,
    factoringProduct: PropTypes.string,
    financingCurrencyCode: PropTypes.string,
    status: PropTypes.oneOf(contractStatuses),
    usedFactoringCreditLineAmount: PropTypes.number,
  })
);

export const contractDocumentsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    contractId: PropTypes.string,
    contractNumber: PropTypes.string,
    contractVersion: PropTypes.number,
    fileName: PropTypes.string,
    languageCode: PropTypes.string,
    status: PropTypes.oneOf(documentStatuses),
    type: PropTypes.string,
    signers: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      })
    ),
  })
);
