import { productType } from "utils/partiesUtils";
import { billPaymentTypes } from "utils/statusUtils";
import { APPLICANT_TYPES } from "services/formService";
export const getProductTitle = (_productType) => {
  switch (_productType.toLowerCase()) {
    case productType.Factoring:
      return "menuFactoring";
    case productType.Loan:
      return "menuLoans";
    case productType.Limit:
      return "menuLimitProducts";
    case productType.Leasing:
      return "menuLeasing";
    case "general":
      return "menuMain";
    default:
      return "";
  }
};

export const getTotalAmountTitle = (_productType) => {
  switch (_productType.toLowerCase()) {
    case productType.Factoring:
      return "totalAmountFactoring";
    case productType.Loan:
      return "totalAmountLoans";
    case productType.Limit:
      return "totalAmountLimitProducts";
    case productType.Leasing:
      return "totalAmountLeasing";
    case "general":
      return "totalAmountMain";
    default:
      return "";
  }
};

export const getAccountUserPermissionsTitle = (_productType) => {
  switch (_productType.toLowerCase()) {
    case productType.Factoring:
      return "accountUserPermissions";
    case productType.Loan:
      return "accountUserPermissionsLoans";
    case productType.Limit:
      return "accountUserPermissionsLimits";
    case productType.Leasing:
      return "accountUserPermissionsLeasing";
    default:
      throw Error("Invalid product type");
  }
};

export const getCompanyOfficialsTitle = (_productType) => {
  switch (_productType.toLowerCase()) {
    case productType.Factoring:
      return "companyOfficials";
    case productType.Loan:
      return "companyOfficialsLoans";
    case productType.Limit:
      return "companyOfficialsLimits";
    case productType.Leasing:
      return "companyOfficialsLeasing";
    default:
      throw Error("Invalid product type");
  }
};

export const getEditUserCurrentRolesText = (_productType) => {
  switch (_productType.toLowerCase()) {
    case productType.Factoring:
      return "editUserCurrentRolesText";
    case productType.Loan:
      return "editUserCurrentRolesTextLoans";
    case productType.Limit:
      return "editUserCurrentRolesTextLimits";
    case productType.Leasing:
      return "editUserCurrentRolesTextLeasing";
    default:
      throw Error("Invalid product type");
  }
};

export const getEditUserNewRolesText = (_productType) => {
  switch (_productType.toLowerCase()) {
    case productType.Factoring:
      return "editUserNewRolesText";
    case productType.Loan:
      return "editUserNewRolesTextLoans";
    case productType.Limit:
      return "editUserNewRolesTextLimits";
    case productType.Leasing:
      return "editUserNewRolesTextLeasing";
    default:
      throw Error("Invalid product type");
  }
};

export const getLoanBillPaymentType = (currentPaymentType) => {
  switch (currentPaymentType) {
    case billPaymentTypes.Payment:
      return "billPaymentTypePayment";
    case billPaymentTypes.Prepayment:
      return "billPaymentTypePrepayment";
    case billPaymentTypes.CreditNote:
      return "billPaymentTypeCreditNote";
    default:
      throw Error("Invalid bill payment type");
  }
};

export const getFormSuccessStateMessage = (applicant) => {
  switch (applicant) {
    case APPLICANT_TYPES.DEFAULT:
      return "formSuccessState";
    case APPLICANT_TYPES.SPOUSE:
      return "formSuccessStatesSpouse";
    case APPLICANT_TYPES.GUARANTOR:
      return "formSuccessStateGuarantor";
    default:
      return "formSuccessState";
  }
};
